import { AsideDefault } from 'layout/aside/AsideDefault'
import { AsideMobile } from 'layout/aside/AsideMobile'
import { ClientParamsPage } from 'pages/client/parameters/ClientParametersPage'
import { BinnacleEventPage } from 'pages/service/events/BinnacleEventPage'
import { ExceptionManagementPage } from 'pages/service/exception/ExceptionManagementPage'
import { ContractedServicesPage } from 'pages/service/management/ContractedServicesPage'
import { PetitionTrackingPage } from 'pages/service/tracking/PetitionTrackingPage'
import { FC } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { AuthRoute, MasterLayout, WelcomePage } from 'ufinet-web-components'
import { Authority, UserTypesEnum, useTranslator } from 'ufinet-web-functions'

export const PATH_HOME = ''

export const PATH_SERVICES = 'services'
export const PATH_CONFIGURATION = 'configuration'

export const PATH_SERVICES_MANAGEMENT = 'management'
export const PATH_SERVICES_TRACKING = 'constructions'
export const PATH_SERVICES_EXCEPTION = 'exception'

export const PATH_CLIENT_PARAMETERS = 'client_parameters'

export const PATH_EVENTS = 'events'

export const PATH_SERVICES_SERVICES_MANAGEMENT = `${PATH_SERVICES}/${PATH_SERVICES_MANAGEMENT}`
export const PATH_SERVICES_SERVICES_TRACKING = `${PATH_SERVICES}/${PATH_SERVICES_TRACKING}`
export const PATH_SERVICES_SERVICES_EXCEPTION = `${PATH_SERVICES}/${PATH_SERVICES_EXCEPTION}`
export const PATH_CONFIG_CLIENT_PARAMETERS = `${PATH_CONFIGURATION}/${PATH_CLIENT_PARAMETERS}`
export const PATH_SERVICES_EVENTS = `${PATH_SERVICES}/${PATH_SERVICES_TRACKING}/:serviceId/${PATH_EVENTS}`

export const routerNameMap = new Map<string, string>([
	[PATH_HOME, 'PATH_HOME'],
	[PATH_SERVICES, 'MENU.SERVICES'],
	[PATH_CONFIGURATION, 'MENU.CONFIGURATION'],
	[PATH_SERVICES_MANAGEMENT, 'MENU.SERVICE_MANAGEMENT'],
	[PATH_SERVICES_TRACKING, 'MENU.SERVICE_TRACKING'],
	[PATH_SERVICES_EXCEPTION, 'MENU.EXCEPTION_MANAGEMENT'],
	[PATH_CLIENT_PARAMETERS, 'MENU.CLIENT_PARAMETERS'],
	[PATH_EVENTS, 'MENU.EVENTS'],
])

export const routePermissionMap = new Map<string, Authority[]>([
	[PATH_SERVICES_SERVICES_MANAGEMENT, [Authority.servicesModuleRead]],
	[PATH_SERVICES_SERVICES_TRACKING, [Authority.servicesModuleRead]],
	[PATH_SERVICES_SERVICES_EXCEPTION, [Authority.gseExceptionModuleRead]],
	[PATH_CONFIG_CLIENT_PARAMETERS, [Authority.gseConfigurationModuleWrite]],
	[PATH_SERVICES_EVENTS, [Authority.servicesModuleRead]],
])

const PrivateRoutes: FC = () => {
	const translate = useTranslator()
	const location = useLocation()

	return (
		<Routes>
			<Route
				element={
					<MasterLayout
						routerLocation={location}
						asideDefault={<AsideDefault />}
						routerNameMap={routerNameMap}
						asideMobile={<AsideMobile />}
					/>
				}
			>
				<Route
					path={PATH_HOME}
					element={<WelcomePage appName={translate('GDS.NAME')} />}
				/>
				<Route
					path={PATH_SERVICES_SERVICES_MANAGEMENT}
					element={
						<AuthRoute authorizedRoles={routePermissionMap.get(PATH_SERVICES_SERVICES_MANAGEMENT)!}>
							<ContractedServicesPage />
						</AuthRoute>
					}
				/>

				<Route
					path={PATH_SERVICES_SERVICES_TRACKING}
					element={
						<AuthRoute authorizedRoles={routePermissionMap.get(PATH_SERVICES_SERVICES_TRACKING)!}>
							<PetitionTrackingPage />
						</AuthRoute>
					}
				/>
				<Route
					path={PATH_SERVICES_SERVICES_EXCEPTION}
					element={
						<AuthRoute authorizedRoles={routePermissionMap.get(PATH_SERVICES_SERVICES_EXCEPTION)!}>
							<ExceptionManagementPage />
						</AuthRoute>
					}
				/>
				<Route
					path={PATH_CONFIG_CLIENT_PARAMETERS}
					element={
						<AuthRoute
							authorizedUsers={[UserTypesEnum.INTERNAL_USER]}
							authorizedRoles={routePermissionMap.get(PATH_CONFIG_CLIENT_PARAMETERS)!}
						>
							<ClientParamsPage />
						</AuthRoute>
					}
				/>
				<Route
					path={PATH_SERVICES_EVENTS}
					element={
						<AuthRoute authorizedRoles={routePermissionMap.get(PATH_SERVICES_EVENTS)!}>
							<BinnacleEventPage />
						</AuthRoute>
					}
				/>
				<Route
					path="*"
					element={
						<Navigate
							to={PATH_HOME}
							replace
						/>
					}
				/>
			</Route>
		</Routes>
	)
}

export { PrivateRoutes }
