import {
	ContractedServiceOperation,
	ContractedServiceOperationData,
} from 'modules/contractedService/domain/management/operation/ContractedServiceOperation'
import { ContractType } from 'modules/contractedService/domain/management/operation/ContractType'

export class ContractedServiceOperationImmediate
	extends ContractedServiceOperation
	implements ContractedServiceOperationDataImmediate
{
	fromOther(input: ContractedServiceOperationDataImmediate) {
		return new ContractedServiceOperationImmediate(input)
	}

	constructor(
		contractedServiceOperationDataImmediate: Omit<
			ContractedServiceOperationDataImmediate,
			'contractType' | 'awardsImmediately'
		>
	) {
		super({
			...contractedServiceOperationDataImmediate,
			contractType: ContractType.IMMEDIATE,
			awardsImmediately: true,
		})
	}
}

export type ContractedServiceOperationDataImmediate = ContractedServiceOperationData
