import { FC, useCallback } from 'react'

import { useTranslator } from 'ufinet-web-functions'
import styles from './ConfirmResumeTitle.module.scss'

type Props = {
	title: string
	translateTitle?: boolean
}
const ConfirmResumeTitle: FC<Props> = ({ title, translateTitle = true }) => {
	const translate = useTranslator()

	const getTitle = useCallback(
		(title: string) => (translateTitle ? translate(title) : title),
		[translate, translateTitle]
	)

	return <h3 className={styles.ConfirmResumeTitle__title}>{getTitle(title)}</h3>
}
export { ConfirmResumeTitle }
