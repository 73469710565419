import { defaultOptionsFetch, FetchAPI, FetchOptions, IAuthData } from 'ufinet-web-functions'
import { BandwidthRepository } from '../domain/repository/bandwidthRepository'

const url = `${process.env.REACT_APP_URL_BACK_GSE}api/bandwidth`

export const HttpBandwidthRepository = (authData: IAuthData): BandwidthRepository => {
	const fetchOptions: FetchOptions = {
		...defaultOptionsFetch,
		authenticationData: authData,
		sendApiKey: true,
	}

	return {
		findForService: async (bandwidthFindForServiceRequest, options) =>
			await FetchAPI.get_params(`${url}/contracted-service`, bandwidthFindForServiceRequest, {
				...fetchOptions,
				...options,
			}),

		findForImmediateUpgrade: async (bandwidthRequest, options) =>
			await FetchAPI.post(`${url}/immediate-upgrade`, bandwidthRequest, { ...fetchOptions, ...options }),
		findForScheduledUpgrade: async (bandwidthRequest, options) =>
			await FetchAPI.post(`${url}/scheduled-upgrade`, bandwidthRequest, { ...fetchOptions, ...options }),
		findForTemporaryUpgrade: async (bandwidthRequest, options) =>
			await FetchAPI.post(`${url}/temporary-upgrade`, bandwidthRequest, { ...fetchOptions, ...options }),

		findForImmediateDowngrade: async (bandwidthRequest, options) =>
			await FetchAPI.post(`${url}/immediate-downgrade`, bandwidthRequest, { ...fetchOptions, ...options }),
	}
}
