import { useContractedServiceVersionsFindQuery } from 'modules/contractedService/application/find/version/ContractedServiceVersionsFindQueries'
import { ContractedService } from 'modules/contractedService/domain/ContractedService'
import { ContractedServiceRepository } from 'modules/contractedService/domain/repository/ContractedServiceRepository'
import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useTranslator } from 'ufinet-web-functions'

type HookInput = {
	serviceSelected: ContractedService
	serviceRepository: ContractedServiceRepository
	onServiceVersionSelected?: (service: ContractedService) => void
	modalShown: boolean
	modalCloseFn: () => void
}
type HookOutput = {
	serviceVersions: {
		data: ContractedService[]
		selected: ContractedService | undefined
		loading: boolean
		onVersionSelected: (service: ContractedService) => void
	}
}

function useContractedServiceVersionsModal({
	serviceSelected,
	serviceRepository,
	onServiceVersionSelected,
	modalShown,
	modalCloseFn,
}: HookInput): HookOutput {
	const translate = useTranslator()

	const [serviceVersionSelected, setServiceVersionSelected] = useState<ContractedService>()

	const {
		data: serviceVersions,
		isError: isErrorFindingServiceVersions,
		isLoading: loadingServiceVersions,
	} = useContractedServiceVersionsFindQuery(
		serviceRepository,
		{
			serviceId: serviceSelected.id,
		},
		{ enabled: modalShown }
	)

	useEffect(() => {
		if (!isErrorFindingServiceVersions) return
		toast.error(translate('CONTRACT.SERVICE.VERSIONS.FIND.ERROR'))
		modalCloseFn()
	}, [isErrorFindingServiceVersions, modalCloseFn, translate])

	const onVersionSelected = useCallback(
		(service: ContractedService) => {
			setServiceVersionSelected(service)
			onServiceVersionSelected?.(service)
		},
		[onServiceVersionSelected]
	)

	return {
		serviceVersions: {
			data: serviceVersions || [],
			loading: loadingServiceVersions,
			selected: serviceVersionSelected,
			onVersionSelected,
		},
	}
}

export { useContractedServiceVersionsModal }
