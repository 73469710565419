import { useClientTypesFindAllQuery } from 'modules/client/type/application/find/ClientTypeFindQueries'
import { HttpClientTypeRepository } from 'modules/client/type/infrastructure/HttpClientTypeRepository'
import { forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { toast } from 'react-toastify'
import {
	emptyUfinetSelectOption,
	ISelectBaseInterface,
	IUfinetSelectOption,
	UfinetSelect,
	UfinetSelectHandle,
	UfinetSelectOptionValue,
} from 'ufinet-web-components'
import { AuthContext, ClientType, ClientTypeResponse } from 'ufinet-web-functions'

export type ClientTypeSelectHandle = UfinetSelectHandle & {}

export type ClientTypeSelectOption = IUfinetSelectOption & { name: string }
export const emptyClientTypeSelectOption: ClientTypeSelectOption = { ...emptyUfinetSelectOption, name: '' }

type Props = ISelectBaseInterface & {
	preSelectedOption?: ClientType
	allowedOptions?: ClientType[]
	notifyError?: boolean
}

const ClientTypeSelect = forwardRef<ClientTypeSelectHandle, Props>(
	(
		{
			requiredIcon = false,
			className,
			onChange = () => {},
			value,
			error = undefined,
			isMulti,
			isDisabled,
			withoutFetch,
			defaultValue,
			preSelectedOption,
			allowedOptions,
			labelTitle,
			labelStyles,
			tooltipTitle,
			notifyError = false,
			...props
		},
		ref
	) => {
		const selectRef = useRef<UfinetSelectHandle>(null)

		const intl = useIntl()
		const authData = useContext(AuthContext)

		const clientTypeRepository = useMemo(() => HttpClientTypeRepository(authData), [authData])

		const [selectedOption, setSelectedOption] = useState<UfinetSelectOptionValue | undefined>(value)
		const [allOptions, setAllOptions] = useState<ClientTypeSelectOption[]>([])

		useEffect(() => {
			setSelectedOption(value)
		}, [value])

		const mkOptionFromClientType = useCallback<(ct: ClientTypeResponse) => ClientTypeSelectOption>(
			(ct) => ({
				label: intl.formatMessage({ id: `CLIENT.PARAMETERS.CLIENT.TYPE.${ct.name}` }),
				value: ct.value,
				name: ct.name,
			}),
			[intl]
		)

		const onClientTypesFetched = (response: ClientTypeResponse[]): void => {
			const allowedTypes = allowedOptions ? response.filter((type) => allowedOptions.includes(type.name)) : response
			const formattedOptions = allowedTypes.map(mkOptionFromClientType)
			!withoutFetch && setAllOptions(formattedOptions)

			const preSelectedType = preSelectedOption && formattedOptions.find((opt) => opt.name === preSelectedOption)

			if (preSelectedType) {
				setSelectedOption(preSelectedType)
				onChange(preSelectedType)
			}
		}

		const onClientTypesFailedToFetch = (error: any): void => {
			console.warn(error)
			if (notifyError) toast.error(intl.formatMessage({ id: 'CLIENT.PARAMETERS.CLIENT.TYPE.FETCH.ERROR' }))
		}

		const { data: clientTypes, isLoading: isLoadingClientTypes } = useClientTypesFindAllQuery(clientTypeRepository, {
			onSuccess: onClientTypesFetched,
			onError: onClientTypesFailedToFetch,
		})

		useEffect(() => {
			clientTypes && onClientTypesFetched(clientTypes)
		}, [clientTypes])

		useImperativeHandle(
			ref,
			() =>
				({
					clearSelect: selectRef.current?.clearSelect,
					isEmpty: selectRef.current?.isEmpty,
				} as ClientTypeSelectHandle)
		)

		return (
			<UfinetSelect
				ref={selectRef}
				{...props}
				className={className}
				requiredIcon={requiredIcon}
				labelTitle={
					props.noLabel
						? undefined
						: labelTitle || intl.formatMessage({ id: 'CLIENT.PARAMETERS.CLIENT.TYPE.SELECT.TITLE' })
				}
				labelStyles={labelStyles}
				tooltipTitle={tooltipTitle || intl.formatMessage({ id: 'CLIENT.PARAMETERS.CLIENT.TYPE.SELECT.TOOLTIP' })}
				error={error}
				value={!isMulti ? (selectedOption as IUfinetSelectOption)?.value && selectedOption : selectedOption || []}
				defaultValue={defaultValue}
				onChange={onChange}
				options={allOptions}
				isClearable
				isMulti={isMulti}
				isDisabled={isDisabled || false}
				placeholder={intl.formatMessage({
					id: 'CLIENT.PARAMETERS.CLIENT.TYPE.SELECT.TITLE',
				})}
				isLoadingOptions={isLoadingClientTypes}
			/>
		)
	}
)

export { ClientTypeSelect }
