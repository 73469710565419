import { OfferFlowInformation } from 'components/services/management/modal/hooks/useContractedServiceManageModal'
import { ContractedServiceOperation } from 'modules/contractedService/domain/management/operation/ContractedServiceOperation'
import { FC } from 'react'
import { Loading } from 'ufinet-web-components'
import { EndFail } from './EndFail'
import { EndSuccess } from './EndSuccess'

type Props = {
	operation: ContractedServiceOperation
	offer: Pick<OfferFlowInformation, 'value' | 'attemptedConfirmation'>
	isSuccess?: boolean
}

const EndContainer: FC<Props> = ({ operation, offer, isSuccess }) => {
	return (
		<div className="position-relative h-100">
			{isSuccess === undefined ? (
				<Loading />
			) : isSuccess ? (
				<EndSuccess operation={operation} />
			) : (
				<EndFail
					operation={operation}
					offer={offer}
				/>
			)}
		</div>
	)
}

export { EndContainer }
