import { ContractedServiceOrsRepository } from 'modules/contractedService/domain/repository/ContractedServiceOrsRepository'
import { useMutation } from 'react-query'
import { ResponseError } from 'ufinet-web-functions'
import { OrsUnifiedSaveRequest } from './dto/OrsUnifiedSaveRequest'

export const useOrsUnifiedSaveQuery = (
	contractedServiceOrsRepository: ContractedServiceOrsRepository,
	{
		onSuccess,
		onError,
	}: {
		onSuccess?: () => void
		onError?: (error: ResponseError) => void
	} = {}
) => {
	return useMutation({
		mutationKey: 'ors-unified-save-query',
		mutationFn: async (orsUnifiedSaveRequest: OrsUnifiedSaveRequest) => {
			await contractedServiceOrsRepository.saveUnified(orsUnifiedSaveRequest, { notifyError: false })
		},
		onSuccess,
		onError,
	})
}
