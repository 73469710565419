import { FC, useCallback } from 'react'
import { Label } from 'ufinet-web-components'
import { noContentPlaceholder, useTranslator } from 'ufinet-web-functions'

type Props = {
	title: string
	content?: string
	key?: any
	translateTitle?: boolean
}

const RowContainerWizard: FC<Props> = ({ title, content, key, translateTitle = true }) => {
	const translate = useTranslator()

	const getTitle = useCallback(
		(title: string) => (translateTitle ? translate(title) : title),
		[translate, translateTitle]
	)

	return (
		<div key={key}>
			<Label
				labelTitle={getTitle(title)}
				className="fw-bolder"
			/>
			<p style={{ whiteSpace: 'pre-line' }}>{content || noContentPlaceholder}</p>
		</div>
	)
}

export { RowContainerWizard }
