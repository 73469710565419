import { Offer } from 'modules/offer/domain/Offer'
import { OfferRepository } from 'modules/offer/domain/repository/OfferRepository'
import { OfferCreateRequest } from './dto/OfferCreateRequest'
import { OfferCreateResponse } from './dto/OfferCreateResponse'

const offerFromOfferCreateResponse: (offerCreateResponse: OfferCreateResponse) => Offer = (offerCreateResponse) => ({
	id: offerCreateResponse.offerId,
	status: offerCreateResponse.offerStatus.value,
	attempedCreation: true,
	attempedConfirmation: false,
})

export const offerCreator = (
	offerRepository: OfferRepository,
	offerCreateRequest: OfferCreateRequest
): Promise<Offer> => {
	return offerRepository.create(offerCreateRequest, { notifyError: false }).then(offerFromOfferCreateResponse)
}
