import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { clsx } from 'clsx'
import { clientParametersMultipleDeleter } from 'modules/client/parameters/application/delete/clientParametersMultipleDeleter'
import { useClientParametersFindAllQuery } from 'modules/client/parameters/application/find/ClientParametersFindQueries'
import { ClientParametersFindResponse } from 'modules/client/parameters/application/find/dto/ClientParametersFindResponse'
import { HttpClientParametersRepository } from 'modules/client/parameters/infrastructure/HttpClientParametersRepository'
import { notificationPublisher } from 'modules/event/application/notification/NotificationPublisher'
import { FC, useCallback, useContext, useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { toast } from 'react-toastify'
import {
	getEditPencilBodyTemplate,
	Loading,
	MultipleDeleter,
	Table,
	TableHandle,
	UfinetBox,
	UfinetButton,
	UfinetModal,
	UfinetSectionBox,
} from 'ufinet-web-components'
import { AuthContext, Authority, IMultipleDeleteRequest, useModal, useTranslator } from 'ufinet-web-functions'
import ClientParametersModal, {
	ClientParametersFormHandler,
} from '../../../components/client/parameters/modal/ClientParametersModal'
import { mkColsClientParams } from './ClientParametersColsData'

export type ClientParameters = ClientParametersFindResponse & {
	displayClientName: string
	displayClientType?: string
}

const ClientParamsPage: FC = () => {
	const intl = useIntl()
	const translate = useTranslator()

	const authData = useContext(AuthContext)
	const roles = authData.userData?.authorities || []
	const permissions = Authority.getGseConfigPermissions(roles)

	const [clientParams, setClientParams] = useState<ClientParameters[]>()
	const [currentClientParamsId, setCurrentClientParamsId] = useState<number>()
	const clientParametersRepository = useMemo(() => HttpClientParametersRepository(authData), [authData])

	const tableRef = useRef<TableHandle>(null)
	const colsClientParams = useMemo(() => mkColsClientParams(translate), [translate])
	const [selectedValues, setSelectedValues] = useState<ClientParameters[]>()

	const [createUpdateModal, showCreateUpdateModal, hideCreateUpdateNewModal] = useModal()

	const {
		isLoading: loadingClientParameters,
		isFetching: fetchingClientParameters,
		refetch: fetchClientParameters,
	} = useClientParametersFindAllQuery(clientParametersRepository, {
		onSuccess: (params): void => {
			const processedParams: ClientParameters[] = params.map((p) => ({
				...p,
				displayClientName: p.client?.value || translate('BY_DEFAULT'),
				displayClientType: !p.clientType
					? undefined
					: translate(`CLIENT.PARAMETERS.CLIENT.TYPE.${p.clientType?.value}`),
			}))
			setClientParams(processedParams)
		},
		onError: () => toast.error(translate('CLIENT.PARAMETERS.FETCH.ERROR')),
	})

	const onSelectionChange = useCallback((values: ClientParameters[]) => setSelectedValues(values), [])

	const getHeaderButtons = useCallback(() => {
		return (
			<>
				{permissions.canDelete && (
					<MultipleDeleter
						setSelectedValues={setSelectedValues}
						selectedValues={selectedValues}
						deleteEndpoint={(deleteIds: IMultipleDeleteRequest<number>) =>
							clientParametersMultipleDeleter(clientParametersRepository, deleteIds, {
								intl,
								publisher: notificationPublisher,
							})
						}
						search={fetchClientParameters}
					/>
				)}
				{permissions.canWrite && (
					<>
						<UfinetButton
							className="me-3"
							icon={faPlus}
							onClick={() => {
								showCreateUpdateModal()
							}}
							content={translate('NEW.REGISTER')}
						/>
					</>
				)}
			</>
		)
	}, [
		permissions.canDelete,
		permissions.canWrite,
		selectedValues,
		fetchClientParameters,
		translate,
		clientParametersRepository,
		intl,
		showCreateUpdateModal,
	])

	const getActionBodyTemplate = useCallback(
		(params: ClientParameters) => {
			if (!permissions.canUpdate) return

			return getEditPencilBodyTemplate(params, () => {
				setSelectedValues([])
				setCurrentClientParamsId(params.id)
				showCreateUpdateModal()
			})
		},
		[permissions.canUpdate, showCreateUpdateModal]
	)

	const handleModalClose = useCallback(() => {
		hideCreateUpdateNewModal()
		setCurrentClientParamsId(undefined)
	}, [hideCreateUpdateNewModal])

	const handleModalLoadError = useCallback(() => {
		handleModalClose()
	}, [handleModalClose])

	const handleModalSubmit = useCallback<ClientParametersFormHandler>(
		(_clientParams, shouldClose) => {
			fetchClientParameters()
			if (shouldClose) handleModalClose()
		},
		[handleModalClose, fetchClientParameters]
	)

	return (
		<UfinetBox>
			<UfinetSectionBox className="position-relative">
				<h2 className="fs-3 fw-bolder text-dark">{translate('CLIENT.PARAMETERS')}</h2>
				<Table
					className={clsx(fetchingClientParameters && 'form-disabled')}
					ref={tableRef}
					content={clientParams}
					selectedValues={selectedValues}
					onSelectionChange={permissions.canDelete ? onSelectionChange : undefined}
					actionBodyTemplate={getActionBodyTemplate}
					cols={colsClientParams}
					headerButtons={getHeaderButtons()}
					emptyMessage={loadingClientParameters ? translate('LOADING_DOTS') : undefined}
				/>
				{fetchingClientParameters && <Loading />}
			</UfinetSectionBox>
			<UfinetModal
				size="lg"
				show={createUpdateModal}
				handleClose={handleModalClose}
				title={translate(
					currentClientParamsId ? 'CLIENT.PARAMETERS.MODAL.UPDATE.TITLE' : 'CLIENT.PARAMETERS.MODAL.NEW.TITLE'
				)}
			>
				<ClientParametersModal
					paramsId={currentClientParamsId}
					onLoadError={handleModalLoadError}
					onParamsSubmitted={handleModalSubmit}
				/>
			</UfinetModal>
		</UfinetBox>
	)
}
export { ClientParamsPage }
