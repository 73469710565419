import { ContractType } from './ContractType'
import { ManagementOperation } from './ManagementOperation'

export abstract class ContractedServiceOperation implements ContractedServiceOperationData {
	readonly awardsImmediately: boolean
	readonly bandwidth: { id: string; label: string }
	readonly contact?: { id: string; name: string }
	readonly contractType: ContractType
	readonly currency: { id: string; name: string }
	readonly deadline: number
	readonly keepDeadline: boolean
	readonly mrc: number
	readonly mrcAdjustment?: number | undefined
	readonly nrc: number
	readonly nrcAdjustment?: number | undefined
	readonly operations: ManagementOperation[]
	readonly service: { id: string; code: string }

	static isValidForCalculation(input: Partial<ContractedServiceOperationData>): boolean {
		return (
			input.service !== undefined &&
			input.bandwidth !== undefined &&
			input.deadline !== undefined &&
			input.keepDeadline !== undefined
		)
	}

	isCalculated() {
		return this.nrc !== undefined && this.mrc !== undefined
	}

	constructor({
		awardsImmediately = false,
		bandwidth = { id: '', label: '' },
		contact,
		contractType = ContractType.IMMEDIATE,
		currency = { id: '', name: '' },
		deadline = 0,
		keepDeadline = false,
		mrc = 0,
		mrcAdjustment,
		nrc = 0,
		nrcAdjustment,
		operations = [ManagementOperation.UPGRADE],
		service = { id: '', code: '' },
	}: Partial<ContractedServiceOperationData>) {
		this.awardsImmediately = awardsImmediately
		this.bandwidth = bandwidth
		this.contact = contact
		this.contractType = contractType
		this.currency = currency
		this.deadline = deadline
		this.keepDeadline = keepDeadline
		this.mrc = mrc
		this.mrcAdjustment = mrcAdjustment
		this.nrc = nrc
		this.nrcAdjustment = nrcAdjustment
		this.operations = operations
		this.service = service
	}
}

export type ContractedServiceOperationData = {
	awardsImmediately: boolean
	bandwidth: {
		id: string
		label: string
	}
	contact?: { id: string; name: string }
	contractType: ContractType
	currency: { id: string; name: string }
	deadline: number
	keepDeadline: boolean
	mrc: number
	mrcAdjustment?: number
	nrc: number
	nrcAdjustment?: number
	operations: ManagementOperation[]
	service: { id: string; code: string }
}
