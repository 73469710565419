import { BandwidthFindRequest } from 'modules/bandwidth/application/common/dto/BandwidthFindRequest'
import { BandwidthFindResponse } from 'modules/bandwidth/application/common/dto/BandwidthFindResponse'
import { BandwidthRepository } from 'modules/bandwidth/domain/repository/bandwidthRepository'
import { ContractType } from 'modules/contractedService/domain/management/operation/ContractType'
import { ManagementOperation } from 'modules/contractedService/domain/management/operation/ManagementOperation'
import { useQuery } from 'react-query'
import { FetchOptions } from 'ufinet-web-functions'

export const useBandwidthFindForOperation = (
	managementOperation: ManagementOperation.UPGRADE | ManagementOperation.DOWNGRADE,
	bandwidthRepository: BandwidthRepository,
	bandwidthFindRequest: BandwidthFindRequest,
	contractType?: ContractType,
	{
		enabled = true,
		onSuccess,
		onError,
	}: {
		enabled?: boolean
		onSuccess?: (BandwidthFindResponses: BandwidthFindResponse[]) => void
		onError?: (error: unknown) => void
	} = {}
) => {
	const contractTypeToRepositoryMethod = {
		[ContractType.IMMEDIATE]:
			managementOperation === ManagementOperation.DOWNGRADE
				? bandwidthRepository.findForImmediateDowngrade
				: bandwidthRepository.findForImmediateUpgrade,
		[ContractType.SCHEDULED]: bandwidthRepository.findForScheduledUpgrade,
		[ContractType.TEMPORAL]: bandwidthRepository.findForTemporaryUpgrade,
	}

	return useQuery(
		[
			'bandwidth-find-by-priceListId-priceListTypeId-productId-query',
			managementOperation,
			bandwidthRepository,
			bandwidthFindRequest,
			contractType,
		],
		() => {
			const fetchOptions: FetchOptions = { notifyError: false }
			const repositoryMethod = contractType ? contractTypeToRepositoryMethod[contractType] : undefined

			return repositoryMethod ? repositoryMethod(bandwidthFindRequest, fetchOptions) : []
		},
		{
			enabled,
			onSuccess,
			onError,
			staleTime: 60000,
		}
	)
}
