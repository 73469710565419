import { FC, useMemo } from 'react'
import { useTranslator } from 'ufinet-web-functions'

type Remark = {
	description: string
	penalties: string
}

const TermsAndConditionsRemarks: FC<{}> = () => {
	const translate = useTranslator()

	const remarks: Remark[] = useMemo(
		() => [
			{
				description: 'Visita fallida de instalación',
				penalties: 'Este tendrá un costo de $450 por visita de Ufinet que no se pueda realizar por causas del cliente.',
			},
			{
				description: 'Cancelación anticipada de servicio activo',
				penalties: 'Se penalizará con el valor de los meses restantes del acuerdo firmado.',
			},
			{
				description: 'Cancelación anticipada de servicio en proceso de instalación',
				penalties:
					'Se penalizará con el costo de instalación que Ufinet estipule, más 12 meses de MRC firmado en el acuerdo del servicio puntual.',
			},
			{
				description: 'Cancelación anticipada de servicio después de firmada la orden de servicio',
				penalties:
					'Primero, si el cliente comunica a Ufinet que hay un retraso en la instalación previo a que Ufinet inicie el proceso de instalación, se le dará un tiempo de extensión máximo de 60 días de su instalación firmada en la orden de servicio. Si excediera este tiempo se penalizará con 12 meses de MRC del acuerdo firmado puntualmente del servicio específico.',
			},
			{
				description: 'Traslados de servicios durante el tiempo de contratación',
				penalties:
					'Se evaluará el costo del traslado por medio de una factibilidad del lado de Ufinet, se propondrá el precio al cliente, y este último decidirá si se realiza o no.',
			},
		],
		[]
	)

	return (
		<div>
			<p className="terms-and-conditions-header">{translate('CONTRACT.SERVICE.TERMS.REMARKS')}</p>
			<table className="terms-and-conditions-table remarks-table">
				<thead>
					<tr>
						<th>{translate('CONTRACT.SERVICE.TERMS.REMARKS.DESCRIPTION')}</th>
						<th>{translate('CONTRACT.SERVICE.TERMS.REMARKS.PENALTIES')}</th>
					</tr>
				</thead>
				<tbody>
					{remarks.map((it, idx) => (
						<tr key={idx}>
							<td>{it.description}</td>
							<td>{it.penalties}</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	)
}
export { TermsAndConditionsRemarks }
