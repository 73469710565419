import { ContractedServiceOperationScheduled } from 'modules/contractedService/domain/management/operation/scheduled/ContractedServiceOperationScheduled'
import { offerCreateRequestFromOperation } from 'modules/offer/application/create/dto/OfferCreateRequest'
import { OfferConfirmRequest } from '../../confirm/dto/OfferConfirmRequest'

export type OfferScheduleRequest = Omit<OfferConfirmRequest, 'offerCode'> & {
	offerCode?: string
	modifyAt: string
}

export const offerScheduleRequestFromServiceOperation = ({
	contractedServiceOperation,
}: {
	contractedServiceOperation: ContractedServiceOperationScheduled
}): OfferScheduleRequest => {
	const offerRequest = offerCreateRequestFromOperation(contractedServiceOperation)
	return {
		serviceCode: contractedServiceOperation.service.id,
		operations: contractedServiceOperation.operations,
		modifyAt: contractedServiceOperation.modifyAt.toISOString(),
		offerRequest,
	}
}
