import { useTranslator } from 'ufinet-web-functions'
import { ModalButtonsText } from '../modals/defaultSwalConfig'

const useModalButtonsText = (): ModalButtonsText => {
	const translate = useTranslator()

	return {
		confirmButtonText: translate('ACCEPT'),
		cancelButtonText: translate('CANCEL'),
	}
}

export { useModalButtonsText }
