import { ContractedServiceAttributes } from 'modules/contractedService/domain/attributes/ContractedServiceAttributes'
import { FC } from 'react'
import { Table as BootstrapTable } from 'react-bootstrap'
import { UfinetButton } from 'ufinet-web-components'
import { useTranslator } from 'ufinet-web-functions'

type Props = {
	serviceAttributes: ContractedServiceAttributes
	closeModal: () => void
}

const ContractedServiceDetailsModal: FC<Props> = ({ serviceAttributes: serviceDetails, closeModal }) => {
	const translate = useTranslator()
	return (
		<>
			<div>
				<BootstrapTable
					hover
					responsive
					size="xs"
				>
					<tbody>
						{Object.keys(serviceDetails).map((key, idx) => (
							<tr key={idx}>
								<td>{translate(`CONTRACT.SERVICE.DETAILS.${key.toUpperCase()}`)}</td>
								<td>
									<strong>{serviceDetails[key] || '-'}</strong>
								</td>
							</tr>
						))}
					</tbody>
				</BootstrapTable>
			</div>
			<div className="d-flex justify-content-end">
				<UfinetButton
					content={translate('CONTRACT.SERVICE.DETAILS.CLOSE')}
					onClick={closeModal}
				/>
			</div>
		</>
	)
}

export { ContractedServiceDetailsModal }
