import { ContractedServiceOperation } from 'modules/contractedService/domain/management/operation/ContractedServiceOperation'
import { ManagementOperation } from 'modules/contractedService/domain/management/operation/ManagementOperation'
import {
	OfferCreateRequest,
	offerCreateRequestFromOperation,
} from 'modules/offer/application/create/dto/OfferCreateRequest'

export type OfferConfirmRequest = {
	offerCode: string
	serviceCode: string
	operations: ManagementOperation[]
	offerRequest: OfferCreateRequest
}

export const offerConfirmRequestFromManagementOperation = <T extends ContractedServiceOperation>({
	offerCode,
	contractedServiceOperation,
}: {
	offerCode: string
	contractedServiceOperation: T
}): OfferConfirmRequest => {
	const offerRequest = offerCreateRequestFromOperation(contractedServiceOperation)
	return {
		offerCode,
		serviceCode: contractedServiceOperation.service.id,
		operations: contractedServiceOperation.operations,
		offerRequest,
	}
}
