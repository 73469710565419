import { NotificationEvent } from 'modules/event/domain/notification/NotificationEvent'
import { Publisher } from 'modules/event/domain/Publisher'
import { IntlShape } from 'react-intl'
import { successNotifications } from 'ufinet-web-functions'
import { ClientParametersRepository } from '../../domain/repository/clientParametersRepository'
import { getClientNameAndTypePair } from '../getClientNameAndTypePair'
import { ClientParametersSaveRequest } from './dto/ClientParametersSaveRequest'

export const clientParametersSaver = async (
	clientParametersRepository: ClientParametersRepository,
	clientParametersSaveRequest: ClientParametersSaveRequest,
	publishOptions?: { intl: IntlShape; publisher: Publisher }
): Promise<void> => {
	try {
		await clientParametersRepository.create(clientParametersSaveRequest, { notifyError: false })
		publishOptions &&
			publishOnClientParametersSaved(publishOptions.publisher, publishOptions.intl, clientParametersSaveRequest)
	} catch (error) {
		publishOptions && publishOnClientParametersFailedToSave(publishOptions.publisher, publishOptions.intl, error)
	}
}

const publishOnClientParametersFailedToSave = (publisher: Publisher, intl: IntlShape, { errors }: any) => {
	const event = new NotificationEvent({
		notificationType: 'error',
		notificationContent: errors
			? [errors].filter(Boolean).join('. ')
			: intl.formatMessage({ id: 'CLIENT.PARAMETERS.CREATION.ERROR' }),
	})
	publisher.publish(event)
}

const publishOnClientParametersSaved = (
	publisher: Publisher,
	intl: IntlShape,
	{ clientName, clientTypeName }: ClientParametersSaveRequest
) => {
	const event = new NotificationEvent({
		notificationType: 'success',
		notificationContent: successNotifications.createRegistry(
			intl,
			getClientNameAndTypePair(intl, clientName, clientTypeName)
		),
	})
	publisher.publish(event)
}
