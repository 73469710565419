import { defaultOptionsFetch, FetchAPI, FetchOptions, IAuthData } from 'ufinet-web-functions'
import { ClientTypeRepository } from '../domain/repository/ClientTypeRepository'

const url = `${process.env.REACT_APP_URL_BACK_GSE}api/client/types`

export const HttpClientTypeRepository = (authData: IAuthData): ClientTypeRepository => {
	const fetchOptions: FetchOptions = {
		...defaultOptionsFetch,
		authenticationData: authData,
		sendApiKey: true,
	}

	return {
		findAll: async () =>
			await FetchAPI.get(url, {
				...fetchOptions,
			}),
	}
}
