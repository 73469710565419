import dayjs from 'dayjs'
import { ContractedServiceOperation } from 'modules/contractedService/domain/management/operation/ContractedServiceOperation'
import { ContractType } from 'modules/contractedService/domain/management/operation/ContractType'
import { ContractedServiceOperationDataScheduled } from 'modules/contractedService/domain/management/operation/scheduled/ContractedServiceOperationScheduled'

export class ContractedServiceOperationTemporal
	extends ContractedServiceOperation
	implements ContractedServiceOperationDataTemporal
{
	static isValidForCalculation(input: Partial<ContractedServiceOperationDataTemporal>): boolean {
		return (
			ContractedServiceOperation.isValidForCalculation(input) &&
			input.modifyAt !== undefined &&
			input.period !== undefined
		)
	}

	fromOther(input: ContractedServiceOperationDataTemporal) {
		return new ContractedServiceOperationTemporal(input)
	}

	static readonly rollbackAfterDays = [2, 7, 30]
	readonly modifyAt: Date
	readonly rollbackAt: Date
	readonly period: number

	constructor(
		contractedServiceOperationDataTemporal: Omit<
			ContractedServiceOperationDataTemporal,
			'contractType' | 'awardsImmediately' | 'rollbackAt'
		>
	) {
		super({
			...contractedServiceOperationDataTemporal,
			contractType: ContractType.TEMPORAL,
			awardsImmediately: dayjs(contractedServiceOperationDataTemporal.modifyAt).isSame(dayjs(), 'day'),
		})

		this.modifyAt = dayjs(contractedServiceOperationDataTemporal.modifyAt)
			.add(Math.abs(contractedServiceOperationDataTemporal.modifyAt.getTimezoneOffset()), 'minutes')
			.toDate()
		this.period = contractedServiceOperationDataTemporal.period
		this.rollbackAt = dayjs(this.modifyAt)
			.add(contractedServiceOperationDataTemporal.period, 'days')
			.add(Math.abs(contractedServiceOperationDataTemporal.modifyAt.getTimezoneOffset()), 'minutes')
			.toDate()
	}
}

export type ContractedServiceOperationDataTemporal = ContractedServiceOperationDataScheduled & {
	rollbackAt: Date
	period: number
}
