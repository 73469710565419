import { ContractedServiceOrsRepository } from 'modules/contractedService/domain/repository/ContractedServiceOrsRepository'
import { defaultOptionsFetch, FetchAPI, FetchOptions, IAuthData } from 'ufinet-web-functions'

const url = `${process.env.REACT_APP_URL_BACK_GSE}api/services/contracted/ors`

export const HttpContractedServiceOrsRepository = (authData: IAuthData): ContractedServiceOrsRepository => {
	const fetchOptions: FetchOptions = {
		...defaultOptionsFetch,
		authenticationData: authData,
		sendApiKey: true,
	}

	return {
		getAllPetitions: async (options) =>
			await FetchAPI.get(url + '/petitions', {
				...fetchOptions,
				...options,
			}),
		getAllReasons: async (petition, options) =>
			await FetchAPI.get_params(`${url}/reasons`, petition, {
				...fetchOptions,
				...options,
			}),
		saveIndividual: async (request, options) =>
			await FetchAPI.post(url + '/individual', request, {
				...fetchOptions,
				...options,
			}),
		saveUnified: async (request, options) =>
			await FetchAPI.post(url + '/unified', request, {
				...fetchOptions,
				...options,
			}),
	}
}
