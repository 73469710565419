import { useContractedServiceManageFormContext } from 'components/services/management/modal/wizard/modify-service/manage/ContractedServiceManageFormContextProvider'
import { ManageFormValidationCreator } from 'components/services/management/modal/wizard/modify-service/manage/inner/base/manageFormValidationCreator'
import dayjs, { Dayjs } from 'dayjs'
import { FormikProps } from 'formik'
import {
	ContractedServiceOperationDataTemporal,
	ContractedServiceOperationTemporal,
} from 'modules/contractedService/domain/management/operation/temporary/ContractedServiceOperationTemporary'

import { isExpiredByDueDate } from 'modules/contractedService/application/isExpired'
import { ManagementOperation } from 'modules/contractedService/domain/management/operation/ManagementOperation'
import { CalendarChangeParams } from 'primereact/calendar'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { IUfinetSelectOption, emptyUfinetSelectOption } from 'ufinet-web-components'
import { LoadingControls, isNumeric, useInternalUser, useTranslator } from 'ufinet-web-functions'
import { TemporalManageFormParameters, useTemporaryManageFormValidation } from './useTemporaryManageFormValidation'

type HookOutput = {
	operationPeriod: {
		options: number[]
		value?: number
	}
	awardDate: {
		value?: Date
		min: Date
		max: Date
	}
	operationDate: {
		value?: Date
	}
	onChange: {
		operationPeriod: (operationPeriod: IUfinetSelectOption) => void
		awardDate: (calendarChangeParams: CalendarChangeParams) => void
	}
	form: {
		formik: FormikProps<TemporalManageFormParameters>
		loadingControls: LoadingControls
		validator: ManageFormValidationCreator<ContractedServiceOperationDataTemporal>
	}
	errorMessage?: string
}

function useTemporaryManageForm(): HookOutput {
	const {
		form: { value: form },
		service,
		contractedServiceOperation: { selected: selectedOperations, contractType },
	} = useContractedServiceManageFormContext<ContractedServiceOperationDataTemporal>()

	const internalUser = useInternalUser()

	const translate = useTranslator()

	const [operationPeriod, setOperationPeriod] = useState<number>()
	const [awardDate, setAwardDate] = useState<Dayjs>()
	const operationDate = useMemo(
		() =>
			operationPeriod !== undefined && awardDate !== undefined ? awardDate.add(operationPeriod, 'days') : undefined,
		[awardDate, operationPeriod]
	)

	const errorMessage = useMemo(() => {
		const isUpgrade = selectedOperations.includes(ManagementOperation.UPGRADE)

		if (!isUpgrade || selectedOperations.length > 1)
			return translate('CONTRACT.SERVICE.CONTRACT.TYPE.TEMPORAL.ERROR.UPGRADE')

		if (isExpiredByDueDate(service.dueDate)) {
			return translate(`CONTRACT.SERVICE.CONTRACT.TYPE.ERROR.EXPIRED.${internalUser ? 'INTERNAL' : 'EXTERNAL'}`, {
				contractType: translate(`CONTRACT.SERVICE.CONTRACT.TYPE.${contractType}`).toLowerCase(),
			})
		}
	}, [selectedOperations, service.dueDate, internalUser, contractType, translate])

	const minStartDate = dayjs().startOf('day')
	const serviceExpirationDate = service.remainingDeadline ? dayjs().add(service.remainingDeadline, 'months') : undefined
	const defaultMaxAllowedDate = minStartDate.add(1, 'months')

	const maxAllowedDate = useMemo(
		() =>
			!serviceExpirationDate
				? defaultMaxAllowedDate
				: serviceExpirationDate.isBefore(defaultMaxAllowedDate)
				? serviceExpirationDate
				: defaultMaxAllowedDate,
		[defaultMaxAllowedDate, serviceExpirationDate]
	)

	const { formik, initialValues, validationSchema, loadingControls } = useTemporaryManageFormValidation({
		minStartDate,
	})

	const onOperationPeriodChange = useCallback(
		(operationPeriod?: number) => {
			form.setFieldValue('period', operationPeriod)
			setOperationPeriod(operationPeriod)
		},
		[form]
	)

	const handleOperationPeriodChange = useCallback(
		(operationPeriod: IUfinetSelectOption = emptyUfinetSelectOption) => {
			const deadlineNumeric = isNumeric(operationPeriod?.value) ? +operationPeriod.value : undefined
			onOperationPeriodChange(deadlineNumeric)
		},
		[onOperationPeriodChange]
	)

	const onAwardDateChange = useCallback(
		(newAwardDate?: Date) => {
			form.setFieldValue('modifyAt', newAwardDate)
			setAwardDate(dayjs(newAwardDate))
		},
		[form]
	)

	const handleAwardDateChange = useCallback(
		(calendarChangeParams: CalendarChangeParams) => {
			const newAwardDate = calendarChangeParams.value instanceof Date ? calendarChangeParams.value : undefined
			onAwardDateChange(newAwardDate)
		},
		[onAwardDateChange]
	)

	useEffect(() => {
		if (form.values?.period !== undefined) onOperationPeriodChange(form.values?.period)
		if (form.values?.modifyAt !== undefined) onAwardDateChange(form.values?.modifyAt)
	}, [])

	return {
		operationPeriod: {
			options: ContractedServiceOperationTemporal.rollbackAfterDays,
			value: operationPeriod,
		},
		awardDate: {
			value: awardDate?.toDate(),
			min: minStartDate.toDate(),
			max: maxAllowedDate.toDate(),
		},
		operationDate: {
			value: operationDate?.toDate(),
		},
		onChange: {
			operationPeriod: handleOperationPeriodChange,
			awardDate: handleAwardDateChange,
		},
		form: {
			formik,
			loadingControls,
			validator: () => ({ formik, initialValues, validationSchema, loadingControls }),
		},
		errorMessage,
	}
}

export { useTemporaryManageForm }
