import { BandwidthFindForServiceRequest } from 'modules/bandwidth/application/common/dto/BandwidthFindForServiceRequest'
import { BandwidthFindForServiceResponse } from 'modules/bandwidth/application/common/dto/BandwidthFindForServiceResponse'
import { BandwidthRepository } from 'modules/bandwidth/domain/repository/bandwidthRepository'
import { useQuery } from 'react-query'

export const useBandwidthFindForService = (
	bandwidthRepository: BandwidthRepository,
	bandwidthFindForServiceRequest: BandwidthFindForServiceRequest,
	{
		enabled = true,
		onStart,
		onSuccess,
		onError,
		onSettled,
	}: {
		enabled?: boolean
		onStart?: () => void
		onSuccess?: (bandwidthFindForServiceResponse: BandwidthFindForServiceResponse) => void
		onError?: (error: unknown) => void
		onSettled?: () => void
	} = {}
) => {
	return useQuery(
		['bandwidth-find-for-service-query', bandwidthFindForServiceRequest.serviceId],
		() => {
			onStart?.()
			return bandwidthRepository.findForService(bandwidthFindForServiceRequest, { notifyError: false })
		},
		{
			enabled,
			onSuccess,
			onError,
			onSettled,
			staleTime: 120000,
		}
	)
}
