import { FC, useMemo } from 'react'
import { Label } from 'ufinet-web-components'
import { noContentPlaceholder } from 'ufinet-web-functions'

type Props = {
	title: string
	content?: string
	noContentText?: string
}

export const ContractedServiceDetailTableRow: FC<Props> = ({
	title,
	content,
	noContentText = noContentPlaceholder,
}) => {
	const finalContent = useMemo(() => content || noContentText, [content, noContentText])
	return (
		<div>
			<Label
				labelTitle={title}
				className="fw-bolder"
			/>
			<p style={{ whiteSpace: 'pre-line' }}>{finalContent}</p>
		</div>
	)
}
