import { ContractedServiceDetail } from 'modules/contractedService/domain/detail/ContractedServiceDetail'
import { ContractedServiceOperation } from 'modules/contractedService/domain/management/operation/ContractedServiceOperation'
import { ContractType } from 'modules/contractedService/domain/management/operation/ContractType'
import { Offer } from 'modules/offer/domain/Offer'
import { FC, PropsWithChildren } from 'react'
import { ContainerWizard } from '../../common/ContainerWizard'
import styles from './ConfirmResume.module.scss'
import { ConfirmResumeCommon } from './parts/common/ConfirmResumeCommon'
import { ConfirmResumeCurrent } from './parts/current/ConfirmResumeCurrent'
import { ConfirmResumeToHire } from './parts/to-hire/ConfirmResumeToHire'

type Props = {
	contractType: ContractType
	contractedServiceDetail: ContractedServiceDetail
	contractedServiceOperation: ContractedServiceOperation
	offer?: Offer
}

const ConfirmResume: FC<PropsWithChildren<Props>> = ({
	children,
	contractType,
	contractedServiceDetail,
	contractedServiceOperation,
	offer,
}) => {
	return (
		<ContainerWizard>
			<div className={styles.ConfirmResume__main}>
				<ConfirmResumeCommon
					contractedServiceManagementOperations={contractedServiceOperation.operations}
					contractType={contractType}
					contractedServiceDetail={contractedServiceDetail}
					offer={offer}
				/>
			</div>
			<div className={styles.ConfirmResume__container}>
				<div className={styles.ConfirmResume__container__box}>
					<ConfirmResumeCurrent contractedServiceDetail={contractedServiceDetail} />
				</div>
				<div className={styles.ConfirmResume__container__box}>
					<ConfirmResumeToHire
						offer={offer}
						contractedServiceOperation={contractedServiceOperation}
					/>
				</div>
			</div>
			{children}
		</ContainerWizard>
	)
}
export { ConfirmResume }
