import { useManagementOperationFindAllQuery } from 'modules/contractedService/application/management/operation/find/ManagementOperationFindQueries'
import { ManagementOperation } from 'modules/contractedService/domain/management/operation/ManagementOperation'
import { HttpContractedServiceManagementRepository } from 'modules/contractedService/infrastructure/management/operation/HttpContractedServiceManagementRepository'
import { FC, forwardRef, useCallback, useContext, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { ISelectBaseInterface, IUfinetSelectOption, UfinetSelect, UfinetSelectHandle } from 'ufinet-web-components'
import { AuthContext, getEnumKeyFromEnumValue, TranslatorFunction, useTranslator } from 'ufinet-web-functions'

const mkOptionFromManagementOperation = (
	managementOperation: ManagementOperation,
	translate: TranslatorFunction
): IUfinetSelectOption => {
	return {
		label: translate(
			`CONTRACT.SERVICE.MANAGEMENT.OPERATION.${getEnumKeyFromEnumValue(ManagementOperation, managementOperation)}`
		),
		value: managementOperation.toString(),
	}
}

export type ManagementOperationSelectHandle = UfinetSelectHandle & {}

type Props = Omit<ISelectBaseInterface, 'isMulti' | 'value'> & {
	value?: IUfinetSelectOption[]
	preSelectedOperations?: ManagementOperation[]
	allowedOperations?: ManagementOperation[]
}

const ManagementOperationSelect: FC<Props> = forwardRef<ManagementOperationSelectHandle, Props>(
	(
		{
			requiredIcon = false,
			className,
			onChange: propsOnChange,
			value = [],
			error = undefined,
			isDisabled,
			preSelectedOperations = [],
			allowedOperations,
			labelStyles,
			...props
		},
		ref
	) => {
		const selectRef = useRef<UfinetSelectHandle>(null)

		const translate = useTranslator()
		const authData = useContext(AuthContext)

		const contractedServiceManagementRepository = useMemo(
			() => HttpContractedServiceManagementRepository(authData),
			[authData]
		)

		const { data: managementOperations, isLoading } = useManagementOperationFindAllQuery(
			contractedServiceManagementRepository,
			{
				onError: console.warn,
			}
		)

		const [selectedOption, setSelectedOption] = useState<IUfinetSelectOption[]>(value)

		const operationOptions = useMemo(() => {
			const serverOperations = managementOperations || []
			const allowedOptions = allowedOperations
				? serverOperations.filter((opt) => allowedOperations.includes(opt))
				: serverOperations
			return allowedOptions.map((opt) => mkOptionFromManagementOperation(opt, translate))
		}, [allowedOperations, managementOperations, translate])

		const preSelectedOptions = useMemo(() => {
			const preSelectedOptions =
				preSelectedOperations && operationOptions.filter((opt) => preSelectedOperations.includes(parseInt(opt.value)))
			return preSelectedOptions
		}, [operationOptions, preSelectedOperations])

		useImperativeHandle(
			ref,
			() =>
				({
					clearSelect: selectRef.current?.clearSelect,
					isEmpty: selectRef.current?.isEmpty,
				} as ManagementOperationSelectHandle)
		)

		const onChange = useCallback(
			(value: IUfinetSelectOption[]) => {
				setSelectedOption(value)
				propsOnChange?.(value)
			},
			[propsOnChange]
		)

		return (
			<UfinetSelect
				ref={selectRef}
				{...props}
				className={className}
				requiredIcon={requiredIcon}
				labelTitle={translate('SELECT.MANAGEMENT.OPTION.TITLE')}
				labelStyles={labelStyles}
				tooltipTitle={translate('SELECT.MANAGEMENT.OPTION.TOOLTIP')}
				error={error}
				value={selectedOption}
				defaultValue={!preSelectedOptions ? [] : preSelectedOptions}
				shouldClearDefaultValue
				onChange={(selection) => onChange(selection as IUfinetSelectOption[])}
				options={operationOptions}
				isClearable
				isMulti
				isDisabled={isDisabled || false}
				placeholder={translate('SELECT.MANAGEMENT.OPTION.TITLE')}
				isLoadingOptions={isLoading}
			/>
		)
	}
)

export { ManagementOperationSelect }
