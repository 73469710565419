import { ContractedServiceAttributes } from 'modules/contractedService/domain/attributes/ContractedServiceAttributes'
import { ContractedServiceWithDetails } from 'modules/contractedService/domain/ContractedServiceWithDetails'
import { ContractedServiceOperation } from 'modules/contractedService/domain/management/operation/ContractedServiceOperation'
import { ContractedServiceEconomicAssessment } from 'modules/contractedService/domain/management/operation/economic-assesment/ContractedServiceEconomicAssesment'
import { ManagementOperation } from 'modules/contractedService/domain/management/operation/ManagementOperation'
import { useMemo } from 'react'
import {
	getEnumKeyFromEnumValue,
	joinValuesWithConjunctionAndSeparator,
	makeBandwidthLabel,
	useTranslator,
} from 'ufinet-web-functions'

type CurrentAndNew<T> = {
	current: T
	new: T
}

type CurrentAndNewOptional<T> = Partial<CurrentAndNew<T>>

type HookInput = {
	contractedServiceWithDetails: ContractedServiceWithDetails
	contractedServiceOperation: ContractedServiceOperation
}

type HookOutput = {
	current: ContractedServiceEconomicAssessment
	new: ContractedServiceEconomicAssessment
}

function useTermsAndConditionsEconomicAssesment({
	contractedServiceWithDetails,
	contractedServiceOperation,
}: HookInput): HookOutput {
	const translate = useTranslator()

	const displayedName: CurrentAndNew<string> = useMemo(() => {
		const serviceTypeStr =
			contractedServiceWithDetails.typeOfService &&
			translate(`CONTRACT.SERVICE.TYPE.${contractedServiceWithDetails.typeOfService.value}`)
		const attributeStr = contractedServiceWithDetails.techType?.value

		const currentBandwidthStr =
			!!contractedServiceWithDetails.bandwidth &&
			!!contractedServiceWithDetails.bandwidthUnit &&
			makeBandwidthLabel(contractedServiceWithDetails.bandwidth, contractedServiceWithDetails.bandwidthUnit)
		const newBandwidthStr = contractedServiceOperation.bandwidth.label

		const currentName = [serviceTypeStr, attributeStr, currentBandwidthStr].filter(Boolean).join(' ')
		const newName = [serviceTypeStr, attributeStr, newBandwidthStr].filter(Boolean).join(' ')

		return { current: currentName, new: newName }
	}, [
		contractedServiceWithDetails.bandwidth,
		contractedServiceWithDetails.bandwidthUnit,
		contractedServiceWithDetails.techType?.value,
		contractedServiceWithDetails.typeOfService,
		translate,
		contractedServiceOperation,
	])

	const detailedAttributes: CurrentAndNewOptional<ContractedServiceAttributes> = useMemo(
		() => ({
			current: contractedServiceWithDetails.detailedAttributes,
			new: contractedServiceWithDetails.detailedAttributes,
		}),
		[contractedServiceWithDetails.detailedAttributes]
	)

	const displayedOperation: CurrentAndNewOptional<string> = useMemo(
		() => ({
			current: translate(
				`CONTRACT.SERVICE.MANAGEMENT.OPERATION.${getEnumKeyFromEnumValue(
					ManagementOperation,
					ManagementOperation.ENLACE_NUEVO
				)}`
			),
			new: joinValuesWithConjunctionAndSeparator(
				contractedServiceOperation.operations.map((operation) =>
					translate(`CONTRACT.SERVICE.MANAGEMENT.OPERATION.${getEnumKeyFromEnumValue(ManagementOperation, operation)}`)
				),
				translate('AND')
			),
		}),
		[contractedServiceOperation, translate]
	)

	const displayedCurrency: CurrentAndNewOptional<string> = useMemo(
		() => ({ current: contractedServiceWithDetails.currency, new: contractedServiceOperation.currency.name }),
		[contractedServiceWithDetails.currency, contractedServiceOperation]
	)

	const displayedNrc: CurrentAndNewOptional<number> = useMemo(
		() => ({
			current: contractedServiceWithDetails.nrc,
			new: contractedServiceOperation.nrcAdjustment || contractedServiceOperation.nrc,
		}),
		[contractedServiceWithDetails.nrc, contractedServiceOperation]
	)

	const displayedMrc: CurrentAndNewOptional<number> = useMemo(
		() => ({
			current: contractedServiceWithDetails.mrc,
			new: contractedServiceOperation.mrcAdjustment || contractedServiceOperation.mrc,
		}),
		[contractedServiceWithDetails.mrc, contractedServiceOperation]
	)

	const displayedDeadline: CurrentAndNewOptional<number> = useMemo(
		() => ({ current: contractedServiceWithDetails.deadline, new: contractedServiceOperation.deadline }),
		[contractedServiceWithDetails.deadline, contractedServiceOperation]
	)

	return {
		current: {
			name: displayedName.current,
			contractType: contractedServiceOperation.contractType,
			operation: displayedOperation.current,
			attributes: detailedAttributes.current,
			currency: displayedCurrency.current,
			nrc: displayedNrc.current,
			mrc: displayedMrc.current,
			deadline: displayedDeadline.current,
			origin: contractedServiceWithDetails.origin,
			destination: contractedServiceWithDetails.destination,
		},
		new: {
			name: displayedName.new,
			contractType: contractedServiceOperation.contractType,
			operation: displayedOperation.new,
			attributes: detailedAttributes.new,
			currency: displayedCurrency.new,
			nrc: displayedNrc.new,
			mrc: displayedMrc.new,
			deadline: displayedDeadline.new,
			origin: contractedServiceWithDetails.origin,
			destination: contractedServiceWithDetails.destination,
		},
	}
}

export { useTermsAndConditionsEconomicAssesment }
