import { colorConstants } from 'ufinet-web-components'

type ModalButtonsText = {
	confirmButtonText: string
	cancelButtonText: string
}

const defaultSwalConfig = (buttonsText: ModalButtonsText) => {
	return {
		allowOutsideClick: false,
		showCancelButton: true,
		showConfirmButton: true,
		confirmButtonColor: colorConstants.primaryColor,
		cancelButtonColor: colorConstants.dangerColor,
		allowEscapeKey: false,
		...buttonsText,
	}
}

export { defaultSwalConfig }
export type { ModalButtonsText }
