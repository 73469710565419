import { ContractedServiceOrsUnifiedForm } from 'components/services/ors/modal/forms/unified/ContractedServiceOrsUnifiedForm'
import { ContractedService } from 'modules/contractedService/domain/ContractedService'
import { OrsEntryType } from 'modules/contractedService/domain/ors/petition/base/OrsEntryType'
import { OrsPetition } from 'modules/contractedService/domain/ors/petition/base/OrsPetition'
import { OrsOperationType } from 'modules/contractedService/domain/ors/petition/base/OrsReactivationType'
import { OrsStrategy } from 'modules/contractedService/domain/ors/strategies/OrsStrategy'
import { ContractedServiceOrsRepository } from 'modules/contractedService/domain/repository/ContractedServiceOrsRepository'
import { FC } from 'react'
import { ContractedServiceOrsIndividualForm } from './individual/ContractedServiceOrsIndividualForm'

type Props = {
	services: ContractedService[]
	selectedEntry: OrsEntryType
	selectedOperation: OrsOperationType[]
	selectedPetition: OrsPetition
	onServiceRemove: (id: string) => void
	onSend: () => void
	serviceOrsRepository: ContractedServiceOrsRepository
	orsStrategy: OrsStrategy
}

const ContractedServiceOrsForm: FC<Props> = ({
	services,
	selectedEntry,
	selectedPetition,
	selectedOperation,
	onServiceRemove,
	onSend,
	serviceOrsRepository,
	orsStrategy,
}) => {
	if (selectedEntry === OrsEntryType.INDIVIDUAL) {
		return (
			<ContractedServiceOrsIndividualForm
				selectedEntry={selectedEntry}
				selectedPetition={selectedPetition}
				selectedOperation={selectedOperation}
				services={services}
				onServiceRemove={onServiceRemove}
				onSend={onSend}
				serviceOrsRepository={serviceOrsRepository}
				orsStrategy={orsStrategy}
			/>
		)
	}
	if (selectedEntry === OrsEntryType.UNIFIED) {
		return (
			<ContractedServiceOrsUnifiedForm
				selectedEntry={selectedEntry}
				selectedPetition={selectedPetition}
				selectedOperation={selectedOperation}
				services={services}
				onServiceRemove={onServiceRemove}
				onSend={onSend}
				serviceOrsRepository={serviceOrsRepository}
				orsStrategy={orsStrategy}
			/>
		)
	}
	return <></>
}

export { ContractedServiceOrsForm }
