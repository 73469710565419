import { ContractedServiceWithDetails } from 'modules/contractedService/domain/ContractedServiceWithDetails'
import { ContractedServiceOperation } from 'modules/contractedService/domain/management/operation/ContractedServiceOperation'
import { Offer } from 'modules/offer/domain/Offer'
import { FC } from 'react'
import { TermsAndConditionsCommercialConditions } from './conditions/TermsAndConditionsCommercialConditions'
import { TermsAndConditionsContractConditions } from './conditions/TermsAndConditionsContractConditions'
import { TermsAndConditionsRemarks } from './conditions/TermsAndConditionsRemarks'
import { TermsAndConditionsEconomicAssesment } from './economic-assesment/TermsAndConditionsEconomicAssesment'
import { TermsAndConditionsOperationSummary } from './summary/TermsAndConditionsOperationSummary'

type Props = {
	offer?: Offer
	contractedServiceWithDetails: ContractedServiceWithDetails
	contractedServiceOperation: ContractedServiceOperation
}

const TermsAndConditionsContent: FC<Props> = ({ offer, contractedServiceWithDetails, contractedServiceOperation }) => {
	return (
		<div className="content d-flex flex-column gap-5 align-items-start">
			<TermsAndConditionsOperationSummary
				offer={offer}
				contractedServiceDetail={contractedServiceWithDetails}
				contractedServiceOperation={contractedServiceOperation}
			/>
			<TermsAndConditionsEconomicAssesment
				contractedServiceWithDetails={contractedServiceWithDetails}
				contractedServiceOperation={contractedServiceOperation}
			/>
			<TermsAndConditionsCommercialConditions />
			<TermsAndConditionsContractConditions />
			<TermsAndConditionsRemarks />
		</div>
	)
}
export { TermsAndConditionsContent }
