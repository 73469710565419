import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { ContractedServiceDetailsModal } from 'components/services/detail/modal/ContractedServiceDetailsModal'
import { useContractedServicesTable } from 'components/services/management/hooks/useContractedServicesTable'
import { ContractedServiceManageModal } from 'components/services/management/modal/ContractedServiceManageModal'
import { ContractedServiceOrsModal } from 'components/services/ors/modal/ContractedServiceOrsModal'
import { ContractedServiceVersionsModalHOC } from 'components/services/version/modal/ContractedServiceVersionsModal'
import { HttpContractedServiceDetailRepository } from 'modules/contractedService/infrastructure/detail/HttpContractedServiceDetailRepository'
import { HttpContractedServiceRepository } from 'modules/contractedService/infrastructure/HttpContractedServiceRepository'
import { HttpContractedServiceManagementRepository } from 'modules/contractedService/infrastructure/management/operation/HttpContractedServiceManagementRepository'
import { HttpContractedServiceOrsRepository } from 'modules/contractedService/infrastructure/ors/HttpContractedServiceOrsRepository'
import { HttpOfferRepository } from 'modules/offer/infrastructure/HttpOfferRepository'
import {
	colsContractedServicesExternal,
	colsContractedServicesInternal,
} from 'pages/service/management/ContractedServicesColData'
import { useContext, useMemo, useRef } from 'react'
import { Filter, Table, TableHandle, UfinetModal, UfinetSectionBox } from 'ufinet-web-components'
import { FilterHandle } from 'ufinet-web-components/dist/components/filters/Filter'
import {
	AuthContext,
	clientService,
	corporateGroupService,
	countriesService,
	FrontEndApplication,
	useInternalUser,
	useTranslator,
} from 'ufinet-web-functions'

const ContractedServicesTable = () => {
	const translate = useTranslator()
	const internalUser = useInternalUser()
	const authData = useContext(AuthContext)

	const contractedServiceRepository = useMemo(() => HttpContractedServiceRepository(authData), [authData])
	const _countriesService = useMemo(() => countriesService(authData), [authData])
	const _corporateGroupService = useMemo(() => corporateGroupService(authData), [authData])
	const _clientService = useMemo(
		() => clientService(authData, { frontendApplication: FrontEndApplication.GDE01 }),
		[authData]
	)

	const serviceManagementRepository = useMemo(() => HttpContractedServiceManagementRepository(authData), [authData])
	const serviceDetailRepository = useMemo(() => HttpContractedServiceDetailRepository(authData), [authData])
	const serviceOrsRepository = useMemo(() => HttpContractedServiceOrsRepository(authData), [authData])

	const offerRepository = useMemo(() => HttpOfferRepository(authData), [authData])

	const filterRef = useRef<FilterHandle>(null)
	const tableRef = useRef<TableHandle>(null)

	const { filter, header, content, pagination, focusedService, manageModal, detailsModal, versionsModal, orsModal } =
		useContractedServicesTable({
			tableRef,
			repository: {
				clients: _clientService,
				corporateGroups: _corporateGroupService,
				countries: _countriesService,
				contractedService: contractedServiceRepository,
				serviceManagement: serviceManagementRepository,
			},
		})

	return (
		<>
			<UfinetSectionBox className="mb-5">
				<div className="row justify-content-between align-items-end">
					<Filter
						ref={filterRef}
						internalUser={internalUser}
						afterCountryChange={filter.onCountriesChange}
						afterCorporateGroupChange={filter.onCorporateGroupsChange}
						afterClientChange={filter.onClientsChange}
						disabled={filter.isDisabled}
						hidden={{
							hideCountry: !internalUser,
							hideContact: true,
							hideReference: true,
							hideSubmit: false,
						}}
						multi={{ multiClient: true, multiCorporateGroup: true, multiCountry: true }}
						required={false}
						preSelected
						setFilter={filter.set}
						icon={faSearch}
						submitButtonContent={translate('SEARCH')}
					/>
				</div>
			</UfinetSectionBox>

			<UfinetSectionBox className="p-7">
				<Table
					actionBodyTemplate={content.getServiceActionBodyTemplate}
					afterTablePFSEvent={pagination.onPageFilterSortEvent}
					cols={internalUser ? colsContractedServicesInternal(translate) : colsContractedServicesExternal(translate)}
					content={content.values}
					emptyMessage={content.isLoading ? translate('LOADING_DOTS') : undefined}
					filterOnDisplayedData={false}
					headerButtons={header.buttons}
					lazySettings={pagination.settings}
					selectedValues={content.selected}
					onSelectionChange={content.onSelectionChange}
					onFilterClear={filter.onClear}
					ref={tableRef}
				/>
			</UfinetSectionBox>

			<UfinetModal
				size="xl"
				show={orsModal.state}
				handleClose={orsModal.onClose}
				showCloseButton
				title={translate('CONTRACT.SERVICE.CREATE_ORS.TITLE')}
			>
				<ContractedServiceOrsModal
					services={content.selectedVersion ? [content.selectedVersion] : content.selected}
					onServiceRemove={orsModal.onServiceRemove}
					serviceOrsRepository={serviceOrsRepository}
					isOldServiceVersion={content.selectedVersion !== undefined}
					onSend={orsModal.onSend}
				/>
			</UfinetModal>

			{focusedService.value && (
				<ContractedServiceVersionsModalHOC
					serviceSelected={focusedService.value}
					serviceRepository={contractedServiceRepository}
					onServiceVersionSelected={versionsModal.onVersionSelected}
					modalShown={versionsModal.state}
					modalCloseFn={versionsModal.hide}
				/>
			)}

			{focusedService.value?.detailedAttributes && (
				<UfinetModal
					size="m"
					show={detailsModal.state}
					handleClose={detailsModal.hide}
					showCloseButton={false}
					title={detailsModal.title}
				>
					<ContractedServiceDetailsModal
						serviceAttributes={focusedService.value.detailedAttributes}
						closeModal={detailsModal.hide}
					/>
				</UfinetModal>
			)}

			{focusedService.value && !focusedService.isValidating && !focusedService.validationError && (
				<UfinetModal
					size="xxl"
					show={manageModal.state}
					handleClose={manageModal.hide}
					title={manageModal.title}
					stickyHeader
					showCloseButton={false}
					preventCloseOnEscapeKey
				>
					<ContractedServiceManageModal
						service={focusedService.value}
						closeModal={manageModal.hide}
						contractedServiceDetailRepository={serviceDetailRepository}
						offerRepository={offerRepository}
						onOfferConfirmed={manageModal.onOfferConfirmed}
					/>
				</UfinetModal>
			)}
		</>
	)
}

export { ContractedServicesTable }
