import { ContractedServiceVersionFindRequest } from 'modules/contractedService/application/find/version/dto/ContractedServiceVersionFindRequest'
import { ContractedService } from 'modules/contractedService/domain/ContractedService'
import { ContractedServiceRepository } from 'modules/contractedService/domain/repository/ContractedServiceRepository'
import { useQuery } from 'react-query'

export const useContractedServiceVersionsFindQuery = (
	contractedServiceRepository: ContractedServiceRepository,
	contractedServiceVersionFindRequest: ContractedServiceVersionFindRequest,
	{
		enabled = true,
		onSuccess,
		onError,
	}: {
		enabled?: boolean
		onSuccess?: (contractedServices: ContractedService[]) => void
		onError?: (error: unknown) => void
	} = {}
) => {
	return useQuery(
		['contracted-services-versions-find-query', contractedServiceVersionFindRequest.serviceId],
		() => contractedServiceRepository.findServiceVersions(contractedServiceVersionFindRequest, { notifyError: false }),
		{
			staleTime: 180000,
			enabled,
			onSuccess,
			onError,
		}
	)
}
