import { ManagementOperation } from 'modules/contractedService/domain/management/operation/ManagementOperation'
import { ContractedServiceManagementRepository } from 'modules/contractedService/domain/repository/ContractedServiceManagementRepository'
import { useQuery } from 'react-query'

export const useManagementOperationFindAllQuery = (
	contractedServiceManagementRepository: ContractedServiceManagementRepository,
	{
		enabled = true,
		onSuccess,
		onError,
	}: {
		enabled?: boolean
		onSuccess?: (contractedServiceManagementOperations: ManagementOperation[]) => void
		onError?: (error: unknown) => void
	} = {}
) => {
	return useQuery(
		'management-operation-find-all-query',
		() => contractedServiceManagementRepository.getAllOperations(),
		{
			enabled,
			select: (response) => response.operations,
			onSuccess,
			onError,
		}
	)
}
