import { rest } from 'msw'
import { ICorporateGroup } from 'ufinet-web-functions'
import { CorporateGroupMother } from '../domain/CorporateGroupMother'

export const corporateGroupHandlers = [
	rest.get('*api/corporate-group', (req, res, ctx) => {
		return res(ctx.status(200), ctx.json([CorporateGroupMother.ifx()] as ICorporateGroup[]))
	}),
	rest.post('*api/corporate-group/find/byCountry', (req, res, ctx) => {
		return res(ctx.status(200), ctx.json([]))
	}),
]
