import { ContractedServicesOrsReasonsResponse } from 'modules/contractedService/application/ors/petition/find/dto/ContractedServicesOrsReasonsResponse'
import { OrsReasonFindForPetitionType } from 'modules/contractedService/application/ors/petition/find/dto/OrsReasonFindForPetitionType'
import { ContractedServiceOrsRepository } from 'modules/contractedService/domain/repository/ContractedServiceOrsRepository'
import { useQuery } from 'react-query'

export const useOrsReasonFindAllQuery = (
	contractedServiceOrsReasonRepository: ContractedServiceOrsRepository,
	petition: OrsReasonFindForPetitionType,
	{
		enabled = true,
		onSuccess,
		onError,
	}: {
		enabled?: boolean
		onSuccess?: (contractedServiceOrsReasons: ContractedServicesOrsReasonsResponse) => void
		onError?: (error: unknown) => void
	} = {}
) => {
	return useQuery(
		['ors-reason-find-all-query', petition],
		() => contractedServiceOrsReasonRepository.getAllReasons(petition),
		{
			enabled,
			select: (data) => data as ContractedServicesOrsReasonsResponse,
			onSuccess,
			onError,
		}
	)
}
