import { useBaseManageFormValidation } from 'components/services/management/modal/wizard/modify-service/manage/inner/base/hooks/validation/useBaseManageFormValidation'
import { ManageFormValidationCreator } from 'components/services/management/modal/wizard/modify-service/manage/inner/base/manageFormValidationCreator'
import { useFormik } from 'formik'
import { ContractedServiceOperationDataImmediate } from 'modules/contractedService/domain/management/operation/immediate/ContractedServiceOperationImmediate'
import * as Yup from 'yup'

export type ImmediateManageFormParameters = Partial<ContractedServiceOperationDataImmediate>
type ImmediateManageFormSchema = {
	[key in keyof ImmediateManageFormParameters]: Yup.SchemaOf<any>
}

const useImmediateManageFormValidation: ManageFormValidationCreator<ContractedServiceOperationDataImmediate> = () => {
	const {
		formik: baseFormik,
		initialValues: baseInitialValues,
		validationSchema: baseValidationSchema,
		loadingControls,
	} = useBaseManageFormValidation()

	const initialValues: Partial<ContractedServiceOperationDataImmediate> = { ...baseInitialValues }
	const validationSchema = baseValidationSchema.shape<ImmediateManageFormSchema>({})

	const formik = useFormik<ImmediateManageFormParameters>({
		...baseFormik,
		initialValues,
		validationSchema,
		onSubmit: baseFormik.submitForm,
		onReset: () => baseFormik.resetForm(),
	})

	return { formik, initialValues, validationSchema, loadingControls }
}

export { useImmediateManageFormValidation }
