import { FC, useMemo } from 'react'
import { useTranslator } from 'ufinet-web-functions'

const TermsAndConditionsContractConditions: FC<{}> = () => {
	const translate = useTranslator()

	const conditions = useMemo(
		() => [
			'De ser aceptada la presente oferta, debe ser firmada y sellada por un representante autorizado de la empresa y será incorporada como ANEXO al contrato maestro firmado (MSA) y formará parte integral del mismo, y se regirá por lo que en el contrato se disponga en lo que sea aplicable. En ningún caso se aceptarán modificaciones verbales.',
			'La vigencia contractual del servicio comenzará a partir de la notificación a EL CLIENTE vía correo electrónico u otro medio, de la entrega e instalación del mismo, con la información necesaria para que EL CLIENTE lo configure. El servicio se considerará aceptado al transcurrir un término de setenta y dos (72) horas a partir de recibir la notificación antes mencionada, si EL CLIENTE no manifiesta inconformidad por una causa técnica justificada que deberá ser validada por UFINET; y, se procederá a enviar a contabilidad para el inicio de su facturación.',
			'Si el servicio es cancelado por parte de EL CLIENTE durante el proceso de instalación, tendrá una penalización como se indica en los puntos de abajo "Penalizaciones".',
		],
		[]
	)

	return (
		<div>
			<p className="terms-and-conditions-header">{translate('CONTRACT.SERVICE.TERMS.CONDITIONS.CONTRACT')}</p>
			<ol>
				{conditions.map((it, idx) => (
					<li key={idx}>{it}</li>
				))}
			</ol>
		</div>
	)
}
export { TermsAndConditionsContractConditions }
