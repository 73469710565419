import { ContractedServiceOrsRepository } from 'modules/contractedService/domain/repository/ContractedServiceOrsRepository'
import { useQuery } from 'react-query'
import { ContractedServicesOrsPetitionsResponse } from './dto/ContractedServicesOrsPetitionsResponse'

export const useOrsPetitionFindAllQuery = (
	contractedServiceOrsRepository: ContractedServiceOrsRepository,
	{
		enabled = true,
		onSuccess,
		onError,
	}: {
		enabled?: boolean
		onSuccess?: (contractedServiceOrsPetitions: ContractedServicesOrsPetitionsResponse) => void
		onError?: (error: unknown) => void
	} = {}
) => {
	return useQuery('ors-petition-find-all-query', () => contractedServiceOrsRepository.getAllPetitions(), {
		enabled,
		select: (data) => data as ContractedServicesOrsPetitionsResponse,
		onSuccess,
		onError,
	})
}
