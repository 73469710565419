import { ContractedService } from 'modules/contractedService/domain/ContractedService'
import { ColData } from 'ufinet-web-components'
import { noContentPlaceholder, TranslatorFunction } from 'ufinet-web-functions'

type HookInput = {
	translate: TranslatorFunction
}

type HookOutput = {
	columns: ColData<ContractedService>[]
}
const useContractedServiceVersionsColsData: (input: HookInput) => HookOutput = ({ translate }) => {
	const columns: ColData<ContractedService>[] = [
		{
			field: 'version',
			header: <>{translate('CONTRACT.SERVICE.VERSIONS.TABLE.VERSION_NUMBER')}</>,
			nonFilterable: true,
			nonSortable: false,
			nonSearchable: true,
		},
		{
			field: 'administrativeCode',
			header: <>{translate('CONTRACT.SERVICE.VERSIONS.TABLE.ADMINISTRATIVE_CODE')}</>,
			nonFilterable: true,
			nonSortable: true,
			nonSearchable: true,
		},
		{
			field: 'serviceName',
			header: <>{translate('CONTRACT.SERVICE.VERSIONS.TABLE.SERVICE_NAME')}</>,
			nonFilterable: true,
			nonSortable: true,
			nonSearchable: true,
		},
		{
			field: 'state',
			header: <>{translate('CONTRACT.SERVICE.VERSIONS.TABLE.SERVICE_STATE')}</>,
			nonFilterable: true,
			nonSortable: true,
			nonSearchable: true,
			body: (row: ContractedService) =>
				!row.state ? noContentPlaceholder : translate(`CONTRACT.SERVICE.STATE.${row.state.value}`),
		},
	]
	return {
		columns,
	}
}

export { useContractedServiceVersionsColsData }
