import { ContractedServiceManageFormContextProvider } from 'components/services/management/modal/wizard/modify-service/manage/ContractedServiceManageFormContextProvider'
import { ContractedServiceManageInnerForm } from 'components/services/management/modal/wizard/modify-service/manage/inner/ContractedServiceManageInnerForm'
import { FormikProps } from 'formik'
import { ClientParameters } from 'modules/client/parameters/domain/ClientParameters'
import { ContractedService } from 'modules/contractedService/domain/ContractedService'
import { ContractedServiceDetail } from 'modules/contractedService/domain/detail/ContractedServiceDetail'
import {
	ContractedServiceOperation,
	ContractedServiceOperationData,
} from 'modules/contractedService/domain/management/operation/ContractedServiceOperation'
import { ContractType } from 'modules/contractedService/domain/management/operation/ContractType'
import { ManagementOperation } from 'modules/contractedService/domain/management/operation/ManagementOperation'

import { ServiceManagementErrorHandler } from 'modules/contractedService/domain/ServiceManagementErrorHandler'
import { FC } from 'react'

type Props = {
	service: ContractedServiceDetail & ContractedService
	clientParameters: {
		value?: ClientParameters
		onChange: (clientParameters?: ClientParameters) => void
	}
	contractedServiceOperation: {
		selected: ManagementOperation[]
		value?: ContractedServiceOperation
		onChange: (contractedServiceOperation?: ContractedServiceOperation) => void
	}
	contractType: {
		value?: ContractType
		onChange: (contractType?: ContractType) => void
	}
	onEvent: {
		onError: ServiceManagementErrorHandler
		onEditing?: (editing: boolean) => void
		onFormChange?: (formState: FormikProps<Partial<ContractedServiceOperationData>>) => void
	}
}

const ContractedServiceManageForm: FC<Props> = ({
	service,
	clientParameters,
	contractType,
	contractedServiceOperation,
	onEvent,
}) => {
	if (!contractedServiceOperation.selected.length) return <></>

	return (
		<ContractedServiceManageFormContextProvider
			service={service}
			clientParameters={clientParameters}
			contractedServiceOperation={{
				contractType: contractType.value,
				selected: contractedServiceOperation.selected,
				value: contractedServiceOperation.value,
				onChange: contractedServiceOperation.onChange,
			}}
			onEvent={onEvent}
		>
			<ContractedServiceManageInnerForm onContractTypeChange={contractType.onChange} />
		</ContractedServiceManageFormContextProvider>
	)
}

export { ContractedServiceManageForm }
