import { IUfinetSelectOption } from 'ufinet-web-components'
import { OrsPetition } from '../OrsPetition'

export const orsPetitionFromSelectedType = (selected: IUfinetSelectOption): OrsPetition => {
	const petition: OrsPetition | undefined = Object.values(OrsPetition)
		.filter((value): value is OrsPetition => typeof value === 'number')
		.find((petition) => petition === parseInt(selected.value || ''))

	if (!petition) {
		throw new Error('Cannot get any petition from the given selection')
	}

	return petition
}
