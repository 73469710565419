import { rest } from 'msw'
import { clientHandlers } from 'tests/modules/client/application/clientHandlers'
import { managementOperationHandlers } from 'tests/modules/contractedService/application/management/operation/managementOperationHandlers'
import { petitionTrackingHandlers } from 'tests/modules/contractedService/application/management/tracking/petitionTrackingHandlers'
import { orsExceptionHandlers } from 'tests/modules/contractedService/application/ors/exception/orsExceptionHandlers'
import { orsPetitionHandlers } from 'tests/modules/contractedService/application/ors/petition/orsPetitionHandlers'
import { corporateGroupHandlers } from 'tests/modules/corporate-group/application/corporateGroupHandlers'
import { countryHandlers } from 'tests/modules/country/application/countryHandlers'

const baseHandlers = [
	rest.all(/^(?!.*api\/).*$/, (req, res, ctx) => {
		return res(ctx.status(200), ctx.json([]))
	}),
]

export const handlers = [
	...managementOperationHandlers,
	...countryHandlers,
	...corporateGroupHandlers,
	...clientHandlers,
	...petitionTrackingHandlers,
	...orsPetitionHandlers,
	...orsExceptionHandlers,
	...baseHandlers,
]
