import { Offer } from 'modules/offer/domain/Offer'
import { useInternalUser, useTranslator } from 'ufinet-web-functions'
import { useModalButtonsText } from '../../../common/hooks/useModalButtonsText'
import { fireModalBeforeCloseExternalUser, fireModalBeforeCloseInternalUser } from '../modals/beforeCloseModal'

const useBeforeCloseConfirmResume: () => {
	beforeCloseConfirmResume: (input: { offer?: Offer; onConfirm: () => void }) => Promise<boolean>
} = () => {
	const translate = useTranslator()
	const internalUser = useInternalUser()

	const modalButtonsText = useModalButtonsText()

	const getTextId = (internalUser: boolean) => {
		return internalUser
			? 'CONTRACT.SERVICE.MANAGEMENT.INTERNAL_USER_CLOSE_CONFIRMATION_MESSAGE'
			: 'CONTRACT.SERVICE.MANAGEMENT.EXTERNAL_USER_CLOSE_CONFIRMATION_MESSAGE'
	}

	return {
		beforeCloseConfirmResume: ({ offer, onConfirm }) => {
			if (!offer) return Promise.resolve(true)

			const text = translate(getTextId(internalUser), { offerId: offer.id })

			internalUser
				? fireModalBeforeCloseInternalUser(text, modalButtonsText, onConfirm)
				: fireModalBeforeCloseExternalUser(text, modalButtonsText, onConfirm)

			return Promise.resolve(false)
		},
	}
}

export { useBeforeCloseConfirmResume }
