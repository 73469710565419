import { ContractedServiceDetail } from 'modules/contractedService/domain/detail/ContractedServiceDetail'
import { ContractedServiceDetailRepository } from 'modules/contractedService/domain/repository/ContractedServiceDetailRepository'
import { useQuery } from 'react-query'
import { formatDestination, formatOrigin } from '../../management/formatLocation'
import { ContractedServiceDetailFindRequest } from './dto/ContractedServiceDetailFindRequest'

export const useContractedServiceDetailFindQuery = (
	contractedServiceRepository: ContractedServiceDetailRepository,
	contractedServiceDetailRequest: ContractedServiceDetailFindRequest,
	{
		enabled = true,
		onSuccess,
		onError,
	}: {
		enabled?: boolean
		onSuccess?: (contractedServiceDetail: ContractedServiceDetail) => void
		onError?: (error: unknown) => void
	} = {}
) => {
	const { serviceId } = contractedServiceDetailRequest
	return useQuery(
		['contracted-service-detail-query', serviceId],
		() => contractedServiceRepository.getServiceDetail(contractedServiceDetailRequest, { notifyError: false }),
		{
			staleTime: 0,
			enabled,
			select: (response) => ({
				...response,
				origin: formatOrigin(response.origin),
				destination: formatDestination(response.destination),
			}),
			onSuccess,
			onError,
		}
	)
}
