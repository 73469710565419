import { ContractedService } from 'modules/contractedService/domain/ContractedService'
import { OrsEntryType } from 'modules/contractedService/domain/ors/petition/base/OrsEntryType'
import { OrsPetition } from 'modules/contractedService/domain/ors/petition/base/OrsPetition'
import { ContractedServiceOrsRepository } from 'modules/contractedService/domain/repository/ContractedServiceOrsRepository'
import { FC } from 'react'
import { IUfinetSelectOption, UfinetCheckBoxGroup, UfinetRadioButtonGroup } from 'ufinet-web-components'
import { useTranslator } from 'ufinet-web-functions'
import { OrsPetitionSelect } from '../selects/OrsPetitionSelect'
import { ContractedServiceOrsForm } from './forms/ContractedServiceOrsForm'
import { useContractedServiceOrsModal } from './hooks/useContractedServiceOrsModal'

type Props = {
	onServiceRemove: (id: string) => void
	onSend: () => void
	services: ContractedService[]
	serviceOrsRepository: ContractedServiceOrsRepository
	isOldServiceVersion?: boolean
}

const ContractedServiceOrsModal: FC<Props> = ({
	services,
	onServiceRemove,
	onSend,
	serviceOrsRepository,
	isOldServiceVersion = false,
}) => {
	const {
		selectedEntry,
		selectedOperation,
		selectedPetition,
		allowedPetitions,
		entryTypeOptions,
		operationTypeOptions,
		showForm,
		onChange,
		orsStrategy,
	} = useContractedServiceOrsModal({ isOldServiceVersion })

	const translate = useTranslator()
	return (
		<div className="row">
			<div className="col-lg d-flex flex-column gap-5">
				<OrsPetitionSelect
					preSelectedPetition={selectedPetition}
					allowedPetitions={allowedPetitions}
					value={
						{
							label: translate(`CONTRACT.SERVICE.ORS.PETITION.${OrsPetition[selectedPetition]}`),
							value: selectedPetition.toString(),
						} as IUfinetSelectOption
					}
					onPetitionChange={onChange.petition}
					serviceOrsRepository={serviceOrsRepository}
				/>
				<div className="row">
					<UfinetRadioButtonGroup
						title={translate('ORS.ENTRY.TYPE')}
						titleClassName="fw-bolder"
						className="col"
						selection={selectedEntry}
						onChange={(entry) => onChange.entry(entry?.value as OrsEntryType)}
						inline
						options={entryTypeOptions}
						disabled={false}
					/>
					{orsStrategy.enableOperations && (
						<UfinetCheckBoxGroup
							title={translate(`ORS.OPERATION.TYPE.TITLE.${OrsPetition[selectedPetition]}`)}
							titleClassName="fw-bolder"
							className="col"
							onChange={onChange.reactivation}
							selection={selectedOperation}
							inline
							options={operationTypeOptions}
							disabled={false}
						/>
					)}
				</div>
				{showForm && (
					<ContractedServiceOrsForm
						services={services}
						selectedEntry={selectedEntry!}
						selectedOperation={selectedOperation!}
						selectedPetition={selectedPetition!}
						onServiceRemove={onServiceRemove}
						onSend={onSend}
						serviceOrsRepository={serviceOrsRepository}
						orsStrategy={orsStrategy}
					/>
				)}
			</div>
		</div>
	)
}

export { ContractedServiceOrsModal }
