import { BaseManageForm } from 'components/services/management/modal/wizard/modify-service/manage/inner/base/BaseManageForm'
import { ContractedServiceOperationTemporal } from 'modules/contractedService/domain/management/operation/temporary/ContractedServiceOperationTemporary'
import { FC } from 'react'
import { DatePicker, DatePickerTypeEnum, DeadlineSelect, IUfinetSelectOption } from 'ufinet-web-components'
import { useTranslator } from 'ufinet-web-functions'
import { useTemporaryManageForm } from './hooks/useTemporaryManageForm'

const TemporaryManageForm: FC = () => {
	const translate = useTranslator()

	const { operationPeriod, awardDate, operationDate, onChange, form, errorMessage } = useTemporaryManageForm()

	return (
		<>
			{errorMessage ? (
				<div className="text-danger ">{errorMessage}</div>
			) : (
				<BaseManageForm
					isValidOperationForCalculation={ContractedServiceOperationTemporal.isValidForCalculation}
					formValidationCreator={form.validator}
					additionalFields={{
						below: (
							<div className="row row-gap-4">
								<div className="col-2 col-md-4 col-sm-12">
									<DeadlineSelect
										requiredIcon={false}
										noTooltip
										includeDefaultOptions={false}
										labelTitle={translate('CONTRACT.SERVICE.OPERATION.TEMPORAL.DURATION')}
										value={operationPeriod.value}
										options={operationPeriod.options}
										unit={{ single: translate('DAY'), plural: translate('DAYS') }}
										isDisabled={false}
										isMulti={false}
										onChange={(period) => onChange.operationPeriod?.(period as IUfinetSelectOption)}
									/>
								</div>
								<div className="col-2 col-md-4 col-sm-12">
									<DatePicker
										label={translate('CONTRACT.SERVICE.OPERATION.TEMPORAL.SCHEDULED.START_DATE')}
										type={DatePickerTypeEnum.DATE_ONLY}
										showCalendarIcon
										value={awardDate.value}
										onChange={onChange.awardDate}
										min={awardDate.min}
										max={awardDate.max}
									/>
								</div>
								<div className="col-2 col-md-4 col-sm-12">
									<DatePicker
										label={translate('CONTRACT.SERVICE.OPERATION.TEMPORAL.END_DATE')}
										type={DatePickerTypeEnum.DATE_ONLY}
										disabled
										showCalendarIcon
										value={operationDate.value}
										onChange={() => {}}
									/>
								</div>
							</div>
						),
					}}
				/>
			)}
		</>
	)
}

export { TemporaryManageForm }
