import { defaultOptionsFetch, FetchAPI, FetchOptions, IAuthData } from 'ufinet-web-functions'
import { ClientParametersDeleteRequest } from '../application/delete/dto/ClientParametersDeleteRequest'
import { ClientParametersFindRequest } from '../application/find/dto/ClientParametersFindRequest'
import { ClientParametersFindResponse } from '../application/find/dto/ClientParametersFindResponse'
import { ClientParametersSaveRequest } from '../application/save/dto/ClientParametersSaveRequest'
import { ClientParametersUpdateRequest } from '../application/update/dto/ClientParametersUpdateRequest'
import { ClientParametersRepository } from '../domain/repository/clientParametersRepository'

const url = `${process.env.REACT_APP_URL_BACK_GSE}api/client/parameters`

export const HttpClientParametersRepository: (authData: IAuthData) => ClientParametersRepository = (authData) => {
	const fetchOptions: FetchOptions = {
		...defaultOptionsFetch,
		authenticationData: authData,
		sendApiKey: true,
	}

	return {
		findAll: async (options?: FetchOptions): Promise<ClientParametersFindResponse[]> =>
			await FetchAPI.get(url, {
				...fetchOptions,
				...options,
			}),

		findOne: async (id: number, options?: FetchOptions): Promise<ClientParametersFindResponse> =>
			await FetchAPI.get(`${url}/${id}`, {
				...fetchOptions,
				...options,
			}),

		find: async (
			requestData: ClientParametersFindRequest,
			options?: FetchOptions
		): Promise<ClientParametersFindResponse> =>
			await FetchAPI.post(`${url}/find`, requestData, {
				...fetchOptions,
				...options,
			}),

		create: async (requestData: ClientParametersSaveRequest, options?: FetchOptions): Promise<void> =>
			await FetchAPI.post(url, requestData, {
				...fetchOptions,
				...options,
			}),

		update: async (requestData: ClientParametersUpdateRequest, options?: FetchOptions): Promise<void> =>
			await FetchAPI.put(`${url}/${requestData.id}`, requestData, {
				...fetchOptions,
				...options,
			}),

		deleteOne: async (id: number, options?: FetchOptions): Promise<void> =>
			await FetchAPI.delete(`${url}/${id}`, {
				...fetchOptions,
				...options,
			}),

		deleteMany: async (requestData: ClientParametersDeleteRequest, options?: FetchOptions): Promise<void> =>
			await FetchAPI.deleteWithBody(url, requestData, {
				...fetchOptions,
				...options,
			}),
	}
}
