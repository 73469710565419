import { rest } from 'msw'
import { IClientResponse } from 'ufinet-web-functions'
import { ClientMother } from '../domain/ClientMother'

export const clientHandlers = [
	rest.get('*api/client/find', (req, res, ctx) => {
		return res(ctx.status(200), ctx.json([ClientMother.ifx_networks_colombia_sas()] as IClientResponse[]))
	}),
	rest.post('*api/client/find', (req, res, ctx) => {
		return res(ctx.status(200), ctx.json([ClientMother.ifx_networks_colombia_sas()] as IClientResponse[]))
	}),
]
