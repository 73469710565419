import { TemporaryPricingRequest } from 'modules/contractedService/application/management/calculate/dto/TemporaryPricingRequest'
import { ContractType } from 'modules/contractedService/domain/management/operation/ContractType'
import { ManagementOperation } from 'modules/contractedService/domain/management/operation/ManagementOperation'

export type PricingRequest = {
	contractedServiceId: string
	bandwidthId: string
	totalPrice: {
		nrc: number
		mrc: number
	}
	serviceTypeId: number
	clientId: string
	countryId: string
	period: number
	operations: ManagementOperation[]
}

export const validatePricingRequest = (pricingRequest: PricingRequest, contractType: ContractType) => {
	if (!pricingRequest.clientId)
		return Promise.reject(new Error('clientId cannot be undefined when calculating operation pricing'))
	if (!pricingRequest.countryId)
		return Promise.reject(new Error('countryId cannot be undefined when calculating operation pricing'))
	if (contractType === ContractType.TEMPORAL) {
		if ((pricingRequest as TemporaryPricingRequest).days === undefined)
			return Promise.reject(
				new Error('Operation days cannot be undefined when calculating temporary operation pricing')
			)
	}
}
