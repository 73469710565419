import { BaseManageForm } from 'components/services/management/modal/wizard/modify-service/manage/inner/base/BaseManageForm'
import { ContractedServiceOperationImmediate } from 'modules/contractedService/domain/management/operation/immediate/ContractedServiceOperationImmediate'
import { FC } from 'react'
import { useImmediateManageFormValidation } from './hooks/useImmediateManageFormValidation'

const ImmediateManageForm: FC = () => {
	return (
		<BaseManageForm
			isValidOperationForCalculation={ContractedServiceOperationImmediate.isValidForCalculation}
			formValidationCreator={useImmediateManageFormValidation}
		/>
	)
}

export { ImmediateManageForm }
