import { useContractedServiceManageFormContext } from 'components/services/management/modal/wizard/modify-service/manage/ContractedServiceManageFormContextProvider'
import { useTranslator } from 'ufinet-web-functions'

type HookInput = {
	isDeadlineIncrease: boolean
	onSuccess: (deadline: number) => void
	onError: (deadline: number, error: Error) => void
}

type HookOutput = {
	validateOperationDeadline: (deadline: number) => void
}

type DeadlineType = 'deadline' | 'remainingDeadline'

function useOperationDeadlineValidation({ isDeadlineIncrease, onSuccess, onError }: HookInput): HookOutput {
	const translate = useTranslator()

	const { service } = useContractedServiceManageFormContext()

	const validateDeadline = (newDeadline: number, isIncrease: boolean) => {
		const deadlineType: DeadlineType = service.dueDate ? 'remainingDeadline' : 'deadline'
		const deadline = service[deadlineType]

		if (!deadline) return

		const isValid = isIncrease ? newDeadline > deadline : newDeadline < deadline

		if (newDeadline === undefined || !isValid) {
			const errorMessage = translate(
				`CONTRACT.SERVICE.MANAGEMENT.DEADLINE.VALIDATION.${isIncrease ? 'GREATER' : 'LOWER'}`,
				{
					currentDeadline: deadline,
				}
			)
			throw new Error(errorMessage)
		}
	}

	const validateOperationDeadline = (deadline: number) => {
		try {
			validateDeadline(deadline, isDeadlineIncrease)
			onSuccess(deadline)
		} catch (error) {
			onError(deadline, error as Error)
		}
	}

	return {
		validateOperationDeadline,
	}
}

export { useOperationDeadlineValidation }
