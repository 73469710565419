import { ClientParametersFindResponse } from 'modules/client/parameters/application/find/dto/ClientParametersFindResponse'
import { ContractType } from 'modules/contractedService/domain/management/operation/ContractType'

export const getAllowedContractTypesFromClientParameters = (
	clientParametersServerResponse?: ClientParametersFindResponse
): ContractType[] =>
	[
		clientParametersServerResponse?.immediate && ContractType.IMMEDIATE,
		clientParametersServerResponse?.programmed && ContractType.SCHEDULED,
		clientParametersServerResponse?.temporary && ContractType.TEMPORAL,
	].filter(Boolean) as ContractType[]
