import dayjs from 'dayjs'
import { ContractedServiceOperation } from 'modules/contractedService/domain/management/operation/ContractedServiceOperation'
import { ContractType } from 'modules/contractedService/domain/management/operation/ContractType'
import { ContractedServiceOperationScheduled } from 'modules/contractedService/domain/management/operation/scheduled/ContractedServiceOperationScheduled'
import { ContractedServiceOperationTemporal } from 'modules/contractedService/domain/management/operation/temporary/ContractedServiceOperationTemporary'
import { getNrcAdjustmentForOffer } from 'modules/offer/application/create/getNrcAdjustmentForOffer'
import { Offer } from 'modules/offer/domain/Offer'
import { OfferStatus, useTranslator } from 'ufinet-web-functions'

type HookInput = {
	offer?: Offer
	contractedServiceOperation: ContractedServiceOperation
}

type HookOutput = {
	creationDate: string
	awardDate?: string
	operationDeadline: string
	rollbackDate?: string
	operationDuration?: string
	displayNrc: string
}

function useConfirmResumeToHire({ offer, contractedServiceOperation }: HookInput): HookOutput {
	const translate = useTranslator()

	const creationDate = dayjs().format('DD/MM/YYYY HH:mm')
	const contractType = contractedServiceOperation.contractType

	const awardDate =
		offer?.status === OfferStatus.IN_ELABORATION || contractType === ContractType.IMMEDIATE
			? undefined
			: dayjs((contractedServiceOperation as ContractedServiceOperationScheduled).modifyAt).format('DD/MM/YYYY')

	const rollbackDate =
		contractType === ContractType.TEMPORAL
			? dayjs((contractedServiceOperation as ContractedServiceOperationTemporal).rollbackAt).format('DD/MM/YYYY')
			: undefined

	const operationDuration =
		contractType !== ContractType.TEMPORAL
			? undefined
			: (contractedServiceOperation as ContractedServiceOperationTemporal).period +
			  ` ${
					(contractedServiceOperation as ContractedServiceOperationTemporal).period === 1
						? translate('DAY')
						: translate('DAYS')
			  }`.toLowerCase()

	const operationDeadline =
		contractedServiceOperation.deadline +
		` ${contractedServiceOperation.deadline === 1 ? translate('MONTH') : translate('MONTHS')}`

	const displayNrc = (getNrcAdjustmentForOffer(contractedServiceOperation) ?? contractedServiceOperation.nrc).toString()

	return {
		creationDate,
		awardDate,
		operationDeadline,
		rollbackDate,
		operationDuration,
		displayNrc,
	}
}

export { useConfirmResumeToHire }
