import dayjs from 'dayjs'
import { ContractedServiceOperation } from 'modules/contractedService/domain/management/operation/ContractedServiceOperation'
import { ContractType } from 'modules/contractedService/domain/management/operation/ContractType'
import { ManagementOperation } from 'modules/contractedService/domain/management/operation/ManagementOperation'
import { ContractedServiceOperationScheduled } from 'modules/contractedService/domain/management/operation/scheduled/ContractedServiceOperationScheduled'
import { ContractedServiceOperationTemporal } from 'modules/contractedService/domain/management/operation/temporary/ContractedServiceOperationTemporary'
import { Offer } from 'modules/offer/domain/Offer'
import { getEnumKeyFromEnumValue, joinValuesWithConjunctionAndSeparator, useTranslator } from 'ufinet-web-functions'

type ModalContentsFunctionInput = { offer?: Offer; operation: ContractedServiceOperation }
type ModalContentsFunctionOutput = { title: string; content: string }

type HookOutput = {
	makeModalContents: (input: ModalContentsFunctionInput) => ModalContentsFunctionOutput
}

const useInternalConfirmModal: () => HookOutput = () => {
	const translate = useTranslator()

	const makeModalContents: (input: ModalContentsFunctionInput) => ModalContentsFunctionOutput = ({
		offer,
		operation,
	}) => {
		const innerI18nKey = `OPERATION_${operation.contractType}`

		const title = translate(`CONTRACT.SERVICE.MANAGEMENT.${innerI18nKey}.INTERNAL_USER_CONFIRMATION_MESSAGE_TITLE`, {
			operation: joinValuesWithConjunctionAndSeparator(
				operation.operations.map((operation) =>
					translate(
						`CONTRACT.SERVICE.MANAGEMENT.OPERATION.${getEnumKeyFromEnumValue(ManagementOperation, operation)}`
					).toLowerCase()
				),
				translate('AND')
			),
		})
		const content = translate(`CONTRACT.SERVICE.MANAGEMENT.${innerI18nKey}.INTERNAL_USER_CONFIRMATION_MESSAGE`, {
			offerId: offer?.id || '',
			awardAt: [ContractType.SCHEDULED, ContractType.TEMPORAL].includes(operation.contractType)
				? dayjs((operation as ContractedServiceOperationScheduled).modifyAt).format('DD/MM/YYYY')
				: undefined,
			duration: [ContractType.TEMPORAL].includes(operation.contractType)
				? (operation as ContractedServiceOperationTemporal).period
				: undefined,
		})
		return { title, content }
	}

	return {
		makeModalContents,
	}
}

export { useInternalConfirmModal }
