import dayjs from 'dayjs'
import {
	ContractedServiceOperation,
	ContractedServiceOperationData,
} from 'modules/contractedService/domain/management/operation/ContractedServiceOperation'
import { ContractType } from 'modules/contractedService/domain/management/operation/ContractType'

export class ContractedServiceOperationScheduled
	extends ContractedServiceOperation
	implements ContractedServiceOperationDataScheduled
{
	static isValidForCalculation(input: Partial<ContractedServiceOperationDataScheduled>): boolean {
		return ContractedServiceOperation.isValidForCalculation(input) && input.modifyAt !== undefined
	}

	fromOther(input: ContractedServiceOperationDataScheduled) {
		return new ContractedServiceOperationScheduled(input)
	}

	readonly modifyAt: Date

	constructor(
		contractedServiceOperationDataScheduled: Omit<
			ContractedServiceOperationDataScheduled,
			'contractType' | 'awardsImmediately'
		>
	) {
		super({
			...contractedServiceOperationDataScheduled,
			contractType: ContractType.SCHEDULED,
			awardsImmediately: dayjs(contractedServiceOperationDataScheduled.modifyAt).isSame(dayjs(), 'day'),
		})

		this.modifyAt = dayjs(contractedServiceOperationDataScheduled.modifyAt)
			.add(Math.abs(contractedServiceOperationDataScheduled.modifyAt.getTimezoneOffset()), 'minutes')
			.toDate()
	}
}

export type ContractedServiceOperationDataScheduled = ContractedServiceOperationData & { modifyAt: Date }
