import { rest } from 'msw'

export const petitionTrackingHandlers = [
	rest.get('*api/services/contracted/management/tracking/cancel', (req, res, ctx) => {
		return res(ctx.status(200))
	}),
	rest.get('*api/services/contracted/management/tracking', (req, res, ctx) => {
		return res(ctx.status(200), ctx.json([]))
	}),
]
