import { NotificationEvent } from 'modules/event/domain/notification/NotificationEvent'
import { Publisher } from 'modules/event/domain/Publisher'
import { IntlShape } from 'react-intl'
import { successNotifications } from 'ufinet-web-functions'
import { ClientParametersRepository } from '../../domain/repository/clientParametersRepository'
import { getClientNameAndTypePair } from '../getClientNameAndTypePair'
import { ClientParametersUpdateRequest } from './dto/ClientParametersUpdateRequest'

export const clientParametersUpdater = async (
	clientParametersRepository: ClientParametersRepository,
	clientParametersUpdateRequest: ClientParametersUpdateRequest,
	publishOptions?: { intl: IntlShape; publisher: Publisher }
): Promise<void> => {
	try {
		await clientParametersRepository.update(clientParametersUpdateRequest, { notifyError: false })
		publishOptions &&
			publishOnClientParametersUpdated(publishOptions.publisher, publishOptions.intl, clientParametersUpdateRequest)
	} catch (error) {
		publishOptions && publishOnClientParametersFailedToUpdate(publishOptions.publisher, publishOptions.intl, error)
	}
}

const publishOnClientParametersFailedToUpdate = (publisher: Publisher, intl: IntlShape, { errors }: any) => {
	const event = new NotificationEvent({
		notificationType: 'error',
		notificationContent: errors
			? [errors].filter(Boolean).join('. ')
			: intl.formatMessage({ id: 'CLIENT.PARAMETERS.UPDATE.ERROR' }),
	})
	publisher.publish(event)
}

const publishOnClientParametersUpdated = (
	publisher: Publisher,
	intl: IntlShape,
	{ clientName, clientTypeName }: ClientParametersUpdateRequest
) => {
	const event = new NotificationEvent({
		notificationType: 'success',
		notificationContent: successNotifications.updateRegistry(
			intl,
			getClientNameAndTypePair(intl, clientName, clientTypeName)
		),
	})
	publisher.publish(event)
}
