import { useContractedServiceManageFormContext } from 'components/services/management/modal/wizard/modify-service/manage/ContractedServiceManageFormContextProvider'
import { useFormik } from 'formik'
import { ContractedServiceOperationData } from 'modules/contractedService/domain/management/operation/ContractedServiceOperation'
import { pricingProperties } from 'modules/contractedService/domain/pricing/BasePricing'
import { useMemo } from 'react'
import { useLoading, useTranslator } from 'ufinet-web-functions'
import * as Yup from 'yup'
import { ManageFormValidationCreator } from '../../manageFormValidationCreator'

export type ManageFormParameters<T extends ContractedServiceOperationData> = Partial<T>
export type BaseManageFormParameters = Partial<ContractedServiceOperationData>
type BaseManageFormSchema = {
	[key in keyof BaseManageFormParameters]: Yup.SchemaOf<any>
}

const useBaseManageFormValidation: ManageFormValidationCreator<ContractedServiceOperationData> = () => {
	const { form, service } = useContractedServiceManageFormContext()

	const translate = useTranslator()
	const loadingControls = useLoading()

	const baseInitialValues: Partial<ContractedServiceOperationData> = useMemo(
		() => ({
			service: { id: service.serviceId, code: service.serviceCode },
			keepDeadline: false,
			...form?.value?.values,
		}),
		[form?.value?.values, service.serviceCode, service.serviceId]
	)

	const initialValues = useMemo(() => {
		const initialValuesCopy: Record<string, unknown> = { ...baseInitialValues }
		Object.keys(baseInitialValues).forEach((key) => {
			if (pricingProperties.includes(key)) initialValuesCopy[key] = undefined
		})
		return initialValuesCopy
	}, [baseInitialValues])

	const validationSchema = Yup.object().shape<BaseManageFormSchema>(
		{
			bandwidth: Yup.object().shape({
				id: Yup.string().required(translate('ERROR.REQUIRED')),
			}),
			deadline: Yup.number().required(translate('ERROR.REQUIRED')),
			mrc: Yup.number().required(translate('ERROR.REQUIRED')),
			nrc: Yup.number().required(translate('ERROR.REQUIRED')),
			nrcAdjustment: Yup.number().optional(),
			mrcAdjustment: Yup.number().optional(),
			currency: Yup.object().shape({
				id: Yup.string().required(translate('ERROR.REQUIRED')),
			}),
			contact: Yup.object()
				.shape({
					id: Yup.string().optional(),
				})
				.optional(),
			keepDeadline: Yup.bool().required(translate('ERROR.REQUIRED')),
		},
		[]
	)

	const formik = useFormik<BaseManageFormParameters>({
		initialValues: baseInitialValues,
		validationSchema,
		onSubmit: () => {},
		validateOnChange: false,
		validateOnBlur: false,
		enableReinitialize: true,
	})

	return { formik, initialValues, validationSchema, loadingControls }
}

export { useBaseManageFormValidation }
