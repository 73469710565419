export enum ContractedServiceState {
	ADJUDICADO = 'ADJUDICADO',
	EN_CONSTRUCCION = 'EN_CONSTRUCCION',
	PENDIENTE_CLIENTE = 'PENDIENTE_CLIENTE',
	PENDIENTE_COMERCIAL = 'PENDIENTE_COMERCIAL',
	PENDIENTE_SUSPENSION = 'PENDIENTE_SUSPENSION',
	SUSPENDIDO = 'SUSPENDIDO',
	PENDIENTE_REACTIVACION = 'PENDIENTE_REACTIVACION',
	EN_SERVICIO = 'EN_SERVICIO',
	BAJA_LOGICA = 'BAJA_LOGICA',
	DADO_BAJA = 'DADO_BAJA',
	PENDIENTE_CANCELACION = 'PENDIENTE_CANCELACION',
	CANCELADO = 'CANCELADO',
	INACTIVO = 'INACTIVO',
	ANULADO = 'ANULADO',
	EN_PLANIFICACION = 'EN_PLANIFICACION',
	DETENIDO = 'DETENIDO',
	NO_USAR_INACTIVO = 'NO_USAR_INACTIVO',
}
