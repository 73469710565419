import { Publisher } from 'modules/event/domain/Publisher'
import { NotificationEvent } from 'modules/event/domain/notification/NotificationEvent'
import { IntlShape } from 'react-intl'
import { successNotifications } from 'ufinet-web-functions'
import { ClientParametersRepository } from '../../domain/repository/clientParametersRepository'
import { ClientParametersDeleteRequest } from './dto/ClientParametersDeleteRequest'

export const clientParametersMultipleDeleter = async (
	clientParametersRepository: ClientParametersRepository,
	deleteRequest: ClientParametersDeleteRequest,
	publishOptions?: { intl: IntlShape; publisher: Publisher }
): Promise<void> => {
	await clientParametersRepository.deleteMany(deleteRequest)
	publishOptions && publishOnClientParametersDeleted(publishOptions.publisher, publishOptions.intl)
}

const publishOnClientParametersDeleted = (publisher: Publisher, intl: IntlShape) => {
	const successEvent = new NotificationEvent({
		notificationType: 'success',
		notificationContent: successNotifications.deleteByIdsList(intl, {
			section: intl.formatMessage({ id: 'CLIENT.PARAMETERS' }).toLowerCase(),
		}),
	})
	publisher.publish(successEvent)
}
