import { ContractedServiceRepository } from 'modules/contractedService/domain/repository/ContractedServiceRepository'
import { ContractedServiceFindRequest } from './dto/ContractedServiceFindRequest'
import { ContractedServiceFindResponse } from './dto/ContractedServiceFindResponse'

let abortController = new AbortController()

export const contractedServiceFinder = (
	contractedServiceRepository: ContractedServiceRepository,
	contractedServiceFindRequest: ContractedServiceFindRequest,
	options?: { abortPrevious: boolean }
): Promise<ContractedServiceFindResponse> => {
	if (options?.abortPrevious) abortController.abort()
	abortController = new AbortController()
	return contractedServiceRepository.find(contractedServiceFindRequest, { signal: abortController.signal })
}
