import { ContractedServiceOperation } from 'modules/contractedService/domain/management/operation/ContractedServiceOperation'
import { getModifyingBandwidthOperation } from 'modules/contractedService/domain/management/operation/helpers/getModifyingBandwidthOperation'
import { getModifyingDeadlineOperation } from 'modules/contractedService/domain/management/operation/helpers/getModifyingDeadlineOperation'
import { getModifyingPriceOperation } from 'modules/contractedService/domain/management/operation/helpers/getModifyingPriceOperation'

export const getNrcAdjustmentForOffer = <T extends ContractedServiceOperation>(
	contractedServiceOperation: T
): number | undefined => {
	const modifyingPrice = getModifyingPriceOperation(contractedServiceOperation.operations) !== undefined
	const modifyingBandwidth = getModifyingBandwidthOperation(contractedServiceOperation.operations) !== undefined
	const modifyingDeadline = getModifyingDeadlineOperation(contractedServiceOperation.operations) !== undefined
	const isModifyingPrice = modifyingPrice
	const isOnlyModifyingDeadline = modifyingDeadline && !modifyingBandwidth
	return (isModifyingPrice || isOnlyModifyingDeadline) && contractedServiceOperation.nrcAdjustment === undefined
		? 0
		: contractedServiceOperation.nrcAdjustment
}
