import { i18nMessages } from 'ufinet-web-components'
import contractServiceManagementEnMessages from './contract/service/management/en.json'
import contractServiceManagementEsMessages from './contract/service/management/es.json'
import contractServiceManagementPtMessages from './contract/service/management/pt.json'
import contractServiceToSEnMessages from './contract/service/terms-and-conditions/en.json'
import contractServiceToSEsMessages from './contract/service/terms-and-conditions/es.json'
import contractServiceToSPtMessages from './contract/service/terms-and-conditions/pt.json'
import gseEnMessages from './en.json'
import gseEsMessages from './es.json'
import gsePtMessages from './pt.json'

export const gseI18nMessages: i18nMessages = {
	en: {
		...gseEnMessages,
		...contractServiceManagementEnMessages,
		...contractServiceToSEnMessages,
	},
	es: {
		...gseEsMessages,
		...contractServiceManagementEsMessages,
		...contractServiceToSEsMessages,
	},
	pt: {
		...gsePtMessages,
		...contractServiceManagementPtMessages,
		...contractServiceToSPtMessages,
	},
}
