import { useContractedServiceManageFormContext } from 'components/services/management/modal/wizard/modify-service/manage/ContractedServiceManageFormContextProvider'
import { ContractedServiceOperation } from 'modules/contractedService/domain/management/operation/ContractedServiceOperation'
import { buildOperationFromDataObject } from 'modules/contractedService/domain/management/operation/helpers/buildOperationFromDataObject'
import { isNumeric, useTranslator } from 'ufinet-web-functions'

type HookInput<T extends ContractedServiceOperation> = {
	isPriceIncrease: boolean
	onSuccess: (newData: T) => void
	onError: (errorData: T, error: Error) => void
}

type HookOutput<T extends ContractedServiceOperation> = {
	validateOperationPricing: (newData: T) => void
}

function useOperationPricingValidation<T extends ContractedServiceOperation>({
	isPriceIncrease,
	onSuccess,
	onError,
}: HookInput<T>): HookOutput<T> {
	const translate = useTranslator()

	const {
		service: originalService,
		contractedServiceOperation: { isModifying },
	} = useContractedServiceManageFormContext()

	const isOnlyModifyingPrice = isModifying.bandwidth === false && isModifying.price === true

	const isValidPriceIncrease = ({ nrc: newNrc, mrc: newMrc, nrcAdjustment, mrcAdjustment }: T) => {
		if (nrcAdjustment === undefined) nrcAdjustment = 0
		if (nrcAdjustment !== undefined && nrcAdjustment < 0)
			throw new Error(translate('CONTRACT.SERVICE.MANAGEMENT.PRICING.VALIDATION.NRC_MOD_POSITIVE'))
		if (mrcAdjustment !== undefined && mrcAdjustment < 0)
			throw new Error(translate('CONTRACT.SERVICE.MANAGEMENT.PRICING.VALIDATION.MRC_MOD_POSITIVE'))

		const referenceNrc = isModifying.price ? nrcAdjustment : nrcAdjustment ?? newNrc
		const comparisonMrc = isOnlyModifyingPrice ? mrcAdjustment : mrcAdjustment ?? newMrc

		if (originalService.mrc === undefined || comparisonMrc === undefined)
			throw new Error(translate('CONTRACT.SERVICE.MANAGEMENT.PRICING.VALIDATION.MRC_GREATER_THAN_ORIGINAL'))
		if (referenceNrc === undefined || referenceNrc < 0) {
			if (comparisonMrc <= originalService.mrc)
				throw new Error(translate('CONTRACT.SERVICE.MANAGEMENT.PRICING.VALIDATION.MRC_GREATER_THAN_ORIGINAL'))
		} else {
			if (comparisonMrc < originalService.mrc)
				throw new Error(translate('CONTRACT.SERVICE.MANAGEMENT.PRICING.VALIDATION.MRC_GREATER_OR_EQUAL_THAN_ORIGINAL'))
		}
	}
	const isValidPriceDecrease = ({ mrc: newMrc, nrcAdjustment, mrcAdjustment }: T) => {
		if (nrcAdjustment !== undefined && nrcAdjustment < 0)
			throw new Error(translate('CONTRACT.SERVICE.MANAGEMENT.PRICING.VALIDATION.NRC_MOD_POSITIVE'))
		if (mrcAdjustment !== undefined && mrcAdjustment < 0)
			throw new Error(translate('CONTRACT.SERVICE.MANAGEMENT.PRICING.VALIDATION.MRC_MOD_POSITIVE'))

		const comparisonMrc = isOnlyModifyingPrice ? mrcAdjustment : mrcAdjustment ?? newMrc

		if (originalService.mrc === undefined || comparisonMrc === undefined || comparisonMrc >= originalService.mrc)
			throw new Error(translate('CONTRACT.SERVICE.MANAGEMENT.PRICING.VALIDATION.MRC_LOWER_THAN_ORIGINAL'))
	}

	const validateOperationPricing = (newData: T) => {
		const processedNewData: T = buildOperationFromDataObject({
			...newData,
			nrcAdjustment: isNumeric(newData.nrcAdjustment) ? +newData.nrcAdjustment! : undefined,
			mrcAdjustment: isNumeric(newData.mrcAdjustment) ? +newData.mrcAdjustment! : undefined,
		}) as T

		const validate = isPriceIncrease
			? () => isValidPriceIncrease(processedNewData)
			: () => isValidPriceDecrease(processedNewData)

		try {
			validate()
			onSuccess(processedNewData)
		} catch (error) {
			onError(processedNewData, error as Error)
		}
	}

	return {
		validateOperationPricing,
	}
}

export { useOperationPricingValidation }
