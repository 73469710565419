import { ContractedService } from 'modules/contractedService/domain/ContractedService'
import { ContractedServiceType } from 'modules/contractedService/domain/ContractedServiceType'
import { ContractedServiceState } from 'modules/contractedService/domain/state/ContractedServiceState'
import { ContractedServiceStateExternal } from 'modules/contractedService/domain/state/ContractedServiceStateExternal'
import { ColData, DivWrapped, WRAP_MODE } from 'ufinet-web-components'
import { TranslatorFunction, noContentPlaceholder } from 'ufinet-web-functions'

const contractServiceStateExcludedValues = [
	ContractedServiceState.INACTIVO,
	ContractedServiceState.CANCELADO,
	ContractedServiceState.ANULADO,
	ContractedServiceState.PENDIENTE_CANCELACION,
	ContractedServiceState.PENDIENTE_SUSPENSION,
	ContractedServiceState.PENDIENTE_REACTIVACION,
	ContractedServiceState.NO_USAR_INACTIVO,
]

export const colsContractedServicesExternal = (translate: TranslatorFunction): ColData[] => [
	{
		field: 'administrativeCode',
		header: 'CONTRACT.SERVICE.ADMINISTRATIVE_CODE',
		nonFilterable: true,
		nonSearchable: false,
		width: '150px',
		body: (row: ContractedService) => {
			return row.administrativeCode?.trim() || '-'
		},
	},
	{
		field: 'serviceName',
		header: 'CONTRACT.SERVICE.NAME',
		nonFilterable: true,
		nonSearchable: false,
		width: '150px',
		body: (row: ContractedService) =>
			row.serviceName ? (
				<DivWrapped
					className="ufinet-table-cell text-break"
					hideSeparator
					wrapMode={WRAP_MODE.LINEBREAK}
					input={row.serviceName}
					separator={['-']}
				/>
			) : (
				'-'
			),
	},
	{
		field: 'typeOfService',
		header: 'CONTRACT.SERVICE.TYPE',
		nonFilterable: false,
		nonSearchable: true,
		nonSortable: true,
		filterOptions: Object.keys(ContractedServiceType)
			.filter((type) => isNaN(Number(type)))
			.map((type) => ({ label: translate(`CONTRACT.SERVICE.TYPE.${type}`), value: type })),
		body: (row: ContractedService) =>
			!row.typeOfService ? noContentPlaceholder : translate(`CONTRACT.SERVICE.TYPE.${row.typeOfService.value}`),
	},
	{
		field: 'serviceAttributes',
		header: 'CONTRACT.SERVICE.ATTRIBUTES',
		nonFilterable: true,
		nonSortable: true,
		body: (row: ContractedService) => {
			return row.attributes?.trim() || '-'
		},
	},
	{
		field: 'originSummary',
		header: 'CONTRACT.SERVICE.ORIGIN_SITE',
		nonFilterable: true,
		nonSearchable: false,
		width: '200px',
		body: (row: ContractedService) =>
			row.originSummary && (
				<DivWrapped
					className="ufinet-table-cell text-break"
					hideSeparator
					wrapMode={WRAP_MODE.LINEBREAK}
					input={row.originSummary}
					separator={['|']}
				/>
			),
	},
	{
		field: 'destinationSummary',
		header: 'CONTRACT.SERVICE.DESTINATION_SITE',
		nonFilterable: true,
		nonSearchable: false,
		width: '200px',
		body: (row: ContractedService) =>
			row.destinationSummary && (
				<DivWrapped
					className="ufinet-table-cell text-break"
					hideSeparator
					wrapMode={WRAP_MODE.LINEBREAK}
					input={row.destinationSummary}
					separator={['|']}
				/>
			),
	},
	{
		field: 'state',
		header: 'CONTRACT.SERVICE.STATE',
		nonFilterable: false,
		nonSearchable: true,
		nonSortable: true,
		filterOptions: Object.keys(ContractedServiceStateExternal)
			.filter((type) => isNaN(Number(type)))
			.map((type) => ({ label: translate(`CONTRACT.SERVICE.STATE.${type}`), value: type })),
		body: (row: ContractedService) =>
			!row.state ? noContentPlaceholder : translate(`CONTRACT.SERVICE.STATE.${row.state.value}`),
	},
]

export const colsContractedServicesInternal = (translate: TranslatorFunction): ColData[] => [
	{
		field: 'administrativeCode',
		header: 'CONTRACT.SERVICE.ADMINISTRATIVE_CODE',
		nonFilterable: true,
		nonSearchable: false,
		width: '150px',
		body: (row: ContractedService) => {
			return row.administrativeCode?.trim() || noContentPlaceholder
		},
	},
	{
		field: 'serviceName',
		header: 'CONTRACT.SERVICE.NAME',
		nonFilterable: true,
		nonSearchable: false,
		width: '150px',
		body: (row: ContractedService) =>
			row.serviceName ? (
				<DivWrapped
					className="ufinet-table-cell text-break"
					hideSeparator
					wrapMode={WRAP_MODE.LINEBREAK}
					input={row.serviceName}
					separator={['-']}
				/>
			) : (
				'-'
			),
	},
	{
		field: 'typeOfService',
		header: 'CONTRACT.SERVICE.TYPE',
		nonFilterable: false,
		nonSearchable: true,
		nonSortable: true,
		filterOptions: Object.keys(ContractedServiceType)
			.filter((type) => isNaN(Number(type)))
			.map((type) => ({ label: translate(`CONTRACT.SERVICE.TYPE.${type}`), value: type })),
		body: (row: ContractedService) =>
			!row.typeOfService ? noContentPlaceholder : translate(`CONTRACT.SERVICE.TYPE.${row.typeOfService.value}`),
	},
	{
		field: 'serviceAttributes',
		header: 'CONTRACT.SERVICE.ATTRIBUTES',
		nonFilterable: true,
		nonSortable: true,
		body: (row: ContractedService) => {
			return row.attributes?.trim() || noContentPlaceholder
		},
	},
	{
		field: 'originSummary',
		header: 'CONTRACT.SERVICE.ORIGIN_SITE',
		nonFilterable: true,
		nonSearchable: false,
		nonSortable: true,
		width: '200px',
		body: (row: ContractedService) =>
			row.originSummary ? (
				<DivWrapped
					className="ufinet-table-cell text-break"
					hideSeparator
					wrapMode={WRAP_MODE.LINEBREAK}
					input={row.originSummary}
					separator={['|']}
				/>
			) : (
				noContentPlaceholder
			),
	},
	{
		field: 'destinationSummary',
		header: 'CONTRACT.SERVICE.DESTINATION_SITE',
		nonFilterable: true,
		nonSearchable: false,
		nonSortable: true,
		width: '200px',
		body: (row: ContractedService) =>
			row.destinationSummary ? (
				<DivWrapped
					className="ufinet-table-cell text-break"
					hideSeparator
					wrapMode={WRAP_MODE.LINEBREAK}
					input={row.destinationSummary}
					separator={['|']}
				/>
			) : (
				noContentPlaceholder
			),
	},
	{
		field: 'state',
		header: 'CONTRACT.SERVICE.STATE',
		nonFilterable: false,
		nonSearchable: true,
		nonSortable: true,
		filterOptions: Object.keys(ContractedServiceState)
			.filter(
				(type) =>
					isNaN(Number(type)) &&
					!contractServiceStateExcludedValues.includes(
						ContractedServiceState[type as keyof typeof ContractedServiceState]
					)
			)
			.map((type) => ({ label: translate(`CONTRACT.SERVICE.STATE.${type}`), value: type })),
		body: (row: ContractedService) =>
			!row.state ? noContentPlaceholder : translate(`CONTRACT.SERVICE.STATE.${row.state.value}`),
	},
]
