import clsx from 'clsx'
import { ManagementOperationSelect } from 'components/services/management/selects/ManagementOperationSelect'
import { useContractedServiceDetailFindQuery } from 'modules/contractedService/application/find/detail/ContractedServiceDetailFindQueries'
import { ContractedServiceDetail } from 'modules/contractedService/domain/detail/ContractedServiceDetail'
import { emptyContractedServiceDetail } from 'modules/contractedService/domain/detail/emptyContractedServiceDetail'
import { getModifyingPriceOperation } from 'modules/contractedService/domain/management/operation/helpers/getModifyingPriceOperation'
import { ManagementOperation } from 'modules/contractedService/domain/management/operation/ManagementOperation'
import { ContractedServiceDetailRepository } from 'modules/contractedService/domain/repository/ContractedServiceDetailRepository'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { IUfinetSelectOption, Loading, WizardVerticalHandle } from 'ufinet-web-components'
import {
	getEnumKeyFromEnumValue,
	joinValuesWithConjunctionAndSeparator,
	useInternalUser,
	useTranslator,
} from 'ufinet-web-functions'
import { ContractedServiceDetailTableRow } from './ContractedServiceDetailTableRow'

type Props = {
	allowOperationTypeChange?: boolean
	incompatibleOperations?: ManagementOperation[][]
	invalidDueToExpiredService?: boolean
	contractedServiceDetailRepository: ContractedServiceDetailRepository
	contractedServiceManagementOperations: ManagementOperation[] // If provided, the operation type select will be hidden and the value will be used to show it as another service property
	onOperationTypeChange?: (mt?: IUfinetSelectOption[]) => void
	onServiceDetailsFetched?: (service: ContractedServiceDetail) => void
	serviceData: string | ContractedServiceDetail // Either the administrative code (so that the table fetches the data) or the data already prepared
	summary?: boolean
	wizardRef?: React.RefObject<WizardVerticalHandle>
}

const ContractedServiceDetailTable: FC<Props> = ({
	allowOperationTypeChange = false,
	incompatibleOperations = [],
	invalidDueToExpiredService = false,
	contractedServiceDetailRepository,
	contractedServiceManagementOperations,
	onOperationTypeChange,
	onServiceDetailsFetched: afterServiceDetailsFetched,
	serviceData,
	summary = false,
	wizardRef,
}) => {
	const translate = useTranslator()
	const internalUser = useInternalUser()
	const serviceId = useMemo(
		() => (typeof serviceData === 'string' ? serviceData : serviceData.serviceId),
		[serviceData]
	)
	const [serviceDetails, setServiceDetails] = useState<ContractedServiceDetail>(emptyContractedServiceDetail)

	const contractedServiceManagementOperationOptions = useMemo<IUfinetSelectOption[]>(
		() =>
			contractedServiceManagementOperations.map((contractedServiceManagementOperation) => ({
				value: contractedServiceManagementOperation.toString(),
				label: translate(
					`CONTRACT.SERVICE.MANAGEMENT.OPERATION.${getEnumKeyFromEnumValue(
						ManagementOperation,
						contractedServiceManagementOperation
					)}`
				),
			})),
		[contractedServiceManagementOperations, translate]
	)

	const modifyingPrice = getModifyingPriceOperation(contractedServiceManagementOperations) !== undefined
	const showMrc = !summary || modifyingPrice
	const showNrc = summary && modifyingPrice

	const onServiceDetailFetched = useCallback(
		(serviceData: ContractedServiceDetail): void => {
			setServiceDetails(serviceData)
			afterServiceDetailsFetched?.(serviceData)
		},
		[afterServiceDetailsFetched]
	)

	const onServiceDetailFailedToFetch = useCallback((): void => {
		toast.error(translate('CONTRACT.SERVICE.DETAIL.ERROR'))
		wizardRef?.current?.close()
	}, [translate, wizardRef])

	const { data: serviceDetail, isLoading } = useContractedServiceDetailFindQuery(
		contractedServiceDetailRepository,
		{ serviceId },
		{ onSuccess: onServiceDetailFetched, onError: onServiceDetailFailedToFetch }
	)

	useEffect(() => {
		serviceDetail && onServiceDetailFetched(serviceDetail)
	}, [serviceDetail, onServiceDetailFetched])

	const onManagementTypeChange = useCallback(
		(selectedOptions: IUfinetSelectOption[]) => {
			onOperationTypeChange?.(selectedOptions)
		},
		[onOperationTypeChange]
	)

	const userAllowedOperations = useMemo((): ManagementOperation[] => {
		if (internalUser) {
			return [
				ManagementOperation.UPGRADE,
				ManagementOperation.DOWNGRADE,
				ManagementOperation.AUMENTO_PRECIO,
				ManagementOperation.BAJA_PRECIO,
				ManagementOperation.AUMENTO_PLAZO,
				ManagementOperation.DISMINUCION_PLAZO,
			]
		}
		return [ManagementOperation.UPGRADE]
	}, [internalUser])

	const incompatibleOptionsError = useMemo(
		() =>
			incompatibleOperations.length > 0 &&
			translate('CONTRACT.SERVICE.MANAGEMENT.OPERATION.ARE_INVALID_OPERATIONS_SELECTED', {
				incompatibleOptions: joinValuesWithConjunctionAndSeparator(
					incompatibleOperations.map((operationSet) =>
						joinValuesWithConjunctionAndSeparator(
							contractedServiceManagementOperations
								.filter((value) => operationSet.includes(value))
								.map((value) =>
									translate(
										`CONTRACT.SERVICE.MANAGEMENT.OPERATION.${getEnumKeyFromEnumValue(ManagementOperation, value)}`
									).toLowerCase()
								),
							translate('AND')
						)
					),
					translate('OR')
				),
			}),
		[contractedServiceManagementOperations, incompatibleOperations, translate]
	)

	return (
		<div className={clsx('position-relative', isLoading && 'form-disabled')}>
			<div>
				{!summary ? (
					<>
						<ManagementOperationSelect
							allowedOperations={userAllowedOperations}
							preSelectedOperations={[ManagementOperation.UPGRADE]}
							isDisabled={!allowOperationTypeChange}
							value={contractedServiceManagementOperationOptions}
							onChange={(mt) => onManagementTypeChange(mt as IUfinetSelectOption[])}
							className="mb-4"
							labelStyles={{ fontWeight: 'bold', color: 'black' }}
							error={
								incompatibleOptionsError ||
								(invalidDueToExpiredService &&
									translate('CONTRACT.SERVICE.MANAGEMENT.OPERATION.INVALID_SERVICE_EXPIRED'))
							}
						/>
					</>
				) : (
					<ContractedServiceDetailTableRow
						title={translate('SELECT.MANAGEMENT.OPTION.TITLE')}
						content={contractedServiceManagementOperations
							.map((contractedServiceManagementOperation) =>
								translate(
									`CONTRACT.SERVICE.MANAGEMENT.OPERATION.${getEnumKeyFromEnumValue(
										ManagementOperation,
										contractedServiceManagementOperation
									)}`
								)
							)
							.join(', ')}
					/>
				)}
				{!summary && (
					<ContractedServiceDetailTableRow
						title={translate(`CONTRACT.SERVICE.MANAGEMENT.CLIENTNAME`)}
						content={serviceDetails.clientName}
					/>
				)}
				{!summary && (
					<ContractedServiceDetailTableRow
						title={translate(`CONTRACT.SERVICE.MANAGEMENT.SERVICECODE`)}
						content={serviceDetails.serviceCode}
					/>
				)}
				{!summary && (
					<ContractedServiceDetailTableRow
						title={translate(`CONTRACT.SERVICE.MANAGEMENT.SERVICENAME`)}
						content={serviceDetails.serviceName}
					/>
				)}
				{!summary && (
					<ContractedServiceDetailTableRow
						title={translate(`CONTRACT.SERVICE.MANAGEMENT.SERVICETYPE`)}
						content={
							serviceDetails.serviceType
								? translate(`CONTRACT.SERVICE.TYPE.${serviceDetails.serviceType.value}`)
								: undefined
						}
					/>
				)}
				<ContractedServiceDetailTableRow
					title={translate(`CONTRACT.SERVICE.MANAGEMENT.BANDWIDTH`)}
					content={serviceDetails.bandwidthFormatted}
				/>
				{!summary && (
					<ContractedServiceDetailTableRow
						title={translate(`CONTRACT.SERVICE.MANAGEMENT.CURRENCY`)}
						content={serviceDetails.currency}
					/>
				)}
				{(showMrc || showNrc) && (
					<div className="d-flex gap-5">
						{showMrc && (
							<ContractedServiceDetailTableRow
								title={translate(`CONTRACT.SERVICE.MANAGEMENT.MRC`)}
								content={serviceDetails.mrc?.toString()}
							/>
						)}
						{showNrc && (
							<ContractedServiceDetailTableRow
								title={translate(`CONTRACT.SERVICE.MANAGEMENT.NRC`)}
								content={serviceDetails.nrc?.toString()}
							/>
						)}
					</div>
				)}
				{!summary && (
					<ContractedServiceDetailTableRow
						title={translate(`CONTRACT.SERVICE.MANAGEMENT.ORIGIN`)}
						content={serviceDetails.origin}
					/>
				)}
				{!summary && (
					<ContractedServiceDetailTableRow
						title={translate(`CONTRACT.SERVICE.MANAGEMENT.DESTINATION`)}
						content={serviceDetails.destination}
					/>
				)}
				{!summary && (
					<ContractedServiceDetailTableRow
						title={translate(`CONTRACT.SERVICE.MANAGEMENT.STATE`)}
						content={
							serviceDetails.state ? translate(`CONTRACT.SERVICE.STATE.${serviceDetails.state.value}`) : undefined
						}
					/>
				)}
			</div>
			{isLoading && <Loading />}
		</div>
	)
}

export { ContractedServiceDetailTable }
