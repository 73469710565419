import { useTermsAndConditionsEconomicAssesment } from 'components/services/management/modal/wizard/modify-service/confirm-resume/modals/terms-and-conditions/content/economic-assesment/hooks/useTermsAndConditionsEconomicAssesment'
import { TermsAndConditionsEconomicAssesmentDataset } from 'components/services/management/modal/wizard/modify-service/confirm-resume/modals/terms-and-conditions/content/economic-assesment/TermsAndConditionsEconomicAssesmentDataset'
import { ContractedServiceWithDetails } from 'modules/contractedService/domain/ContractedServiceWithDetails'
import { ContractedServiceOperation } from 'modules/contractedService/domain/management/operation/ContractedServiceOperation'
import { FC } from 'react'

type Props = {
	contractedServiceWithDetails: ContractedServiceWithDetails
	contractedServiceOperation: ContractedServiceOperation
}

const TermsAndConditionsEconomicAssesment: FC<Props> = (props) => {
	const { current: currentEconomicAssesment, new: newEconomicAssesment } = useTermsAndConditionsEconomicAssesment({
		...props,
	})

	return (
		<div>
			<table className="terms-and-conditions-table economic-assesment-table">
				<tbody>
					<TermsAndConditionsEconomicAssesmentDataset
						isCurrent
						{...currentEconomicAssesment}
					/>
					<TermsAndConditionsEconomicAssesmentDataset
						isCurrent={false}
						{...newEconomicAssesment}
					/>
				</tbody>
			</table>
		</div>
	)
}
export { TermsAndConditionsEconomicAssesment }
