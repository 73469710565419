import { AUTO_FIGURES, formatCellNumber, noContentPlaceholder } from 'ufinet-web-functions'

export const formatCalculation = (input?: number): string =>
	formatCellNumber(input, {
		figures: AUTO_FIGURES,
		separators: false,
		percentage: false,
		placeholder: noContentPlaceholder,
		placeholderOnZero: false,
	})
