import { clsx } from 'clsx'
import { useContractedServiceManageFormContext } from 'components/services/management/modal/wizard/modify-service/manage/ContractedServiceManageFormContextProvider'
import { usePricingColumns } from 'components/services/management/modal/wizard/modify-service/manage/inner/base/hooks/usePricingColumns'
import {
	ContractedServiceOperation,
	ContractedServiceOperationData,
} from 'modules/contractedService/domain/management/operation/ContractedServiceOperation'

import { ReactElement } from 'react'
import {
	BandwidthSelect,
	DeadlineSelect,
	IUfinetSelectOption,
	Loading,
	Table,
	UfinetCheckBoxGroup,
	UfinetSelect,
} from 'ufinet-web-components'
import { isEmptyObject, useInternalUser, useTranslator } from 'ufinet-web-functions'
import { useManageInnerOperationForm } from './hooks/useManageInnerOperationForm'
import { ManageFormValidationCreator } from './manageFormValidationCreator'

export type BeforeServiceManageChange = <T extends ContractedServiceOperation>(operation?: T) => T | undefined
type Props<T extends ContractedServiceOperationData = ContractedServiceOperationData> = {
	isValidOperationForCalculation?: <T>(operationData: Partial<T>) => boolean
	formValidationCreator: ManageFormValidationCreator<T>
	additionalFields?: { inline?: ReactElement<unknown>; below?: ReactElement<unknown> }
}

const BaseManageForm = <T extends ContractedServiceOperationData>({
	isValidOperationForCalculation = ContractedServiceOperation.isValidForCalculation,
	formValidationCreator,
	additionalFields,
}: Props<T>) => {
	const translate = useTranslator()
	const internalUser = useInternalUser()

	const {
		contractedServiceOperation,
		onEvent: { onEditing },
	} = useContractedServiceManageFormContext()

	const { bandwidth, deadline, contact, keepDeadline, form, onChange } = useManageInnerOperationForm<T>({
		isValidOperationForCalculation: isValidOperationForCalculation,
		formValidationCreator,
	})

	const { columns: pricingColumns } = usePricingColumns()

	if (isEmptyObject(form?.formik)) return null

	return (
		<div
			className={clsx('position-relative d-flex flex-column gap-3', form.loadingControls.loading && 'form-disabled')}
		>
			<div className="row row-gap-4">
				{!internalUser && (
					<div className="row">
						<UfinetCheckBoxGroup
							options={keepDeadline.options}
							error={form.formik.errors.keepDeadline}
							className="col-12 col-md-3"
							title={translate('CLIENT.PARAMETERS.KEEP_DEADLINE')}
							titleClassName="fw-bolder"
							selection={keepDeadline.value ? [keepDeadline.options[0].value] : []}
							onChange={onChange.keepDeadline}
							disabled={keepDeadline.disabled}
						/>
					</div>
				)}
				{form.visibility.showBandwidthSelect && (
					<BandwidthSelect
						labelTitle={translate('CONTRACT.SERVICE.MANAGEMENT.BANDWIDTH')}
						className={(clsx(!additionalFields ? 'col-12' : 'col-6'), 'col-md-4 col-sm-12')}
						requiredIcon={false}
						tooltipTitle=""
						value={bandwidth.selected}
						options={bandwidth.options}
						error={form.formik.errors.bandwidth}
						onChange={(bw) => onChange.bandwidth?.(bw as IUfinetSelectOption)}
						isLoadingOptions={bandwidth.loading}
					/>
				)}
				{(form.visibility.showDeadlineSelect || !internalUser) && (
					<>
						<DeadlineSelect
							className={(clsx(!additionalFields ? 'col-12' : 'col-6'), 'col-md-4 col-sm-12')}
							requiredIcon={false}
							noTooltip
							includeDefaultOptions={false}
							labelTitle={translate('CONTRACT.SERVICE.DEADLINE.CONTRACT')}
							value={deadline.selected}
							options={deadline.options}
							isCreatable={internalUser}
							isMulti={false}
							menuIsOpen={internalUser ? false : undefined}
							preventDefaultOnSubmit={false}
							components={internalUser ? { DropdownIndicator: null } : undefined}
							unit={{ single: translate('MONTH'), plural: translate('MONTHS') }}
							isDisabled={internalUser ? deadline.disabled : keepDeadline.value}
							onChange={(dl) => onChange.deadline?.(dl as IUfinetSelectOption)}
						/>
						{form.deadline.error && <label className="text-danger">{form.deadline.error}</label>}
					</>
				)}
				{additionalFields?.inline || <></>}
			</div>
			{additionalFields?.below || <></>}
			{form.visibility.showPricingTable && (
				<>
					<div className="row">
						<Table
							filterButtons={undefined}
							content={[contractedServiceOperation.value!]}
							cols={pricingColumns}
							onCellEditInit={form.pricingTable.editable ? () => onEditing?.(true) : undefined}
							onCellEditCancel={form.pricingTable.editable ? () => onEditing?.(false) : undefined}
							onCellEditComplete={
								form.pricingTable.editable
									? ({ rowData }) => onChange.pricingTable?.(form.mapper.formDataToOperation(rowData))
									: undefined
							}
						/>
					</div>
					{form.pricingTable.error && <label className="text-danger">{form.pricingTable.error}</label>}
				</>
			)}
			{form.visibility.showContactSelect && (
				<div className="row">
					<UfinetSelect
						className="col-12 col-md-3"
						requiredIcon={false}
						labelTitle={translate('CONTRACT.SERVICE.MANAGEMENT.NOTIFY_CONTACT')}
						placeholder={translate('SELECT.CONTACT.NONE')}
						value={contact.selected}
						options={contact.options}
						isClearable
						onChange={(ct) => onChange.contact?.(ct as IUfinetSelectOption)}
						isLoadingOptions={contact.loading}
					/>
				</div>
			)}
			<input
				className="d-none"
				type="submit"
			/>
			{form.loadingControls.loading && <Loading />}
		</div>
	)
}

export { BaseManageForm }
