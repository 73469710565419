import { useContractedServiceManageFormContext } from 'components/services/management/modal/wizard/modify-service/manage/ContractedServiceManageFormContextProvider'
import { formatCalculation } from 'modules/contractedService/application/management/formatCalculation'
import { ContractedServiceOperation } from 'modules/contractedService/domain/management/operation/ContractedServiceOperation'
import { ColData } from 'ufinet-web-components'

export type ContractedServicePricingColumns = {
	nrc: ColData<ContractedServiceOperation>
	mrc: ColData<ContractedServiceOperation>
	nrcAdjustment?: ColData<ContractedServiceOperation>
	mrcAdjustment?: ColData<ContractedServiceOperation>
}

type HookOutput = {
	columns: ColData[]
}

export enum PricingColumnsMode {
	NONE,
	CURRENT_PRICING,
	MOD_PRICING,
	ALL,
}

function usePricingColumns(): HookOutput {
	const {
		contractedServiceOperation: { value: contractedServiceOperation, isModifying },
	} = useContractedServiceManageFormContext()

	const pricingColumnsMode: PricingColumnsMode =
		isModifying.bandwidth && isModifying.price
			? PricingColumnsMode.ALL
			: isModifying.bandwidth
			? PricingColumnsMode.CURRENT_PRICING
			: PricingColumnsMode.MOD_PRICING

	const mkBasePricingColumns = (operation: ContractedServiceOperation) =>
		({
			nrc: {
				field: 'nrc',
				width: pricingColumnsMode === PricingColumnsMode.ALL ? '25%' : '50%',
				header: {
					key: 'CONTRACT.SERVICE.MANAGEMENT.WIZARD.MODIFY_SERVICE.NRC',
					variables: {
						currencyName: operation.currency.name,
					},
				},
				nonFilterable: true,
				nonSortable: true,
				editable: false,
				hidden: pricingColumnsMode === PricingColumnsMode.MOD_PRICING,
				body: formatCalculation(operation.nrc),
			},
			mrc: {
				field: 'mrc',
				width: pricingColumnsMode === PricingColumnsMode.ALL ? '25%' : '50%',
				header: {
					key: 'CONTRACT.SERVICE.MANAGEMENT.WIZARD.MODIFY_SERVICE.MRC',
					variables: {
						currencyName: operation.currency.name,
					},
				},
				nonFilterable: true,
				nonSortable: true,
				editable: false,
				hidden: pricingColumnsMode === PricingColumnsMode.MOD_PRICING,
				body: formatCalculation(operation.mrc),
			},
			nrcAdjustment: {
				field: 'nrcAdjustment',
				width: pricingColumnsMode === PricingColumnsMode.ALL ? '25%' : '50%',
				header: 'CONTRACT.SERVICE.MANAGEMENT.WIZARD.MODIFY_SERVICE.NRC_MOD',
				nonFilterable: true,
				nonSortable: true,
				editable: true,
				hidden: pricingColumnsMode === PricingColumnsMode.CURRENT_PRICING,
				body: formatCalculation(operation.nrcAdjustment),
			},
			mrcAdjustment: {
				field: 'mrcAdjustment',
				width: pricingColumnsMode === PricingColumnsMode.ALL ? '25%' : '50%',
				header: 'CONTRACT.SERVICE.MANAGEMENT.WIZARD.MODIFY_SERVICE.MRC_MOD',
				nonFilterable: true,
				nonSortable: true,
				editable: true,
				hidden: pricingColumnsMode === PricingColumnsMode.CURRENT_PRICING,
				body: formatCalculation(operation.mrcAdjustment),
			},
		} as ContractedServicePricingColumns)

	return {
		columns:
			!contractedServiceOperation || PricingColumnsMode.NONE
				? []
				: Object.values(mkBasePricingColumns(contractedServiceOperation)),
	}
}

export { usePricingColumns }
