import { clsx } from 'clsx'
import { useContractedServiceManageFormContext } from 'components/services/management/modal/wizard/modify-service/manage/ContractedServiceManageFormContextProvider'
import { useManageInnerForm } from 'components/services/management/modal/wizard/modify-service/manage/inner/base/hooks/useManageInnerForm'
import { ImmediateManageForm } from 'components/services/management/modal/wizard/modify-service/manage/inner/immediate/ImmediateManageForm'
import { ScheduledManageForm } from 'components/services/management/modal/wizard/modify-service/manage/inner/scheduled/ScheduledManageForm'
import { TemporaryManageForm } from 'components/services/management/modal/wizard/modify-service/manage/inner/temporary/TemporaryManageForm'
import { ContractType } from 'modules/contractedService/domain/management/operation/ContractType'
import { FC, useEffect } from 'react'
import { Loading, UfinetRadioButtonGroup } from 'ufinet-web-components'
import { useTranslator } from 'ufinet-web-functions'

type Props = {
	onContractTypeChange: (ct?: ContractType) => void
}

const ContractedServiceManageInnerForm: FC<Props> = ({ onContractTypeChange }) => {
	const translate = useTranslator()

	const {
		form: innerForm,
		service,
		clientParameters,
		repository,
		onEvent: { onError, onFormChange },
	} = useContractedServiceManageFormContext()

	const {
		form: parentForm,
		contractType,
		onChange,
		operationAvailable,
	} = useManageInnerForm({
		service,
		clientParameters: {
			value: clientParameters.value,
			onFetched: clientParameters.onChange,
		},
		onError,
		onContractTypeChange,
		clientParametersRepository: repository.clientParameters,
	})

	useEffect(() => {
		onFormChange?.(innerForm.value)
	}, [innerForm.value, onFormChange])

	return (
		<form
			onSubmit={(e) => e.preventDefault()}
			className={clsx('position-relative d-flex flex-column gap-3', parentForm.loading && 'form-disabled')}
		>
			<div className="row">
				{contractType.options.length > 0 && (
					<UfinetRadioButtonGroup
						title={translate('CONTRACT.SERVICE.CONTRACT.TYPE')}
						titleClassName="fw-bolder"
						selection={contractType.selected}
						onChange={onChange.contractType}
						inline
						options={contractType.options}
						disabled={innerForm.loadingControls.loading}
					/>
				)}
			</div>
			{clientParameters.value && (
				<>
					{operationAvailable.immediate ? (
						<ImmediateManageForm />
					) : operationAvailable.scheduled ? (
						<ScheduledManageForm />
					) : operationAvailable.temporal ? (
						<TemporaryManageForm />
					) : (
						<></>
					)}
				</>
			)}
			<input
				className="d-none"
				type="submit"
			/>
			{parentForm.loading && <Loading />}
		</form>
	)
}

export { ContractedServiceManageInnerForm }
