import { ContractedService } from 'modules/contractedService/domain/ContractedService'
import { BillingStatusType } from 'modules/contractedService/domain/billing/BillinStatusType'
import { OrsEntryType } from 'modules/contractedService/domain/ors/petition/base/OrsEntryType'
import { OrsPetition } from 'modules/contractedService/domain/ors/petition/base/OrsPetition'
import { OrsOperationType } from 'modules/contractedService/domain/ors/petition/base/OrsReactivationType'
import { ContractedServiceState } from 'modules/contractedService/domain/state/ContractedServiceState'
import { ContractedServiceStateExternal } from 'modules/contractedService/domain/state/ContractedServiceStateExternal'
import { OrsStrategy } from './OrsStrategy'

export class OrsStrategySuspension implements OrsStrategy {
	public showForm(selectedPetition: OrsPetition, selectedEntry: OrsEntryType, selectedOperations: OrsOperationType[]) {
		return selectedPetition && selectedEntry && selectedOperations && selectedOperations.length > 0
	}

	public calculateIndividualServiceMinimumDate(
		selectedOperations: OrsOperationType[],
		_serviceId: string,
		_services: ContractedService[]
	) {
		const hasOnlyBilling = selectedOperations.every((operation) => operation === OrsOperationType.BILLING)

		return hasOnlyBilling ? undefined : new Date()
	}

	public calculateUnifiedServicesMinimumDate(selectedOperations: OrsOperationType[], _services: ContractedService[]) {
		const hasOnlyBilling = selectedOperations.every((operation) => operation === OrsOperationType.BILLING)

		return hasOnlyBilling ? undefined : new Date()
	}

	public isSelectedServiceInvalid(service: ContractedService, selectedOperations: OrsOperationType[]) {
		const isTechnical = selectedOperations.includes(OrsOperationType.TECHNICAL)
		const isBilling = selectedOperations.includes(OrsOperationType.BILLING)

		const isTechnicalSuspensionInvalid = (service: ContractedService) =>
			isTechnical && service.state?.value !== ContractedServiceState.EN_SERVICIO

		const isBillingSuspensionInvalid = (service: ContractedService) =>
			isBilling &&
			(service.billingStatus === undefined ||
				![BillingStatusType.IN_PROCESS, BillingStatusType.BILLED].includes(service.billingStatus) ||
				service.state?.value === undefined ||
				!(
					[
						ContractedServiceState.EN_SERVICIO,
						ContractedServiceState.EN_CONSTRUCCION,
						ContractedServiceState.SUSPENDIDO,
					] as (ContractedServiceState | ContractedServiceStateExternal)[]
				).includes(service.state?.value))

		return isTechnicalSuspensionInvalid(service) || isBillingSuspensionInvalid(service)
	}

	public enableOperations = true

	public enableReason = true

	public enableCompletionDate = true

	public isObservationRequired = false

	public defaultOperations = [OrsOperationType.TECHNICAL]

	public observationsLabel = 'CONTRACT.SERVICE.ORS.OBSERVATIONS'
}
