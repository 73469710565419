import { faArrowsRotate } from '@fortawesome/free-solid-svg-icons'
import clsx from 'clsx'
import { deepEqual } from 'fast-equals'
import { useFormik } from 'formik'
import { contractedServiceFinder } from 'modules/contractedService/application/find/contractedServiceFinder'
import { ContractedServicePropertyMap } from 'modules/contractedService/application/find/ContractedServicePropertyMap'
import { ContractedServiceFindRequest } from 'modules/contractedService/application/find/dto/ContractedServiceFindRequest'
import { ContractedServiceFindResponse } from 'modules/contractedService/application/find/dto/ContractedServiceFindResponse'
import { ServiceValidationResponse } from 'modules/contractedService/application/management/validate/dto/ServiceValidationResponse'
import { useServiceValidateByIdQuery } from 'modules/contractedService/application/management/validate/ServiceValidationQueries'
import { ContractedService } from 'modules/contractedService/domain/ContractedService'
import { ContractedServiceType } from 'modules/contractedService/domain/ContractedServiceType'
import { NetworkType } from 'modules/contractedService/domain/network/NetworkType'
import { ContractedServiceManagementRepository } from 'modules/contractedService/domain/repository/ContractedServiceManagementRepository'
import { ContractedServiceRepository } from 'modules/contractedService/domain/repository/ContractedServiceRepository'
import { ContractedServiceState } from 'modules/contractedService/domain/state/ContractedServiceState'
import { ContractedServiceStateExternal } from 'modules/contractedService/domain/state/ContractedServiceStateExternal'
import { ServiceValidationState } from 'modules/contractedService/domain/validate/ServiceValidationState'
import { ColumnBodyType } from 'primereact/column'
import { DataTableFilterMeta, DataTablePFSEvent } from 'primereact/datatable'
import { RefObject, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import {
	allowAllFilterFields,
	emptyUfinetSelectOption,
	formatTableFilters,
	IDisabledOptions,
	IFilterState,
	IUfinetSelectOption,
	LazyTableSettings,
	TableHandle,
	tableSortValueToSortDirection,
	UfinetButton,
} from 'ufinet-web-components'
import {
	AuthContext,
	Authority,
	defaultPagingParameters,
	IClientRepository,
	ICorporateGroupRepository,
	ICountriesRepository,
	isEmptyObject,
	onFormikChanges,
	PagingData,
	useAsync,
	useInternalUser,
	useModal,
	UserTypesEnum,
	useTranslator,
} from 'ufinet-web-functions'

type HookInput = {
	tableRef: RefObject<TableHandle>
	repository: {
		contractedService: ContractedServiceRepository
		serviceManagement: ContractedServiceManagementRepository
		countries: ICountriesRepository
		corporateGroups: ICorporateGroupRepository
		clients: IClientRepository
	}
}
type HookOutput = {
	header: {
		buttons: JSX.Element
	}
	content: {
		values: ContractedService[]
		selected: ContractedService[]
		selectedVersion?: ContractedService
		isLoading: boolean
		onSelectionChange?: (values: ContractedService[]) => void
		getServiceActionBodyTemplate: (service: ContractedService) => ColumnBodyType[]
	}
	focusedService: {
		value?: ContractedService
		validationResult?: ServiceValidationResponse
		validationError: unknown
		isValidating: boolean
	}
	filter: {
		set: (filterData: IFilterState) => void
		onClear: (emptyFilters: DataTableFilterMeta) => void
		isEmpty: boolean
		isDisabled: IDisabledOptions | boolean
		countries: string[]
		onCountriesChange: (country: IUfinetSelectOption[]) => void
		corporateGroups: string[]
		onCorporateGroupsChange: (country: IUfinetSelectOption[]) => void
		clients: string[]
		onClientsChange: (country: IUfinetSelectOption[]) => void
	}
	pagination: {
		paging: PagingData
		pageFilterSortEvent?: DataTablePFSEvent
		onPageFilterSortEvent: (event: DataTablePFSEvent) => void
		dataRequest: ContractedServiceFindRequest
		loading: boolean
		onFilter: (e: DataTablePFSEvent) => void
		onPage: (e: DataTablePFSEvent) => void
		onSort: (e: DataTablePFSEvent) => void
		settings: LazyTableSettings
	}
	detailsModal: {
		state: boolean
		show: () => void
		hide: () => void
		title: string
	}
	versionsModal: {
		state: boolean
		show: () => void
		hide: () => void
		title: string
		onVersionSelected: (service: ContractedService) => void
	}
	manageModal: {
		state: boolean
		show: () => void
		hide: () => void
		title: string
		onOfferConfirmed?: () => void
	}
	orsModal: {
		state: boolean
		show: () => void
		hide: () => void
		onServiceRemove: (serviceId: string) => void
		onSend: () => void
		onClose: () => void
	}
}

function useContractedServicesTable({ tableRef, repository }: HookInput): HookOutput {
	const internalUser = useInternalUser()
	const translate = useTranslator()
	const authData = useContext(AuthContext)

	const roles = useMemo(() => authData.userData?.authorities || [], [authData])
	const servicesPermissions = useMemo(() => Authority.getServicesPermissions(roles), [roles])
	const ORSPermissions = Authority.getGseORSPermissions(roles)

	const [tableServices, setTableServices] = useState<ContractedService[]>([])
	const [selectedServices, setSelectedServices] = useState<ContractedService[]>([])
	const [selectedServiceVersion, setSelectedServiceVersion] = useState<ContractedService>()
	const [focusedService, setFocusedService] = useState<ContractedService>()

	const [selectedCountries, setSelectedCountries] = useState<string[]>([])
	const [selectedCorporateGroups, setSelectedCorporateGroups] = useState<string[]>([])
	const [selectedClients, setSelectedClients] = useState<string[]>([])

	const [serviceRequestData, setServiceRequestData] = useState<ContractedServiceFindRequest>({
		...defaultPagingParameters,
		pageSize: 10,
	})
	const [tablePaging, setTablePaging] = useState<PagingData>({
		pageNumber: 0,
		pageSize: 0,
		totalElements: 0,
		totalPages: 0,
	})
	const [tablePFSEvent, setTablePFSEvent] = useState<DataTablePFSEvent>()
	const [tableLoading, setTableLoading] = useState<boolean>(false)

	const [serviceDetailsModalState, showServiceDetailsModal, hideServiceDetailsModal] = useModal()
	const [serviceVersionsModalState, showServiceVersionsModal, hideServiceVersionsModal] = useModal()
	const [serviceManageModalState, showServiceManageModal, hideServiceManageModal] = useModal()
	const [contractedServicesOrsModalState, showContractedServicesOrsModal, hideContractedServicesOrsModal] = useModal()

	const isEmptyTableFilter = useMemo(
		() =>
			isEmptyObject(tablePFSEvent?.filters) || deepEqual(tablePFSEvent?.filters, tableRef.current?.getInitialFilters()),
		[tablePFSEvent?.filters, tableRef]
	)

	const initialValues = {
		countrySelect: [emptyUfinetSelectOption],
		corporateGroupSelect: [emptyUfinetSelectOption],
		clientSelect: [emptyUfinetSelectOption],
	}

	const formik = useFormik({
		initialValues,
		onSubmit: () => {},
		validateOnChange: true,
		validateOnBlur: true,
		enableReinitialize: true,
	})

	const onFilterClear = (emptyFilters: DataTableFilterMeta): void => {
		if (!tablePFSEvent || isEmptyTableFilter) return

		const newEventData = { ...tablePFSEvent, filters: emptyFilters }
		setTablePFSEvent(newEventData)
		onFilter(newEventData)
	}

	const setFilter = (filterData: IFilterState) => {
		onFormikChanges(formik, 'countrySelect')(filterData.countrySelect)
		onFormikChanges(formik, 'corporateGroupSelect')(filterData.corporateGroupSelect)
		onFormikChanges(formik, 'clientSelect')(filterData.clientSelect)

		const newRequestData = {
			countryIds: selectedCountries,
			corporateGroupIds: selectedCorporateGroups,
			clientIds: selectedClients,
			// Keep page size, discard page number, sorting and filtering
			pageNumber: 0,
			pageSize: serviceRequestData.pageSize,
		}
		tableRef.current?.clearFilter()
		tableRef.current?.clearSort()

		// Force search if useAsync does not pick it up (for UX)
		deepEqual(newRequestData, serviceRequestData) ? findContractedServices() : setServiceRequestData(newRequestData)
	}

	const allowFilterSubmit = useMemo(
		() => selectedCountries.length > 0 || selectedCorporateGroups.length > 0 || selectedClients.length > 0,
		[selectedCountries, selectedCorporateGroups, selectedClients]
	)

	const afterCountryChange = (country: IUfinetSelectOption[]) => {
		setSelectedCountries(country?.map((c) => c.value))
		setSelectedCorporateGroups([])
		setSelectedClients([])
	}

	const afterCorporateGroupChange = (cg: IUfinetSelectOption[]) => {
		setSelectedCorporateGroups(cg?.map((c) => c.value))
		setSelectedClients([])
	}

	const afterClientChange = (client: IUfinetSelectOption[]) => {
		setSelectedClients(client?.map((c) => c.value))
	}

	const onFilter = (e: DataTablePFSEvent): void => {
		const formattedFilters = { ...formatTableFilters(e) }
		setTablePFSEvent(e)
		setServiceRequestData({ ...serviceRequestData, ...formattedFilters })
	}

	const onPage = (e: DataTablePFSEvent): void => {
		if (e.page !== serviceRequestData?.pageNumber || e.rows !== serviceRequestData?.pageSize) {
			const pageNumber = e.page
			const pageSize = e.rows
			setServiceRequestData({ ...serviceRequestData, pageNumber, pageSize })
		}
	}

	const onSort = (e: DataTablePFSEvent): void => {
		const finalField = ContractedServicePropertyMap.get(e.sortField)
		const finalOrder = tableSortValueToSortDirection(e.sortOrder)
		setServiceRequestData({
			...serviceRequestData,
			sortFields: finalField ? [finalField] : undefined,
			sortOrders: finalField && finalOrder ? [finalOrder] : undefined,
			// Return to first page
			pageNumber: 0,
		})
	}

	const errorMessageServiceDetail = new Map([
		[
			ServiceValidationState.ACTIVE_TEMPORAL_UPGRADE,
			`CONTRACT.SERVICE.MODIFY.ERROR.ACTIVE_TEMPORAL_UPGRADE.${
				internalUser ? UserTypesEnum.INTERNAL_USER : UserTypesEnum.EXTERNAL_USER
			}`,
		],
		[ServiceValidationState.ARCHIVED, 'CONTRACT.SERVICE.MODIFY.ERROR.ARCHIVED'],
		[ServiceValidationState.SERVICE_NOT_FOUND, 'CONTRACT.SERVICE.MODIFY.ERROR.SERVICE_NOT_FOUND'],
		[
			ServiceValidationState.SERVICE_WITH_CHURN_DATE,
			`CONTRACT.SERVICE.MODIFY.ERROR.SERVICE_WITH_CHURN_DATE.${
				internalUser ? UserTypesEnum.INTERNAL_USER : UserTypesEnum.EXTERNAL_USER
			}`,
		],
	])

	const {
		data: serviceServerValidation,
		error: serviceServerValidationError,
		isLoading: validatingService,
	} = useServiceValidateByIdQuery(
		repository.serviceManagement,
		{ serviceId: focusedService?.id || '' },
		{ enabled: focusedService !== undefined && serviceManageModalState === true && serviceDetailsModalState === false }
	)

	useEffect(() => {
		if (!focusedService) return
		if (serviceServerValidationError && serviceManageModalState === true) {
			hideServiceManageModal()
			toast.error(translate('CONTRACT.SERVICE.DETAIL.ERROR'))
			return
		}
		if (!serviceServerValidation) return
		if (serviceServerValidation.state !== ServiceValidationState.VALID && serviceManageModalState === true) {
			hideServiceManageModal()
			toast.error(
				translate(errorMessageServiceDetail.get(serviceServerValidation.state) ?? 'CONTRACT.SERVICE.DETAIL.ERROR')
			)
		}
	}, [focusedService, serviceServerValidation, serviceServerValidationError, serviceManageModalState])

	useEffect(() => {
		let timeout: NodeJS.Timeout
		if (!serviceDetailsModalState && !serviceVersionsModalState && !serviceManageModalState) {
			timeout = setTimeout(() => setFocusedService(undefined), 700)
		}
		return () => timeout && clearTimeout(timeout)
	}, [serviceDetailsModalState, serviceManageModalState, serviceVersionsModalState])

	const serviceTypeName = useMemo(() => {
		if (!focusedService) return undefined
		return focusedService.typeOfService
			? translate(`CONTRACT.SERVICE.TYPE.${focusedService.typeOfService.value}`)
			: undefined
	}, [focusedService, translate])

	const detailsModalTitle = useMemo(() => {
		return `${translate('CONTRACT.SERVICE.DETAILS.TITLE', {
			serviceCode: focusedService?.administrativeCode!,
		})}${serviceTypeName ? ` (${serviceTypeName})` : ''}`
	}, [focusedService?.administrativeCode, serviceTypeName, translate])

	const versionsModalTitle = useMemo(() => {
		return `${translate('CONTRACT.SERVICE.VERSIONS.MODAL.TITLE', {
			serviceCode: focusedService?.administrativeCode!,
		})}${serviceTypeName ? ` (${serviceTypeName})` : ''}`
	}, [focusedService?.administrativeCode, serviceTypeName, translate])

	const manageModalTitle = useMemo(() => {
		return `${translate('CONTRACT.SERVICE.MANAGEMENT.TITLE', {
			serviceCode: focusedService?.administrativeCode!,
		})}${serviceTypeName ? ` (${serviceTypeName})` : ''}`
	}, [focusedService?.administrativeCode, serviceTypeName, translate])

	// Every change the user makes in the Filter (Country, Group, Clients) changes the target of the search query.
	//
	// If the filters are explicitly selected, the query will be restricted to them.
	// If the filters are not selected, they will be inferred by the countries and/or corporate groups selected.
	const mkClients = useCallback<() => Promise<ContractedServiceFindRequest>>(async () => {
		// Countries: either the selected ones or all findable by user
		const filterCountries = await (selectedCountries.length > 0
			? Promise.resolve(selectedCountries)
			: internalUser
			? Promise.resolve([])
			: repository.countries
					.getAllCountries()
					.then((countries) => (countries.length === 0 ? undefined : countries.map((c) => c.id as string))))
		// Corporate Groups: either the selected ones or all (clients will restrict the search)
		const filterCorporateGroups = await (selectedCorporateGroups.length > 0
			? Promise.resolve(selectedCorporateGroups)
			: filterCountries === undefined
			? undefined
			: internalUser
			? Promise.resolve([])
			: repository.corporateGroups
					.getCorporateGroupsByCountries({ countries: filterCountries })
					.then((cgs) => (cgs.length === 0 ? undefined : cgs.map((c) => c.id as string))))
		// Clients: either the selected ones or all findable by user
		const filterClients = await (selectedClients.length > 0
			? Promise.resolve(selectedClients)
			: filterCorporateGroups === undefined
			? undefined
			: internalUser
			? Promise.resolve([])
			: repository.clients
					.findClients({ countries: filterCountries || [], corporateGroups: filterCorporateGroups })
					.then((clients) => (clients.length === 0 ? undefined : clients.map((c) => c.id as string))))

		return {
			countryIds: filterCountries,
			corporateGroupIds: filterCorporateGroups,
			clientIds: filterClients,
		}
	}, [
		selectedCountries,
		internalUser,
		repository.countries,
		repository.corporateGroups,
		repository.clients,
		selectedCorporateGroups,
		selectedClients,
	])

	const mkServicesRequest = useCallback<() => Promise<ContractedServiceFindRequest>>(async () => {
		return mkClients().then((req) => ({
			...serviceRequestData,
			countryIds: req.countryIds,
			corporateGroupIds: req.corporateGroupIds,
			clientIds: req.clientIds,
		}))
	}, [mkClients, serviceRequestData])

	const getContractedServices = useCallback(async () => {
		setTableLoading(true)
		return await mkServicesRequest().then((req) =>
			// If no clientsIds were found for search, return empty list
			req.clientIds === undefined
				? Promise.resolve({ pagingData: undefined, services: [] })
				: contractedServiceFinder(repository.contractedService, req, { abortPrevious: true })
		)
	}, [mkServicesRequest, repository.contractedService])

	const onContractedServicesFetched = useCallback((findResponse: ContractedServiceFindResponse) => {
		setTableServices(findResponse.services)
		if (findResponse.pagingData) setTablePaging(findResponse.pagingData)
	}, [])

	const onContractedServicesFailedToFetch = useCallback(
		(err: any) => {
			if (err instanceof DOMException) return
			toast.error(translate('CONTRACT.SERVICE.FIND.ERROR'))
		},
		[translate]
	)

	const findContractedServices = useAsync(
		{
			asyncFn: getContractedServices,
			onSuccess: onContractedServicesFetched,
			onFailure: onContractedServicesFailedToFetch,
			runFinally: () => setTableLoading(false),
			options: { deep: true },
		},
		[serviceRequestData]
	)

	const onCloseOrs = useCallback(() => {
		setSelectedServices([])
		hideContractedServicesOrsModal()
	}, [hideContractedServicesOrsModal])

	const onSendOrs = useCallback(() => {
		onCloseOrs()
		findContractedServices()
	}, [onCloseOrs, findContractedServices])

	const onOrsModalServiceRemove = useCallback(
		(id: string) => {
			setSelectedServices((prev) => {
				const newSelectedValues = prev?.filter((sv) => sv.id !== id)
				newSelectedValues?.length === 0 && hideContractedServicesOrsModal()
				return newSelectedValues
			})
		},
		[hideContractedServicesOrsModal]
	)

	const onVersionsModalVersionSelected = useCallback(
		(service: ContractedService) => {
			setSelectedServiceVersion(service)
			showContractedServicesOrsModal()
		},
		[showContractedServicesOrsModal]
	)

	const headerButtons = useMemo(() => {
		return ORSPermissions.canRead ? (
			<>
				<UfinetButton
					className="me-3"
					isDisabled={tableLoading || selectedServices.length === 0}
					onClick={() => {
						showContractedServicesOrsModal()
					}}
					content={translate('CONTRACT.SERVICE.CREATE_ORS.BUTTON.CREATE_PETITION')}
				/>
				<UfinetButton
					className={`me-3 ${tableLoading ? 'edit-disabled' : ''}`}
					iconClassName={tableLoading ? 'rotating' : ''}
					icon={faArrowsRotate}
					isDisabled={tableLoading}
					onClick={findContractedServices}
				/>
			</>
		) : (
			<></>
		)
	}, [
		ORSPermissions.canRead,
		findContractedServices,
		selectedServices.length,
		showContractedServicesOrsModal,
		tableLoading,
		translate,
	])

	const getServiceActionBodyTemplate = (service: ContractedService): ColumnBodyType[] => {
		const serviceManagementColumn = servicesPermissions.canUpdate ? (
			<i
				className={clsx(
					'pi pi-pencil edit-pencil me-4',
					((service.typeOfService?.value !== ContractedServiceType.CAPACIDAD &&
						service.typeOfService?.value !== ContractedServiceType.INTERNET) ||
						(internalUser
							? service.state?.value !== ContractedServiceState.EN_SERVICIO
							: service.state?.value !== ContractedServiceStateExternal.EN_SERVICIO)) &&
						'invisible'
				)}
				onClick={() => {
					if (!internalUser && service.network.id === NetworkType.OFF_NET) {
						toast.error(
							translate('EDITING.OFF_NET.CONTRACTED.SERVICE.ERROR', { serviceIdentifier: service.administrativeCode })
						)
					} else {
						setFocusedService(service)
						showServiceManageModal()
					}
				}}
			/>
		) : undefined

		const serviceVersionsColumn = ORSPermissions.canWrite ? (
			<i
				style={{ cursor: 'pointer' }}
				className="pi pi-chevron-down me-4"
				onClick={() => {
					setSelectedServices([])
					setFocusedService(service)
					showServiceVersionsModal()
				}}
			/>
		) : undefined

		const serviceDetailColumn = servicesPermissions.canRead ? (
			<i
				style={{ cursor: 'pointer' }}
				className={clsx('pi pi-info-circle', isEmptyObject(service.detailedAttributes) ? 'edit-disabled' : '')}
				onClick={() => {
					setFocusedService(service)
					showServiceDetailsModal()
				}}
			/>
		) : undefined

		return [serviceManagementColumn, serviceVersionsColumn, serviceDetailColumn]
			.filter(Boolean)
			.map((it, idx) => ({ ...it, key: idx }))
	}

	return {
		header: {
			buttons: headerButtons,
		},
		content: {
			values: tableServices,
			selected: selectedServices,
			selectedVersion: selectedServiceVersion,
			isLoading: tableLoading,
			onSelectionChange: ORSPermissions.canRead ? setSelectedServices : undefined,
			getServiceActionBodyTemplate,
		},
		focusedService: {
			value: focusedService,
			validationResult: serviceServerValidation,
			validationError: serviceServerValidationError,
			isValidating: validatingService,
		},
		filter: {
			set: setFilter,
			onClear: onFilterClear,
			isEmpty: isEmptyTableFilter,
			isDisabled: tableLoading ? true : { ...allowAllFilterFields, allowSubmit: allowFilterSubmit },
			countries: selectedCountries,
			onCountriesChange: afterCountryChange,
			corporateGroups: selectedCorporateGroups,
			onCorporateGroupsChange: afterCorporateGroupChange,
			clients: selectedClients,
			onClientsChange: afterClientChange,
		},
		pagination: {
			paging: tablePaging,
			pageFilterSortEvent: tablePFSEvent,
			onPageFilterSortEvent: setTablePFSEvent,
			dataRequest: serviceRequestData,
			onFilter,
			onPage,
			onSort,
			loading: tableLoading,
			settings: {
				...tablePaging,
				loading: tableLoading || validatingService,
				onFilter,
				onPage,
				onSort,
			},
		},
		detailsModal: {
			state: serviceDetailsModalState,
			show: showServiceDetailsModal,
			hide: hideServiceDetailsModal,
			title: detailsModalTitle,
		},
		versionsModal: {
			state: serviceVersionsModalState,
			show: showServiceVersionsModal,
			hide: () => {
				setSelectedServiceVersion(undefined)
				hideServiceVersionsModal()
			},
			title: versionsModalTitle,
			onVersionSelected: onVersionsModalVersionSelected,
		},
		manageModal: {
			state: serviceManageModalState,
			show: showServiceManageModal,
			hide: hideServiceManageModal,
			title: manageModalTitle,
			onOfferConfirmed: findContractedServices,
		},
		orsModal: {
			state: contractedServicesOrsModalState,
			show: showContractedServicesOrsModal,
			hide: hideContractedServicesOrsModal,
			onServiceRemove: onOrsModalServiceRemove,
			onSend: onSendOrs,
			onClose: onCloseOrs,
		},
	}
}

export { useContractedServicesTable }
