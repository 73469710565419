import { ServiceValidationRequest } from 'modules/contractedService/application/management/validate/dto/ServiceValidationRequest'
import { ServiceValidationResponse } from 'modules/contractedService/application/management/validate/dto/ServiceValidationResponse'
import { ContractedServiceManagementRepository } from 'modules/contractedService/domain/repository/ContractedServiceManagementRepository'
import { useQuery } from 'react-query'

export const useServiceValidateByIdQuery = (
	contractedServiceManagementRepository: ContractedServiceManagementRepository,
	serviceValidationRequest: ServiceValidationRequest,
	{
		enabled = true,
		onSuccess,
		onError,
	}: {
		enabled?: boolean
		onSuccess?: (serviceValidationResponse: ServiceValidationResponse) => void
		onError?: (error: unknown) => void
	} = {}
) => {
	return useQuery({
		queryKey: ['service-validate-by-id-query', serviceValidationRequest.serviceId],
		queryFn: () => contractedServiceManagementRepository.isModifiable(serviceValidationRequest, { notifyError: false }),
		enabled,
		onSuccess,
		onError,
		cacheTime: 0,
	})
}
