import { FormikProps, useFormik } from 'formik'
import { BandwidthRepository } from 'modules/bandwidth/domain/repository/bandwidthRepository'
import { HttpBandwidthRepository } from 'modules/bandwidth/infrastructure/HttpBandwidthRepository'
import { ClientParameters } from 'modules/client/parameters/domain/ClientParameters'
import { ClientParametersRepository } from 'modules/client/parameters/domain/repository/clientParametersRepository'
import { HttpClientParametersRepository } from 'modules/client/parameters/infrastructure/HttpClientParametersRepository'
import { ContractedService } from 'modules/contractedService/domain/ContractedService'
import { ContractedServiceDetail } from 'modules/contractedService/domain/detail/ContractedServiceDetail'
import {
	ContractedServiceOperation,
	ContractedServiceOperationData,
} from 'modules/contractedService/domain/management/operation/ContractedServiceOperation'
import { ContractType } from 'modules/contractedService/domain/management/operation/ContractType'

import { getModifyingBandwidthOperation } from 'modules/contractedService/domain/management/operation/helpers/getModifyingBandwidthOperation'
import { getModifyingDeadlineOperation } from 'modules/contractedService/domain/management/operation/helpers/getModifyingDeadlineOperation'
import { getModifyingPriceOperation } from 'modules/contractedService/domain/management/operation/helpers/getModifyingPriceOperation'
import { ManagementOperation } from 'modules/contractedService/domain/management/operation/ManagementOperation'
import { ContractedServicePricingCalculatorRepository } from 'modules/contractedService/domain/pricing/repository/ContractedServicePricingCalculatorRepository'
import { ServiceManagementErrorHandler } from 'modules/contractedService/domain/ServiceManagementErrorHandler'
import { HttpContractedServicePricingCalculatorRepository } from 'modules/contractedService/infrastructure/management/pricing/HttpContractedServicePricingCalculatorRepository'
import { createContext, FC, useContext, useMemo, useState } from 'react'
import {
	AuthContext,
	contactsService,
	IBandwidthResponse,
	IContactRepository,
	IContactResponse,
	LoadingControls,
	useInternalUser,
	useLoading,
} from 'ufinet-web-functions'

type ContractedServiceManageFormContextRepositories = {
	clientParameters: ClientParametersRepository
	contractedServiceCalculator: ContractedServicePricingCalculatorRepository
	bandwidth: BandwidthRepository
	contact: IContactRepository
}

type ContractedServiceManageFormContextData<T extends ContractedServiceOperationData = ContractedServiceOperationData> =
	{
		service: ContractedServiceDetail & ContractedService
		clientParameters: {
			value?: ClientParameters
			onChange: (clientParameters?: ClientParameters) => void
		}
		contractedServiceOperation: {
			isModifying: { bandwidth: boolean; price: boolean; deadline: boolean }
			onChange: (operation?: ContractedServiceOperation) => void
			contractType?: ContractType
			selected: ManagementOperation[]
			value?: ContractedServiceOperation
		}
		bandwidth: {
			options: IBandwidthResponse[]
			setOptions: (newBandwidths: IBandwidthResponse[]) => void
		}
		contact: {
			options: IContactResponse[]
			setOptions: (newContacts: IContactResponse[]) => void
		}
		repository: ContractedServiceManageFormContextRepositories
		form: {
			value: FormikProps<Partial<T>>
			setter: (newForm: FormikProps<Partial<T>>) => void
			loadingControls: LoadingControls
		}
		onEvent: {
			onError: ServiceManagementErrorHandler
			onEditing?: (editing: boolean) => void
			onFormChange?: (formState: FormikProps<Partial<T>>) => void
		}
	}
const ContractedServiceManageFormContext = createContext<ContractedServiceManageFormContextData>(
	{} as ContractedServiceManageFormContextData
)

export const ContractedServiceManageFormContextProvider: FC<
	Pick<ContractedServiceManageFormContextData, 'service' | 'clientParameters' | 'onEvent'> & {
		contractedServiceOperation: Omit<
			ContractedServiceManageFormContextData['contractedServiceOperation'],
			'isModifying'
		>
		repository?: Partial<ContractedServiceManageFormContextRepositories>
	}
> = ({ children, ...props }) => {
	const internalUser = useInternalUser()
	const authData = useContext(AuthContext)

	const modifyingBandwidthOperation = useMemo(
		() => getModifyingBandwidthOperation(props.contractedServiceOperation.selected),
		[props.contractedServiceOperation.selected]
	)
	const modifyingPriceOperation = useMemo(
		() => getModifyingPriceOperation(props.contractedServiceOperation.selected),
		[props.contractedServiceOperation.selected]
	)

	const modifyingDeadlineOperation = useMemo(
		() => getModifyingDeadlineOperation(props.contractedServiceOperation.selected),
		[props.contractedServiceOperation.selected]
	)

	const clientParametersRepository = props.repository?.clientParameters || HttpClientParametersRepository(authData)
	const contractedServiceCalculatorRepository =
		props.repository?.contractedServiceCalculator || HttpContractedServicePricingCalculatorRepository(authData)
	const bandwidthRepository = props.repository?.bandwidth || HttpBandwidthRepository(authData)
	const contactRepository = props.repository?.contact || contactsService(authData)

	const baseForm = useFormik({ initialValues: {}, onSubmit: () => {} })
	const [formData, setFormData] = useState<FormikProps<Partial<ContractedServiceOperationData>>>(baseForm)
	const formLoading = useLoading()

	const [bandwidths, setBandwidths] = useState<IBandwidthResponse[]>([])
	const [contacts, setContacts] = useState<IContactResponse[]>([])

	const providerValue: ContractedServiceManageFormContextData = {
		...props,
		contractedServiceOperation: {
			...props.contractedServiceOperation,
			isModifying: {
				bandwidth: modifyingBandwidthOperation !== undefined,
				price: modifyingPriceOperation !== undefined,
				deadline: modifyingDeadlineOperation !== undefined || !internalUser, // TODO: remove this when external users have deadlines
			},
		},
		repository: {
			clientParameters: clientParametersRepository,
			contractedServiceCalculator: contractedServiceCalculatorRepository,
			bandwidth: bandwidthRepository,
			contact: contactRepository,
		},
		bandwidth: {
			options: bandwidths,
			setOptions: setBandwidths,
		},
		contact: {
			options: contacts,
			setOptions: setContacts,
		},
		form: {
			value: formData,
			setter: setFormData,
			loadingControls: formLoading,
		},
	}

	return (
		<ContractedServiceManageFormContext.Provider value={providerValue}>
			{children}
		</ContractedServiceManageFormContext.Provider>
	)
}

export const useContractedServiceManageFormContext = <T extends ContractedServiceOperationData>() =>
	useContext(ContractedServiceManageFormContext) as unknown as ContractedServiceManageFormContextData<T>
