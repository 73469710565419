import { useBaseManageFormValidation } from 'components/services/management/modal/wizard/modify-service/manage/inner/base/hooks/validation/useBaseManageFormValidation'
import dayjs from 'dayjs'
import { useFormik } from 'formik'
import { ContractedServiceOperationDataScheduled } from 'modules/contractedService/domain/management/operation/scheduled/ContractedServiceOperationScheduled'
import { useTranslator } from 'ufinet-web-functions'
import * as Yup from 'yup'
import { ManageFormValidationCreator, ManageFormValidationInput } from '../../base/manageFormValidationCreator'

export type ScheduledManageFormParameters = Partial<ContractedServiceOperationDataScheduled>
type ScheduledManageFormSchema = {
	[key in keyof ScheduledManageFormParameters]: Yup.SchemaOf<any>
}

export type ScheduledManageFormValidationInput = ManageFormValidationInput<ContractedServiceOperationDataScheduled> & {
	minStartDate: dayjs.Dayjs
	maxFinishDate: dayjs.Dayjs
}

const useScheduledManageFormValidation: ManageFormValidationCreator<
	ContractedServiceOperationDataScheduled,
	ScheduledManageFormValidationInput
> = (input: ScheduledManageFormValidationInput) => {
	const translate = useTranslator()

	const {
		formik: baseFormik,
		initialValues: baseInitialValues,
		validationSchema: baseValidationSchema,
		loadingControls,
	} = useBaseManageFormValidation()

	const { minStartDate, maxFinishDate } = input

	const initialValues: Partial<ContractedServiceOperationDataScheduled> = { ...baseInitialValues }

	const validationSchema = baseValidationSchema.shape<ScheduledManageFormSchema>({
		modifyAt: Yup.date()
			.required(translate('ERROR.REQUIRED'))
			.min(minStartDate.toDate(), translate('FORM.ERROR.AWARD_DATE.MIN.TODAY'))
			.max(maxFinishDate.toDate(), translate('FORM.ERROR.AWARD_DATE')),
	})

	const formik = useFormik<ScheduledManageFormParameters>({
		...baseFormik,
		initialValues,
		validationSchema,
		onSubmit: baseFormik.submitForm,
	})

	return { formik, initialValues, validationSchema, loadingControls }
}

export { useScheduledManageFormValidation }
