import { EventType } from 'modules/event/domain/EventTypesEnum'
import { Publisher } from 'modules/event/domain/Publisher'
import { NotificationEvent } from 'modules/event/domain/notification/NotificationEvent'
import { toast } from 'react-toastify'

export const notificationPublisher: Publisher = {
	publish(event: NotificationEvent) {
		const { notificationType, notificationContent } = event.data
		switch (notificationType) {
			case 'success':
				toast.success(notificationContent)
				break
			case 'error':
				toast.error(notificationContent)
				break
			case 'info':
				toast.info(notificationContent)
				break
			case 'warn':
				toast.warn(notificationContent)
				break
		}

		return { ...event, type: EventType.NOTIFICATION }
	},
}
