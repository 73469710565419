import { FC } from 'react'
import { Ufineti18nProvider, I18nProvider } from 'ufinet-web-components'
import { gseI18nMessages } from '../../../modules/i18n/domain/messages'

const GseI18nProvider: FC = ({ children }) => {
	return (
		<Ufineti18nProvider>
			<I18nProvider messagesByLanguage={gseI18nMessages}>{children}</I18nProvider>
		</Ufineti18nProvider>
	)
}

export { GseI18nProvider }
