import { FC, useMemo } from 'react'

import { formatRemainingDeadline } from 'modules/contractedService/application/management/formatRemainingDeadline'
import { ContractedServiceDetail } from 'modules/contractedService/domain/detail/ContractedServiceDetail'
import { useTranslator } from 'ufinet-web-functions'
import { ContainerWizard } from '../../../../common/ContainerWizard'
import { RowContainerWizard } from '../../../../common/RowContainerWizard'
import { ConfirmResumeTitle } from '../common/ConfirmResumeTitle'

type Props = {
	contractedServiceDetail: ContractedServiceDetail
}

const ConfirmResumeCurrent: FC<Props> = ({ contractedServiceDetail }) => {
	const translate = useTranslator()

	const _remainingDeadlineShown = useMemo(() => {
		if (!contractedServiceDetail.remainingDeadline) return
		const remainingDeadline = formatRemainingDeadline(contractedServiceDetail.remainingDeadline)
		return remainingDeadline + ` ${remainingDeadline === 1 ? translate('MONTH') : translate('MONTHS')}`
	}, [contractedServiceDetail.remainingDeadline, translate])

	return (
		<ContainerWizard>
			<ConfirmResumeTitle title="CONTRACT.SERVICE.MANAGEMENT.CURRENT_SERVICE" />
			<RowContainerWizard
				title="CONTRACT.SERVICE.MANAGEMENT.BANDWIDTH"
				content={contractedServiceDetail.bandwidthFormatted}
			/>
			<RowContainerWizard
				title="CONTRACT.SERVICE.MANAGEMENT.MONTHLY_PAYMENT"
				content={contractedServiceDetail.mrc?.toString()}
			/>
			<RowContainerWizard
				title="CONTRACT.SERVICE.MANAGEMENT.CURRENCY"
				content={contractedServiceDetail.currency}
			/>
		</ContainerWizard>
	)
}
export { ConfirmResumeCurrent }
