import { FormikProps, useFormik } from 'formik'
import { ContractedService } from 'modules/contractedService/domain/ContractedService'
import { OrsEntryType } from 'modules/contractedService/domain/ors/petition/base/OrsEntryType'
import { OrsPetition } from 'modules/contractedService/domain/ors/petition/base/OrsPetition'
import { OrsOperationType } from 'modules/contractedService/domain/ors/petition/base/OrsReactivationType'
import {
	ContractedServiceOrsUnifiedData,
	UnifiedServiceData,
} from 'modules/contractedService/domain/ors/petition/unified/ContractedServiceOrsUnified'
import { OrsStrategy } from 'modules/contractedService/domain/ors/strategies/OrsStrategy'
import { useMemo } from 'react'
import { useTranslator } from 'ufinet-web-functions'
import * as Yup from 'yup'
import { useOrsBaseFormValidation } from '../../base/hooks/useOrsBaseFormValidation'

type HookOutput = {
	form: FormikProps<ContractedServiceOrsUnifiedData>
	servicesValidation: string | undefined
}

type HookInput = {
	selectedEntry: OrsEntryType
	selectedOperation: OrsOperationType[]
	selectedPetition: OrsPetition
	services: ContractedService[]
	handleSubmit: (params: ContractedServiceOrsUnifiedData) => void
	orsStrategy: OrsStrategy
}

const useOrsUnifiedFormValidation = ({
	handleSubmit,
	selectedEntry,
	selectedPetition,
	selectedOperation,
	services,
	orsStrategy,
}: HookInput): HookOutput => {
	const translate = useTranslator()

	const {
		initialValues: baseInitialValues,
		validationSchema: baseValidationSchema,
		form: baseForm,
		servicesValidation,
	} = useOrsBaseFormValidation({
		selectedEntry,
		selectedPetition,
		selectedOperation,
		services,
		orsStrategy,
	})

	const initialValues = useMemo(() => {
		return {
			...baseInitialValues,
			services: services.map((service) => {
				return {
					administrativeCode: service.administrativeCode,
					id: service.id,
				} as UnifiedServiceData
			}),
		}
	}, [baseInitialValues, services])

	const validationSchema = baseValidationSchema.shape({
		observations: Yup.string().when([], {
			is: () => orsStrategy.isObservationRequired,
			then: Yup.string().required(translate('ERROR.REQUIRED')),
			otherwise: Yup.string().notRequired(),
		}),
		date: Yup.date().when([], {
			is: () => orsStrategy.enableCompletionDate,
			then: Yup.date().required(translate('ERROR.REQUIRED')),
			otherwise: Yup.date().notRequired(),
		}),
		reason: Yup.object().when([], {
			is: () => orsStrategy.enableReason,
			then: Yup.object().shape({
				code: Yup.string().required(translate('ERROR.REQUIRED')),
			}),
			otherwise: (schema) => schema.notRequired(),
		}),
	})

	const form = useFormik<ContractedServiceOrsUnifiedData>({
		...baseForm,
		initialValues: initialValues,
		onSubmit: (params, _form) => handleSubmit(params),
		validationSchema: validationSchema,
		onReset: () => baseForm.resetForm(),
	})

	return { form, servicesValidation }
}

export { useOrsUnifiedFormValidation }
