import { useOrsPetitionFindAllQuery } from 'modules/contractedService/application/ors/petition/find/OrsPetitionFindQueries'
import { OrsPetition } from 'modules/contractedService/domain/ors/petition/base/OrsPetition'
import { orsPetitionFromSelectedType } from 'modules/contractedService/domain/ors/petition/base/mappings/orsPetitionFromSelectedType'
import { ContractedServiceOrsRepository } from 'modules/contractedService/domain/repository/ContractedServiceOrsRepository'
import { FC, forwardRef, useCallback, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { ISelectBaseInterface, IUfinetSelectOption, UfinetSelect, UfinetSelectHandle } from 'ufinet-web-components'
import { TranslatorFunction, useTranslator } from 'ufinet-web-functions'

const mkOptionFromOrsPetition = (petition: OrsPetition, translate: TranslatorFunction): IUfinetSelectOption => {
	return {
		label: translate(`CONTRACT.SERVICE.ORS.PETITION.${OrsPetition[petition]}`),
		value: petition.toString(),
	}
}

export type OrsPetitionSelectHandle = UfinetSelectHandle & {}

type Props = ISelectBaseInterface & {
	value: IUfinetSelectOption
	preSelectedPetition?: OrsPetition
	allowedPetitions?: OrsPetition[]
	onPetitionChange: (petition: OrsPetition) => void
	serviceOrsRepository: ContractedServiceOrsRepository
}

const OrsPetitionSelect: FC<Props> = forwardRef<OrsPetitionSelectHandle, Props>(
	(
		{
			requiredIcon = false,
			className,
			onPetitionChange: propsOnChange,
			value,
			error = undefined,
			isDisabled,
			allowedPetitions,
			preSelectedPetition = undefined,
			labelStyles,
			serviceOrsRepository,
			...props
		},
		ref
	) => {
		const selectRef = useRef<UfinetSelectHandle>(null)

		const translate = useTranslator()

		const { data: orsPetitions, isLoading } = useOrsPetitionFindAllQuery(serviceOrsRepository, {
			onError: console.warn,
		})

		const [selectedOption, setSelectedOption] = useState<IUfinetSelectOption>(value)

		const petitionOptions = useMemo(() => {
			const serverPetitions = orsPetitions || []
			const allowedOptions = allowedPetitions
				? serverPetitions.filter((opt) => allowedPetitions.includes(opt))
				: serverPetitions
			return allowedOptions.map((opt) => mkOptionFromOrsPetition(opt, translate))
		}, [allowedPetitions, orsPetitions, translate])

		const preSelectedOption = useMemo(() => {
			const preSelectedOption =
				preSelectedPetition && petitionOptions.find((opt) => preSelectedPetition === parseInt(opt.value))
			return preSelectedOption
		}, [petitionOptions, preSelectedPetition])

		useImperativeHandle(
			ref,
			() =>
				({
					clearSelect: selectRef.current?.clearSelect,
					isEmpty: selectRef.current?.isEmpty,
				} as OrsPetitionSelectHandle)
		)

		const onChange = useCallback(
			(selectedOption: IUfinetSelectOption) => {
				setSelectedOption(selectedOption)
				propsOnChange?.(orsPetitionFromSelectedType(selectedOption))
			},
			[propsOnChange]
		)

		return (
			<UfinetSelect
				ref={selectRef}
				{...props}
				className={className}
				requiredIcon={requiredIcon}
				labelTitle={translate('SELECT.PETITION.OPTION.TITLE')}
				labelStyles={labelStyles}
				tooltipTitle={translate('SELECT.PETITION.OPTION.TOOLTIP')}
				error={error}
				value={selectedOption}
				defaultValue={!preSelectedOption ? undefined : preSelectedOption}
				onChange={(selection) => onChange(selection as IUfinetSelectOption)}
				options={petitionOptions}
				isDisabled={isDisabled || false}
				placeholder={translate('SELECT.PETITION.OPTION.TITLE')}
				isLoadingOptions={isLoading}
			/>
		)
	}
)

export { OrsPetitionSelect }
