import { ContractedServiceManagementRepository } from 'modules/contractedService/domain/repository/ContractedServiceManagementRepository'
import { defaultOptionsFetch, FetchAPI, FetchOptions, IAuthData } from 'ufinet-web-functions'

const url = `${process.env.REACT_APP_URL_BACK_GSE}api/services/contracted/management`

export const HttpContractedServiceManagementRepository = (
	authData: IAuthData
): ContractedServiceManagementRepository => {
	const fetchOptions: FetchOptions = {
		...defaultOptionsFetch,
		authenticationData: authData,
		sendApiKey: true,
	}

	return {
		getAllOperations: async () =>
			await FetchAPI.get(`${url}/operations`, {
				...fetchOptions,
			}),
		isModifiable: async (serviceValidationRequest) =>
			await FetchAPI.post(`${url}/validate`, serviceValidationRequest, { ...fetchOptions }),
	}
}
