import { ContractedServicesManagementOperationsResponse } from 'modules/contractedService/application/management/operation/find/dto/ContractedServicesManagementOperationsResponse'
import { ManagementOperation } from 'modules/contractedService/domain/management/operation/ManagementOperation'
import { rest } from 'msw'

export const managementOperationHandlers = [
	rest.get('*api/services/contracted/management/operations', (req, res, ctx) => {
		return res(
			ctx.status(200),
			ctx.json({
				operations: [ManagementOperation.UPGRADE],
			} as ContractedServicesManagementOperationsResponse)
		)
	}),
]
