import { ContractedServiceOperation } from 'modules/contractedService/domain/management/operation/ContractedServiceOperation'
import { offerCreateRequestFromOperation } from 'modules/offer/application/create/dto/OfferCreateRequest'
import { offerCreator } from 'modules/offer/application/create/offerCreator'
import { Offer } from 'modules/offer/domain/Offer'
import { OfferRepository } from 'modules/offer/domain/repository/OfferRepository'

type HookInput = {
	offerRepository: OfferRepository
}

type HookOutput = {
	createOffer: (input: {
		contractedServiceOperation?: ContractedServiceOperation
		onSuccess: (offer: Offer) => void
		onFail: (err?: Error) => void
	}) => Promise<boolean>
}

export const useOfferCreate: (input: HookInput) => HookOutput = ({ offerRepository }) => {
	return {
		createOffer: ({ contractedServiceOperation, onSuccess, onFail }) => {
			if (!contractedServiceOperation) {
				onFail(new Error('Cannot create offer creation request from missing operation data'))
				return Promise.resolve(false)
			}

			const offerCreateRequest = offerCreateRequestFromOperation(contractedServiceOperation)

			return offerCreator(offerRepository, offerCreateRequest)
				.then((offer) => {
					onSuccess(offer)
					return true
				})
				.catch((e) => {
					onFail(e)
					return false
				})
		},
	}
}
