import { ContractedServiceOperation } from 'modules/contractedService/domain/management/operation/ContractedServiceOperation'
import { ContractType } from 'modules/contractedService/domain/management/operation/ContractType'
import { ContractedServiceOperationScheduled } from 'modules/contractedService/domain/management/operation/scheduled/ContractedServiceOperationScheduled'
import { ContractedServiceOperationTemporal } from 'modules/contractedService/domain/management/operation/temporary/ContractedServiceOperationTemporary'
import {
	OfferConfirmRequest,
	offerConfirmRequestFromManagementOperation,
} from 'modules/offer/application/confirm/dto/OfferConfirmRequest'
import { OfferConfirmResponse } from 'modules/offer/application/confirm/dto/OfferConfirmResponse'
import {
	OfferScheduleRequest,
	offerScheduleRequestFromServiceOperation,
} from 'modules/offer/application/schedule/dto/OfferScheduleRequest'
import {
	OfferTemporalRequest,
	offerTemporalRequestFromServiceOperation,
} from 'modules/offer/application/temporal/dto/OfferTemporalRequest'
import { OfferRepository } from 'modules/offer/domain/repository/OfferRepository'

type HookInput = {
	offerRepository: OfferRepository
}

type HookOutput = {
	confirmOffer: (input: {
		offerCode?: string
		contractedServiceOperation: ContractedServiceOperation
		onSuccess: (response: OfferConfirmResponse) => void
		onFail: (err?: Error) => void
	}) => Promise<boolean>
}

type ConfirmOfferFunctionParameters<T extends ContractedServiceOperation = ContractedServiceOperation> = {
	offerCode?: string
	contractedServiceOperation: T
	onSuccess: (response: OfferConfirmResponse) => void
	onFail: (err?: Error) => void
}

type ConfirmOfferFunction = (input: ConfirmOfferFunctionParameters) => Promise<boolean>

export const useOfferConfirm: (input: HookInput) => HookOutput = ({ offerRepository }) => {
	const awardOffer: (
		input: Required<ConfirmOfferFunctionParameters<ContractedServiceOperation>>
	) => Promise<boolean> = async ({ contractedServiceOperation, offerCode, onSuccess, onFail }) => {
		const offerConfirmRequest: OfferConfirmRequest = offerConfirmRequestFromManagementOperation({
			offerCode,
			contractedServiceOperation,
		})
		try {
			const res = await offerRepository.confirm(offerConfirmRequest)
			onSuccess(res)
			return true
		} catch (e) {
			onFail(e as Error)
			return false
		}
	}

	const scheduleOffer: (
		input: ConfirmOfferFunctionParameters<ContractedServiceOperationScheduled>
	) => Promise<boolean> = async ({ contractedServiceOperation, onSuccess, onFail }) => {
		const offerScheduleRequest: OfferScheduleRequest = offerScheduleRequestFromServiceOperation({
			contractedServiceOperation,
		})
		try {
			const res = await offerRepository.schedule(offerScheduleRequest)
			onSuccess(res)
			return true
		} catch (e) {
			onFail(e as Error)
			return false
		}
	}

	const scheduleTemporaryOffer: (
		input: ConfirmOfferFunctionParameters<ContractedServiceOperationTemporal>
	) => Promise<boolean> = async ({ contractedServiceOperation, offerCode, onSuccess, onFail }) => {
		const offerTemporalRequest: OfferTemporalRequest = offerTemporalRequestFromServiceOperation({
			offerCode,
			contractedServiceOperation,
		})
		try {
			const res = contractedServiceOperation.awardsImmediately
				? await offerRepository.temporalImmediateConfirm(offerTemporalRequest)
				: await offerRepository.temporal(offerTemporalRequest)
			onSuccess(res)
			return true
		} catch (e) {
			onFail(e as Error)
			return false
		}
	}

	const confirmOffer: ConfirmOfferFunction = (input) => {
		if (!input.contractedServiceOperation || (input.contractedServiceOperation.awardsImmediately && !input.offerCode)) {
			input.onFail(new Error('Cannot create offer confirm request'))
			return Promise.resolve(false)
		}

		const { contractedServiceOperation: operation, onFail } = input

		switch (operation.contractType) {
			case ContractType.IMMEDIATE:
				return awardOffer(input as Required<ConfirmOfferFunctionParameters>)
			case ContractType.SCHEDULED:
				return scheduleOffer(input as ConfirmOfferFunctionParameters<ContractedServiceOperationScheduled>)
			case ContractType.TEMPORAL:
				return scheduleTemporaryOffer(input as ConfirmOfferFunctionParameters<ContractedServiceOperationTemporal>)
			default:
				onFail(new Error('Cannot create offer confirmation request from unknown management operation'))
				return Promise.resolve(false)
		}
	}

	return {
		confirmOffer,
	}
}
