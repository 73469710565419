import { useFormik } from 'formik'
import { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'

import { faArrowRotateLeft, faEdit, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import clsx from 'clsx'
import {
	ClientTypeSelect,
	ClientTypeSelectHandle,
	ClientTypeSelectOption,
} from 'components/client/type/selects/ClientTypeSelect'
import { useClientParametersFindByIdQuery } from 'modules/client/parameters/application/find/ClientParametersFindQueries'
import { ClientParametersFindResponse } from 'modules/client/parameters/application/find/dto/ClientParametersFindResponse'
import { clientParametersSaver } from 'modules/client/parameters/application/save/clientParametersSaver'
import { ClientParametersSaveRequest } from 'modules/client/parameters/application/save/dto/ClientParametersSaveRequest'
import { clientParametersUpdater } from 'modules/client/parameters/application/update/clientParametersUpdater'
import { ClientParametersUpdateRequest } from 'modules/client/parameters/application/update/dto/ClientParametersUpdateRequest'
import { HttpClientParametersRepository } from 'modules/client/parameters/infrastructure/HttpClientParametersRepository'
import { ContractType } from 'modules/contractedService/domain/management/operation/ContractType'
import { notificationPublisher } from 'modules/event/application/notification/NotificationPublisher'
import { toast } from 'react-toastify'
import {
	CheckBoxGroupHandle,
	ClientSelect,
	ClientSelectHandle,
	ClientSelectOption,
	DeadlineSelect,
	DeadlineSelectHandle,
	emptyClientSelectOption,
	emptyUfinetSelectOption,
	IUfinetSelectOption,
	Loading,
	UfinetButton,
	UfinetCheckBoxGroup,
	UfinetCheckBoxOption,
	UfinetInput,
	UfinetRadioButtonOption,
} from 'ufinet-web-components'
import {
	AuthContext,
	IdAndValue,
	isNumeric,
	onFormikNumberChanges,
	TranslatorFunction,
	useTranslator,
} from 'ufinet-web-functions'
import * as Yup from 'yup'

const mkFormValidation = (translate: TranslatorFunction) =>
	Yup.object().shape<FormSchema>(
		{
			clientId: Yup.string().when('clientType', {
				is: undefined,
				then: (schema) => schema.required(translate('CLIENT.PARAMETERS.CLIENT.SELECT.ERROR')),
				otherwise: (schema) => schema.oneOf([null, undefined], translate('ERROR.NOT_EMPTY')),
			}),
			clientName: Yup.string().optional(),
			clientType: Yup.string().when('clientId', {
				is: undefined,
				then: (schema) => schema.required(translate('CLIENT.PARAMETERS.CLIENT.TYPE.SELECT.ERROR')),
				otherwise: (schema) => schema.oneOf([null, undefined], translate('ERROR.NOT_EMPTY')),
			}),
			currentDeadline: Yup.number()
				.required(translate('ERROR.REQUIRED'))
				.min(1, (value) => translate('ERROR.MUST_BE_GREATER', { value: value.min })),
			minBandwidth: Yup.number()
				.required(translate('ERROR.REQUIRED'))
				.when('maxBandwidth', {
					is: (value?: number) => !value || value <= 0,
					then: (schema) => schema.min(1, (value) => translate('ERROR.MUST_BE_GREATER', { value: value.min })),
					otherwise: (schema) =>
						schema.lessThan(Yup.ref('maxBandwidth'), (_value) =>
							translate('CLIENT.PARAMETERS.MINIMUM_BANDWIDTH.SELECT.ERROR.MAX')
						),
				}),
			maxBandwidth: Yup.number()
				.required(translate('ERROR.REQUIRED'))
				.when('minBandwidth', {
					is: (value?: number) => !value || value <= 0,
					then: (schema) => schema.min(1, (value) => translate('ERROR.MUST_BE_GREATER', { value: value.min })),
					otherwise: (schema) =>
						schema.moreThan(Yup.ref('minBandwidth'), (_value) =>
							translate('CLIENT.PARAMETERS.MAXIMUM_BANDWIDTH.SELECT.ERROR.MIN')
						),
				}),
			immediate: Yup.bool().required(translate('ERROR.REQUIRED')),
			programmed: Yup.bool().required(translate('ERROR.REQUIRED')),
			temporary: Yup.bool().required(translate('ERROR.REQUIRED')),
			hiringTypes: Yup.array()
				.required(translate('ERROR.REQUIRED'))
				.of(Yup.string())
				.min(1, (_value) => translate('ERROR.REQUIRED')),
			hiringDeadlines: Yup.array()
				.required(translate('ERROR.REQUIRED'))
				.of(Yup.number().min(1, (value) => translate('ERROR.MUST_BE_GREATER', { value: value.min })))
				.min(1, (value) => translate('ERROR.MUST_CONTAIN_N_ITEMS', { value: value.min })),
			keepDeadline: Yup.bool().required(translate('ERROR.REQUIRED')),
		},
		[
			['minBandwidth', 'maxBandwidth'],
			['clientId', 'clientType'],
		]
	)

type FormParameters = Partial<ClientParametersSaveRequest & ClientParametersUpdateRequest> & {
	hiringTypes: ContractType[]
}

export type ClientParametersFormHandler = (formData: FormParameters, shouldClose?: boolean) => void

type FormSchema = {
	[key in keyof FormParameters]: Yup.SchemaOf<any>
}
export const initialClientParameters: FormParameters = {
	id: undefined,
	clientId: undefined,
	clientName: undefined,
	clientType: undefined,
	clientTypeName: undefined,
	currentDeadline: undefined,
	immediate: false,
	programmed: false,
	temporary: false,
	hiringTypes: [],
	hiringDeadlines: [],
	minBandwidth: undefined,
	maxBandwidth: undefined,
	keepDeadline: false,
}

type Props = {
	paramsId?: number
	onParamsSubmitted?: ClientParametersFormHandler
	onLoadError?: (error?: any) => void
	onSubmitError?: (error?: any) => void
}

const ClientParametersModal: FC<Props> = ({ paramsId, onParamsSubmitted, onSubmitError, onLoadError }) => {
	const intl = useIntl()
	const translate = useTranslator()
	const authData = useContext(AuthContext)

	const isUpdateModal = useMemo(() => !!paramsId, [paramsId])

	const clientParametersRepository = useMemo(() => HttpClientParametersRepository(authData), [authData])
	const [initialClientParamsData, setInitialClientParamsData] = useState<FormParameters>(initialClientParameters)

	const clientRef = useRef<ClientSelectHandle>(null)
	const clientTypeRef = useRef<ClientTypeSelectHandle>(null)
	const deadlineRef = useRef<DeadlineSelectHandle>(null)
	const checkBoxGroupRef = useRef<CheckBoxGroupHandle>(null)
	const keepDeadlineRef = useRef<CheckBoxGroupHandle>(null)
	const KEEP_DEADLINE = 'KEEP_DEADLINE'

	const [selectedClient, setSelectedClient] = useState<ClientSelectOption>()
	const [selectedClientType, setSelectedClientType] = useState<ClientTypeSelectOption>()
	const isClientSelected = useMemo(
		() => !!selectedClient && selectedClient.value !== emptyClientSelectOption.value,
		[selectedClient]
	)
	const isClientTypeSelected = useMemo(
		() => !!selectedClientType && selectedClientType.value !== emptyUfinetSelectOption.value,
		[selectedClientType]
	)

	const onNumberChange = useCallback(onFormikNumberChanges, [])

	const [loading, setLoading] = useState<boolean>(isUpdateModal)
	const [shouldClose, setShouldClose] = useState<boolean>(false)

	const hiringOptions: UfinetRadioButtonOption[] = useMemo(
		() => [
			{
				value: ContractType.IMMEDIATE,
				label: translate('CONTRACT.SERVICE.CONTRACT.TYPE.IMMEDIATE'),
			},
			{
				value: ContractType.SCHEDULED,
				label: translate('CONTRACT.SERVICE.CONTRACT.TYPE.SCHEDULED'),
			},
			{
				value: ContractType.TEMPORAL,
				label: translate('CONTRACT.SERVICE.CONTRACT.TYPE.TEMPORAL'),
			},
		],
		[translate]
	)

	const keepDeadlineOptions: UfinetCheckBoxOption[] = useMemo(
		() => [
			{
				value: KEEP_DEADLINE,
			},
		],
		[]
	)

	const onClientParametersFound = (clientParams: ClientParametersFindResponse) => {
		const formattedValues: FormParameters = {
			...clientParams,
			id: paramsId,
			clientId: clientParams.client?.id,
			clientName: clientParams.client?.value,
			clientType: clientParams.clientType?.id,
			hiringTypes: [
				clientParams.immediate && ContractType.IMMEDIATE,
				clientParams.programmed && ContractType.SCHEDULED,
				clientParams.temporary && ContractType.TEMPORAL,
			].filter(Boolean) as ContractType[],
			hiringDeadlines: clientParams.hiringDeadlines.map((dl: IdAndValue<number, number>) => dl.value),
			keepDeadline: clientParams.keepDeadline,
		}

		formik.setValues(formattedValues)
		setInitialClientParamsData(formattedValues)

		if (clientParams.client) {
			onClientChange({
				label: clientParams.client.value,
				value: clientParams.client.id,
				corporateGroupId: '',
			})
		}

		if (clientParams.clientType) {
			onClientTypeChange({
				label: translate(`CLIENT.PARAMETERS.CLIENT.TYPE.${clientParams.clientType.value}`),
				value: clientParams.clientType.id,
				name: clientParams.clientType.value,
			})
		}
	}

	const onClientParametersFailedToFetch = (e: unknown) => {
		toast.error(translate('CLIENT.PARAMETERS.FETCH.ERROR'))
		onLoadError?.(e)
	}

	const {
		data: clientParameters,
		isLoading: isLoadingClientParameters,
		refetch,
	} = useClientParametersFindByIdQuery(clientParametersRepository, paramsId!, {
		enabled: false,
		onSuccess: onClientParametersFound,
		onError: onClientParametersFailedToFetch,
	})

	useEffect(() => {
		clientParameters && onClientParametersFound(clientParameters)
		setLoading(isLoadingClientParameters)
	}, [clientParameters, isLoadingClientParameters])

	useEffect(() => {
		clientRef.current?.fillAllClients()
		if (!paramsId) return
		refetch()
	}, [])

	const dataFormSchema = useMemo(() => mkFormValidation(translate), [translate])

	const formik = useFormik<FormParameters>({
		initialValues: initialClientParamsData,
		validationSchema: dataFormSchema,
		onSubmit: (clientParams, _form) => {
			submitParameters(clientParams)
		},
		validateOnChange: false,
		validateOnBlur: false,
		enableReinitialize: true,
		onReset: (_values, form) => {
			form.setValues(initialClientParamsData)
			if (!isUpdateModal) {
				onClientChange()
				onClientTypeChange()
			}
		},
	})

	const onClientChange = useCallback(
		(client?: ClientSelectOption) => {
			formik.setFieldValue('clientId', client?.value)
			formik.setFieldValue('clientName', client?.label)
			formik.setErrors({})
			setSelectedClient(client)
		},
		[formik]
	)

	const onClientTypeChange = useCallback(
		(clientType?: ClientTypeSelectOption) => {
			formik.setFieldValue('clientType', clientType?.value)
			formik.setFieldValue('clientTypeName', clientType?.name)
			formik.setErrors({})
			setSelectedClientType(clientType)
		},
		[formik]
	)

	const onHiringTypesChange = useCallback(
		(hiringTypes: UfinetCheckBoxOption[]) => {
			const values = hiringTypes.map((it) => it.value)
			formik.setFieldValue('hiringTypes', values)

			formik.setFieldValue('immediate', false)
			formik.setFieldValue('programmed', false)
			formik.setFieldValue('temporary', false)

			if (values.includes(ContractType.IMMEDIATE)) formik.setFieldValue('immediate', true)
			if (values.includes(ContractType.SCHEDULED)) formik.setFieldValue('programmed', true)
			if (values.includes(ContractType.TEMPORAL)) formik.setFieldValue('temporary', true)
		},
		[formik]
	)

	const onHiringDeadlinesChange = useCallback(
		(hiringDeadlines: IUfinetSelectOption[] = []) => {
			const newDeadlineOptions = hiringDeadlines
				.map((it) => +it.value)
				.filter(isNumeric)
				.sort((a, b) => a - b)
			formik.setFieldValue('hiringDeadlines', newDeadlineOptions)
		},
		[formik]
	)

	const onKeepDeadlineChange = useCallback(
		(keepDeadline: UfinetCheckBoxOption[]) => {
			const values = keepDeadline.map((it) => it.value)
			formik.setFieldValue('keepDeadline', false)

			if (values.includes(KEEP_DEADLINE)) formik.setFieldValue('keepDeadline', true)
		},
		[formik]
	)

	const submitParameters = useCallback(
		(formParameters: FormParameters): void => {
			setLoading(true)
			const req = isUpdateModal
				? clientParametersUpdater(clientParametersRepository, formParameters as ClientParametersUpdateRequest, {
						intl,
						publisher: notificationPublisher,
				  })
				: clientParametersSaver(clientParametersRepository, formParameters as ClientParametersSaveRequest, {
						intl,
						publisher: notificationPublisher,
				  })
			req
				.then(() => {
					onParamsSubmitted?.(formParameters, shouldClose)
					if (!shouldClose) formik.resetForm()
					return formParameters
				})
				.catch(onSubmitError)
				.finally(() => setLoading(false))
		},
		[isUpdateModal, clientParametersRepository, onSubmitError, intl, onParamsSubmitted, shouldClose, formik]
	)

	const sendClientParameters = useCallback(() => {
		formik
			.validateForm()
			.then((errors) => {
				if (errors && typeof errors === 'object' && Object.keys(errors).length === 0) {
					formik.handleSubmit()
				}
				return errors
			})
			.catch(console.warn)
	}, [formik])

	const sendParametersThenClose = useCallback(() => {
		setShouldClose(true)
		sendClientParameters()
	}, [sendClientParameters])

	const sendParametersThenContinue = useCallback(() => {
		setShouldClose(false)
		sendClientParameters()
	}, [sendClientParameters])

	return (
		<form
			onSubmit={(e) => {
				e.preventDefault()
			}}
			className={clsx(
				'position-relative d-flex flex-column justify-content-center p-0 m-0',
				loading && 'form-disabled'
			)}
		>
			<div className="row">
				<div className="col-lg d-flex flex-column gap-3">
					<ClientSelect
						ref={clientRef}
						requiredIcon={!isClientTypeSelected}
						value={selectedClient}
						error={formik.errors.clientId}
						onChange={(client) => onClientChange(client as ClientSelectOption | undefined)}
						isDisabled={isUpdateModal || isClientTypeSelected}
						placeholder={translate('BY_DEFAULT')}
						tooltipTitle={translate('CLIENT.PARAMETERS.CLIENT.SELECT.TOOLTIP')}
					/>
					<ClientTypeSelect
						ref={clientTypeRef}
						requiredIcon={!isClientSelected}
						value={selectedClientType}
						error={formik.errors.clientType}
						onChange={(clientType) => onClientTypeChange(clientType as ClientTypeSelectOption | undefined)}
						isDisabled={isUpdateModal || isClientSelected}
					/>
					<UfinetInput
						requiredIcon
						labelTitle={translate('CLIENT.PARAMETERS.CURRENT_DEADLINE')}
						type="number"
						value={formik.values.currentDeadline?.toString() || ''}
						error={formik.errors.currentDeadline}
						onChange={onNumberChange(formik, 'currentDeadline')}
						solid={false}
					/>
					<UfinetInput
						requiredIcon
						labelTitle={translate('CLIENT.PARAMETERS.MINIMUM_BANDWIDTH')}
						type="number"
						value={formik.values.minBandwidth?.toString() || ''}
						error={formik.errors.minBandwidth}
						onChange={onNumberChange(formik, 'minBandwidth')}
						solid={false}
					/>
					<UfinetInput
						requiredIcon
						labelTitle={translate('CLIENT.PARAMETERS.MAXIMUM_BANDWIDTH')}
						type="number"
						value={formik.values.maxBandwidth?.toString() || ''}
						error={formik.errors.maxBandwidth}
						onChange={onNumberChange(formik, 'maxBandwidth')}
						solid={false}
					/>
				</div>
				<div className="col-lg d-flex flex-column gap-3">
					<UfinetCheckBoxGroup
						ref={checkBoxGroupRef}
						requiredIcon
						options={hiringOptions}
						inline
						error={formik.errors.hiringTypes}
						selection={formik.values.hiringTypes}
						onChange={onHiringTypesChange}
						title={translate('CLIENT.PARAMETERS.HIRING_TYPE')}
						titleClassName="mt-2"
					/>
					<DeadlineSelect
						ref={deadlineRef}
						requiredIcon
						isMulti
						isCreatable
						includeDefaultOptions={false}
						menuIsOpen={false}
						labelTitle={translate('CLIENT.PARAMETERS.HIRING_DEADLINE')}
						noTooltip
						value={formik.values.hiringDeadlines}
						options={formik.values.hiringDeadlines}
						error={formik.errors.hiringDeadlines}
						onChange={(e) => onHiringDeadlinesChange(e as IUfinetSelectOption[])}
						components={{ DropdownIndicator: null }}
					/>
					<UfinetCheckBoxGroup
						ref={keepDeadlineRef}
						requiredIcon
						options={keepDeadlineOptions}
						inline
						error={formik.errors.keepDeadline}
						selection={[formik.values.keepDeadline ? KEEP_DEADLINE : '']}
						onChange={onKeepDeadlineChange}
						title={translate('CLIENT.PARAMETERS.KEEP_DEADLINE')}
						titleClassName="mt-2"
					/>
				</div>
			</div>
			<div className="row">
				<UfinetButton
					className="mt-5 ms-3 p-5 w-auto"
					content={translate(paramsId ? 'UPDATE' : 'ADD.CLOSE')}
					icon={isUpdateModal ? faEdit : faPlus}
					onClick={sendParametersThenClose}
					isDisabled={false}
				/>
				{!isUpdateModal && (
					<UfinetButton
						className="mt-5 ms-3 p-5 w-auto"
						content={translate('ADD.CONTINUE')}
						icon={faPlus}
						onClick={sendParametersThenContinue}
						isDisabled={false}
					/>
				)}
				<UfinetButton
					secondaryButton
					className="mt-5 ms-3 p-5 w-auto"
					content={translate(isUpdateModal ? 'RESTART' : 'CLEAR')}
					icon={isUpdateModal ? faArrowRotateLeft : faTrash}
					onClick={() => formik.resetForm()}
					isDisabled={false}
				/>
			</div>
			<input
				className="d-none"
				type="submit"
			/>

			{loading && <Loading />}
		</form>
	)
}

export default ClientParametersModal
