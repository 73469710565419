/* eslint-disable react/jsx-no-target-blank */
import { FC, useContext, useState } from 'react'

import {
	PATH_CLIENT_PARAMETERS,
	PATH_CONFIG_CLIENT_PARAMETERS,
	PATH_SERVICES_EXCEPTION,
	PATH_SERVICES_MANAGEMENT,
	PATH_SERVICES_SERVICES_EXCEPTION,
	PATH_SERVICES_SERVICES_MANAGEMENT,
	PATH_SERVICES_SERVICES_TRACKING,
	PATH_SERVICES_TRACKING,
	routerNameMap,
} from '../../routing/protected/PrivateRoutes'

import { faCircle, faExchange, faGear } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useLocation } from 'react-router-dom'
import { AsideMenuItem, AsideMenuItemWithSub, IMenuItem } from 'ufinet-web-components'
import { AuthContext, Authority, useInternalUser, useTranslator } from 'ufinet-web-functions'

enum AsideMenu {
	NONE,
	SERVICES,
	CONFIGURATION,
}

const AsideMenuMain: FC = () => {
	const translate = useTranslator()
	const location = useLocation()

	const { userData } = useContext(AuthContext)
	const internalUser = useInternalUser()

	const [active, setActive] = useState(AsideMenu.NONE)

	const activeMenu = (menu: AsideMenu) => () => setActive(menu)
	const isServicesActive = () => active === AsideMenu.SERVICES
	const isConfigurationActive = () => active === AsideMenu.CONFIGURATION

	const roles = userData?.authorities || []
	const servicePermissions = Authority.getServicesPermissions(roles)
	const configPermissions = Authority.getGseConfigPermissions(roles)
	const exceptionPermissions = Authority.getGseExceptionPermissions(roles)

	const menuServicesItems: IMenuItem[] = [
		{
			to: PATH_SERVICES_SERVICES_MANAGEMENT,
			title: routerNameMap.get(PATH_SERVICES_MANAGEMENT),
			icon: faCircle,
			hidden: !servicePermissions.canRead,
		},
		{
			to: PATH_SERVICES_SERVICES_TRACKING,
			title: routerNameMap.get(PATH_SERVICES_TRACKING),
			icon: faCircle,
			hidden: !servicePermissions.canRead,
		},
		{
			to: PATH_SERVICES_SERVICES_EXCEPTION,
			title: routerNameMap.get(PATH_SERVICES_EXCEPTION),
			icon: faCircle,
			hidden: !exceptionPermissions.canRead,
		},
	]

	const menuConfigurationItems: IMenuItem[] = [
		{ to: PATH_CONFIG_CLIENT_PARAMETERS, title: routerNameMap.get(PATH_CLIENT_PARAMETERS), icon: faCircle },
	].map((it) => ({ ...it, hidden: !configPermissions.canWrite }))

	return (
		<>
			{(servicePermissions.canRead || exceptionPermissions.canRead) && (
				<AsideMenuItemWithSub
					icon={
						<FontAwesomeIcon
							icon={faExchange}
							className="fs-2x"
						/>
					}
					title={translate('MENU.SERVICES')}
					active={isServicesActive()}
				>
					{menuServicesItems
						.filter((item) => !item.hidden)
						.map((item, _index) => (
							<AsideMenuItem
								routerLocation={location}
								key={item.to}
								to={item.to}
								icon={item.icon}
								title={item.title ? translate(item.title) : ''}
								onClick={activeMenu(AsideMenu.SERVICES)}
							/>
						))}
				</AsideMenuItemWithSub>
			)}

			{configPermissions.canWrite && internalUser && (
				<AsideMenuItemWithSub
					icon={
						<FontAwesomeIcon
							icon={faGear}
							className="fs-2x"
						/>
					}
					title={translate('MENU.CONFIGURATION')}
					active={isConfigurationActive()}
				>
					{menuConfigurationItems
						.filter((item) => !item.hidden)
						.map((item, _index) => (
							<AsideMenuItem
								routerLocation={location}
								key={item.to}
								to={item.to}
								icon={item.icon}
								title={item.title ? translate(item.title) : ''}
								onClick={activeMenu(AsideMenu.CONFIGURATION)}
							/>
						))}
				</AsideMenuItemWithSub>
			)}
		</>
	)
}

export { AsideMenuMain }
