import { useBeforeCloseConfirmResume } from 'components/services/management/modal/wizard/modify-service/confirm-resume/hooks/useBeforeCloseConfirmResume'
import { useBeforeConfirm } from 'components/services/management/modal/wizard/modify-service/confirm-resume/hooks/useBeforeConfirm'
import { useBeforeCloseModifyService } from 'components/services/management/modal/wizard/modify-service/hooks/useBeforeCloseModifyService'
import { useOfferConfirm } from 'components/services/management/modal/wizard/modify-service/offer/confirm/hooks/useOfferConfirm'
import { useOfferCreate } from 'components/services/management/modal/wizard/modify-service/offer/create/hooks/useOfferCreate'
import { FormikProps } from 'formik'
import { ClientParameters } from 'modules/client/parameters/domain/ClientParameters'
import { isExpiredByDueDate } from 'modules/contractedService/application/isExpired'
import { ContractedService } from 'modules/contractedService/domain/ContractedService'
import { ContractedServiceDetail } from 'modules/contractedService/domain/detail/ContractedServiceDetail'
import {
	ContractedServiceOperation,
	ContractedServiceOperationData,
} from 'modules/contractedService/domain/management/operation/ContractedServiceOperation'
import { ContractType } from 'modules/contractedService/domain/management/operation/ContractType'
import { ManagementOperation } from 'modules/contractedService/domain/management/operation/ManagementOperation'
import { ServiceManagementErrorHandler } from 'modules/contractedService/domain/ServiceManagementErrorHandler'
import { invalidOfferStatesForExternalUser, Offer } from 'modules/offer/domain/Offer'
import { OfferRepository } from 'modules/offer/domain/repository/OfferRepository'
import { useCallback, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { WizardVerticalHandle } from 'ufinet-web-components'
import { useInternalUser, useLoading, UserTypesEnum, useTranslator } from 'ufinet-web-functions'

type HookInput = {
	wizardRef: React.RefObject<WizardVerticalHandle>
	service: ContractedService
	offer: {
		repository: OfferRepository
		onCreated?: (offer: Offer) => void
		onConfirmed?: () => void
	}
	closeModal: () => void
}

export type OfferFlowInformation = {
	value?: Offer
	awardsImmediately: boolean
	attemptedCreation: boolean
	attemptedConfirmation: boolean
}

type HookOutput = {
	wizard: {
		ref: React.RefObject<WizardVerticalHandle>
		locked: boolean
		flow: {
			canContinueToForm: boolean
			beforeCloseModifyService: (onConfirm: () => void) => Promise<boolean>
			beforeCloseConfirmResume: (input: { offer?: Offer; onConfirm: () => void }) => Promise<boolean>
			canContinueToConfirmation: boolean
			beforeContinueToConfirmation: () => Promise<boolean>
			beforeContinueToEndScreen: () => Promise<boolean>
			beforeContinueToFinish: () => Promise<boolean>
		}
	}
	form: {
		onChange?: (formState: FormikProps<Partial<ContractedServiceOperationData>>) => void
	}
	contractedService: {
		base: ContractedService
		details?: ContractedServiceDetail
	}
	clientParameters?: ClientParameters
	managementOperations: {
		value: ManagementOperation[]
		valid: boolean
		incompatibleOperations: ManagementOperation[][]
		invalidDueToExpiredService: boolean
	}
	contractType?: ContractType
	contractedServiceOperation?: ContractedServiceOperation
	offer: OfferFlowInformation
	operationSuccess?: boolean
	onEditing: (editing: boolean) => void
	onChange: {
		contractedService: {
			details: (service: ContractedServiceDetail) => void
		}
		managementOperations: (contractedServiceManagementOperations: ManagementOperation[]) => void
		contractType: (contractType?: ContractType) => void
		contractedServiceOperation: (contractedServiceOperation?: ContractedServiceOperation) => void
		clientParameters: (clientParameters?: ClientParameters) => void
	}
	onError: ServiceManagementErrorHandler
	loading: {
		calculate: boolean
		confirm: boolean
	}
}

const OPPOSITE_OPTIONS: ManagementOperation[][] = [
	[ManagementOperation.UPGRADE, ManagementOperation.DOWNGRADE],
	[ManagementOperation.AUMENTO_PRECIO, ManagementOperation.BAJA_PRECIO],
	[ManagementOperation.AUMENTO_PLAZO, ManagementOperation.DISMINUCION_PLAZO],
]

function useContractedServiceManageModal({
	wizardRef,
	service,
	offer: { repository: offerRepository, onCreated: onOfferCreated, onConfirmed: onOfferConfirmed },
	closeModal,
}: HookInput): HookOutput {
	const internalUser = useInternalUser()
	const translate = useTranslator()

	const [managementForm, setManagementForm] = useState<FormikProps<Partial<ContractedServiceOperationData>>>()
	const [wizardLocked, setWizardLocked] = useState(false)

	const [serviceDetails, setServiceDetails] = useState<ContractedServiceDetail>()
	const [clientParameters, setClientParameters] = useState<ClientParameters>()

	const [contractType, setContractType] = useState<ContractType>()
	const [managementOperations, setManagementOperations] = useState<ManagementOperation[]>([])

	const sortOppositeOptions = useCallback((managementOperations: ManagementOperation[]): ManagementOperation[][] => {
		const isOperationSelected = (operationId: ManagementOperation): boolean =>
			!!managementOperations.find((option) => option === operationId)
		const isPairSelected = ([operation1, operation2]: ManagementOperation[]): boolean =>
			isOperationSelected(operation1) && isOperationSelected(operation2)

		const getSelectedPair = (operationId: ManagementOperation): ManagementOperation[] | undefined =>
			OPPOSITE_OPTIONS.find((pair) => pair.includes(operationId) && isPairSelected(pair))

		const seen = new Set()
		return managementOperations.reduce((acc: ManagementOperation[][], value) => {
			const pair = getSelectedPair(value)
			if (pair && !seen.has(pair.toString())) {
				seen.add(pair.toString())
				acc.push(pair)
			}
			return acc
		}, [])
	}, [])

	const incompatibleOperations = useMemo(
		() => sortOppositeOptions(managementOperations),
		[managementOperations, sortOppositeOptions]
	)

	const invalidDueToExpiredService = useMemo(() => {
		if (!isExpiredByDueDate(service.dueDate)) return false

		return internalUser && !managementOperations.some((value) => value === ManagementOperation.AUMENTO_PLAZO)
	}, [managementOperations, service.dueDate, internalUser])

	const [contractedServiceOperation, setContractedServiceOperation] = useState<ContractedServiceOperation>()

	const [offer, setOffer] = useState<Offer>()

	const [offerAttemptedCreation, setOfferAttemptedCreation] = useState(false)
	const [offerAttemptedConfirmation, setOfferAttemptedConfirmation] = useState(false)

	const [operationSuccess, setOperationSuccess] = useState<boolean>()

	const onError: ServiceManagementErrorHandler = useCallback(
		(error) => {
			const userType = internalUser ? UserTypesEnum.INTERNAL_USER : UserTypesEnum.EXTERNAL_USER

			if (process.env.REACT_APP_ENV !== 'prod') {
				console.error(error)
			}

			toast.error(translate(`CONTRACT.SERVICE.OPERATION.GENERIC.ERROR.${userType}`))
			wizardRef.current?.close()
		},
		[internalUser, translate, wizardRef]
	)

	const loadingControlsCalculate = useLoading()
	const loadingControlsConfirm = useLoading()

	const canContinueToConfirmation = useMemo(
		() =>
			contractedServiceOperation !== undefined &&
			!loadingControlsCalculate.loading &&
			!wizardLocked &&
			managementForm !== undefined &&
			managementForm.isValid,
		[contractedServiceOperation, loadingControlsCalculate.loading, managementForm, wizardLocked]
	)

	const { beforeConfirm } = useBeforeConfirm()

	const { beforeCloseModifyService } = useBeforeCloseModifyService()
	const { beforeCloseConfirmResume } = useBeforeCloseConfirmResume()

	const { createOffer } = useOfferCreate({
		offerRepository,
	})
	const { confirmOffer } = useOfferConfirm({ offerRepository })

	const beforeContinueToConfirmation = useCallback<() => Promise<boolean>>(() => {
		if (!contractedServiceOperation?.awardsImmediately) {
			setOfferAttemptedCreation(true)
			return Promise.resolve(true)
		}

		loadingControlsCalculate.startLoading()
		return createOffer({
			contractedServiceOperation,
			onSuccess: (newOffer) => {
				setOffer(newOffer)
				if (!internalUser && invalidOfferStatesForExternalUser.includes(newOffer.status)) setOperationSuccess(false)
				onOfferCreated?.(newOffer)
			},
			onFail: onError,
		}).finally(() => {
			setOfferAttemptedCreation(true)
			loadingControlsCalculate.stopLoading()
		})
	}, [contractedServiceOperation, createOffer, internalUser, loadingControlsCalculate, onError, onOfferCreated])

	const beforeContinueToEndScreen = useCallback<() => Promise<boolean>>(() => {
		return beforeConfirm(
			{
				offer,
				service: serviceDetails ? { ...service, ...serviceDetails } : undefined,
				contractedServiceOperation: contractedServiceOperation,
			},
			() => {
				loadingControlsConfirm.startLoading()
				return confirmOffer({
					offerCode: offer?.id,
					contractedServiceOperation: contractedServiceOperation!,
					onSuccess: () => {
						setOperationSuccess(true)
						onOfferConfirmed?.()
					},
					onFail: () => setOperationSuccess(false),
				}).finally(() => {
					setOfferAttemptedConfirmation(true)
					loadingControlsConfirm.stopLoading()
				})
			}
		)
	}, [
		beforeConfirm,
		offer,
		serviceDetails,
		service,
		contractedServiceOperation,
		loadingControlsConfirm,
		confirmOffer,
		onOfferConfirmed,
	])

	const beforeContinueToFinish = useCallback<() => Promise<boolean>>(
		() =>
			new Promise<boolean>((resolve) => {
				closeModal()
				resolve(true)
			}),
		[closeModal]
	)

	const onContractedServiceOperationChange = useCallback((operation?: ContractedServiceOperation) => {
		setContractedServiceOperation(operation)
	}, [])

	return {
		wizard: {
			ref: wizardRef,
			locked: wizardLocked,
			flow: {
				canContinueToForm:
					serviceDetails !== undefined &&
					managementOperations.length > 0 &&
					incompatibleOperations.length === 0 &&
					!invalidDueToExpiredService,
				beforeCloseModifyService,
				beforeCloseConfirmResume,
				canContinueToConfirmation,
				beforeContinueToConfirmation,
				beforeContinueToEndScreen,
				beforeContinueToFinish,
			},
		},
		form: { onChange: setManagementForm },
		contractedService: {
			base: service,
			details: !serviceDetails ? undefined : { ...serviceDetails },
		},
		clientParameters,
		managementOperations: {
			value: managementOperations,
			incompatibleOperations,
			valid: incompatibleOperations.length === 0 && !invalidDueToExpiredService,
			invalidDueToExpiredService,
		},
		contractType,
		contractedServiceOperation,
		offer: {
			value: offer,
			awardsImmediately: contractedServiceOperation?.awardsImmediately || false,
			attemptedCreation: offerAttemptedCreation,
			attemptedConfirmation: offerAttemptedConfirmation,
		},
		operationSuccess,
		onEditing: setWizardLocked,
		onChange: {
			contractedService: {
				details: setServiceDetails,
			},
			managementOperations: setManagementOperations,
			contractType: setContractType,
			contractedServiceOperation: onContractedServiceOperationChange,
			clientParameters: setClientParameters,
		},
		onError,
		loading: {
			calculate: loadingControlsCalculate.loading,
			confirm: loadingControlsConfirm.loading,
		},
	}
}

export { useContractedServiceManageModal }
