import { ContractedServicesTable } from 'components/services/management/ContractedServicesTable'
import { FC } from 'react'
import { UfinetBox } from 'ufinet-web-components'

const ContractedServicesPage: FC<{}> = () => {
	return (
		<UfinetBox>
			<ContractedServicesTable />
		</UfinetBox>
	)
}
export { ContractedServicesPage }
