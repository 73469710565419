import { FC } from 'react'

import { ContractedServiceDetail } from 'modules/contractedService/domain/detail/ContractedServiceDetail'
import { ContractType } from 'modules/contractedService/domain/management/operation/ContractType'
import { ManagementOperation } from 'modules/contractedService/domain/management/operation/ManagementOperation'
import { Offer } from 'modules/offer/domain/Offer'
import { getEnumKeyFromEnumValue, useTranslator } from 'ufinet-web-functions'
import { ContainerWizard } from '../../../../common/ContainerWizard'
import { RowContainerWizard } from '../../../../common/RowContainerWizard'

type Props = {
	contractedServiceManagementOperations: ManagementOperation[]
	contractType: ContractType
	contractedServiceDetail: ContractedServiceDetail
	offer?: Offer
}

const ConfirmResumeCommon: FC<Props> = ({
	contractedServiceManagementOperations,
	contractType,
	contractedServiceDetail,
	offer,
}) => {
	const translate = useTranslator()

	return (
		<ContainerWizard>
			{offer ? (
				<RowContainerWizard
					title="CONTRACT.SERVICE.OFFERNUMBER"
					content={offer.id}
				/>
			) : (
				<></>
			)}
			<RowContainerWizard
				title="SELECT.MANAGEMENT.OPTION.TITLE"
				content={contractedServiceManagementOperations
					.map((contractedServiceManagementOperation) =>
						translate(
							`CONTRACT.SERVICE.MANAGEMENT.OPERATION.${getEnumKeyFromEnumValue(
								ManagementOperation,
								contractedServiceManagementOperation
							)}`
						)
					)
					.join(', ')}
			/>
			<RowContainerWizard
				title="CONTRACT.SERVICE.MODIFICATION.TYPE"
				content={translate(`CLIENT.PARAMETERS.${ContractType[contractType]}`)}
			/>
			<RowContainerWizard
				title="CONTRACT.SERVICE.MANAGEMENT.SERVICECODE"
				content={contractedServiceDetail.serviceCode}
			/>
			<RowContainerWizard
				title="CONTRACT.SERVICE.MANAGEMENT.SERVICENAME"
				content={contractedServiceDetail.serviceName}
			/>
			<RowContainerWizard
				title="CONTRACT.SERVICE.MANAGEMENT.SERVICETYPE"
				content={translate(`CONTRACT.SERVICE.TYPE.${contractedServiceDetail.serviceType?.value}`)}
			/>
		</ContainerWizard>
	)
}
export { ConfirmResumeCommon }
