import { defaultOptionsFetch, FetchAPI, FetchOptions, IAuthData } from 'ufinet-web-functions'
import { OfferRepository } from '../domain/repository/OfferRepository'

const url = `${process.env.REACT_APP_URL_BACK_GSE}api/offer`

export const HttpOfferRepository: (authData: IAuthData) => OfferRepository = (authData) => {
	const fetchOptions: FetchOptions = {
		...defaultOptionsFetch,
		notifyError: false,
		authenticationData: authData,
		sendApiKey: true,
	}

	return {
		create: async (offerCreateRequest, options) =>
			await FetchAPI.post(url + '/create', offerCreateRequest, { ...fetchOptions, ...options }),
		confirm: async (offerConfirmRequest, options) =>
			await FetchAPI.post(url + '/confirm', offerConfirmRequest, { ...fetchOptions, ...options }),
		schedule: async (offerScheduleRequest, options) =>
			await FetchAPI.post(url + '/schedule', offerScheduleRequest, { ...fetchOptions, ...options }),
		temporal: async (offerTemporalRequest, options) =>
			await FetchAPI.post(url + '/temporal', offerTemporalRequest, { ...fetchOptions, ...options }),
		temporalImmediateConfirm: async (offerTemporalRequest, options) =>
			await FetchAPI.post(url + '/temporal/immediate/confirm', offerTemporalRequest, { ...fetchOptions, ...options }),
	}
}
