import { FormikErrors, FormikProps } from 'formik'
import { OrsPetition } from 'modules/contractedService/domain/ors/petition/base/OrsPetition'
import { OrsReason } from 'modules/contractedService/domain/ors/petition/base/OrsReason'
import {
	ContractedServiceOrsIndividualData,
	IndividualServiceData,
} from 'modules/contractedService/domain/ors/petition/individual/ContractedServiceOrsIndividual'
import { OrsStrategy } from 'modules/contractedService/domain/ors/strategies/OrsStrategy'

import { CalendarChangeParams } from 'primereact/calendar'
import { Dropdown, DropdownChangeParams } from 'primereact/dropdown'
import { FC } from 'react'
import { Button } from 'react-bootstrap'
import { DatePicker, DatePickerTypeEnum, UfinetInput } from 'ufinet-web-components'
import { useTranslator } from 'ufinet-web-functions'

type Props = {
	onChange: {
		modifyInput: (data: string, administrativeCode: string) => void
		modifyDatePicker: (data: CalendarChangeParams, administrativeCode: string) => void
		modifyReason: (data: DropdownChangeParams, administrativeCode: string) => void
		removeService: (id: string) => void
	}
	onValidate: {
		dateErrors: (index: number) => FormikErrors<IndividualServiceData>
		reasonErrors: (index: number) => string | undefined
		observationErrors: (index: number) => string | undefined
	}
	selectedPetition: OrsPetition
	reasonOptions?: OrsReason[]
	form: FormikProps<ContractedServiceOrsIndividualData>
	calculateServiceMinimumDate: (serviceId: string) => Date | undefined
	orsStrategy: OrsStrategy
}

const OrsIndividualTable: FC<Props> = ({
	onChange,
	onValidate,
	reasonOptions,
	selectedPetition,
	form,
	calculateServiceMinimumDate,
	orsStrategy,
}) => {
	const translate = useTranslator()

	return (
		<table className="table mt-5">
			<thead>
				<tr>
					<th scope="col" />
					<th scope="col">
						<b>{translate('CONTRACT.SERVICE.ADMINISTRATIVE_CODE')}</b>
					</th>
					{orsStrategy.enableCompletionDate && (
						<th scope="col">
							<b>{translate(`CONTRACT.SERVICE.ORS.${OrsPetition[selectedPetition]}.DATE`)}</b>
						</th>
					)}
					{orsStrategy.enableReason && (
						<th scope="col">
							<b>{translate('CONTRACT.SERVICE.ORS.REASON')}</b>
						</th>
					)}
					<th scope="col">
						<b>{translate(orsStrategy.observationsLabel)}</b>
					</th>
				</tr>
			</thead>
			<tbody>
				{form.values.services.map((service, index) => (
					<tr key={service.administrativeCode}>
						<th
							scope="row"
							className="align-middle"
						>
							<Button
								data-testid={`ors-remove-service-${service.id}`}
								size="sm"
								className="ors-close-button"
								onClick={() => onChange.removeService(service.id)}
							>
								<i className="bi bi-x-lg text-danger p-0" />
							</Button>
						</th>
						<td className="align-middle">{service.administrativeCode}</td>
						{orsStrategy.enableCompletionDate && (
							<td>
								<DatePicker
									type={DatePickerTypeEnum.DATE_ONLY}
									showCalendarIcon
									value={service.date}
									onChange={(e) => onChange.modifyDatePicker(e, service.administrativeCode)}
									min={calculateServiceMinimumDate(service.id)}
								/>

								{onValidate.dateErrors(index)?.date && (
									<div className="invalid-feedback d-block">{onValidate.dateErrors(index).date}</div>
								)}
							</td>
						)}
						{orsStrategy.enableReason && (
							<td>
								<Dropdown
									className="w-100"
									onChange={(e) => onChange.modifyReason(e, service.administrativeCode)}
									optionLabel="name"
									options={reasonOptions}
									placeholder={translate('CONTRACT.SERVICE.ORS.SELECT_REASON')}
									showClear
									value={service.reason}
								/>

								{onValidate.reasonErrors(index) && (
									<div className="invalid-feedback d-block">{onValidate.reasonErrors(index)}</div>
								)}
							</td>
						)}
						<td>
							<UfinetInput
								requiredIcon
								type="text"
								value={service.observations || ''}
								onChange={(e) => onChange.modifyInput(e.target.value, service.administrativeCode)}
								solid={false}
							/>
							{onValidate.observationErrors(index) && (
								<div className="invalid-feedback d-block">{onValidate.observationErrors(index)}</div>
							)}
						</td>
					</tr>
				))}
			</tbody>
		</table>
	)
}

export { OrsIndividualTable }
