import { orsServiceExceptionFindResponseToDomainEntity } from 'modules/contractedService/application/ors/exception/find/dto/OrsServiceExceptionFindResponse'
import { OrsServiceException } from 'modules/contractedService/domain/ors/exception/OrsServiceException'
import { OrsServiceExceptionRepository } from 'modules/contractedService/domain/ors/exception/repository/OrsServiceExceptionRepository'
import { useQuery } from 'react-query'

export const useOrsServiceExceptionFindAllQuery = (
	orsServiceExceptionRepository: OrsServiceExceptionRepository,
	{
		enabled = true,
		onSuccess,
		onError,
	}: {
		enabled?: boolean
		onSuccess?: (orsServiceExceptions: OrsServiceException[]) => void
		onError?: (error: unknown) => void
	} = {}
) => {
	return useQuery('ors-service-exception-find-all-query', () => orsServiceExceptionRepository.findAllOfUser(), {
		enabled,
		select: (data) => data.map(orsServiceExceptionFindResponseToDomainEntity),
		onSuccess,
		onError,
	})
}
