import { NotificationType } from 'ufinet-web-functions'
import { Event } from '../Event'
import { EventType } from '../EventTypesEnum'

export type NotificationEventData = {
	notificationType: NotificationType
	notificationContent: string
}

export class NotificationEvent extends Event {
	public constructor(data: NotificationEventData) {
		super(EventType.NOTIFICATION, data)
	}
}
