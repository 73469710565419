import {
	ContractedServiceOperation,
	ContractedServiceOperationData,
} from 'modules/contractedService/domain/management/operation/ContractedServiceOperation'
import { ContractType } from 'modules/contractedService/domain/management/operation/ContractType'
import { ContractedServiceOperationImmediate } from 'modules/contractedService/domain/management/operation/immediate/ContractedServiceOperationImmediate'
import {
	ContractedServiceOperationDataScheduled,
	ContractedServiceOperationScheduled,
} from 'modules/contractedService/domain/management/operation/scheduled/ContractedServiceOperationScheduled'
import {
	ContractedServiceOperationDataTemporal,
	ContractedServiceOperationTemporal,
} from 'modules/contractedService/domain/management/operation/temporary/ContractedServiceOperationTemporary'

export const buildOperationFromDataObject = (input: ContractedServiceOperationData): ContractedServiceOperation => {
	if (ContractedServiceOperation.isValidForCalculation(input)) {
		if (input.contractType === ContractType.IMMEDIATE) return new ContractedServiceOperationImmediate(input)
		if (input.contractType === ContractType.SCHEDULED)
			return new ContractedServiceOperationScheduled(input as ContractedServiceOperationDataScheduled)
		if (input.contractType === ContractType.TEMPORAL)
			return new ContractedServiceOperationTemporal(input as ContractedServiceOperationDataTemporal)
		throw new Error('Cannot create operation from unknown contract type')
	}
	throw new Error('Cannot create operation from invalid data')
}
