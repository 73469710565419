import { ContractedServiceEconomicAssessment } from 'modules/contractedService/domain/management/operation/economic-assesment/ContractedServiceEconomicAssesment'
import { FC, Fragment, useMemo } from 'react'
import { noContentPlaceholder, useTranslator } from 'ufinet-web-functions'

type Props = {
	isCurrent?: boolean
} & ContractedServiceEconomicAssessment

const TermsAndConditionsEconomicAssesmentDataset: FC<Props> = ({
	isCurrent = true,
	name,
	operation,
	attributes,
	currency,
	nrc,
	mrc,
	deadline,
	origin,
	destination,
}) => {
	const translate = useTranslator()

	const currencyHeaderText = useMemo(() => (currency ? ` (${currency})` : ''), [currency])

	return (
		<>
			<tr className="terms-and-conditions-header">
				<td colSpan={9}>
					{translate(
						isCurrent
							? 'CONTRACT.SERVICE.TERMS.ECONOMIC_ASSESMENT.CURRENT'
							: 'CONTRACT.SERVICE.TERMS.ECONOMIC_ASSESMENT.NEW'
					)}
				</td>
			</tr>
			<tr>
				<th>{translate('CONTRACT.SERVICE.TERMS.ECONOMIC_ASSESMENT.LINK_NUMBER')}</th>
				<th>{translate('CONTRACT.SERVICE.TERMS.ECONOMIC_ASSESMENT.PRODUCT_NAME')}</th>
				<th>{translate('CONTRACT.SERVICE.TERMS.ECONOMIC_ASSESMENT.OPERATION')}</th>
				<th>{translate('CONTRACT.SERVICE.TERMS.ECONOMIC_ASSESMENT.SERVICE_ATTRIBUTES')}</th>
				<th>{`${translate('CONTRACT.SERVICE.TERMS.ECONOMIC_ASSESMENT.NRC')}${currencyHeaderText}`}</th>
				<th>{`${translate('CONTRACT.SERVICE.TERMS.ECONOMIC_ASSESMENT.MRC')}${currencyHeaderText}`}</th>
				<th>{translate('CONTRACT.SERVICE.TERMS.ECONOMIC_ASSESMENT.SERVICE_PERIOD')}</th>
				<th>{translate('CONTRACT.SERVICE.TERMS.ECONOMIC_ASSESMENT.SERVICE_ORIGIN')}</th>
				<th>{translate('CONTRACT.SERVICE.TERMS.ECONOMIC_ASSESMENT.SERVICE_DESTINATION')}</th>
			</tr>
			<tr>
				<td style={{ width: '5%' }}>1</td>
				<td style={{ width: '15%' }}>{name}</td>
				<td style={{ width: '10%' }}>{operation}</td>
				<td style={{ width: '15%' }}>
					{!attributes
						? noContentPlaceholder
						: Object.keys(attributes).map((key, idx) => (
								<Fragment key={idx}>
									{attributes[key] && (
										<p className="service-attribute">
											<span className="service-attribute-name">
												{translate(`CONTRACT.SERVICE.DETAILS.${key.toUpperCase()}`)}
											</span>
											<br />
											<span className="service-attribute-value">{attributes[key]}</span>
										</p>
									)}
								</Fragment>
						  ))}
				</td>
				<td
					className="text-nowrap"
					style={{ width: '10%' }}
				>
					{nrc || noContentPlaceholder}
				</td>
				<td
					className="text-nowrap"
					style={{ width: '10%' }}
				>
					{mrc || noContentPlaceholder}
				</td>
				<td style={{ width: '5%' }}>{deadline}</td>
				<td style={{ width: '15%', whiteSpace: 'pre-line' }}>{origin || noContentPlaceholder}</td>
				<td style={{ width: '15%', whiteSpace: 'pre-line' }}>{destination}</td>
			</tr>
		</>
	)
}
export { TermsAndConditionsEconomicAssesmentDataset }
