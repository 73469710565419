import { FormikErrors, FormikProps } from 'formik'
import { ContractedService } from 'modules/contractedService/domain/ContractedService'
import { OrsEntryType } from 'modules/contractedService/domain/ors/petition/base/OrsEntryType'
import { OrsPetition } from 'modules/contractedService/domain/ors/petition/base/OrsPetition'
import { OrsOperationType } from 'modules/contractedService/domain/ors/petition/base/OrsReactivationType'

import { useOrsConfirmModal } from 'components/services/ors/modal/forms/base/hooks/useOrsConfirmModal'
import dayjs from 'dayjs'
import { OrsIndividualSaveRequest } from 'modules/contractedService/application/ors/save/individual/dto/OrsIndividualSaveRequest'
import { OrsIndividualServiceRequest } from 'modules/contractedService/application/ors/save/individual/dto/service/OrsIndividualServiceRequest'
import { useOrsIndividualSaveQuery } from 'modules/contractedService/application/ors/save/individual/useOrsIndividualSaveQuery'
import { Reason } from 'modules/contractedService/domain/ors/petition/base/ContractedServiceOrsBase'
import { OrsReason } from 'modules/contractedService/domain/ors/petition/base/OrsReason'
import {
	ContractedServiceOrsIndividualData,
	IndividualServiceData,
} from 'modules/contractedService/domain/ors/petition/individual/ContractedServiceOrsIndividual'
import { OrsStrategy } from 'modules/contractedService/domain/ors/strategies/OrsStrategy'
import { ContractedServiceOrsRepository } from 'modules/contractedService/domain/repository/ContractedServiceOrsRepository'
import { CalendarChangeParams } from 'primereact/calendar'
import { DropdownChangeParams } from 'primereact/dropdown'
import { useCallback, useEffect } from 'react'
import { useOrsBaseForm } from '../../base/hooks/useOrsBaseForm'
import { useOrsIndividualFormValidation } from './useOrsIndividualFormValidation'

type HookOutput = {
	onChange: {
		modifyInput: (data: string, administrativeCode: string) => void
		modifyReason: (data: DropdownChangeParams, administrativeCode: string) => void
		modifyDatePicker: (data: CalendarChangeParams, administrativeCode: string) => void
		removeService: (id: string) => void
	}
	onValidate: {
		dateErrors: (index: number) => FormikErrors<IndividualServiceData>
		reasonErrors: (index: number) => string | undefined
		observationErrors: (index: number) => string | undefined
		customError?: string
	}
	form: FormikProps<ContractedServiceOrsIndividualData>
	reasonOptions?: OrsReason[]
	validateForm: () => void
	calculateServiceMinimumDate: (serviceId: string) => Date | undefined
}

type HookInput = {
	selectedEntry: OrsEntryType
	selectedOperation: OrsOperationType[]
	selectedPetition: OrsPetition
	services: ContractedService[]
	onServiceRemove: (id: string) => void
	onSend: () => void
	serviceOrsRepository: ContractedServiceOrsRepository
	orsStrategy: OrsStrategy
}

function useOrsIndividualForm({
	selectedEntry,
	selectedPetition,
	selectedOperation,
	services,
	onServiceRemove,
	onSend,
	serviceOrsRepository,
	orsStrategy,
}: HookInput): HookOutput {
	const { mutate: saveOrsIndividual } = useOrsIndividualSaveQuery(serviceOrsRepository)

	const { showConfirmModal } = useOrsConfirmModal({
		onSend,
		saveOrsQuery: saveOrsIndividual,
	})

	const handleSubmit = useCallback(
		(params: ContractedServiceOrsIndividualData) => {
			showConfirmModal({
				technical: params.selectedOperation.includes(OrsOperationType.TECHNICAL),
				billing: params.selectedOperation.includes(OrsOperationType.BILLING),
				services: params.services.map((service) => {
					return {
						serviceId: service.id,
						completionDate: service.date ? new Date(dayjs(service.date).format('YYYY-MM-DD')).toISOString() : '',
						observations: service.observations,
						cause: service.reason?.code,
					} as OrsIndividualServiceRequest
				}),
				petition: OrsPetition[params.selectedPetition],
			} as OrsIndividualSaveRequest)
		},
		[showConfirmModal]
	)

	const { form, servicesValidation } = useOrsIndividualFormValidation({
		selectedEntry,
		selectedOperation,
		selectedPetition,
		services,
		handleSubmit,
		orsStrategy,
	})

	const calculateServiceMinimumDate = useCallback(
		(serviceId: string) => {
			return orsStrategy.calculateIndividualServiceMinimumDate(selectedOperation, serviceId, services)
		},
		[selectedOperation, services, orsStrategy]
	)

	const { clearErrors, customError, validateForm, reasonOptions } = useOrsBaseForm({
		form,
		servicesValidation,
		serviceOrsRepository,
		selectedPetition,
	})

	const onInputEdit = useCallback(
		(data: string, administrativeCode: string) => {
			form.setFieldValue(
				'services',
				form.values.services.map((service) => {
					if (service.administrativeCode === administrativeCode) {
						return { ...service, observations: data }
					}
					return service
				})
			)
			clearErrors()
		},
		[form, clearErrors]
	)

	const onDropdownChange = useCallback(
		(data: DropdownChangeParams, administrativeCode: string) => {
			const reason =
				typeof data.target.value === 'object' && 'code' in data.target.value && 'name' in data.target.value
					? data.target.value
					: undefined

			form.setFieldValue(
				'services',
				form.values.services.map((service) => {
					if (service.administrativeCode === administrativeCode) {
						return {
							...service,
							reason: {
								code: reason?.code,
								name: reason?.name,
							},
						}
					}
					return service
				})
			)
			clearErrors()
		},
		[form, clearErrors]
	)

	const reasonErrors = useCallback(
		(index: number) => {
			return ((form.errors?.services?.[index] as FormikErrors<IndividualServiceData>)?.reason as FormikErrors<Reason>)
				?.code
		},
		[form.errors]
	)

	const onDatePickerEdit = useCallback(
		(calendarChangeParams: CalendarChangeParams, administrativeCode: string) => {
			const newDate = calendarChangeParams.value instanceof Date ? calendarChangeParams.value : undefined
			form.setFieldValue(
				'services',
				form.values.services.map((service) => {
					if (service.administrativeCode === administrativeCode) {
						return {
							...service,
							date: newDate,
						}
					}
					return service
				})
			)
			clearErrors()
		},
		[form, clearErrors]
	)

	const dateErrors = useCallback(
		(index: number) => {
			return form.errors?.services?.[index] as FormikErrors<IndividualServiceData>
		},
		[form.errors]
	)

	const observationErrors = useCallback(
		(index: number) => {
			return (form.errors?.services?.[index] as FormikErrors<IndividualServiceData>)?.observations
		},
		[form.errors]
	)

	useEffect(() => {
		clearErrors()

		const selectedServices = services.map((service) => {
			const prevInsertedDate = form.values.services.find((data) => data.id === service.id)?.date
			const minimumDate = calculateServiceMinimumDate(service.id)
			const prevInsertedObservations = form.values.services.find((data) => data.id === service.id)?.observations
			return {
				administrativeCode: service.administrativeCode,
				id: service.id,
				date: prevInsertedDate
					? dayjs(minimumDate).isAfter(prevInsertedDate, 'day')
						? undefined
						: prevInsertedDate
					: undefined,
				observations: prevInsertedObservations,
			} as IndividualServiceData
		})

		form.setFieldValue('selectedOperation', selectedOperation)
		form.setFieldValue('selectedPetition', selectedPetition)
		form.setFieldValue('services', selectedServices)
	}, [selectedOperation, selectedPetition, services, calculateServiceMinimumDate])

	return {
		onChange: {
			modifyInput: onInputEdit,
			modifyReason: onDropdownChange,
			modifyDatePicker: onDatePickerEdit,
			removeService: onServiceRemove,
		},
		onValidate: {
			dateErrors: dateErrors,
			reasonErrors: reasonErrors,
			observationErrors: observationErrors,
			customError: customError,
		},
		validateForm: validateForm,
		form: form,
		reasonOptions: reasonOptions,
		calculateServiceMinimumDate,
	}
}

export { useOrsIndividualForm }
