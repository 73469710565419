import { faker } from '@faker-js/faker'
import { IClientResponse } from 'ufinet-web-functions'

export const ClientMother = {
	create: (input?: Partial<IClientResponse>): IClientResponse => {
		return {
			id: faker.string.uuid(),
			name: 'IFX NETWORKS COLOMBIA S.A.S.',
			cantonId: faker.string.uuid(),
			corporateGroupId: faker.string.uuid(),
			countryId: faker.string.uuid(),
			kamId: faker.person.jobType(),
			taxNumber: faker.string.alphanumeric(10),
			discountPercentageMrc: faker.number.float({ min: 0, max: 100, precision: 2 }),
			discountPercentageNrc: faker.number.float({ min: 0, max: 100, precision: 2 }),
			lastModifiedBy: faker.internet.email(),
			lastModifiedAt: faker.date.recent().toISOString(),
			...input,
		}
	},
	ifx_networks_colombia_sas: (): IClientResponse => ({
		id: 'D2209755-02D3-E511-8102-1458D043A750',
		name: 'IFX NETWORKS COLOMBIA S.A.S.',
		cantonId: '1DF10A8E-5DCA-E511-8102-1458D043A750',
		corporateGroupId: 'E422C979-CAA6-EB11-B1AC-000D3ADBD34D',
		countryId: '469B9F0F-C6BD-E511-80FB-3863BB358F90',
		kamId: 'test',
		taxNumber: 'test',
		discountPercentageMrc: 0,
		discountPercentageNrc: 0,
		lastModifiedBy: 'test',
		lastModifiedAt: 'test',
	}),
}
