import { FC, useMemo } from 'react'
import { useTranslator } from 'ufinet-web-functions'

const TermsAndConditionsCommercialConditions: FC<{}> = () => {
	const translate = useTranslator()

	const conditions = useMemo(
		() => [
			'Los circuitos se construyen a través de fibra óptica extremo a extremo.',
			'Los precios de instalación incluyen la fibra óptica y la renta de los equipos terminales de red. Será responsabilidad del cliente proveer a costa suya las condiciones técnicas necesarias para salvaguardar el equipamiento activo y pasivo a instalar, así como para brindar y garantizar el buen funcionamiento del servicio, según el personal de UFINET se lo indique.',
			'El equipo terminal de red y el equipamiento adicional instalado en las instalaciones del cliente, son propiedad de UFINET.',
			'Esta oferta no incluye equipamiento adicional como switches o routers requeridos por el cliente final para el uso de estos circuitos.',
			'El servicio se entrega en interfaz Ethernet (capa 2).',
			'El servicio ofertado es lineal, sin protección de última milla.',
			'Será responsabilidad del cliente y a costa suya, la obtención de las licencias y permisos necesarios para realizar los trabajos de alta y de mantenimiento de los enlaces contemplados en la presente oferta, tanto en el acceso, como en el interior de los edificios, así como los costes de las obras civiles en "Zonas Especiales" y en donde se deba construir nueva infraestructura. En caso de acordarse de que UFINET los asuma, UFINET trasladará estos costes.',
			'Las "Zonas Especiales" incluyen entre otras, las zonas administradas como edificios, centros comerciales, así como cualquier área no citada que maneje tarifas extraordinarias o no habituales, o bien con restricciones y/o normativas especiales de construcción u otras zonas donde no haya infraestructuras disponibles.',
			'La obtención de permisos para acceder a los inmuebles durante la instalación y posterior mantenimiento corren por cuenta del cliente.',
			'El costo de instalación no incluye trabajos de obra civil requeridos dentro de las instalaciones del cliente, ni para el ingreso de la acometida ni para la instalación de equipos activos y/o pasivos ni sus equipos de protección eléctrica (e.g. equipos UPS).',
			'El cliente es responsable de brindar a costa suya, los ductos de acceso necesarios para el ingreso de la fibra óptica al inmueble.',
			'El plazo de entrega, a partir de la firma del contrato, es de 8 semanas. Este tiempo está sujeto a un replanteo previo para definir la viabilidad de acceso a los inmuebles.',
			'Los precios ofertados aplican únicamente si se adjudica la totalidad de items ofertados y bajo las condiciones especificadas.',
			'Los precios de los servicios contratados se especifican para los plazos y volúmenes establecidos. Se entienden como plazos de carácter obligatorio con penalizaciones económicas equivalentes al 100% de las cuotas mensuales faltantes para el cumplimiento del plazo.',
			'Los precios de alta deberán cancelarse en el momento de la aceptación de la oferta y firma del contrato u orden de servicio (SOF) por parte del cliente.',
			'El pago de la "Cuota Mensual" se efectuará de forma mensual anticipada, durante los primeros cinco días del período objeto del servicio.',
			'Los precios ofertados tienen una validez de 30 días.',
			'Los precios ofertados son netos y no incluyen ningún impuesto aplicable, presente o futuro. Dichos impuestos serán trasladados al cliente en cumplimiento de las normativas fiscales en curso.',
		],
		[]
	)

	return (
		<div>
			<p className="terms-and-conditions-header">{translate('CONTRACT.SERVICE.TERMS.CONDITIONS.COMMERCIAL')}</p>
			<ol>
				{conditions.map((it, idx) => (
					<li key={idx}>{it}</li>
				))}
			</ol>
		</div>
	)
}
export { TermsAndConditionsCommercialConditions }
