import { FormikProps } from 'formik'
import { getValidDeadlineOptions } from 'modules/client/parameters/application/getValidDeadlineOptions'
import { contactResponseToSelectOption } from 'modules/contact/application/find/contactResponseToSelectOption'
import { isExpired, isExpiredByDueDate } from 'modules/contractedService/application/isExpired'

import { deepEqual } from 'fast-equals'

import { useContractedServiceManageFormContext } from 'components/services/management/modal/wizard/modify-service/manage/ContractedServiceManageFormContextProvider'
import { useBandwidthFindErrorHandler } from 'components/services/management/modal/wizard/modify-service/manage/inner/base/hooks/useBandwidthFindError'
import { useOperationDeadlineValidation } from 'components/services/management/modal/wizard/modify-service/manage/inner/base/hooks/validation/useOperationDeadlineValidation'
import { useOperationPricingValidation } from 'components/services/management/modal/wizard/modify-service/manage/inner/base/hooks/validation/useOperationPricingValidation'
import { ScheduledManageFormParameters } from 'components/services/management/modal/wizard/modify-service/manage/inner/scheduled/hooks/useScheduledManageFormValidation'
import { TemporalManageFormParameters } from 'components/services/management/modal/wizard/modify-service/manage/inner/temporary/hooks/useTemporaryManageFormValidation'
import { useBandwidthFindForService } from 'modules/bandwidth/application/common/BandwidthFindQueriesForService'
import { useBandwidthFindForOperation } from 'modules/bandwidth/application/find/BandwidthFindQueriesForOperation'
import { bandwidthFindRequestFromServiceData } from 'modules/bandwidth/application/find/dto/bandwidthFindRequestFromServiceData'
import { useContactFindFilteredQuery } from 'modules/contact/application/find/ContactFindQueries'
import { contactFindRequestFromServiceData } from 'modules/contact/application/find/dto/contactFindRequestFromServiceData'
import { contractedServiceCalculator } from 'modules/contractedService/application/management/calculate/contractedServiceCalculator'
import { ImmediatePricingRequest } from 'modules/contractedService/application/management/calculate/dto/ImmediatePricingRequest'
import { PricingRequest } from 'modules/contractedService/application/management/calculate/dto/PricingRequest'
import { ScheduledPricingRequest } from 'modules/contractedService/application/management/calculate/dto/ScheduledPricingRequest'
import { ContractType } from 'modules/contractedService/domain/management/operation/ContractType'
import {
	ContractedServiceOperation,
	ContractedServiceOperationData,
} from 'modules/contractedService/domain/management/operation/ContractedServiceOperation'
import { ManagementOperation } from 'modules/contractedService/domain/management/operation/ManagementOperation'
import { buildOperationFromDataObject } from 'modules/contractedService/domain/management/operation/helpers/buildOperationFromDataObject'
import { ContractedServiceOperationImmediate } from 'modules/contractedService/domain/management/operation/immediate/ContractedServiceOperationImmediate'
import { ContractedServiceOperationScheduled } from 'modules/contractedService/domain/management/operation/scheduled/ContractedServiceOperationScheduled'
import { ContractedServiceOperationTemporal } from 'modules/contractedService/domain/management/operation/temporary/ContractedServiceOperationTemporary'
import { BasePricing } from 'modules/contractedService/domain/pricing/BasePricing'
import { ModPricing } from 'modules/contractedService/domain/pricing/ModPricing'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { IUfinetSelectOption, UfinetCheckBoxOption, emptyUfinetSelectOption } from 'ufinet-web-components'
import {
	IBandwidthResponse,
	IContactResponse,
	LoadingControls,
	isNumeric,
	makeBandwidthLabel,
	makeSelectOptionFromBandwidth,
	useDeepEffect,
	useInternalUser,
} from 'ufinet-web-functions'
import { ManageFormValidationCreator } from '../manageFormValidationCreator'
import { ManageFormParameters } from './validation/useBaseManageFormValidation'

type HookInput<T extends ContractedServiceOperationData> = {
	isValidOperationForCalculation: (operationData: Partial<T>) => boolean
	formValidationCreator: ManageFormValidationCreator<T>
}

type HookOutput<T extends ContractedServiceOperationData> = {
	bandwidth: {
		loading: boolean
		selected?: IUfinetSelectOption
		options: IUfinetSelectOption[]
	}
	deadline: {
		current?: number
		remaining?: number
		selected?: number
		options: number[]
		disabled: boolean
	}
	contact: {
		loading: boolean
		selected?: IUfinetSelectOption
		options: IUfinetSelectOption[]
	}
	keepDeadline: {
		value: boolean
		options: UfinetCheckBoxOption[]
		disabled: boolean
	}
	onChange: {
		bandwidth?: (bandwidth: IUfinetSelectOption) => void
		contact?: (contact: IUfinetSelectOption) => void
		deadline?: (deadline: IUfinetSelectOption) => void
		keepDeadline?: (keepDeadline: UfinetCheckBoxOption[]) => void
		mrcAdjustment?: (mrcAdjustment?: number) => void
		pricingTable?: (operationData: ContractedServiceOperation) => void
	}
	form: {
		formik: FormikProps<ManageFormParameters<T>>
		loadingControls: LoadingControls
		deadline: {
			error?: string
		}
		pricingTable: {
			editable: boolean
			error?: string
		}
		visibility: {
			showBandwidthSelect: boolean
			showDeadlineSelect: boolean
			showContactSelect: boolean
			showPricingTable: boolean
		}
		mapper: {
			formDataToOperation: (formData: ContractedServiceOperationData) => ContractedServiceOperation
		}
	}
}

function useManageInnerOperationForm<T extends ContractedServiceOperationData>({
	isValidOperationForCalculation,
	formValidationCreator,
}: HookInput<T>): HookOutput<T> {
	const {
		form: {
			value: form,
			setter: setForm,
			loadingControls: { loading: loadingForm, startLoading: startLoadingForm, stopLoading: stopLoadingForm },
		},
		service,
		clientParameters,
		contractedServiceOperation,
		bandwidth: { options: bandwidths, setOptions: setBandwidths },
		contact: { options: contacts, setOptions: setContacts },
		repository,
		onEvent: { onError, onEditing },
	} = useContractedServiceManageFormContext<T>()

	const internalUser = useInternalUser()

	const { formik } = formValidationCreator()

	const wasAlreadyMounted = useRef(false)

	const isOnlyModifyingPrice =
		contractedServiceOperation.isModifying.bandwidth === false && contractedServiceOperation.isModifying.price === true

	const isOnlyModifyingDeadline =
		contractedServiceOperation.isModifying.deadline === true &&
		contractedServiceOperation.isModifying.bandwidth === false &&
		contractedServiceOperation.isModifying.price === false

	useDeepEffect(() => {
		setForm(formik)
	}, [formik.getFieldMeta])

	const [selectedBandwidth, setSelectedBandwidth] = useState<IBandwidthResponse>()
	const selectedBandwidthOption = useMemo(
		() => (selectedBandwidth ? makeSelectOptionFromBandwidth(selectedBandwidth) : undefined),
		[selectedBandwidth]
	)
	const bandwidthOptions = useMemo(() => bandwidths.map(makeSelectOptionFromBandwidth), [bandwidths])
	const { bandwidthFindErrorHandler } = useBandwidthFindErrorHandler({ onUnknownErrorHandler: onError })

	const [selectedContact, setSelectedContact] = useState<IContactResponse>()
	const selectedContactOption = useMemo(
		() => (selectedContact ? contactResponseToSelectOption(selectedContact) : undefined),
		[selectedContact]
	)

	const contactOptions = useMemo(() => contacts.map(contactResponseToSelectOption), [contacts])

	const [selectedDeadline, setSelectedDeadline] = useState<number>()

	const userCustomDeadlineOptions = useMemo(
		() => (internalUser && selectedDeadline !== undefined ? [selectedDeadline] : []),
		[internalUser, selectedDeadline]
	)

	const isExpiredService = isExpired(service.remainingDeadline)
	const isExpiredByDueDateService = isExpiredByDueDate(service.dueDate)

	const deadlineOptions = useMemo(
		() =>
			internalUser
				? userCustomDeadlineOptions
				: getValidDeadlineOptions(
						service.remainingDeadline,
						clientParameters.value?.hiringDeadlines.map((it) => it.value),
						isExpiredService
				  ),
		[
			clientParameters.value?.hiringDeadlines,
			internalUser,
			isExpiredService,
			service.remainingDeadline,
			userCustomDeadlineOptions,
		]
	)

	const deadlineTemporalValue = useMemo(
		() => (isExpiredService ? 0 : service.remainingDeadline),
		[service.remainingDeadline, isExpiredService]
	)

	const keepDeadlineOptions = useMemo(
		() => [
			{
				value: 'KEEP_DEADLINE',
			},
		],
		[]
	)
	const [isCheckBoxKeepDeadlineChecked, setIsCheckBoxKeepDeadlineChecked] = useState<boolean>(false)
	const keepDeadlinePeriodValue = useMemo(
		() => (isExpiredService ? 0 : service.remainingDeadline),
		[service.remainingDeadline, isExpiredService]
	)
	const keepDeadlineDisabled = useMemo(() => {
		return (
			contractedServiceOperation.contractType === ContractType.TEMPORAL ||
			clientParameters.value?.keepDeadline === false ||
			!service.remainingDeadline ||
			service.remainingDeadline <= 0
		)
	}, [clientParameters.value?.keepDeadline, service.remainingDeadline, contractedServiceOperation.contractType])

	const pricingTableError = useMemo(
		() => (form.errors.nrcAdjustment || form.errors.mrcAdjustment) as unknown as string | undefined,
		[form.errors.mrcAdjustment, form.errors.nrcAdjustment]
	)

	const deadlineError = useMemo(() => form.errors.deadline as unknown as string | undefined, [form.errors.deadline])

	const { data: bandwidthsFetched, isLoading: loadingBandwidth } = useBandwidthFindForOperation(
		contractedServiceOperation.selected.includes(ManagementOperation.UPGRADE)
			? ManagementOperation.UPGRADE
			: ManagementOperation.DOWNGRADE,
		repository.bandwidth,
		bandwidthFindRequestFromServiceData(service, clientParameters.value),
		contractedServiceOperation.contractType,
		{
			enabled: contractedServiceOperation.isModifying.bandwidth,
			onError: bandwidthFindErrorHandler,
		}
	)

	const { data: serviceCurrentBandwidth } = useBandwidthFindForService(
		repository.bandwidth,
		{ serviceId: service.serviceId },
		{
			enabled: !contractedServiceOperation.isModifying.bandwidth,
			onStart: startLoadingForm,
			onError: bandwidthFindErrorHandler,
			onSettled: stopLoadingForm,
		}
	)

	const { data: contactsFetched, isLoading: loadingContact } = useContactFindFilteredQuery(
		repository.contact,
		contactFindRequestFromServiceData(service),
		{ internalUser, fixedValues: contacts, onSuccess: setContacts, onError }
	)

	useEffect(() => {
		bandwidthsFetched !== undefined && setBandwidths(bandwidthsFetched)
		contactsFetched !== undefined && setContacts(contactsFetched)
	}, [bandwidthsFetched, contactsFetched, setBandwidths, setContacts])

	const onOperationPricingCalculated = useCallback(
		<T extends BasePricing>(operationPricing: T): T => {
			form.setFieldValue('nrc', operationPricing.nrc)
			form.setFieldValue('mrc', operationPricing.mrc)
			form.setFieldValue('nrcAdjustment', undefined)
			form.setFieldValue('mrcAdjustment', operationPricing.mrc)
			return operationPricing
		},
		[form]
	)

	const modifyMrcMod = (operationPricing: ModPricing): ModPricing => {
		if (contractedServiceOperation.isModifying.price) {
			operationPricing.mrcAdjustment = service.mrc
		}

		return operationPricing
	}

	const calculateServicePricing = useCallback<
		(bandwidth: IBandwidthResponse, deadline: number) => Promise<ContractedServiceOperation>
	>(
		(bandwidth, deadline) => {
			startLoadingForm()
			const serviceCalculator = contractedServiceCalculator(repository.contractedServiceCalculator)
			const pricingRequest: PricingRequest = {
				contractedServiceId: form.values.service!.id,
				bandwidthId: bandwidth.id,
				totalPrice: {
					nrc: bandwidth.nrc,
					mrc: bandwidth.mrc,
				},
				serviceTypeId: service.serviceType!.id,
				clientId: service.clientId!,
				countryId: service.countryDestinationId!,
				period: deadline,
				operations: contractedServiceOperation.selected,
			}

			const baseOperation: Omit<
				ContractedServiceOperationData,
				'nrc' | 'mrc' | 'contractType' | 'awardsImmediately' | 'isCalculated'
			> = {
				...form.values,
				operations: contractedServiceOperation.selected,
				service: form.values.service!,
				bandwidth: { id: bandwidth.id, label: makeBandwidthLabel(bandwidth.bandwidth, bandwidth.unit) },
				deadline,
				currency: {
					id: selectedBandwidth?.currencyId || '',
					name: selectedBandwidth?.currencyName || service.currency!,
				},
				contact: selectedContact ? { id: selectedContact?.id, name: selectedContact?.name } : undefined,
				keepDeadline: internalUser ? !contractedServiceOperation.isModifying.deadline : isCheckBoxKeepDeadlineChecked,
			}

			const currentServicePricing: ModPricing | undefined =
				contractedServiceOperation.isModifying.bandwidth || service.nrc === undefined || service.mrc === undefined
					? undefined
					: {
							nrc: service.nrc,
							mrc: service.mrc,
							nrcAdjustment: undefined,
							mrcAdjustment: isOnlyModifyingPrice ? service.mrc : undefined,
					  }

			switch (contractedServiceOperation.contractType) {
				case ContractType.IMMEDIATE: {
					const operationPricing = currentServicePricing
						? Promise.resolve(currentServicePricing)
						: serviceCalculator.getImmediateOperationPricing(pricingRequest as ImmediatePricingRequest)
					return operationPricing
						.then(modifyMrcMod)
						.then(onOperationPricingCalculated)
						.then(
							(operationPricing) =>
								new ContractedServiceOperationImmediate({
									...baseOperation,
									...operationPricing,
								})
						)
				}
				case ContractType.SCHEDULED: {
					const operationPricing = currentServicePricing
						? Promise.resolve(currentServicePricing)
						: serviceCalculator.getScheduledOperationPricing(pricingRequest as ScheduledPricingRequest)
					return operationPricing
						.then(modifyMrcMod)
						.then(onOperationPricingCalculated)
						.then(
							(operationPricing) =>
								new ContractedServiceOperationScheduled({
									...baseOperation,
									...operationPricing,
									modifyAt: (form.values as ScheduledManageFormParameters).modifyAt!,
								})
						)
				}
				case ContractType.TEMPORAL: {
					const operationPricing = currentServicePricing
						? Promise.resolve(currentServicePricing)
						: serviceCalculator.getTemporaryOperationPricing({
								...pricingRequest,
								days: (form.values as TemporalManageFormParameters).period!,
						  })

					return operationPricing.then(onOperationPricingCalculated).then(
						(operationPricing) =>
							new ContractedServiceOperationTemporal({
								...baseOperation,
								...operationPricing,
								keepDeadline: true,
								modifyAt: (form.values as TemporalManageFormParameters).modifyAt!,
								period: (form.values as TemporalManageFormParameters).period!,
							})
					)
				}
				default:
					throw new Error(`Received an unexpected contract type: ${contractedServiceOperation.contractType}`)
			}
		},
		[
			startLoadingForm,
			repository.contractedServiceCalculator,
			form.values,
			service.serviceType,
			service.clientId,
			service.countryDestinationId,
			service.currency,
			service.nrc,
			service.mrc,
			contractedServiceOperation.selected,
			contractedServiceOperation.isModifying.deadline,
			contractedServiceOperation.isModifying.bandwidth,
			contractedServiceOperation.contractType,
			selectedBandwidth?.currencyId,
			selectedBandwidth?.currencyName,
			selectedContact,
			internalUser,
			isCheckBoxKeepDeadlineChecked,
			isOnlyModifyingPrice,
			onOperationPricingCalculated,
		]
	)

	const validateOperationAndCalculate = () => {
		contractedServiceOperation.isModifying.deadline &&
			contractedServiceOperation.contractType !== ContractType.TEMPORAL &&
			internalUser &&
			selectedDeadline &&
			validateOperationDeadline(selectedDeadline)

		if (!isValidOperationForCalculation(form.values)) {
			contractedServiceOperation.onChange(undefined)
			return
		}

		if (
			!selectedBandwidth ||
			!formik.values.bandwidth?.id ||
			selectedDeadline === undefined ||
			formik.values.deadline === undefined
		)
			return

		if (
			contractedServiceOperation.value?.contact &&
			!deepEqual(selectedContact, contractedServiceOperation.value.contact)
		) {
			contractedServiceOperation.onChange(
				buildOperationFromDataObject({
					...contractedServiceOperation.value,
					contact: selectedContact,
				})
			)
			return
		}

		calculateServicePricing(selectedBandwidth, selectedDeadline)
			.then((newOperation) => {
				contractedServiceOperation.onChange(newOperation)
				contractedServiceOperation.isModifying.price && validateOperationPricing(newOperation)
				contractedServiceOperation.isModifying.deadline &&
					contractedServiceOperation.contractType !== ContractType.TEMPORAL &&
					internalUser && // TODO: remove internal user check when the external users have access to the deadline-specific operations
					validateOperationDeadline(newOperation.deadline)
				return newOperation
			})
			.catch(onError)
			.finally(() => {
				stopLoadingForm()
			})
	}

	useEffect(
		() => {
			validateOperationAndCalculate()
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			contractedServiceOperation.contractType,
			form.values.bandwidth?.id,
			form.values.deadline,
			selectedBandwidth,
			selectedDeadline,
			(form.values as ScheduledManageFormParameters).modifyAt,
			(form.values as TemporalManageFormParameters).period,
			isValidOperationForCalculation,
		]
	)

	const onBandwidthChange = useCallback(
		(newBandwidth?: IBandwidthResponse) => {
			if (selectedBandwidth?.id === newBandwidth?.id && form.values.bandwidth !== undefined) return
			form.setFieldValue('bandwidth', newBandwidth ? { id: newBandwidth.id, name: newBandwidth.name } : undefined)
			form.setFieldValue(
				'currency',
				newBandwidth ? { id: newBandwidth.currencyId, name: newBandwidth.currencyName } : undefined
			)
			setSelectedBandwidth(newBandwidth)
		},
		[form, selectedBandwidth]
	)

	const handleBandwidthChange = useCallback(
		(newBandwidthOption: IUfinetSelectOption = emptyUfinetSelectOption) => {
			const newBandwidth = bandwidths.find((bw) => bw.id === newBandwidthOption?.value)
			onBandwidthChange(newBandwidth)
		},
		[bandwidths, onBandwidthChange]
	)

	useEffect(() => {
		if (!contractedServiceOperation.isModifying.bandwidth) return
		handleBandwidthChange(selectedBandwidthOption)
	}, [handleBandwidthChange, contractedServiceOperation.isModifying.bandwidth, selectedBandwidthOption])

	useEffect(() => {
		if (contractedServiceOperation.isModifying.bandwidth) return
		serviceCurrentBandwidth !== undefined ? setBandwidths([serviceCurrentBandwidth]) : setBandwidths([])
		onBandwidthChange(serviceCurrentBandwidth)
	}, [contractedServiceOperation.isModifying.bandwidth, onBandwidthChange, serviceCurrentBandwidth, setBandwidths])

	const resetBandwidthSelect = () => {
		const newBandwidthList = contractedServiceOperation.isModifying.bandwidth
			? bandwidthsFetched || []
			: serviceCurrentBandwidth
			? [serviceCurrentBandwidth]
			: []
		setBandwidths(newBandwidthList)
		onBandwidthChange(undefined)
	}

	useEffect(
		() => {
			if (wasAlreadyMounted.current === true) {
				resetBandwidthSelect()
			}
			wasAlreadyMounted.current = true
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[contractedServiceOperation.isModifying.bandwidth]
	)

	useEffect(
		() => {
			if (wasAlreadyMounted.current === true) {
				form.setFieldError('nrcAdjustment', undefined)
				form.setFieldError('mrcAdjustment', undefined)
				form.setFieldError('deadline', undefined)
				contractedServiceOperation.isModifying.price &&
					contractedServiceOperation.value &&
					validateOperationPricing(contractedServiceOperation.value)
				contractedServiceOperation.isModifying.deadline &&
					contractedServiceOperation.value &&
					internalUser &&
					validateOperationDeadline(contractedServiceOperation.value.deadline)
			}
			wasAlreadyMounted.current = true
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[contractedServiceOperation.isModifying.price, contractedServiceOperation.isModifying.deadline, internalUser]
	)

	useEffect(() => {
		form.setFieldValue('deadline', selectedDeadline)
	}, [selectedDeadline, form])

	const onDeadlineChange = useCallback(
		(deadline?: number) => {
			form.setFieldValue('deadline', deadline)
			setSelectedDeadline(deadline)
		},
		[form]
	)

	const handleDeadlineChange = useCallback(
		(deadline: IUfinetSelectOption = emptyUfinetSelectOption) => {
			const deadlineNumeric = isNumeric(deadline?.value) ? +deadline.value : undefined
			onDeadlineChange(deadlineNumeric)
		},
		[onDeadlineChange]
	)

	const onContactChange = useCallback(
		(newContact?: IContactResponse) => {
			form.setFieldValue('contact', newContact ? { id: newContact.id, name: newContact.name } : undefined)
			setSelectedContact(newContact)
		},
		[form]
	)

	const handleContactChange = useCallback(
		(newContactOption: IUfinetSelectOption = emptyUfinetSelectOption) => {
			const newContact = contacts.find((con) => con.id === newContactOption?.value)
			onContactChange(newContact)
		},
		[contacts, onContactChange]
	)

	useEffect(
		() => {
			handleContactChange(selectedContactOption)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[contactsFetched, selectedContactOption]
	)

	const onKeepDeadlineChange = useCallback(
		(keepDeadline: boolean) => {
			form.setFieldValue('keepDeadline', keepDeadline)
			setIsCheckBoxKeepDeadlineChecked(keepDeadline)

			if (keepDeadline) {
				setSelectedDeadline(keepDeadlinePeriodValue)
				form.setFieldValue('deadline', keepDeadlinePeriodValue)
			} else {
				setSelectedDeadline(undefined)
				form.setFieldValue('deadline', undefined)
			}
		},
		[form, keepDeadlinePeriodValue]
	)

	const handleKeepDeadlineChange = useCallback(
		(newKeepDeadline: UfinetCheckBoxOption[]) => {
			const newKeepDeadlineValue = newKeepDeadline.length !== 0
			if (newKeepDeadlineValue !== isCheckBoxKeepDeadlineChecked) onKeepDeadlineChange(newKeepDeadlineValue)
		},
		[isCheckBoxKeepDeadlineChecked, onKeepDeadlineChange]
	)

	const onOperationTableEdit = useCallback(
		(updatedOperation: ContractedServiceOperation) => {
			form.setFieldError('nrcAdjustment', undefined)
			form.setFieldError('mrcAdjustment', undefined)
			form.setFieldValue('nrcAdjustment', updatedOperation.nrcAdjustment)
			form.setFieldValue('mrcAdjustment', updatedOperation.mrcAdjustment)
			contractedServiceOperation.onChange(updatedOperation)
			onEditing?.(false)
		},
		[form, onEditing, contractedServiceOperation]
	)

	const { validateOperationPricing } = useOperationPricingValidation({
		isPriceIncrease: contractedServiceOperation.selected.includes(ManagementOperation.AUMENTO_PRECIO),
		onSuccess: onOperationTableEdit,
		onError: (errorData, { message }) => {
			onOperationTableEdit(errorData)
			form.setFieldError('nrcAdjustment', message)
			form.setFieldError('mrcAdjustment', message)
		},
	})

	const onDeadlineEdit = useCallback(
		(deadline: number) => {
			form.setFieldError('deadline', undefined)
			form.setFieldValue('deadline', deadline)
			contractedServiceOperation.value &&
				contractedServiceOperation.onChange(
					buildOperationFromDataObject({ ...contractedServiceOperation.value, deadline })
				)
		},
		[form, contractedServiceOperation]
	)

	const { validateOperationDeadline } = useOperationDeadlineValidation({
		isDeadlineIncrease: contractedServiceOperation.selected.includes(ManagementOperation.AUMENTO_PLAZO),
		onSuccess: onDeadlineEdit,
		onError: (deadline, { message }) => {
			onDeadlineEdit(deadline)
			form.setFieldError('deadline', message)
		},
	})

	const calculateInitialDeadline = useCallback(() => {
		if (contractedServiceOperation.contractType === ContractType.TEMPORAL) return deadlineTemporalValue

		if (!service.dueDate) return service.deadline
		if (!isExpiredByDueDateService && internalUser) return service.remainingDeadline
		return undefined
	}, [deadlineTemporalValue, internalUser, isExpiredByDueDateService, service, contractedServiceOperation.contractType])

	const buildStateFromPreviousFormData = useCallback(
		(form: FormikProps<Partial<T>>) => {
			if (form.values?.bandwidth !== undefined)
				handleBandwidthChange({ label: form.values.bandwidth.label, value: form.values.bandwidth.id })

			const newKeepDeadlineValue =
				contractedServiceOperation.contractType === ContractType.TEMPORAL
					? true
					: (contractedServiceOperation.contractType === ContractType.IMMEDIATE ||
							contractedServiceOperation.contractType === ContractType.SCHEDULED) &&
					  isExpiredService
					? false
					: clientParameters.value?.keepDeadline
					? form.values.keepDeadline
					: false
			if (newKeepDeadlineValue !== undefined) onKeepDeadlineChange(newKeepDeadlineValue)

			const initialDeadline = calculateInitialDeadline()

			if (form.values?.deadline !== undefined) {
				if (contractedServiceOperation.isModifying.deadline) {
					const deadline =
						contractedServiceOperation.contractType === ContractType.TEMPORAL
							? calculateInitialDeadline()
							: form.values?.deadline

					onDeadlineChange(deadlineOptions.includes(deadline!) || newKeepDeadlineValue ? deadline : undefined)
				} else {
					onDeadlineChange(form.values?.deadline)
				}
			} else {
				onDeadlineChange(initialDeadline)
			}

			if (form.values?.contact !== undefined)
				handleContactChange({ label: form.values.contact.name, value: form.values.contact.id })
		},
		[
			handleBandwidthChange,
			contractedServiceOperation.contractType,
			contractedServiceOperation.isModifying.deadline,
			isExpiredService,
			clientParameters.value?.keepDeadline,
			onKeepDeadlineChange,
			calculateInitialDeadline,
			handleContactChange,
			onDeadlineChange,
			deadlineOptions,
		]
	)

	useEffect(() => {
		buildStateFromPreviousFormData(form)
	}, [])

	return {
		bandwidth: {
			loading: loadingBandwidth,
			selected: selectedBandwidthOption,
			options: bandwidthOptions,
		},
		deadline: {
			current: clientParameters.value?.currentDeadline,
			selected: selectedDeadline,
			options: deadlineOptions,
			disabled:
				!contractedServiceOperation.isModifying.deadline ||
				contractedServiceOperation.contractType === ContractType.TEMPORAL,
		},
		contact: {
			loading: loadingContact,
			selected: selectedContactOption,
			options: contactOptions,
		},
		keepDeadline: {
			value: isCheckBoxKeepDeadlineChecked,
			options: keepDeadlineOptions,
			disabled: keepDeadlineDisabled,
		},
		onChange: {
			bandwidth: handleBandwidthChange,
			contact: handleContactChange,
			deadline: handleDeadlineChange,
			keepDeadline: handleKeepDeadlineChange,
			pricingTable: contractedServiceOperation.isModifying.price ? validateOperationPricing : onOperationTableEdit,
		},
		form: {
			formik: form,
			loadingControls: { loading: loadingForm, startLoading: startLoadingForm, stopLoading: startLoadingForm },
			deadline: {
				error: deadlineError,
			},
			pricingTable: {
				editable: contractedServiceOperation.isModifying.price,
				error: pricingTableError,
			},
			visibility: {
				showBandwidthSelect: contractedServiceOperation.isModifying.bandwidth,
				showDeadlineSelect: contractedServiceOperation.isModifying.deadline && internalUser,
				showContactSelect: internalUser,
				showPricingTable:
					!isOnlyModifyingDeadline && internalUser && (contractedServiceOperation.value?.isCalculated() || false),
			},
			mapper: {
				formDataToOperation: buildOperationFromDataObject,
			},
		},
	}
}

export { useManageInnerOperationForm }
