import { useQuery } from 'react-query'
import { IContactRepository, IContactResponse } from 'ufinet-web-functions'
import { ContactFindRequest } from './dto/ContactFindRequest'

export const useContactFindFilteredQuery = (
	contactRepository: IContactRepository,
	contactFindRequest: ContactFindRequest,
	{
		internalUser = false,
		fixedValues = [],
		enabled = true,
		onSuccess,
		onError,
	}: {
		internalUser?: boolean
		fixedValues?: IContactResponse[]
		enabled?: boolean
		onSuccess?: (contactResponses: IContactResponse[]) => void
		onError?: (error: unknown) => void
	} = {}
) => {
	return useQuery(
		['contracted-service-detail-query', contactFindRequest],
		() =>
			internalUser
				? fixedValues.length > 0
					? fixedValues
					: contactRepository.findContactsByClientId(contactFindRequest, { notifyError: false })
				: [],
		{
			enabled,
			onSuccess,
			onError,
		}
	)
}
