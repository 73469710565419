import { faArrowsRotate } from '@fortawesome/free-solid-svg-icons'
import { usePetitionTrackingColsData } from 'components/services/management/tracking/table/hooks/usePetitionTrackingColsData'
import { usePetitionTrackingCancelQuery } from 'modules/contractedService/application/management/tracking/cancel/usePetitionTrackingCancelQuery'
import { usePetitionTrackingFindQuery } from 'modules/contractedService/application/management/tracking/find/PetitionTrackingFindQueries'
import { PetitionTracking } from 'modules/contractedService/domain/management/tracking/PetitionTracking'
import { PetitionTrackingRepository } from 'modules/contractedService/domain/management/tracking/repository/PetitionTrackingRepository'
import { useCallback, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import {
	ColData,
	IDisabledOptions,
	IFilterState,
	IUfinetSelectOption,
	UfinetButton,
	allowAllFilterFields,
} from 'ufinet-web-components'
import { useTranslator } from 'ufinet-web-functions'

type HookInput = {
	petitionTrackingRepository: PetitionTrackingRepository
}

type HookOutput = {
	petitionTrackings: PetitionTracking[]
	filter: {
		loading: boolean
		empty: boolean
		disabled: boolean | IDisabledOptions
		onChange: {
			corporateGroups: (groups: IUfinetSelectOption[]) => void
			clients: (countries: IUfinetSelectOption[]) => void
		}
		onSubmit: (state: IFilterState) => void
	}
	table: {
		loading: boolean
		columns: ColData[]
		header: {
			buttons: JSX.Element
		}
	}
}

function usePetitionTrackingTable({ petitionTrackingRepository }: HookInput): HookOutput {
	const translate = useTranslator()

	const [petitionTrackings, setPetitionTrackings] = useState<PetitionTracking[]>([])

	const [filterCorporateGroups, setFilterCorporateGroups] = useState<string[]>([])
	const [filterClients, setFilterClients] = useState<string[]>([])

	const filterEmpty = useMemo(
		() => filterCorporateGroups.length === 0 && filterClients.length === 0,
		[filterCorporateGroups.length, filterClients.length]
	)

	const onFilterChanges = useCallback<(newValues: IUfinetSelectOption[], callback: (input: string[]) => void) => void>(
		(newValues, callback) => {
			const formattedValues = newValues.map((it) => it.value)
			callback(formattedValues)
		},
		[]
	)

	const {
		isLoading: loadingTrackings,
		isFetching: fetchingTrackings,
		refetch: fetchTrackings,
	} = usePetitionTrackingFindQuery(
		petitionTrackingRepository,
		filterEmpty ? undefined : { corporateGroupIds: filterCorporateGroups, clientIds: filterClients },
		{
			onSuccess: setPetitionTrackings,
			onError: () => toast.error(translate('PETITION.TRACKING.FIND.ERROR')),
		}
	)

	const { isLoading: cancellingTrackings, mutate: cancelPendingOperations } = usePetitionTrackingCancelQuery(
		petitionTrackingRepository,
		{
			onSuccess: () => {
				toast.success(translate('PETITION.TRACKING.CANCEL.SUCCESS'))
				fetchTrackings()
			},
			onError: () => toast.error(translate('PETITION.TRACKING.CANCEL.ERROR')),
		}
	)

	const tableLoading = useMemo(
		() => loadingTrackings || fetchingTrackings || cancellingTrackings,
		[cancellingTrackings, fetchingTrackings, loadingTrackings]
	)
	const { columns: tableColumns } = usePetitionTrackingColsData({
		petitionTrackings,
		actions: { cancelOperations: cancelPendingOperations },
	})
	const tableFilterDisabled = useMemo(
		() => tableLoading || { ...allowAllFilterFields, allowSubmit: true },
		[tableLoading]
	)

	const headerButtons = useMemo(() => {
		return (
			<>
				<UfinetButton
					className={`me-3 ${tableLoading ? 'edit-disabled' : ''}`}
					iconClassName={tableLoading ? 'rotating' : ''}
					icon={faArrowsRotate}
					isDisabled={tableLoading}
					onClick={() => {
						fetchTrackings()
					}}
				/>
			</>
		)
	}, [fetchTrackings, tableLoading])

	return {
		petitionTrackings: petitionTrackings,
		filter: {
			loading: loadingTrackings || fetchingTrackings,
			empty: filterEmpty,
			disabled: tableFilterDisabled,
			onChange: {
				corporateGroups: (cgs) => {
					onFilterChanges(cgs, setFilterCorporateGroups)
					onFilterChanges([], setFilterClients)
				},
				clients: (clients) => onFilterChanges(clients, setFilterClients),
			},
			onSubmit: () => fetchTrackings(),
		},
		table: {
			loading: tableLoading,
			columns: tableColumns,
			header: {
				buttons: headerButtons,
			},
		},
	}
}

export { usePetitionTrackingTable }
