import { OfferFlowInformation } from 'components/services/management/modal/hooks/useContractedServiceManageModal'
import { ContractedServiceOperation } from 'modules/contractedService/domain/management/operation/ContractedServiceOperation'
import { FC } from 'react'
import { OfferStatus, useInternalUser, useTranslator } from 'ufinet-web-functions'

type Props = {
	operation: ContractedServiceOperation
	offer: Pick<OfferFlowInformation, 'value' | 'attemptedConfirmation'>
}
const EndFail: FC<Props> = ({ operation, offer: { value: offer, attemptedConfirmation } }) => {
	const translate = useTranslator()
	const internalUser = useInternalUser()

	return (
		<div className="d-flex flex-column align-items-center justify-content-center h-100 p-6 gap-6">
			<i
				className={`bi ${
					attemptedConfirmation ? 'text-warning bi-exclamation-circle' : 'text-success bi-check2-circle'
				}`}
				style={{ fontSize: '10em' }}
			/>
			<b>
				{attemptedConfirmation
					? translate(
							internalUser
								? `CONTRACT.SERVICE.MANAGEMENT.WIZARD.END.FAIL_MESSAGE.${operation.contractType}.INTERNAL`
								: `CONTRACT.SERVICE.MANAGEMENT.WIZARD.END.FAIL_MESSAGE.${operation.contractType}.EXTERNAL`,
							{
								offerId: offer?.id,
								offerStatus: translate(`OFFER.STATUS.${offer?.status || OfferStatus.SUBMITTED}`).toLowerCase(),
							}
					  )
					: translate('CONTRACT.SERVICE.MANAGEMENT.WIZARD.CREATE.FAIL_MESSAGE', { offerId: offer?.id })}
			</b>
		</div>
	)
}

export { EndFail }
