import Swal from 'sweetalert2'
import { ModalButtonsText, defaultSwalConfig } from '../../common/modals/defaultSwalConfig'

const fireModalBeforeCloseModifyService = (
	title: string,
	text: string,
	buttonsText: ModalButtonsText,
	onConfirm: () => void
) => {
	Swal.fire({
		title,
		text,
		...defaultSwalConfig(buttonsText),
		icon: 'warning',
		reverseButtons: true,
		allowEnterKey: true,
		allowEscapeKey: true,
		allowOutsideClick: false,
		preConfirm: () => {
			onConfirm()
		},
	})
}

export { fireModalBeforeCloseModifyService }
