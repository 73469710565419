import { IUfinetSelectOption } from 'ufinet-web-components'
import { getEnumKeyFromEnumValue } from 'ufinet-web-functions'
import { ManagementOperation } from '../ManagementOperation'

export const managementOperationsFromSelectedOptions = (
	selected: IUfinetSelectOption[] = []
): ManagementOperation[] => {
	return selected.map((option) => {
		const managementOperation = getEnumKeyFromEnumValue(ManagementOperation, +option.value || '')
		if (!managementOperation) {
			throw new Error('Cannot get any management operation from the given selection')
		}

		return +option.value as ManagementOperation
	})
}
