import { useContractedServiceManageFormContext } from 'components/services/management/modal/wizard/modify-service/manage/ContractedServiceManageFormContextProvider'
import dayjs from 'dayjs'
import { FormikProps } from 'formik'
import { isExpiredByDueDate } from 'modules/contractedService/application/isExpired'
import { ManagementOperation } from 'modules/contractedService/domain/management/operation/ManagementOperation'
import { ContractedServiceOperationDataScheduled } from 'modules/contractedService/domain/management/operation/scheduled/ContractedServiceOperationScheduled'
import { CalendarChangeParams } from 'primereact/calendar'
import { useCallback, useEffect, useMemo } from 'react'
import { LoadingControls, useInternalUser, useTranslator } from 'ufinet-web-functions'
import { ManageFormValidationCreator } from '../../base/manageFormValidationCreator'
import { ScheduledManageFormParameters, useScheduledManageFormValidation } from './useScheduledManageFormValidation'

type HookOutput = {
	awardDate: {
		value?: Date
		min: Date
		max: Date
	}
	onChange: {
		awardDate: (calendarChangeParams: CalendarChangeParams) => void
	}
	form: {
		formik: FormikProps<ScheduledManageFormParameters>
		loadingControls: LoadingControls
		validator: ManageFormValidationCreator<ContractedServiceOperationDataScheduled>
	}
	errorMessage?: string
}

function useScheduledManageForm(): HookOutput {
	const {
		form: { value: form },
		service,
		contractedServiceOperation: { selected: selectedOperations, contractType },
	} = useContractedServiceManageFormContext<ContractedServiceOperationDataScheduled>()

	const internalUser = useInternalUser()

	const translate = useTranslator()

	const errorMessage = useMemo(() => {
		const isUpgrade = selectedOperations.includes(ManagementOperation.UPGRADE)

		if (!isUpgrade) return

		if (isExpiredByDueDate(service.dueDate)) {
			return translate(`CONTRACT.SERVICE.CONTRACT.TYPE.ERROR.EXPIRED.${internalUser ? 'INTERNAL' : 'EXTERNAL'}`, {
				contractType: translate(`CONTRACT.SERVICE.CONTRACT.TYPE.${contractType}`).toLowerCase(),
			})
		}
	}, [selectedOperations, service.dueDate, internalUser, contractType, translate])

	const minStartDate = dayjs().startOf('day').add(1, 'day')
	const serviceExpirationDate = service.remainingDeadline ? dayjs().add(service.remainingDeadline, 'months') : undefined
	const defaultMaxAllowedDate = minStartDate.add(1, 'month')

	const maxFinishDate = useMemo(
		() =>
			!serviceExpirationDate
				? defaultMaxAllowedDate
				: serviceExpirationDate.isBefore(defaultMaxAllowedDate)
				? serviceExpirationDate
				: defaultMaxAllowedDate,
		[defaultMaxAllowedDate, serviceExpirationDate]
	)

	const { formik, initialValues, validationSchema, loadingControls } = useScheduledManageFormValidation({
		minStartDate,
		maxFinishDate,
	})

	const onAwardDateChange = useCallback(
		(newAwardDate?: Date) => {
			form.setFieldValue('modifyAt', newAwardDate)
		},
		[form]
	)

	const handleAwardDateChange = useCallback(
		(calendarChangeParams: CalendarChangeParams) => {
			const newAwardDate = calendarChangeParams.value instanceof Date ? calendarChangeParams.value : undefined
			onAwardDateChange(newAwardDate)
		},
		[onAwardDateChange]
	)

	useEffect(() => {
		if (form.values?.modifyAt !== undefined) onAwardDateChange(form.values?.modifyAt)
	}, [])

	return {
		awardDate: {
			value: (form.values as ScheduledManageFormParameters).modifyAt,
			min: minStartDate.toDate(),
			max: maxFinishDate.toDate(),
		},
		onChange: {
			awardDate: handleAwardDateChange,
		},
		form: {
			formik,
			loadingControls,
			validator: () => ({ formik, initialValues, validationSchema, loadingControls }),
		},
		errorMessage,
	}
}

export { useScheduledManageForm }
