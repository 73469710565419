import { FormikProps, useFormik } from 'formik'
import { ContractedService } from 'modules/contractedService/domain/ContractedService'
import { ContractedServiceOrsBaseData } from 'modules/contractedService/domain/ors/petition/base/ContractedServiceOrsBase'
import { OrsEntryType } from 'modules/contractedService/domain/ors/petition/base/OrsEntryType'
import { OrsPetition } from 'modules/contractedService/domain/ors/petition/base/OrsPetition'
import { OrsOperationType } from 'modules/contractedService/domain/ors/petition/base/OrsReactivationType'
import { OrsStrategy } from 'modules/contractedService/domain/ors/strategies/OrsStrategy'
import { useMemo } from 'react'
import { useInternalUser, useTranslator } from 'ufinet-web-functions'
import * as Yup from 'yup'

type HookOutput = {
	form: FormikProps<ContractedServiceOrsBaseData>
	initialValues: ContractedServiceOrsBaseData
	validationSchema: Yup.ObjectSchema<any>
	servicesValidation: string | undefined
}

type HookInput = {
	selectedEntry: OrsEntryType
	selectedOperation: OrsOperationType[]
	selectedPetition: OrsPetition
	services: ContractedService[]
	orsStrategy: OrsStrategy
}

const useOrsBaseFormValidation = ({
	selectedEntry,
	selectedPetition,
	selectedOperation,
	services,
	orsStrategy,
}: HookInput): HookOutput => {
	const translate = useTranslator()
	const internalUser = useInternalUser()

	const initialValues = useMemo(
		() => ({
			selectedEntry: selectedEntry,
			selectedOperation: selectedOperation,
			selectedPetition: selectedPetition,
		}),
		[selectedEntry, selectedPetition, selectedOperation]
	)

	const validationSchema = Yup.object().shape({
		selectedEntry: Yup.string().required(translate('ERROR.REQUIRED')),
		selectedOperation: Yup.array().of(Yup.string().required(translate('ERROR.REQUIRED'))),
		selectedPetition: Yup.number().required(translate('ERROR.REQUIRED')),
	})

	const form = useFormik<ContractedServiceOrsBaseData>({
		initialValues: initialValues,
		onSubmit: () => {},
		validationSchema: validationSchema,
		validateOnChange: false,
		validateOnBlur: false,
		enableReinitialize: true,
		onReset: (_values, form) => {
			form.setValues(initialValues)
		},
	})

	const servicesValidation = useMemo(() => {
		const isTechnical = selectedOperation.includes(OrsOperationType.TECHNICAL)
		const isBilling = selectedOperation.includes(OrsOperationType.BILLING)

		const invalidAdministrativeCodes = services
			.filter((service) => orsStrategy.isSelectedServiceInvalid(service, selectedOperation))
			.map((service) => service.administrativeCode)

		if (invalidAdministrativeCodes.length > 0) {
			const reactivations = [
				isTechnical && translate('ORS.OPERATION.TYPE.TECHNICAL').toLocaleLowerCase(),
				isBilling && translate('CONTRACT.SERVICE.ORS.VALIDATION.BILLING'),
			]
				.filter(Boolean)
				.join(` ${translate('AND')} `)

			const codes = invalidAdministrativeCodes.join(', ')

			return translate(
				`CONTRACT.SERVICE.ORS.VALIDATION.${internalUser ? 'INTERNAL' : 'EXTERNAL'}.${
					invalidAdministrativeCodes.length === 1 ? 'SINGULAR' : 'PLURAL'
				}`,
				{
					codes: codes,
					petition: translate(`CONTRACT.SERVICE.ORS.PETITION.${OrsPetition[selectedPetition]}`).toLocaleLowerCase(),
					reactivations: reactivations,
				}
			)
		}

		return undefined
	}, [selectedOperation, services, translate, internalUser, selectedPetition, orsStrategy])

	return { form, initialValues, validationSchema, servicesValidation }
}

export { useOrsBaseFormValidation }
