import { useQuery } from 'react-query'
import { ClientTypeResponse } from 'ufinet-web-functions'
import { ClientTypeRepository } from '../../domain/repository/ClientTypeRepository'

export const useClientTypesFindAllQuery = (
	clientTypeRepository: ClientTypeRepository,
	{
		enabled = true,
		onSuccess,
		onError,
	}: {
		enabled?: boolean
		onSuccess?: (clientTypeResponses: ClientTypeResponse[]) => void
		onError?: (error: unknown) => void
	} = {}
) => {
	return useQuery('client-types-find-all-query', () => clientTypeRepository.findAll(), {
		enabled,
		onSuccess,
		onError,
	})
}
