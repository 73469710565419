import { binnacleTableCols } from 'components/services/management/tracking/table/binnacleTableCols'
import { usePetitionTrackingFindBinnacleQuery } from 'modules/contractedService/application/management/tracking/find/PetitionTrackingFindQueries'
import { PetitionTrackingRepository } from 'modules/contractedService/domain/management/tracking/repository/PetitionTrackingRepository'
import { FC } from 'react'
import { Table, UfinetSectionBox } from 'ufinet-web-components'
import { useTranslator } from 'ufinet-web-functions'

type BinnacleTableProps = {
	serviceId: string
	petitionTrackingRepository: PetitionTrackingRepository
}

export const BinnacleTable: FC<BinnacleTableProps> = ({ serviceId, petitionTrackingRepository }) => {
	const translate = useTranslator()
	const { data = { binnacle: [] }, isLoading: isLoadingEvents } = usePetitionTrackingFindBinnacleQuery(
		petitionTrackingRepository,
		serviceId,
		{ enabled: Boolean(serviceId) }
	)

	return (
		<UfinetSectionBox className="position-relative">
			<h2 className="fs-3 fw-bolder text-dark">{translate('BINNACLE.TITLE')}</h2>
			<Table
				content={data?.binnacle}
				cols={binnacleTableCols(translate)}
				emptyMessage={isLoadingEvents ? translate('LOADING_DOTS') : undefined}
			/>
		</UfinetSectionBox>
	)
}
