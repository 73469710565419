import { useTermsAndConditionsOperationSummary } from 'components/services/management/modal/wizard/modify-service/confirm-resume/modals/terms-and-conditions/content/summary/hooks/useTermsAndConditionsOperationSummary'
import { ContractedServiceDetail } from 'modules/contractedService/domain/detail/ContractedServiceDetail'
import { ContractedServiceOperation } from 'modules/contractedService/domain/management/operation/ContractedServiceOperation'
import { Offer } from 'modules/offer/domain/Offer'
import { FC } from 'react'
import { noContentPlaceholder, useTranslator } from 'ufinet-web-functions'

type Props = {
	offer?: Offer
	contractedServiceDetail: ContractedServiceDetail
	contractedServiceOperation: ContractedServiceOperation
}

const TermsAndConditionsOperationSummary: FC<Props> = ({
	offer,
	contractedServiceDetail,
	contractedServiceOperation,
}) => {
	const translate = useTranslator()

	const { contact, awardDate } = useTermsAndConditionsOperationSummary({ contractedServiceOperation })

	return (
		<div className="terms-and-conditions-header">
			<div className="terms-and-conditions-header">
				<p>
					{offer && `${translate('CONTRACT.SERVICE.TERMS.OFFER_NUMBER')}: ${offer.id}`}
					{awardDate && (
						<>
							<br />
							{`${translate('CONTRACT.SERVICE.TERMS.AWARD_DATE')}: ${awardDate}`}
						</>
					)}
					<br />
					{`${translate('CONTRACT.SERVICE.TERMS.CLIENT')}: ${
						contractedServiceDetail.clientName || noContentPlaceholder
					}`}
					{contact && (
						<>
							<br />
							{`${translate('CONTRACT.SERVICE.TERMS.CONTACT')}: ${contact}`}
						</>
					)}
				</p>
			</div>
		</div>
	)
}
export { TermsAndConditionsOperationSummary }
