import { ContractedServiceOperationTemporal } from 'modules/contractedService/domain/management/operation/temporary/ContractedServiceOperationTemporary'
import { offerCreateRequestFromOperation } from 'modules/offer/application/create/dto/OfferCreateRequest'
import { OfferScheduleRequest } from 'modules/offer/application/schedule/dto/OfferScheduleRequest'

export type OfferTemporalRequest = OfferScheduleRequest & {
	rollbackAt: string
	period: number
}

export const offerTemporalRequestFromServiceOperation = ({
	offerCode,
	contractedServiceOperation,
}: {
	offerCode?: string
	contractedServiceOperation: ContractedServiceOperationTemporal
}): OfferTemporalRequest => {
	const offerRequest = offerCreateRequestFromOperation(contractedServiceOperation)
	return {
		offerCode,
		serviceCode: contractedServiceOperation.service.id,
		operations: contractedServiceOperation.operations,
		modifyAt: contractedServiceOperation.modifyAt.toISOString(),
		rollbackAt: contractedServiceOperation.rollbackAt.toISOString(),
		period: contractedServiceOperation.period,
		offerRequest,
	}
}
