import { ContractedServiceOperation } from 'modules/contractedService/domain/management/operation/ContractedServiceOperation'
import { ManagementOperation } from 'modules/contractedService/domain/management/operation/ManagementOperation'
import { FC } from 'react'
import { getEnumKeyFromEnumValue, joinValuesWithConjunctionAndSeparator, useTranslator } from 'ufinet-web-functions'

type Props = { operation: ContractedServiceOperation }

const EndSuccess: FC<Props> = ({ operation }) => {
	const translate = useTranslator()
	return (
		<div className="d-flex flex-column align-items-center justify-content-center h-100 p-6 gap-6">
			<i
				className="bi text-success bi-check2-circle"
				style={{ fontSize: '10em' }}
			/>
			<b>
				{translate(`CONTRACT.SERVICE.MANAGEMENT.WIZARD.END.SUCCESS_MESSAGE.${operation.contractType}`, {
					operation: joinValuesWithConjunctionAndSeparator(
						operation.operations.map((operation) =>
							translate(
								`CONTRACT.SERVICE.MANAGEMENT.OPERATION.${getEnumKeyFromEnumValue(ManagementOperation, operation)}`
							).toLowerCase()
						),
						translate('AND')
					),
				})}
			</b>
		</div>
	)
}

export { EndSuccess }
