import { PetitionTrackingBinnacleResponse } from 'modules/contractedService/application/management/tracking/find/dto/PetitionTrackingBinnacleResponse'
import { PetitionTrackingBinnacle } from 'modules/contractedService/domain/management/tracking/entities/PetitionTrackingBinnacle'

export const binnacleMapper = (
	petitionTrackingBinnacleResponse: PetitionTrackingBinnacleResponse
): PetitionTrackingBinnacle => {
	return {
		binnacle: petitionTrackingBinnacleResponse.binnacle.map((binnacle) => {
			return {
				id: binnacle.id,
				serviceId: binnacle.serviceId,
				eventType: binnacle.eventType,
				date: binnacle.date,
				createdBy: binnacle.createdBy,
				observation: binnacle.observation,
				notifyExternalUser: binnacle.notifyExternalUser,
				notifyInternalUser: binnacle.notifyInternalUser,
			}
		}),
	}
}
