import { useOrsConfirmModal } from 'components/services/ors/modal/forms/base/hooks/useOrsConfirmModal'
import dayjs from 'dayjs'
import { FormikProps } from 'formik'
import { OrsUnifiedSaveRequest } from 'modules/contractedService/application/ors/save/unified/dto/OrsUnifiedSaveRequest'
import { OrsUnifiedServiceRequest } from 'modules/contractedService/application/ors/save/unified/dto/service/OrsUnifiedServiceRequest'
import { useOrsUnifiedSaveQuery } from 'modules/contractedService/application/ors/save/unified/useOrsUnifiedSaveQuery'
import { ContractedService } from 'modules/contractedService/domain/ContractedService'
import { Reason } from 'modules/contractedService/domain/ors/petition/base/ContractedServiceOrsBase'
import { OrsEntryType } from 'modules/contractedService/domain/ors/petition/base/OrsEntryType'
import { OrsPetition } from 'modules/contractedService/domain/ors/petition/base/OrsPetition'
import { OrsOperationType } from 'modules/contractedService/domain/ors/petition/base/OrsReactivationType'
import { OrsReason } from 'modules/contractedService/domain/ors/petition/base/OrsReason'
import {
	ContractedServiceOrsUnifiedData,
	UnifiedServiceData,
} from 'modules/contractedService/domain/ors/petition/unified/ContractedServiceOrsUnified'
import { OrsStrategy } from 'modules/contractedService/domain/ors/strategies/OrsStrategy'
import { ContractedServiceOrsRepository } from 'modules/contractedService/domain/repository/ContractedServiceOrsRepository'
import { CalendarChangeParams } from 'primereact/calendar'
import { useCallback, useEffect, useMemo } from 'react'
import { useOrsBaseForm } from '../../base/hooks/useOrsBaseForm'
import { useOrsUnifiedFormValidation } from './useOrsUnifiedFormValidation'

type HookOutput = {
	onChange: {
		modifyInput: (data: string) => void
		modifyReason: (data: Reason) => void
		modifyDatePicker: (data: CalendarChangeParams) => void
		removeService: (id: string) => void
	}
	onValidate: {
		customError?: string
	}
	form: FormikProps<ContractedServiceOrsUnifiedData>
	reasonOptions?: OrsReason[]
	validateForm: () => void
	calculateMinimumDate: Date | undefined
}

type HookInput = {
	selectedEntry: OrsEntryType
	selectedOperation: OrsOperationType[]
	selectedPetition: OrsPetition
	services: ContractedService[]
	onServiceRemove: (id: string) => void
	onSend: () => void
	serviceOrsRepository: ContractedServiceOrsRepository
	orsStrategy: OrsStrategy
}

function useOrsUnifiedForm({
	selectedEntry,
	selectedPetition,
	selectedOperation,
	services,
	onServiceRemove,
	onSend,
	serviceOrsRepository,
	orsStrategy,
}: HookInput): HookOutput {
	const { mutate: saveOrsUnified } = useOrsUnifiedSaveQuery(serviceOrsRepository)

	const { showConfirmModal } = useOrsConfirmModal({
		onSend,
		saveOrsQuery: saveOrsUnified,
	})

	const handleSubmit = useCallback(
		(params: ContractedServiceOrsUnifiedData) => {
			showConfirmModal({
				technical: params.selectedOperation.includes(OrsOperationType.TECHNICAL),
				billing: params.selectedOperation.includes(OrsOperationType.BILLING),
				observations: params.observations,
				completionDate: params.date ? new Date(dayjs(params.date).format('YYYY-MM-DD')).toISOString() : '',
				services: params.services.map((service) => {
					return { serviceId: service.id } as OrsUnifiedServiceRequest
				}),
				cause: params.reason?.code,
				petition: OrsPetition[params.selectedPetition],
			} as OrsUnifiedSaveRequest)
		},
		[showConfirmModal]
	)

	const { form, servicesValidation } = useOrsUnifiedFormValidation({
		selectedEntry,
		selectedOperation,
		selectedPetition,
		services,
		handleSubmit,
		orsStrategy,
	})

	const calculateMinimumDate = useMemo(() => {
		return orsStrategy.calculateUnifiedServicesMinimumDate(selectedOperation, services)
	}, [selectedOperation, services, orsStrategy])

	const { clearErrors, customError, validateForm, reasonOptions } = useOrsBaseForm({
		form,
		servicesValidation,
		serviceOrsRepository,
		selectedPetition,
	})

	const onInputEdit = useCallback(
		(data: string) => {
			form.setFieldValue('observations', data)
			clearErrors()
		},
		[form, clearErrors]
	)

	const onDropdownChange = useCallback(
		(data: Reason) => {
			form.setFieldValue('reason', data)
			clearErrors()
		},
		[form, clearErrors]
	)

	const onDatePickerEdit = useCallback(
		(calendarChangeParams: CalendarChangeParams) => {
			const newDate = calendarChangeParams.value instanceof Date ? calendarChangeParams.value : undefined
			form.setFieldValue('date', newDate)
			clearErrors()
		},
		[form, clearErrors]
	)

	useEffect(() => {
		clearErrors()

		const selectedServices = services.map((service) => ({
			administrativeCode: service.administrativeCode,
			id: service.id,
		})) as UnifiedServiceData[]

		const minimumDate = form.values.date
			? dayjs(calculateMinimumDate).isAfter(dayjs(form.values.date), 'day')
				? undefined
				: form.values.date
			: undefined

		form.setFieldValue('selectedOperation', selectedOperation)
		form.setFieldValue('selectedPetition', selectedPetition)
		form.setFieldValue('services', selectedServices)
		form.setFieldValue('date', minimumDate)
		form.setFieldValue('observations', form.values.observations)
	}, [selectedOperation, selectedPetition, services, calculateMinimumDate])

	return {
		onChange: {
			modifyInput: onInputEdit,
			modifyReason: onDropdownChange,
			modifyDatePicker: onDatePickerEdit,
			removeService: onServiceRemove,
		},
		onValidate: {
			customError: customError,
		},
		validateForm: validateForm,
		form: form,
		reasonOptions: reasonOptions,
		calculateMinimumDate,
	}
}

export { useOrsUnifiedForm }
