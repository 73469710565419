import { OrsServiceExceptionRepository } from 'modules/contractedService/domain/ors/exception/repository/OrsServiceExceptionRepository'
import { defaultOptionsFetch, FetchAPI, FetchOptions, IAuthData } from 'ufinet-web-functions'

const url = `${process.env.REACT_APP_URL_BACK_GSE}api/services/contracted/ors/exception`

export const HttpOrsServiceExceptionRepository = (authData: IAuthData): OrsServiceExceptionRepository => {
	const fetchOptions: FetchOptions = {
		...defaultOptionsFetch,
		authenticationData: authData,
		sendApiKey: true,
	}

	return {
		findAllOfUser: async (options) =>
			await FetchAPI.get(url, {
				...fetchOptions,
				...options,
			}),
		updateStates: async (request, options) => await FetchAPI.patch(url, request, { ...fetchOptions, ...options }),
	}
}
