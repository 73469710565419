import { faker } from '@faker-js/faker'
import { ICorporateGroup } from 'ufinet-web-functions'

export const CorporateGroupMother = {
	create: (input?: Partial<ICorporateGroup>): ICorporateGroup => ({
		id: faker.string.uuid(),
		name: faker.company.name(),
		lastUpdatedBy: faker.internet.email(),
		lastUpdatedAt: faker.date.recent().toISOString(),
		...input,
	}),
	ifx: (): ICorporateGroup => ({
		id: 'E422C979-CAA6-EB11-B1AC-000D3ADBD34D',
		name: 'IFX',
		lastUpdatedBy: 'test',
		lastUpdatedAt: 'test',
	}),
}
