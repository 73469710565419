import dayjs from 'dayjs'
import { ContractedServiceOperation } from 'modules/contractedService/domain/management/operation/ContractedServiceOperation'
import { ContractType } from 'modules/contractedService/domain/management/operation/ContractType'
import { ContractedServiceOperationScheduled } from 'modules/contractedService/domain/management/operation/scheduled/ContractedServiceOperationScheduled'
import { useContext } from 'react'
import { AuthContext } from 'ufinet-web-functions'

type HookInput = {
	contractedServiceOperation: ContractedServiceOperation
}

type HookOutput = {
	contact?: string
	awardDate?: string
}

function useTermsAndConditionsOperationSummary({ contractedServiceOperation }: HookInput): HookOutput {
	const { userData } = useContext(AuthContext)

	const contact = contractedServiceOperation.contact?.name || userData?.user.fullName
	const awardDate =
		contractedServiceOperation.contractType === ContractType.SCHEDULED
			? (contractedServiceOperation as ContractedServiceOperationScheduled).modifyAt
			: undefined

	return {
		contact,
		awardDate: !awardDate ? undefined : dayjs(awardDate).format('DD/MM/YYYY'),
	}
}

export { useTermsAndConditionsOperationSummary }
