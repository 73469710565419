import { OrsEntryType } from 'modules/contractedService/domain/ors/petition/base/OrsEntryType'
import { OrsPetition } from 'modules/contractedService/domain/ors/petition/base/OrsPetition'
import { OrsOperationType } from 'modules/contractedService/domain/ors/petition/base/OrsReactivationType'
import { OrsStrategy } from 'modules/contractedService/domain/ors/strategies/OrsStrategy'
import { OrsStrategyBilling } from 'modules/contractedService/domain/ors/strategies/OrsStrategyBilling'
import { OrsStrategyCancellation } from 'modules/contractedService/domain/ors/strategies/OrsStrategyCancellation'
import { OrsStrategyReactivation } from 'modules/contractedService/domain/ors/strategies/OrsStrategyReactivation'
import { OrsStrategySpecialRequests } from 'modules/contractedService/domain/ors/strategies/OrsStrategySpecialRequests'
import { OrsStrategySuspension } from 'modules/contractedService/domain/ors/strategies/OrsStrategySuspension'
import { OrsStrategyUnsubscribe } from 'modules/contractedService/domain/ors/strategies/OrsStrategyUnsubscribe'
import { useCallback, useMemo, useState } from 'react'
import { UfinetCheckBoxOption, UfinetRadioButtonOption } from 'ufinet-web-components'
import { useTranslator } from 'ufinet-web-functions'

type HookInput = {
	isOldServiceVersion: boolean
}

type HookOutput = {
	selectedEntry: OrsEntryType
	selectedOperation?: OrsOperationType[]
	selectedPetition: OrsPetition
	allowedPetitions: OrsPetition[]
	entryTypeOptions: UfinetRadioButtonOption[]
	operationTypeOptions: UfinetCheckBoxOption[]
	showForm: boolean
	onChange: {
		petition: (petition: OrsPetition) => void
		entry: (entry: OrsEntryType) => void
		reactivation: (reactivation?: UfinetCheckBoxOption[]) => void
	}
	orsStrategy: OrsStrategy
}

const strategies = {
	[OrsPetition.REACTIVATION]: OrsStrategyReactivation,
	[OrsPetition.SUSPENSION]: OrsStrategySuspension,
	[OrsPetition.DISCONNECT]: OrsStrategyUnsubscribe,
	[OrsPetition.CANCELLATION]: OrsStrategyCancellation,
	[OrsPetition.BILLING]: OrsStrategyBilling,
	[OrsPetition.SPECIAL_REQUESTS]: OrsStrategySpecialRequests,
}

const oldServiceVersionsAllowedEntryTypes: OrsEntryType[] = [OrsEntryType.INDIVIDUAL]
const oldServiceVersionsAllowedOptions: OrsOperationType[] = [OrsOperationType.BILLING]

function useContractedServiceOrsModal({ isOldServiceVersion }: HookInput): HookOutput {
	const translate = useTranslator()

	const [selectedPetition, setSelectedPetition] = useState<OrsPetition>(
		isOldServiceVersion ? OrsPetition.BILLING : OrsPetition.REACTIVATION
	)
	const allowedPetitions = useMemo(() => {
		const petitions = isOldServiceVersion ? [OrsPetition.BILLING] : (Object.values(OrsPetition) as OrsPetition[])
		return process.env.REACT_APP_ENV === 'prod'
			? petitions.filter((petition) => petition !== OrsPetition.SPECIAL_REQUESTS)
			: petitions
	}, [isOldServiceVersion])
	const [selectedEntry, setSelectedEntry] = useState<OrsEntryType>(OrsEntryType.INDIVIDUAL)
	const [orsStrategy, setOrsStrategy] = useState<OrsStrategy>(
		isOldServiceVersion ? new OrsStrategyBilling() : new OrsStrategyReactivation()
	)
	const [selectedOperation, setSelectedOperation] = useState<OrsOperationType[]>(orsStrategy.defaultOperations)

	const entryTypeOptions: UfinetRadioButtonOption[] = useMemo(
		() =>
			Object.values(OrsEntryType)
				.filter((entryType) => !isOldServiceVersion || oldServiceVersionsAllowedEntryTypes.includes(entryType))
				.map((entryType) => ({
					value: entryType,
					label: translate(`ORS.ENTRY.TYPE.${entryType}`),
				})),
		[isOldServiceVersion, translate]
	)

	const operationTypeOptions: UfinetCheckBoxOption[] = useMemo(
		() =>
			Object.values(OrsOperationType)
				.filter((operationType) => !isOldServiceVersion || oldServiceVersionsAllowedOptions.includes(operationType))
				.map((operationType) => ({
					value: operationType,
					label: translate(`ORS.OPERATION.TYPE.${operationType}`),
				})),
		[isOldServiceVersion, translate]
	)

	const showForm = useMemo(
		() => orsStrategy.showForm(selectedPetition, selectedEntry, selectedOperation),
		[selectedPetition, selectedEntry, selectedOperation, orsStrategy]
	)

	const onReactivationChange = useCallback((reactivation?: UfinetCheckBoxOption[]) => {
		if (reactivation) {
			setSelectedOperation(reactivation.map((option) => option.value as OrsOperationType))
		}
	}, [])

	const getOrsStrategy = useCallback((petition: OrsPetition) => {
		const Strategy = strategies[petition]
		if (!Strategy) {
			throw new Error('Invalid petition')
		}

		return new Strategy()
	}, [])

	const onPetitionChange = useCallback(
		(petition: OrsPetition) => {
			const newStrategy = getOrsStrategy(petition)
			setOrsStrategy(newStrategy)
			setSelectedOperation(newStrategy.defaultOperations)
			setSelectedPetition(petition)
		},
		[getOrsStrategy]
	)

	return {
		selectedEntry,
		selectedOperation,
		selectedPetition,
		allowedPetitions,
		entryTypeOptions,
		operationTypeOptions,
		showForm,
		onChange: {
			petition: onPetitionChange,
			entry: setSelectedEntry,
			reactivation: onReactivationChange,
		},
		orsStrategy,
	}
}

export { useContractedServiceOrsModal }
