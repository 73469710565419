import { OrsBaseSaveRequest } from 'modules/contractedService/application/ors/save/common/dto/OrsBaseSaveRequest'
import { useCallback } from 'react'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import { useTranslator } from 'ufinet-web-functions'

type HookInput<T extends OrsBaseSaveRequest<unknown>> = {
	onSend: () => void
	saveOrsQuery: (request: T) => void
}

type HookOutput<T extends OrsBaseSaveRequest<unknown>> = {
	showConfirmModal: (request: T) => void
}

const useOrsConfirmModal = <T extends OrsBaseSaveRequest<unknown>>({
	onSend,
	saveOrsQuery,
}: HookInput<T>): HookOutput<T> => {
	const translate = useTranslator()
	const showConfirmModal = useCallback(
		(request: T) => {
			Swal.fire({
				title: translate('ORS.CONFIRM.TITLE'),
				text: translate('ORS.CONFIRM.TEXT'),
				icon: 'warning',
				showCancelButton: true,
				showConfirmButton: true,
				reverseButtons: true,
				confirmButtonText: translate('OK'),
				cancelButtonText: translate('CANCEL'),
				confirmButtonColor: '#0065A4',
				cancelButtonColor: '#f64e60',
				allowOutsideClick: false,
				preConfirm: () => {
					saveOrsQuery(request)
					toast.success(translate('ORS.CONFIRM.SUCCESS'))
					onSend()
				},
			})
		},
		[onSend, saveOrsQuery, translate]
	)

	return {
		showConfirmModal,
	}
}

export { useOrsConfirmModal }
