import dayjs from 'dayjs'
import { ContractedService } from 'modules/contractedService/domain/ContractedService'
import { OrsEntryType } from 'modules/contractedService/domain/ors/petition/base/OrsEntryType'
import { OrsPetition } from 'modules/contractedService/domain/ors/petition/base/OrsPetition'
import { OrsOperationType } from 'modules/contractedService/domain/ors/petition/base/OrsReactivationType'
import { ContractedServiceState } from 'modules/contractedService/domain/state/ContractedServiceState'
import { ContractedServiceStateExternal } from 'modules/contractedService/domain/state/ContractedServiceStateExternal'

import { OrsStrategy } from './OrsStrategy'

export class OrsStrategyReactivation implements OrsStrategy {
	public showForm(selectedPetition: OrsPetition, selectedEntry: OrsEntryType, selectedOperations: OrsOperationType[]) {
		return selectedPetition && selectedEntry && selectedOperations && selectedOperations.length > 0
	}

	public calculateIndividualServiceMinimumDate(
		selectedOperations: OrsOperationType[],
		serviceId: string,
		services: ContractedService[]
	) {
		const isBilling = selectedOperations.includes(OrsOperationType.BILLING)
		const serviceBillingSuspensionDate = services.find((data) => data.id === serviceId)?.billingSuspensionDate
		const minimumBillingSuspensionDate = serviceBillingSuspensionDate
			? dayjs(serviceBillingSuspensionDate).add(1, 'day').toDate()
			: undefined
		if (isBilling && minimumBillingSuspensionDate) {
			if (selectedOperations.includes(OrsOperationType.TECHNICAL)) {
				return dayjs(minimumBillingSuspensionDate).isAfter(dayjs(new Date()))
					? minimumBillingSuspensionDate
					: new Date()
			}
			return minimumBillingSuspensionDate
		}

		return new Date()
	}

	public calculateUnifiedServicesMinimumDate(selectedOperations: OrsOperationType[], services: ContractedService[]) {
		const isBilling = selectedOperations.includes(OrsOperationType.BILLING)
		let defaultMinimumDate = new Date()

		services.forEach((service) => {
			const serviceBillingSuspensionDate = service.billingSuspensionDate
			const minimumBillingSuspensionDate = serviceBillingSuspensionDate
				? dayjs(serviceBillingSuspensionDate).add(1, 'day').toDate()
				: undefined

			if (isBilling && minimumBillingSuspensionDate) {
				if (selectedOperations.includes(OrsOperationType.TECHNICAL)) {
					defaultMinimumDate = dayjs(minimumBillingSuspensionDate).isAfter(dayjs(new Date()))
						? minimumBillingSuspensionDate
						: new Date()
					return
				}
				defaultMinimumDate = minimumBillingSuspensionDate
			}
		})

		return defaultMinimumDate
	}

	public isSelectedServiceInvalid(service: ContractedService, selectedOperations: OrsOperationType[]) {
		const isTechnical = selectedOperations.includes(OrsOperationType.TECHNICAL)
		const isBilling = selectedOperations.includes(OrsOperationType.BILLING)

		const isTechnicalReactivationInvalid = (service: ContractedService) =>
			isTechnical && service.state?.value !== ContractedServiceState.SUSPENDIDO

		const isBillingReactivationInvalid = (service: ContractedService) =>
			isBilling &&
			(service.state?.value === undefined ||
				(
					[
						ContractedServiceState.INACTIVO,
						ContractedServiceState.CANCELADO,
						ContractedServiceState.DADO_BAJA,
						ContractedServiceState.BAJA_LOGICA,
					] as (ContractedServiceState | ContractedServiceStateExternal)[]
				).includes(service.state?.value) ||
				service.billingSuspensionDate == null)

		return isTechnicalReactivationInvalid(service) || isBillingReactivationInvalid(service)
	}

	public enableOperations = true

	public enableReason = false

	public enableCompletionDate = true

	public isObservationRequired = false

	public defaultOperations = [OrsOperationType.TECHNICAL]

	public observationsLabel = 'CONTRACT.SERVICE.ORS.OBSERVATIONS'
}
