import { BandwidthFindRequest } from 'modules/bandwidth/application/common/dto/BandwidthFindRequest'
import { ClientParameters } from 'modules/client/parameters/domain/ClientParameters'
import { ContractedServiceType } from 'modules/contractedService/domain/ContractedServiceType'
import { ContractedServiceDetail } from 'modules/contractedService/domain/detail/ContractedServiceDetail'
import { ProductId } from 'ufinet-web-functions'

const getProductId = (serviceType?: ContractedServiceType): ProductId | undefined => {
	if (!serviceType) return
	switch (serviceType) {
		case ContractedServiceType.INTERNET:
			return ProductId.INTERNET
		case ContractedServiceType.CAPACIDAD:
			return ProductId.ELINE
		default:
			return undefined
	}
}

export const bandwidthFindRequestFromServiceData = (
	serviceData: ContractedServiceDetail,
	clientParameters?: ClientParameters
): BandwidthFindRequest => {
	const productTypeId = getProductId(serviceData.serviceType?.value)
	const { serviceId, priceListTypeId, techType } = serviceData
	if (!priceListTypeId || !productTypeId || !techType)
		throw new Error('Cannot create bandwidth request from service data')
	return {
		serviceId,
		priceListTypeId,
		productTypeId,
		techTypeId: techType.id,
		minCapacity: clientParameters?.minBandwidth,
		maxCapacity: clientParameters?.maxBandwidth,
	}
}
