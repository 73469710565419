import { EventType } from './EventTypesEnum'

export class Event {
	readonly type: EventType
	readonly data: Record<string, Object>

	public constructor(type: EventType, data: Record<string, Object>) {
		this.type = type
		this.data = data
	}
}
