import { binnacleMapper } from 'modules/contractedService/application/management/tracking/mapper/PetitionTrackingBinnacleMapper'
import {
	PetitionTracking,
	petitionTrackingFromResponse,
} from 'modules/contractedService/domain/management/tracking/PetitionTracking'
import { PetitionTrackingRepository } from 'modules/contractedService/domain/management/tracking/repository/PetitionTrackingRepository'
import { useQuery } from 'react-query'
import { PetitionTrackingFindRequest } from './dto/PetitionTrackingFindRequest'

export const usePetitionTrackingFindQuery = (
	petitionTrackingRepository: PetitionTrackingRepository,
	petitionTrackingFindRequest?: PetitionTrackingFindRequest,
	{
		enabled = true,
		onSuccess,
		onError,
	}: {
		enabled?: boolean
		onSuccess?: (petitionTrackings: PetitionTracking[]) => void
		onError?: (error: unknown) => void
	} = {}
) => {
	return useQuery(
		['petition-tracking-find-query', petitionTrackingFindRequest],
		() =>
			petitionTrackingFindRequest
				? petitionTrackingRepository.find(petitionTrackingFindRequest, { notifyError: false })
				: petitionTrackingRepository.findAll({ notifyError: false }),
		{
			staleTime: 0,
			enabled,
			select: (trackingsResponse) => trackingsResponse.map(petitionTrackingFromResponse),
			onSuccess,
			onError,
		}
	)
}

export const usePetitionTrackingFindBinnacleQuery = (
	petitionTrackingRepository: PetitionTrackingRepository,
	serviceId: string,
	{
		enabled = true,
		onError,
	}: {
		enabled?: boolean
		onError?: (error: unknown) => void
	}
) => {
	return useQuery(
		`petition-tracking-find-binnacle-events-of-${serviceId}`,
		() => petitionTrackingRepository.findBinnacleEvents(serviceId),
		{
			enabled,
			onError,
			select: (binnacleResponse) => binnacleMapper(binnacleResponse),
		}
	)
}
