import { ContractedServiceWithDetails } from 'modules/contractedService/domain/ContractedServiceWithDetails'
import { ContractedServiceOperation } from 'modules/contractedService/domain/management/operation/ContractedServiceOperation'
import { Offer } from 'modules/offer/domain/Offer'
import { FC } from 'react'
import { TermsAndConditionsConfirm } from './confirm/TermsAndConditionsConfirm'
import { TermsAndConditionsContent } from './content/TermsAndConditionsContent'

type Props = {
	offer?: Offer
	service: ContractedServiceWithDetails
	contractedServiceOperation: ContractedServiceOperation

	onCancel: () => void
	onConfirm: () => void
}

const TermsAndConditions: FC<Props> = ({ offer, service, contractedServiceOperation, onCancel, onConfirm }) => {
	return (
		<div className="d-flex flex-column gap-5">
			<TermsAndConditionsContent
				offer={offer}
				contractedServiceWithDetails={service}
				contractedServiceOperation={contractedServiceOperation}
			/>
			<TermsAndConditionsConfirm
				onCancel={onCancel}
				onConfirm={onConfirm}
			/>
		</div>
	)
}
export { TermsAndConditions }
