import { useModalButtonsText } from 'components/services/management/modal/wizard/common/hooks/useModalButtonsText'
import { defaultSwalConfig } from 'components/services/management/modal/wizard/common/modals/defaultSwalConfig'
import { PetitionTrackingCancelRequest } from 'modules/contractedService/application/management/tracking/cancel/dto/PetitionTrackingCancelRequest'
import Swal from 'sweetalert2'
import { useTranslator } from 'ufinet-web-functions'

type HookInput = {
	cancelTrackingsFn: (petitionTrackingCancelRequest: PetitionTrackingCancelRequest) => void
}

type HookOutput = {
	fireBeforeCancelTrackingModal: (petitionTrackingCancelRequest: PetitionTrackingCancelRequest) => void
}

function useBeforeCancelTrackingModal({ cancelTrackingsFn }: HookInput): HookOutput {
	const translate = useTranslator()
	const modalButtonsText = useModalButtonsText()

	const fireBeforeCancelTrackingModal = (petitionTrackingCancelRequest: PetitionTrackingCancelRequest) => {
		Swal.fire({
			title: translate('PETITION.TRACKING.CANCEL.MODAL.TITLE', {
				operationId: petitionTrackingCancelRequest.ids[0] || '',
			}),
			text: translate('PETITION.TRACKING.CANCEL.MODAL.CONTENT'),
			...defaultSwalConfig(modalButtonsText),
			icon: 'warning',
			reverseButtons: true,
			preConfirm: () => cancelTrackingsFn(petitionTrackingCancelRequest),
		})
	}

	return {
		fireBeforeCancelTrackingModal,
	}
}

export { useBeforeCancelTrackingModal }
