import { ContractType } from 'modules/contractedService/domain/management/operation/ContractType'
import { ModPricing } from 'modules/contractedService/domain/pricing/ModPricing'
import { ContractedServicePricingCalculatorRepository } from 'modules/contractedService/domain/pricing/repository/ContractedServicePricingCalculatorRepository'
import { ImmediatePricingRequest } from './dto/ImmediatePricingRequest'
import { validatePricingRequest } from './dto/PricingRequest'
import { ScheduledPricingRequest } from './dto/ScheduledPricingRequest'
import { TemporaryPricingRequest } from './dto/TemporaryPricingRequest'

export const contractedServiceCalculator = (repository: ContractedServicePricingCalculatorRepository) => ({
	getImmediateOperationPricing: (request: ImmediatePricingRequest): Promise<ModPricing> => {
		validatePricingRequest(request, ContractType.IMMEDIATE)
		return repository
			.getImmediateOperationPricing(request)
			.then((calculation) => ({ ...calculation, nrcAdjustment: undefined, mrcAdjustment: undefined }))
	},
	getScheduledOperationPricing: (request: ScheduledPricingRequest): Promise<ModPricing> => {
		validatePricingRequest(request, ContractType.SCHEDULED)
		return repository
			.getScheduledOperationPricing(request)
			.then((calculation) => ({ ...calculation, nrcAdjustment: undefined, mrcAdjustment: undefined }))
	},
	getTemporaryOperationPricing: (request: TemporaryPricingRequest): Promise<ModPricing> => {
		validatePricingRequest(request, ContractType.TEMPORAL)
		return repository
			.getTemporaryOperationPricing(request)
			.then((calculation) => ({ ...calculation, nrcAdjustment: undefined, mrcAdjustment: undefined }))
	},
})
