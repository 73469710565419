import { PetitionTrackingCancelRequest } from 'modules/contractedService/application/management/tracking/cancel/dto/PetitionTrackingCancelRequest'
import { PetitionTrackingRepository } from 'modules/contractedService/domain/management/tracking/repository/PetitionTrackingRepository'
import { useMutation } from 'react-query'
import { ResponseError } from 'ufinet-web-functions'

export const usePetitionTrackingCancelQuery = (
	petitionTrackingRepository: PetitionTrackingRepository,
	{
		onSuccess,
		onError,
	}: {
		onSuccess?: (deletedIds: string[]) => void
		onError?: (error: ResponseError) => void
	} = {}
) => {
	return useMutation({
		mutationKey: 'petition-tracking-cancel-query',
		mutationFn: async (petitionTrackingCancelRequest: PetitionTrackingCancelRequest) => {
			await petitionTrackingRepository.cancel(petitionTrackingCancelRequest, { notifyError: false })
			return petitionTrackingCancelRequest.ids
		},
		onSuccess,
		onError,
	})
}
