import Swal from 'sweetalert2'
import { ModalButtonsText, defaultSwalConfig } from '../../../common/modals/defaultSwalConfig'

const fireModalBeforeCloseExternalUser = (text: string, buttonsText: ModalButtonsText, onConfirm: () => void) => {
	Swal.fire({
		text,
		...defaultSwalConfig(buttonsText),
		preConfirm: () => {
			onConfirm()
		},
	})
}

const fireModalBeforeCloseInternalUser = (text: string, buttonsText: ModalButtonsText, onConfirm: () => void) => {
	Swal.fire({
		text,
		...defaultSwalConfig(buttonsText),
		icon: 'warning',
		reverseButtons: true,
		preConfirm: () => {
			onConfirm()
		},
	})
}

export { fireModalBeforeCloseExternalUser, fireModalBeforeCloseInternalUser }
