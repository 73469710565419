import { ColData } from 'ufinet-web-components'
import {
	formatCellNumber,
	noContentPlaceholder,
	tableDeadlineFormattingSettings,
	TranslatorFunction,
} from 'ufinet-web-functions'
import { ClientParameters } from './ClientParametersPage'

export const mkColsClientParams = (translate: TranslatorFunction): ColData[] => [
	{
		field: 'displayClientName',
		header: 'CLIENT.PARAMETERS.CLIENT.NAME',
		nonFilterable: false,
		nonSearchable: false,
		editable: true,
	},
	{
		field: 'displayClientType',
		header: 'CLIENT.PARAMETERS.CLIENT.TYPE',
		nonFilterable: false,
		nonSearchable: false,
		body: (row: ClientParameters) => row.displayClientType || noContentPlaceholder,
	},
	{
		field: 'currentDeadline',
		header: 'CLIENT.PARAMETERS.DEADLINE',
		nonFilterable: true,
		nonSearchable: true,
		body: (row: ClientParameters) => (
			<div className="ufinet-table-cell">{formatCellNumber(row.currentDeadline, tableDeadlineFormattingSettings)}</div>
		),
	},
	{
		field: 'keepDeadline',
		header: 'CLIENT.PARAMETERS.KEEP_DEADLINE',
		nonFilterable: true,
		nonSearchable: true,
		body: (row: ClientParameters) => translate(row.keepDeadline ? 'YES' : 'NO'),
	},
	{
		field: 'immediate',
		header: 'CLIENT.PARAMETERS.IMMEDIATE',
		nonFilterable: true,
		nonSearchable: true,
		body: (row: ClientParameters) => translate(row.immediate ? 'YES' : 'NO'),
	},
	{
		field: 'programmed',
		header: 'CLIENT.PARAMETERS.SCHEDULED',
		nonFilterable: true,
		nonSearchable: true,
		body: (row: ClientParameters) => translate(row.programmed ? 'YES' : 'NO'),
	},
	{
		field: 'temporary',
		header: 'CLIENT.PARAMETERS.TEMPORAL',
		nonFilterable: true,
		nonSearchable: true,
		body: (row: ClientParameters) => translate(row.temporary ? 'YES' : 'NO'),
	},
	{
		field: 'hiringDeadline',
		header: 'CLIENT.PARAMETERS.HIRING_DEADLINE',
		nonFilterable: true,
		nonSearchable: true,
		body: (row: ClientParameters) => (
			<div className="ufinet-table-cell">
				{row.hiringDeadlines.map((dl) => formatCellNumber(dl.value, tableDeadlineFormattingSettings)).join(', ')}
			</div>
		),
	},
	{
		field: 'minBandwidth',
		header: 'CLIENT.PARAMETERS.MINIMUM_BANDWIDTH',
		nonFilterable: true,
		nonSearchable: true,
	},
	{
		field: 'maxBandwidth',
		header: 'CLIENT.PARAMETERS.MAXIMUM_BANDWIDTH',
		nonFilterable: true,
		nonSearchable: true,
	},
]
