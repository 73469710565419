import { ContractedServiceOperation } from 'modules/contractedService/domain/management/operation/ContractedServiceOperation'
import { ContractType } from 'modules/contractedService/domain/management/operation/ContractType'
import { ManagementOperation } from 'modules/contractedService/domain/management/operation/ManagementOperation'
import { ContractedServiceOperationTemporal } from 'modules/contractedService/domain/management/operation/temporary/ContractedServiceOperationTemporary'
import { getNrcAdjustmentForOffer } from 'modules/offer/application/create/getNrcAdjustmentForOffer'

export type OfferCreateRequest = {
	contractType: ContractType
	contractedServiceId: string
	bandwidth: string
	deadline: number
	nrc: number
	mrc: number
	nrcAdjustment?: number
	mrcAdjustment?: number
	currency: string
	contactId?: string
	keepDeadline: boolean
	period?: number
	operations: ManagementOperation[]
}

export const offerCreateRequestFromOperation = (
	contractedServiceOperation: ContractedServiceOperation
): OfferCreateRequest => {
	const period =
		contractedServiceOperation.contractType === ContractType.TEMPORAL
			? (contractedServiceOperation as ContractedServiceOperationTemporal).period
			: undefined

	return {
		contractType: contractedServiceOperation.contractType,
		contractedServiceId: contractedServiceOperation.service.id,
		bandwidth: contractedServiceOperation.bandwidth.id,
		deadline: contractedServiceOperation.deadline,
		nrc: contractedServiceOperation.nrc,
		mrc: contractedServiceOperation.mrc,
		nrcAdjustment: getNrcAdjustmentForOffer(contractedServiceOperation),
		mrcAdjustment: contractedServiceOperation.mrcAdjustment,
		currency: contractedServiceOperation.currency.id,
		contactId: contractedServiceOperation.contact?.id,
		keepDeadline: contractedServiceOperation.keepDeadline,
		period,
		operations: contractedServiceOperation.operations,
	}
}
