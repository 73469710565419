import { FormikProps } from 'formik'
import { useOrsReasonFindAllQuery } from 'modules/contractedService/application/ors/petition/find/OrsReasonFindQueries'
import { OrsPetition } from 'modules/contractedService/domain/ors/petition/base/OrsPetition'
import { OrsReason } from 'modules/contractedService/domain/ors/petition/base/OrsReason'
import { ContractedServiceOrsIndividualData } from 'modules/contractedService/domain/ors/petition/individual/ContractedServiceOrsIndividual'
import { ContractedServiceOrsUnifiedData } from 'modules/contractedService/domain/ors/petition/unified/ContractedServiceOrsUnified'
import { ContractedServiceOrsRepository } from 'modules/contractedService/domain/repository/ContractedServiceOrsRepository'
import { useCallback, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { useTranslator } from 'ufinet-web-functions'

type HookInput = {
	form: FormikProps<ContractedServiceOrsIndividualData | ContractedServiceOrsUnifiedData>
	servicesValidation?: string
	serviceOrsRepository: ContractedServiceOrsRepository
	selectedPetition: OrsPetition
}

type HookOutput = {
	clearErrors: () => void
	customError?: string
	validateForm: () => void
	reasonOptions?: OrsReason[]
}

function useOrsBaseForm({ form, servicesValidation, serviceOrsRepository, selectedPetition }: HookInput): HookOutput {
	const [customError, setCustomError] = useState<string>()
	const translate = useTranslator()

	const { data: reasonOptionsResponse } = useOrsReasonFindAllQuery(
		serviceOrsRepository,
		{ petitionType: selectedPetition },
		{
			onError: () => toast.error(translate('CONTRACT.SERVICE.ORS.REASON.RESPONSE.ERROR')),
		}
	)

	const clearErrors = useCallback(() => {
		form.setErrors({})
		setCustomError?.(undefined)
	}, [form, setCustomError])

	const validateForm = useCallback(() => {
		setCustomError(servicesValidation)
		form
			.validateForm()
			.then((errors) => {
				if (errors && typeof errors === 'object' && Object.keys(errors).length === 0 && !servicesValidation) {
					form.submitForm()
				}
				return errors
			})
			.catch(() => toast.error(translate('CONTRACT.SERVICE.ORS.VALIDATION.ERROR')))
	}, [form, servicesValidation, translate, setCustomError])

	const reasonOptions = useMemo(
		() =>
			reasonOptionsResponse?.map((reason) => {
				return {
					code: reason.id.toString(),
					name: reason.name,
				} as unknown as OrsReason
			}),
		[reasonOptionsResponse]
	)

	return {
		clearErrors,
		customError,
		validateForm,
		reasonOptions,
	}
}

export { useOrsBaseForm }
