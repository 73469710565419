import { PetitionTrackingTable } from 'components/services/management/tracking/table/PetitionTrackingTable'
import { HttpPetitionTrackingRepository } from 'modules/contractedService/infrastructure/management/tracking/HttpPetitionTrackingRepository'
import { FC, useContext, useMemo } from 'react'
import { UfinetBox } from 'ufinet-web-components'
import { AuthContext } from 'ufinet-web-functions'

const PetitionTrackingPage: FC<{}> = () => {
	const authData = useContext(AuthContext)

	const petitionTrackingRepository = useMemo(() => HttpPetitionTrackingRepository(authData), [authData])

	return (
		<UfinetBox>
			<PetitionTrackingTable petitionTrackingRepository={petitionTrackingRepository} />
		</UfinetBox>
	)
}
export { PetitionTrackingPage }
