import { ContractedService } from '../../ContractedService'
import { ContractedServiceState } from '../../state/ContractedServiceState'
import { OrsEntryType } from '../petition/base/OrsEntryType'
import { OrsPetition } from '../petition/base/OrsPetition'
import { OrsOperationType } from '../petition/base/OrsReactivationType'
import { OrsStrategy } from './OrsStrategy'

export class OrsStrategyUnsubscribe implements OrsStrategy {
	public showForm(selectedPetition: OrsPetition, selectedEntry: OrsEntryType, _selectedOperations: OrsOperationType[]) {
		return selectedPetition !== undefined && selectedEntry !== undefined
	}

	public calculateIndividualServiceMinimumDate(
		_selectedOperations: OrsOperationType[],
		_serviceId: string,
		_services: ContractedService[]
	) {
		return new Date()
	}

	public calculateUnifiedServicesMinimumDate(_selectedOperations: OrsOperationType[], _services: ContractedService[]) {
		return new Date()
	}

	public isSelectedServiceInvalid(service: ContractedService, _selectedOperations: OrsOperationType[]) {
		return ![ContractedServiceState.EN_SERVICIO].includes(service.state?.value as ContractedServiceState)
	}

	public enableOperations = false

	public enableReason = true

	public enableCompletionDate = true

	public isObservationRequired = true

	public defaultOperations = []

	public observationsLabel = 'CONTRACT.SERVICE.ORS.JUSTIFICATION'
}
