import { useQuery } from 'react-query'
import { ClientParameters } from '../../domain/ClientParameters'
import { ClientParametersRepository } from '../../domain/repository/clientParametersRepository'
import { ClientParametersFindRequest } from './dto/ClientParametersFindRequest'
import { ClientParametersFindResponse } from './dto/ClientParametersFindResponse'

export const useClientParametersFindAllQuery = (
	clientParametersRepository: ClientParametersRepository,
	{
		enabled = true,
		onSuccess,
		onError,
	}: {
		enabled?: boolean
		onSuccess?: (clientParameters: ClientParameters[]) => void
		onError?: (error: unknown) => void
	} = {}
) => {
	return useQuery('client-parameters-find-all-query', () => clientParametersRepository.findAll(), {
		staleTime: 0,
		enabled,
		onSuccess,
		onError,
	})
}

export const useClientParametersFindFilteredQuery = (
	clientParametersRepository: ClientParametersRepository,
	clientParametersFindRequest: ClientParametersFindRequest,
	{
		enabled = true,
		onSuccess,
		onError,
	}: {
		enabled?: boolean
		onSuccess?: (clientParameters: ClientParameters) => void
		onError?: (error: unknown) => void
	} = {}
) => {
	return useQuery(
		['client-parameters-find-filtered-query', clientParametersFindRequest],
		() => clientParametersRepository.find(clientParametersFindRequest, { notifyError: false }),
		{
			staleTime: 0,
			enabled,
			onSuccess,
			onError,
		}
	)
}

export const useClientParametersFindByIdQuery = (
	clientParametersRepository: ClientParametersRepository,
	id: number,
	{
		enabled = true,
		onSuccess,
		onError,
	}: {
		enabled?: boolean
		onSuccess?: (clientParametersFindResponse: ClientParametersFindResponse) => void
		onError?: (error: unknown) => void
	} = {}
) => {
	return useQuery(['client-parameters-find-by-id-query', id], () => clientParametersRepository.findOne(id), {
		enabled,
		onSuccess,
		onError,
	})
}
