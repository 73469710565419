import { ContractedServiceDetailFindRequest } from 'modules/contractedService/application/find/detail/dto/ContractedServiceDetailFindRequest'
import { ContractedServiceDetailFindResponse } from 'modules/contractedService/application/find/detail/dto/ContractedServiceDetailFindResponse'
import { ContractedServiceDetailRepository } from 'modules/contractedService/domain/repository/ContractedServiceDetailRepository'
import { FetchAPI, FetchOptions, IAuthData, defaultOptionsFetch } from 'ufinet-web-functions'

const url = `${process.env.REACT_APP_URL_BACK_GSE}api/services/contracted/detail`

export const HttpContractedServiceDetailRepository = (authData: IAuthData): ContractedServiceDetailRepository => {
	const fetchOptions: FetchOptions = {
		...defaultOptionsFetch,
		authenticationData: authData,
		sendApiKey: true,
	}

	return {
		getServiceDetail: async (
			requestData: ContractedServiceDetailFindRequest,
			options?: FetchOptions
		): Promise<ContractedServiceDetailFindResponse> =>
			await FetchAPI.get(`${url}/${encodeURIComponent(requestData.serviceId)}`, {
				...fetchOptions,
				...options,
			}),
	}
}
