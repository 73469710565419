import { FormikProps, useFormik } from 'formik'
import { ContractType } from 'modules/contractedService/domain/management/operation/ContractType'
import { useTranslator } from 'ufinet-web-functions'
import * as Yup from 'yup'

export type ContractedServiceManageFormParameters = Partial<{ contractType: ContractType }>
type FormSchema = {
	[key in keyof ContractedServiceManageFormParameters]: Yup.SchemaOf<any>
}

const useManageInnerFormValidation = (): {
	formik: FormikProps<ContractedServiceManageFormParameters>
	initialValues: ContractedServiceManageFormParameters
	validationSchema: Yup.ObjectSchema<any>
} => {
	const translate = useTranslator()

	const initialValues: ContractedServiceManageFormParameters = {}

	const validationSchema = Yup.object().shape<FormSchema>(
		{
			contractType: Yup.string().oneOf(
				[ContractType.IMMEDIATE, ContractType.SCHEDULED, ContractType.TEMPORAL],
				translate('ERROR.REQUIRED')
			),
		},
		[]
	)

	const formik = useFormik<ContractedServiceManageFormParameters>({
		initialValues,
		validationSchema,
		onSubmit: () => {},
		validateOnChange: false,
		validateOnBlur: false,
		enableReinitialize: true,
		onReset: (_values, form) => {
			form.setValues(initialValues)
		},
	})

	return { formik, initialValues, validationSchema }
}

export { useManageInnerFormValidation }
