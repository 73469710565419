import { OrsUnifiedTable } from 'components/services/ors/modal/forms/unified/OrsUnifiedTable'
import { useOrsUnifiedForm } from 'components/services/ors/modal/forms/unified/hooks/useOrsUnifiedForm'
import { ContractedService } from 'modules/contractedService/domain/ContractedService'
import { OrsEntryType } from 'modules/contractedService/domain/ors/petition/base/OrsEntryType'
import { OrsPetition } from 'modules/contractedService/domain/ors/petition/base/OrsPetition'
import { OrsOperationType } from 'modules/contractedService/domain/ors/petition/base/OrsReactivationType'
import { OrsStrategy } from 'modules/contractedService/domain/ors/strategies/OrsStrategy'
import { ContractedServiceOrsRepository } from 'modules/contractedService/domain/repository/ContractedServiceOrsRepository'
import { FC } from 'react'
import { UfinetButton } from 'ufinet-web-components'
import { useTranslator } from 'ufinet-web-functions'

type Props = {
	selectedEntry: OrsEntryType
	selectedOperation: OrsOperationType[]
	selectedPetition: OrsPetition
	services: ContractedService[]
	onServiceRemove: (id: string) => void
	onSend: () => void
	serviceOrsRepository: ContractedServiceOrsRepository
	orsStrategy: OrsStrategy
}

const ContractedServiceOrsUnifiedForm: FC<Props> = ({
	services,
	selectedEntry,
	selectedPetition,
	selectedOperation,
	onServiceRemove,
	onSend,
	serviceOrsRepository,
	orsStrategy,
}) => {
	const { onChange, onValidate, reasonOptions, form, validateForm, calculateMinimumDate } = useOrsUnifiedForm({
		selectedEntry,
		selectedPetition,
		selectedOperation,
		services,
		onServiceRemove,
		onSend: onSend,
		serviceOrsRepository,
		orsStrategy,
	})

	const translate = useTranslator()

	return (
		<form
			onSubmit={(e) => e.preventDefault()}
			className="position-relative d-flex flex-column gap-3"
		>
			<OrsUnifiedTable
				onChange={onChange}
				selectedPetition={selectedPetition}
				reasonOptions={reasonOptions}
				form={form}
				calculateMinimumDate={calculateMinimumDate}
				orsStrategy={orsStrategy}
			/>
			<div className="row justify-content-end">
				<div className="col">
					{onValidate.customError && <div className="col mt-5 text-danger">{onValidate.customError}</div>}
				</div>
				<UfinetButton
					className="mt-5 ms-3 p-5 w-auto"
					content={translate('CONTRACT.SERVICE.CREATE_ORS.BUTTON.CREATE_PETITION')}
					onClick={() => validateForm()}
				/>
			</div>
			<input
				className="d-none"
				type="submit"
			/>
		</form>
	)
}

export { ContractedServiceOrsUnifiedForm }
