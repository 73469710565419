import { useBaseManageFormValidation } from 'components/services/management/modal/wizard/modify-service/manage/inner/base/hooks/validation/useBaseManageFormValidation'
import {
	ManageFormValidationCreator,
	ManageFormValidationInput,
} from 'components/services/management/modal/wizard/modify-service/manage/inner/base/manageFormValidationCreator'
import dayjs from 'dayjs'
import { useFormik } from 'formik'
import {
	ContractedServiceOperationDataTemporal,
	ContractedServiceOperationTemporal,
} from 'modules/contractedService/domain/management/operation/temporary/ContractedServiceOperationTemporary'

import { useTranslator } from 'ufinet-web-functions'
import * as Yup from 'yup'

export type TemporalManageFormParameters = Partial<ContractedServiceOperationDataTemporal>
type TemporaryManageFormSchema = {
	[key in keyof TemporalManageFormParameters]: Yup.SchemaOf<any>
}

export type TemporalManageFormValidationInput = ManageFormValidationInput<ContractedServiceOperationDataTemporal> & {
	minStartDate: dayjs.Dayjs
}

const useTemporaryManageFormValidation: ManageFormValidationCreator<
	ContractedServiceOperationDataTemporal,
	TemporalManageFormValidationInput
> = (input: TemporalManageFormValidationInput) => {
	const translate = useTranslator()

	const {
		formik: baseFormik,
		initialValues: baseInitialValues,
		validationSchema: baseValidationSchema,
		loadingControls,
	} = useBaseManageFormValidation()

	const { minStartDate } = input

	const initialValues: Partial<ContractedServiceOperationDataTemporal> = { ...baseInitialValues }

	const validationSchema = baseValidationSchema.shape<TemporaryManageFormSchema>({
		modifyAt: Yup.date()
			.required(translate('ERROR.REQUIRED'))
			.min(minStartDate.toDate(), translate('FORM.ERROR.AWARD_DATE.MIN.TODAY')),
		period: Yup.number()
			.required(translate('ERROR.REQUIRED'))
			.oneOf(ContractedServiceOperationTemporal.rollbackAfterDays, translate('ERROR.INVALID')),
	})

	const formik = useFormik<TemporalManageFormParameters>({
		...baseFormik,
		initialValues,
		validationSchema,
		onSubmit: baseFormik.submitForm,
	})

	return { formik, initialValues, validationSchema, loadingControls }
}

export { useTemporaryManageFormValidation }
