import { RowContainerWizard } from 'components/services/management/modal/wizard/common/RowContainerWizard'
import { useConfirmResumeToHire } from 'components/services/management/modal/wizard/modify-service/confirm-resume/parts/to-hire/hooks/useConfirmResumeToHire'
import { ContractedServiceOperation } from 'modules/contractedService/domain/management/operation/ContractedServiceOperation'
import { Offer } from 'modules/offer/domain/Offer'
import { ContainerWizard } from '../../../../common/ContainerWizard'
import { ConfirmResumeTitle } from '../common/ConfirmResumeTitle'

type Props<T extends ContractedServiceOperation> = {
	offer?: Offer
	contractedServiceOperation: T
}

const ConfirmResumeToHire = <T extends ContractedServiceOperation>({ offer, contractedServiceOperation }: Props<T>) => {
	const { creationDate, awardDate, operationDeadline, operationDuration, displayNrc } = useConfirmResumeToHire({
		offer,
		contractedServiceOperation,
	})

	return (
		<ContainerWizard>
			<ConfirmResumeTitle title="CONTRACT.SERVICE.MANAGEMENT.SERVICE_TO_HIRE" />
			<>
				<RowContainerWizard
					title="CONTRACT.SERVICE.MANAGEMENT.BANDWIDTH"
					content={contractedServiceOperation.bandwidth.label}
				/>
				<RowContainerWizard
					title="CONTRACT.SERVICE.MANAGEMENT.DEADLINE"
					content={operationDeadline}
				/>
				<RowContainerWizard
					title="CONTRACT.SERVICE.MANAGEMENT.PUNCTUAL_PAYMENT"
					content={displayNrc}
				/>
				<RowContainerWizard
					title="CONTRACT.SERVICE.MANAGEMENT.MONTHLY_PAYMENT"
					content={(contractedServiceOperation.mrcAdjustment ?? contractedServiceOperation.mrc).toString()}
				/>
				<RowContainerWizard
					title="CONTRACT.SERVICE.MANAGEMENT.CURRENCY"
					content={contractedServiceOperation.currency.name}
				/>
				<RowContainerWizard
					title="CONTRACT.SERVICE.MANAGEMENT.APPLICATIONDATE"
					content={creationDate}
				/>
				{awardDate && (
					<RowContainerWizard
						title="CONTRACT.SERVICE.MANAGEMENT.AWARDDATE"
						content={awardDate}
					/>
				)}
				{operationDuration && (
					<RowContainerWizard
						title="CONTRACT.SERVICE.MANAGEMENT.DURATION"
						content={operationDuration}
					/>
				)}
			</>
		</ContainerWizard>
	)
}
export { ConfirmResumeToHire }
