import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { PetitionTrackingRepository } from 'modules/contractedService/domain/management/tracking/repository/PetitionTrackingRepository'
import { FC } from 'react'
import { Filter, Table, UfinetSectionBox } from 'ufinet-web-components'
import { useInternalUser, useTranslator } from 'ufinet-web-functions'
import { usePetitionTrackingTable } from './hooks/usePetitionTrackingTable'

type Props = {
	petitionTrackingRepository: PetitionTrackingRepository
}

const PetitionTrackingTable: FC<Props> = ({ petitionTrackingRepository }) => {
	const translate = useTranslator()
	const internalUser = useInternalUser()

	const { petitionTrackings, filter, table } = usePetitionTrackingTable({ petitionTrackingRepository })

	return (
		<>
			<UfinetSectionBox className="mb-5">
				<div className="row justify-content-between align-items-end mb-5">
					<Filter
						internalUser={internalUser}
						afterCorporateGroupChange={filter.onChange.corporateGroups}
						afterClientChange={filter.onChange.clients}
						disabled={filter.disabled}
						hidden={{
							hideCountry: true,
							hideContact: true,
							hideReference: true,
							hideSubmit: false,
						}}
						multi={{ multiClient: true, multiCorporateGroup: true }}
						required={false}
						preSelected={false}
						setFilter={filter.onSubmit}
						icon={faSearch}
						submitButtonContent={translate('SEARCH')}
					/>
				</div>
			</UfinetSectionBox>

			<UfinetSectionBox>
				<Table
					cols={table.columns}
					content={petitionTrackings}
					emptyMessage={table.loading ? translate('LOADING_DOTS') : undefined}
					headerButtons={table.header.buttons}
					loading={table.loading}
				/>
			</UfinetSectionBox>
		</>
	)
}
export { PetitionTrackingTable }
