import { ServiceManagementErrorHandler } from 'modules/contractedService/domain/ServiceManagementErrorHandler'
import { toast } from 'react-toastify'
import {
	BandwidthCombinationNotFoundError,
	UfinetErrorCode,
	useInternalUser,
	useTranslator,
} from 'ufinet-web-functions'

type HookInput = {
	onUnknownErrorHandler?: ServiceManagementErrorHandler
}

type HookOutput = {
	bandwidthFindErrorHandler: (error: unknown) => void
}

function useBandwidthFindErrorHandler({ onUnknownErrorHandler }: HookInput): HookOutput {
	const translate = useTranslator()
	const internalUser = useInternalUser()

	const mkToastContentFromErrorDto = ({
		originCountryName,
		destinationCountryName,
		productTypeName,
		currencyName,
	}: BandwidthCombinationNotFoundError) => (
		<div>
			<p className="mb-1">{translate('BANDWIDTH.COMBINATION.NO.COINCIDENCES.INTERNAL.USER')}:</p>
			<ul className="mb-0">
				{originCountryName && (
					<li>
						{translate('COUNTRY.ORIGIN')}: {originCountryName}
					</li>
				)}
				{destinationCountryName && (
					<li>
						{translate('COUNTRY.DESTINATION')}: {destinationCountryName}
					</li>
				)}
				{currencyName && (
					<li>
						{translate('OFFER.CURRENCY')}: {currencyName}
					</li>
				)}
				{productTypeName && (
					<li>
						{translate('PRODUCT.TYPE')}: {productTypeName}
					</li>
				)}
			</ul>
		</div>
	)

	const bandwidthFindErrorHandler = (error: unknown) => {
		if (
			(error as BandwidthCombinationNotFoundError)?.ufinetErrorCode === UfinetErrorCode.BANDWIDTH_COMBINATION_NOT_FOUND
		) {
			if (!internalUser) {
				toast.error(translate('BANDWIDTH.COMBINATION.NO.COINCIDENCES.EXTERNAL.USER'))
				return
			}
			toast.error(mkToastContentFromErrorDto(error as BandwidthCombinationNotFoundError))
		} else onUnknownErrorHandler?.(error)
	}

	return {
		bandwidthFindErrorHandler,
	}
}

export { useBandwidthFindErrorHandler }
