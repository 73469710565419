import { clsx } from 'clsx'
import { useContractedServiceVersionsColsData } from 'components/services/version/modal/hooks/useContractedServiceVersionsColsData'
import { useContractedServiceVersionsModal } from 'components/services/version/modal/hooks/useContractedServiceVersionsModal'
import { ContractedService } from 'modules/contractedService/domain/ContractedService'
import { ContractedServiceRepository } from 'modules/contractedService/domain/repository/ContractedServiceRepository'
import { FC, useMemo, useState } from 'react'
import { Loading, Table, UfinetButton, UfinetModal } from 'ufinet-web-components'
import { TranslatorFunction, useTranslator } from 'ufinet-web-functions'

type Props = {
	translate: TranslatorFunction
	modalShown: boolean
	modalCloseFn: () => void
	serviceSelected: ContractedService
	serviceVersions: {
		data: ContractedService[]
		loading: boolean
		onVersionSelected?: (contractedService: ContractedService) => void
	}
}

const ContractedServiceVersionsModal: FC<Props> = ({
	translate,
	modalShown,
	modalCloseFn,
	serviceSelected,
	serviceVersions,
}) => {
	const { columns } = useContractedServiceVersionsColsData({ translate })
	const [selectedServiceVersion, setSelectedServiceVersion] = useState<ContractedService>()

	const modalTitle = useMemo(() => {
		const serviceTypeName = serviceSelected.typeOfService
			? translate(`CONTRACT.SERVICE.TYPE.${serviceSelected.typeOfService.value}`)
			: undefined

		return `${translate('CONTRACT.SERVICE.VERSIONS.MODAL.TITLE', {
			serviceCode: serviceSelected.administrativeCode,
		})}${serviceTypeName ? ` (${serviceTypeName})` : ''}`
	}, [serviceSelected.administrativeCode, serviceSelected.typeOfService, translate])

	return (
		<UfinetModal
			size="xl"
			show={modalShown}
			handleClose={modalCloseFn}
			showCloseButton
			title={modalTitle}
		>
			<div
				className={clsx(
					'position-relative d-flex flex-column justify-content-center p-0 m-0 gap-5',
					serviceVersions.loading && 'form-disabled'
				)}
			>
				<div className="row">
					<Table
						content={serviceVersions.data}
						cols={columns}
						selectedValues={selectedServiceVersion ? [selectedServiceVersion] : []}
						onSelectionChange={(newSelectedServices) =>
							setSelectedServiceVersion(
								newSelectedServices.length > 0 ? newSelectedServices[newSelectedServices.length - 1] : undefined
							)
						}
						emptyMessage={serviceVersions.loading ? translate('LOADING_DOTS') : undefined}
						showSelectAll={false}
						showPaginator={false}
						enableSearchFilter={false}
					/>
				</div>
				<div className="row justify-content-end">
					<div className="col col-12 col-lg-3">
						<UfinetButton
							className="w-100"
							content={translate('CONTRACT.SERVICE.CREATE_ORS.BUTTON.CREATE_PETITION')}
							isDisabled={selectedServiceVersion === undefined}
							onClick={() => selectedServiceVersion && serviceVersions.onVersionSelected?.(selectedServiceVersion)}
						/>
					</div>
				</div>
				{serviceVersions.loading && <Loading />}
			</div>
		</UfinetModal>
	)
}

type PropsHOC = {
	serviceSelected: ContractedService
	serviceRepository: ContractedServiceRepository
	onServiceVersionSelected: (service: ContractedService) => void
	modalShown: boolean
	modalCloseFn: () => void
}

const ContractedServiceVersionsModalHOC: FC<PropsHOC> = ({
	serviceSelected,
	serviceRepository,
	onServiceVersionSelected,
	modalShown,
	modalCloseFn,
}) => {
	const translate = useTranslator()

	const { serviceVersions } = useContractedServiceVersionsModal({
		serviceSelected,
		serviceRepository,
		onServiceVersionSelected,
		modalShown,
		modalCloseFn,
	})

	if (!modalShown) return null
	return (
		<ContractedServiceVersionsModal
			translate={translate}
			modalShown={modalShown}
			modalCloseFn={modalCloseFn}
			serviceSelected={serviceSelected}
			serviceVersions={serviceVersions}
		/>
	)
}

export { ContractedServiceVersionsModalHOC, ContractedServiceVersionsModal }
