import { FC, useState } from 'react'
import { UfinetButton, UfinetCheckbox } from 'ufinet-web-components'
import { useTranslator } from 'ufinet-web-functions'
import { useModalButtonsText } from '../../../../../common/hooks/useModalButtonsText'

type Props = {
	onCancel: () => void
	onConfirm: () => void
}

const TermsAndConditionsConfirm: FC<Props> = ({ onCancel, onConfirm }) => {
	const translate = useTranslator()

	const modalButtonsText = useModalButtonsText()
	const [acceptedTerms, setAcceptedTerms] = useState<boolean>(false)

	return (
		<div className="confirm d-flex flex-column">
			<div className="d-flex flex-row justify-content-center align-items-center">
				<UfinetCheckbox
					title={translate('CONTRACT.SERVICE.MANAGEMENT.ACCEPTANCE_OF_TERMS_AND_CONDITIONS')}
					checked={acceptedTerms}
					onChange={() => setAcceptedTerms(!acceptedTerms)}
					className="me-2"
				/>
			</div>
			{!acceptedTerms && (
				<div className="text-danger">
					{translate('CONTRACT.SERVICE.MANAGEMENT.ACCEPTANCE_OF_TERMS_AND_CONDITIONS_WARNING')}
				</div>
			)}
			<div>
				<UfinetButton
					className="mt-5 ms-3 p-5 w-auto"
					content={modalButtonsText.confirmButtonText}
					onClick={onConfirm}
					isDisabled={!acceptedTerms}
				/>
				<UfinetButton
					className="mt-5 ms-3 p-5 w-auto btn-danger"
					content={modalButtonsText.cancelButtonText}
					onClick={onCancel}
				/>
			</div>
		</div>
	)
}
export { TermsAndConditionsConfirm }
