import { faker } from '@faker-js/faker'
import { ICountry } from 'ufinet-web-functions'

export const CountryMother = {
	create: (input?: Partial<ICountry>): ICountry => ({
		id: faker.string.uuid(),
		name: faker.location.country(),
		isoShort: faker.location.countryCode({ variant: 'alpha-2' }),
		isoLong: faker.location.countryCode({ variant: 'alpha-3' }),
		...input,
	}),
	colombia: (): ICountry => ({
		id: '469B9F0F-C6BD-E511-80FB-3863BB358F90',
		name: 'Colombia',
		isoShort: 'CO',
		isoLong: 'COL',
	}),
	ireland: (): ICountry => ({
		id: 'CBDCB0BF-D492-E711-810E-70106FAA9741',
		name: 'Irlanda',
		isoShort: 'IE',
		isoLong: 'IRL',
	}),
	guatemala: (): ICountry => ({
		id: '3A9B9F0F-C6BD-E511-80FB-3863BB358F90',
		name: 'Guatemala',
		isoShort: 'GT',
		isoLong: 'GTM',
	}),
}
