// Mapping between frontend property names and backend-model user property names, for sorting/filtering
export const ContractedServicePropertyMap = new Map<string, string>([
	['administrativeCode', 'CodigoAdministrativo'],
	['serviceName', 'NombreServicio'],
	['typeOfService', 'TipoServicio'],
	['serviceAttributes', ''],
	['originSummary', 'ResumenOrigen'],
	['destinationSummary', 'ResumenDestino'],
	['state', 'RazonEstado'],
])
