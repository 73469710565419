import { useScheduledManageForm } from 'components/services/management/modal/wizard/modify-service/manage/inner/scheduled/hooks/useScheduledManageForm'
import { ContractedServiceOperationScheduled } from 'modules/contractedService/domain/management/operation/scheduled/ContractedServiceOperationScheduled'
import { FC } from 'react'
import { DatePicker, DatePickerTypeEnum } from 'ufinet-web-components'
import { useTranslator } from 'ufinet-web-functions'
import { BaseManageForm } from '../base/BaseManageForm'

const ScheduledManageForm: FC = () => {
	const translate = useTranslator()

	const { awardDate, onChange, form, errorMessage } = useScheduledManageForm()

	return (
		<>
			{errorMessage ? (
				<div className="text-danger ">{errorMessage}</div>
			) : (
				<BaseManageForm
					isValidOperationForCalculation={ContractedServiceOperationScheduled.isValidForCalculation}
					formValidationCreator={form.validator}
					additionalFields={{
						inline: (
							<div className="row row-gap-4">
								<div className="col-2 col-md-4 col-sm-12 pe-0">
									<DatePicker
										label={translate('CONTRACT.SERVICE.OPERATION.TEMPORAL.SCHEDULED.START_DATE')}
										type={DatePickerTypeEnum.DATE_ONLY}
										showCalendarIcon
										value={awardDate.value}
										onChange={onChange.awardDate}
										min={awardDate.min}
										max={awardDate.max}
									/>
								</div>
							</div>
						),
					}}
				/>
			)}
		</>
	)
}

export { ScheduledManageForm }
