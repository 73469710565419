import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IAreaResponse, ICorporateGroup, ICountry } from 'ufinet-web-functions'
import { RootState } from '../store'

export interface ICountryAndCorporateGroupWrapperState {
	countryResponse: ICountry[] | undefined
	areaResponse: IAreaResponse[] | undefined
	corporateGroupResponse: ICorporateGroup[] | undefined
}

const initialState: ICountryAndCorporateGroupWrapperState = {
	countryResponse: undefined,
	areaResponse: undefined,
	corporateGroupResponse: undefined,
}

const hardFilterWrapperSlice = createSlice({
	name: 'countryAndCorporateGroupTypeWrapper',
	initialState,
	reducers: {
		setCountryResponse(state, action: PayloadAction<ICountry[]>) {
			state.countryResponse = action.payload
		},
		setAreaResponse(state, action: PayloadAction<IAreaResponse[]>) {
			state.areaResponse = action.payload
		},
		setCorporateGroupResponse(state, action: PayloadAction<ICorporateGroup[]>) {
			state.corporateGroupResponse = action.payload
		},
	},
})

export const { setCountryResponse, setAreaResponse, setCorporateGroupResponse } = hardFilterWrapperSlice.actions
export const getCountryResponse = (state: RootState) => state.hardFilter.countryResponse
export const getAreaResponse = (state: RootState) => state.hardFilter.areaResponse
export const getCorporateGroupResponse = (state: RootState) => state.hardFilter.corporateGroupResponse

export default hardFilterWrapperSlice.reducer
