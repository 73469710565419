import { useContractedServiceManageFormContext } from 'components/services/management/modal/wizard/modify-service/manage/ContractedServiceManageFormContextProvider'
import {
	ContractedServiceManageFormParameters,
	useManageInnerFormValidation,
} from 'components/services/management/modal/wizard/modify-service/manage/inner/base/hooks/validation/useManageInnerFormValidation'
import { FormikProps } from 'formik'
import { useClientParametersFindFilteredQuery } from 'modules/client/parameters/application/find/ClientParametersFindQueries'
import { ClientParameters } from 'modules/client/parameters/domain/ClientParameters'
import { ClientParametersRepository } from 'modules/client/parameters/domain/repository/clientParametersRepository'
import { getIsInternetOrCapacity } from 'modules/contractedService/application/isInternetOrCapacity'
import { ContractedServiceDetail } from 'modules/contractedService/domain/detail/ContractedServiceDetail'
import { ContractType } from 'modules/contractedService/domain/management/operation/ContractType'
import { ManagementOperation } from 'modules/contractedService/domain/management/operation/ManagementOperation'
import { ServiceManagementErrorHandler } from 'modules/contractedService/domain/ServiceManagementErrorHandler'
import { getAllowedContractTypesFromClientParameters } from 'modules/contractTypes/application/getAllowedContractTypesFromClientParameters'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { UfinetRadioButtonOption } from 'ufinet-web-components'
import { useTranslator } from 'ufinet-web-functions'

const allowedContractTypesForDowngrade = [ContractType.IMMEDIATE]

type HookInput = {
	service: ContractedServiceDetail
	clientParameters: {
		value?: ClientParameters
		onFetched?: (clientParameters: ClientParameters) => void
	}
	onError: ServiceManagementErrorHandler
	onContractTypeChange: (ct?: ContractType) => void
	clientParametersRepository: ClientParametersRepository
}

type HookOutput = {
	contractType: {
		selected?: ContractType
		options: UfinetRadioButtonOption[]
	}
	onChange: {
		contractType?: (contractType?: UfinetRadioButtonOption) => void
	}
	form: {
		formik: FormikProps<ContractedServiceManageFormParameters>
		loading: boolean
	}
	operationAvailable: {
		immediate: boolean
		scheduled: boolean
		temporal: boolean
	}
}

function useManageInnerForm({
	service,
	clientParameters,
	onError,
	onContractTypeChange,
	clientParametersRepository,
}: HookInput): HookOutput {
	const translate = useTranslator()

	const {
		contractedServiceOperation: {
			selected: selectedOperations,
			isModifying: { bandwidth: isModifyingBandwidth, price: isModifyingPrice, deadline: isModifyingDeadline },
		},
	} = useContractedServiceManageFormContext()

	const { formik } = useManageInnerFormValidation()

	const [selectedContractType, setSelectedContractType] = useState<ContractType>()

	const allowedContractTypes: ContractType[] = useMemo(() => {
		const isDowngrade = selectedOperations.includes(ManagementOperation.DOWNGRADE)
		const allowedContractTypes =
			(isModifyingPrice || isModifyingDeadline) && !isModifyingBandwidth
				? [ContractType.IMMEDIATE]
				: getAllowedContractTypesFromClientParameters(clientParameters.value)

		// TODO: we are manually filtering the contract types available for downgrades until they are implemented
		return allowedContractTypes.filter((it) => (isDowngrade ? allowedContractTypesForDowngrade.includes(it) : true))
	}, [selectedOperations, isModifyingPrice, isModifyingDeadline, isModifyingBandwidth, clientParameters.value])

	const isInternetOrCapacity = useMemo(() => getIsInternetOrCapacity(service.serviceType?.value), [service])

	const { data: clientParametersFetched, isLoading: loadingClientParameters } = useClientParametersFindFilteredQuery(
		clientParametersRepository,
		{
			clientId: service.clientId!,
			clientType: service.clientType!,
		},
		{ onSuccess: clientParameters.onFetched, onError }
	)

	useEffect(() => {
		if (selectedContractType && !allowedContractTypes.includes(selectedContractType)) setSelectedContractType(undefined)
	}, [selectedContractType, allowedContractTypes])

	useEffect(() => {
		clientParametersFetched && clientParameters.onFetched?.(clientParametersFetched)
	}, [clientParametersFetched])

	const contractTypeOptions: UfinetRadioButtonOption[] = useMemo(
		() =>
			allowedContractTypes.map((contractType) => ({
				value: contractType,
				label: translate(`CONTRACT.SERVICE.CONTRACT.TYPE.${contractType}`),
			})),
		[translate, allowedContractTypes]
	)

	const onContractTypeSelectorChange = useCallback(
		(opt?: UfinetRadioButtonOption) => {
			const newContractType = opt?.value as ContractType
			setSelectedContractType(newContractType)
			onContractTypeChange?.(newContractType)
		},
		[onContractTypeChange]
	)

	return {
		contractType: {
			selected: selectedContractType,
			options: contractTypeOptions,
		},
		onChange: {
			contractType: onContractTypeSelectorChange,
		},
		form: {
			formik,
			loading: loadingClientParameters,
		},
		operationAvailable: {
			immediate: selectedContractType === ContractType.IMMEDIATE && isInternetOrCapacity,
			scheduled: selectedContractType === ContractType.SCHEDULED && isInternetOrCapacity,
			temporal: selectedContractType === ContractType.TEMPORAL && isInternetOrCapacity,
		},
	}
}

export { useManageInnerForm }
