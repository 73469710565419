import { FormikErrors, FormikProps } from 'formik'
import { Reason } from 'modules/contractedService/domain/ors/petition/base/ContractedServiceOrsBase'
import { OrsPetition } from 'modules/contractedService/domain/ors/petition/base/OrsPetition'
import { OrsReason } from 'modules/contractedService/domain/ors/petition/base/OrsReason'
import { ContractedServiceOrsUnifiedData } from 'modules/contractedService/domain/ors/petition/unified/ContractedServiceOrsUnified'
import { OrsStrategy } from 'modules/contractedService/domain/ors/strategies/OrsStrategy'
import { CalendarChangeParams } from 'primereact/calendar'
import { Dropdown } from 'primereact/dropdown'
import { FC } from 'react'
import { Button } from 'react-bootstrap'
import { DatePicker, DatePickerTypeEnum, UfinetInput } from 'ufinet-web-components'
import { useTranslator } from 'ufinet-web-functions'

type Props = {
	onChange: {
		modifyInput: (data: string) => void
		modifyDatePicker: (data: CalendarChangeParams) => void
		modifyReason: (data: Reason) => void
		removeService: (id: string) => void
	}
	selectedPetition: OrsPetition
	reasonOptions?: OrsReason[]
	form: FormikProps<ContractedServiceOrsUnifiedData>
	calculateMinimumDate: Date | undefined
	orsStrategy: OrsStrategy
}

const OrsUnifiedTable: FC<Props> = ({
	onChange,
	selectedPetition,
	reasonOptions,
	form,
	calculateMinimumDate,
	orsStrategy,
}) => {
	const translate = useTranslator()

	return (
		<div className="d-flex align-items-start">
			<table
				className="table mt-5"
				style={{ width: '40%' }}
			>
				<thead>
					<tr>
						<th scope="col" />
						<th scope="col">
							<b>{translate('CONTRACT.SERVICE.ADMINISTRATIVE_CODE')}</b>
						</th>
					</tr>
				</thead>
				<tbody>
					{form.values.services.map((service) => (
						<tr key={service.administrativeCode}>
							<td className="align-middle">
								<Button
									data-testid={`ors-remove-service-${service.id}`}
									size="sm"
									className="ors-close-button"
									onClick={() => onChange.removeService(service.id)}
								>
									<i className="bi bi-x-lg text-danger p-0" />
								</Button>
							</td>
							<td className="align-middle">{service.administrativeCode}</td>
						</tr>
					))}
				</tbody>
			</table>
			<table className="table mt-5">
				<thead>
					<tr>
						{orsStrategy.enableCompletionDate && (
							<th scope="col">
								<b>{translate(`CONTRACT.SERVICE.ORS.${OrsPetition[selectedPetition]}.DATE`)}</b>
							</th>
						)}
						{orsStrategy.enableReason && (
							<th scope="col">
								<b>{translate('CONTRACT.SERVICE.ORS.REASON')}</b>
							</th>
						)}
						<th scope="col">
							<b>{translate(orsStrategy.observationsLabel)}</b>
						</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						{orsStrategy.enableCompletionDate && (
							<td>
								<DatePicker
									type={DatePickerTypeEnum.DATE_ONLY}
									showCalendarIcon
									value={form.values.date}
									onChange={onChange.modifyDatePicker}
									min={calculateMinimumDate}
								/>
								{form.errors.date && <div className="invalid-feedback d-block">{form.errors.date}</div>}
							</td>
						)}
						{orsStrategy.enableReason && (
							<td>
								<Dropdown
									className="w-100"
									onChange={(e) => onChange.modifyReason(e.target.value)}
									optionLabel="name"
									options={reasonOptions}
									placeholder={translate('CONTRACT.SERVICE.ORS.SELECT_REASON')}
									showClear
									value={form.values.reason}
								/>

								{form.errors?.reason && (
									<div className="invalid-feedback d-block">{(form.errors?.reason as FormikErrors<Reason>).code}</div>
								)}
							</td>
						)}
						<td>
							<UfinetInput
								requiredIcon
								type="text"
								value={form.values.observations || ''}
								onChange={(e) => onChange.modifyInput(e.target.value)}
								solid={false}
							/>
							{form.errors.observations && <div className="invalid-feedback d-block">{form.errors.observations}</div>}
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	)
}

export { OrsUnifiedTable }
