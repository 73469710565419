import { ImmediatePricing } from 'modules/contractedService/domain/pricing/ImmediatePricing'
import { ContractedServicePricingCalculatorRepository } from 'modules/contractedService/domain/pricing/repository/ContractedServicePricingCalculatorRepository'
import { ScheduledPricing } from 'modules/contractedService/domain/pricing/ScheduledPricing'
import { TemporaryPricing } from 'modules/contractedService/domain/pricing/TemporaryPricing'
import { defaultOptionsFetch, FetchAPI, FetchOptions, IAuthData } from 'ufinet-web-functions'

const url = `${process.env.REACT_APP_URL_BACK_GSE}api/quotation/service/pricing`

export const HttpContractedServicePricingCalculatorRepository: (
	authData: IAuthData
) => ContractedServicePricingCalculatorRepository = (authData) => {
	const fetchOptions: FetchOptions = {
		...defaultOptionsFetch,
		notifyError: false,
		authenticationData: authData,
		sendApiKey: true,
	}

	return {
		getImmediateOperationPricing: async (requestData, options): Promise<ImmediatePricing> =>
			await FetchAPI.post(url + '/immediate', requestData, {
				...fetchOptions,
				...options,
			}),
		getScheduledOperationPricing: async (requestData, options): Promise<ScheduledPricing> =>
			await FetchAPI.post(url + '/scheduled', requestData, {
				...fetchOptions,
				...options,
			}),
		getTemporaryOperationPricing: async (requestData, options): Promise<TemporaryPricing> =>
			await FetchAPI.post(url + '/temporal', requestData, {
				...fetchOptions,
				...options,
			}),
	}
}
