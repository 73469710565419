import { GseI18nProvider } from 'components/common/i18n/GseI18nProvider'
import { ContractedServiceWithDetails } from 'modules/contractedService/domain/ContractedServiceWithDetails'
import { ContractedServiceOperation } from 'modules/contractedService/domain/management/operation/ContractedServiceOperation'
import { Offer } from 'modules/offer/domain/Offer'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { AuthProvider } from 'ufinet-web-components'
import { FrontEndApplication, useInternalUser } from 'ufinet-web-functions'
import { useModalButtonsText } from '../../../common/hooks/useModalButtonsText'
import { defaultSwalConfig, ModalButtonsText } from '../../../common/modals/defaultSwalConfig'
import { TermsAndConditions } from '../modals/terms-and-conditions/TermsAndConditions'
import { useInternalConfirmModal } from './useInternalConfirmModal'

type HookOutput = {
	beforeConfirm: (operationData: Partial<OperationData>, onConfirm: () => void) => Promise<boolean>
}

type OperationData = {
	offer?: Offer
	service: ContractedServiceWithDetails
	contractedServiceOperation: ContractedServiceOperation
}

const useBeforeConfirm: () => HookOutput = () => {
	const internalUser = useInternalUser()

	const { makeModalContents: makeInternalUserModalContents } = useInternalConfirmModal()
	const modalButtonsText = useModalButtonsText()

	const fireModalBeforeConfirmInternalUser = (
		title: string,
		text: string,
		buttonsText: ModalButtonsText,
		onConfirm: () => void
	) => {
		Swal.fire({
			title,
			text,
			...defaultSwalConfig(buttonsText),
			icon: 'warning',
			reverseButtons: true,
			preConfirm: () => {
				onConfirm()
			},
		})
	}

	const fireModalBeforeConfirmExternalUser = (operationData: OperationData, onConfirm: () => void) => {
		const ReactSwal = withReactContent(Swal)
		ReactSwal.fire({
			html: (
				<AuthProvider application={FrontEndApplication.GDE01}>
					<GseI18nProvider>
						<TermsAndConditions
							{...operationData}
							onCancel={() => ReactSwal.close()}
							onConfirm={() => {
								ReactSwal.close()
								onConfirm()
							}}
						/>
					</GseI18nProvider>
				</AuthProvider>
			),
			customClass: {
				htmlContainer: 'terms-and-conditions-modal-html-container',
				popup: 'terms-and-conditions-modal-popup',
			},
			allowOutsideClick: false,
			showConfirmButton: false,
			allowEscapeKey: false,
			heightAuto: false,
		})
	}

	return {
		beforeConfirm: (operationData, onConfirm: () => void) => {
			return new Promise<boolean>((resolve, reject) => {
				if (
					!operationData.service ||
					!operationData.contractedServiceOperation ||
					(operationData.contractedServiceOperation.awardsImmediately && !operationData.offer)
				) {
					reject(new Error('The operation data is not complete'))
					return
				}

				const { title, content } = makeInternalUserModalContents({
					offer: operationData.offer,
					operation: operationData.contractedServiceOperation,
				})

				internalUser
					? fireModalBeforeConfirmInternalUser(title, content, modalButtonsText, () => {
							onConfirm()
							resolve(true)
					  })
					: fireModalBeforeConfirmExternalUser(operationData as OperationData, () => {
							onConfirm()
							resolve(true)
					  })
			})
		},
	}
}

export { useBeforeConfirm }
