import { useTranslator } from 'ufinet-web-functions'
import { useModalButtonsText } from '../../common/hooks/useModalButtonsText'
import { fireModalBeforeCloseModifyService } from '../modals/beforeCloseModifyServiceModal'

const useBeforeCloseModifyService: () => {
	beforeCloseModifyService: (onConfirm: () => void) => Promise<boolean>
} = () => {
	const translate = useTranslator()
	const modalButtonsText = useModalButtonsText()

	return {
		beforeCloseModifyService: (onConfirm: () => void) => {
			const title = translate('CONTRACT.SERVICE.MANAGEMENT.WIZARD.MODIFY_SERVICE.CANCEL_MANAGEMENT')
			const text = translate('CONTRACT.SERVICE.MANAGEMENT.WIZARD.MODIFY_SERVICE.CANCEL_MANAGEMENT_MESSAGE')

			fireModalBeforeCloseModifyService(title, text, modalButtonsText, onConfirm)

			return new Promise<boolean>((resolve) => {
				resolve(false)
			})
		},
	}
}
export { useBeforeCloseModifyService }
