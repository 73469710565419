import { ConfirmResume } from 'components/services/management/modal/wizard/modify-service/confirm-resume/ConfirmResume'
import { EndContainer } from 'components/services/management/modal/wizard/modify-service/end/EndContainer'
import { ContractedService } from 'modules/contractedService/domain/ContractedService'
import { ContractType } from 'modules/contractedService/domain/management/operation/ContractType'
import { managementOperationsFromSelectedOptions } from 'modules/contractedService/domain/management/operation/helpers/managementOperationFromSelectedOptions'
import { ContractedServiceDetailRepository } from 'modules/contractedService/domain/repository/ContractedServiceDetailRepository'
import { Offer } from 'modules/offer/domain/Offer'
import { OfferRepository } from 'modules/offer/domain/repository/OfferRepository'
import { FC, useMemo, useRef } from 'react'
import {
	IWizardInterface,
	Label,
	Loading,
	WizardButtonClose,
	WizardButtonNext,
	WizardButtonPrev,
	WizardButtonsWrapper,
	WizardVertical,
	WizardVerticalHandle,
} from 'ufinet-web-components'
import { useTranslator } from 'ufinet-web-functions'
import { ContractedServiceDetailTable } from '../../detail/table/ContractedServiceDetailTable'
import { useContractedServiceManageModal } from './hooks/useContractedServiceManageModal'
import { ContractedServiceManageForm } from './wizard/modify-service/manage/ContractedServiceManageForm'

type Props = {
	service: ContractedService
	contractedServiceDetailRepository: ContractedServiceDetailRepository
	offerRepository: OfferRepository
	closeModal: () => void
	onOfferCreated?: (offer: Offer) => void
	onOfferConfirmed?: () => void
}

const ContractedServiceManageModal: FC<Props> = ({
	service,
	contractedServiceDetailRepository,
	offerRepository,
	closeModal,
	onOfferCreated,
	onOfferConfirmed,
}) => {
	const translate = useTranslator()

	const wizardRef = useRef<WizardVerticalHandle>(null)

	const {
		wizard,
		contractedService,
		managementOperations,
		contractType,
		clientParameters,
		contractedServiceOperation,
		offer,
		operationSuccess,
		onEditing,
		onChange,
		onError,
		form,
		loading,
	} = useContractedServiceManageModal({
		wizardRef,
		service,
		offer: { repository: offerRepository, onCreated: onOfferCreated, onConfirmed: onOfferConfirmed },
		closeModal,
	})

	const contentArray = useMemo<IWizardInterface[]>(
		() => [
			{
				stepperTitle: translate('CONTRACT.SERVICE.MANAGEMENT.WIZARD.TODO_OPERATION.TITLE'),
				stepperDescription: translate('CONTRACT.SERVICE.MANAGEMENT.WIZARD.TODO_OPERATION.DESC'),
				stepperComponent: (
					<div className="container p-8 h-100 d-flex flex-column justify-content-between">
						<ContractedServiceDetailTable
							allowOperationTypeChange
							contractedServiceManagementOperations={managementOperations.value}
							incompatibleOperations={managementOperations.incompatibleOperations}
							invalidDueToExpiredService={managementOperations.invalidDueToExpiredService}
							contractedServiceDetailRepository={contractedServiceDetailRepository}
							onOperationTypeChange={(managementTypes) =>
								onChange.managementOperations(managementOperationsFromSelectedOptions(managementTypes))
							}
							onServiceDetailsFetched={onChange.contractedService.details}
							serviceData={service.id}
							wizardRef={wizardRef}
						/>
						<WizardButtonsWrapper alignment="right">
							<WizardButtonClose wizardRef={wizardRef} />
							<WizardButtonNext
								wizardRef={wizardRef}
								isDisabled={!wizard.flow.canContinueToForm}
							/>
						</WizardButtonsWrapper>
					</div>
				),
			},
			{
				stepperTitle: translate('CONTRACT.SERVICE.MANAGEMENT.WIZARD.MODIFY_SERVICE.TITLE'),
				stepperDescription: translate('CONTRACT.SERVICE.MANAGEMENT.WIZARD.MODIFY_SERVICE.DESC'),

				stepperComponent: (
					<div className="container p-8 h-100 d-flex flex-column justify-content-between">
						{!loading.calculate && contractedService.details ? (
							<>
								<ContractedServiceDetailTable
									allowOperationTypeChange={false}
									incompatibleOperations={managementOperations.incompatibleOperations}
									invalidDueToExpiredService={managementOperations.invalidDueToExpiredService}
									contractedServiceDetailRepository={contractedServiceDetailRepository}
									contractedServiceManagementOperations={managementOperations.value}
									serviceData={contractedService.details}
									summary
									wizardRef={wizardRef}
								/>
								<ContractedServiceManageForm
									service={{ ...contractedService.details, ...contractedService.base }}
									clientParameters={{ value: clientParameters, onChange: onChange.clientParameters }}
									contractType={{ value: contractType, onChange: onChange.contractType }}
									contractedServiceOperation={{
										selected: managementOperations.value,
										value: contractedServiceOperation,
										onChange: onChange.contractedServiceOperation,
									}}
									onEvent={{
										onError,
										onEditing,
										onFormChange: form.onChange,
									}}
								/>

								<WizardButtonsWrapper alignment="right">
									<WizardButtonPrev wizardRef={wizardRef} />
									<WizardButtonClose
										wizardRef={wizardRef}
										beforeContinue={() => wizard.flow.beforeCloseModifyService(closeModal)}
									/>
									<WizardButtonNext
										wizardRef={wizardRef}
										isDisabled={!wizard.flow.canContinueToConfirmation}
										beforeContinue={wizard.flow.beforeContinueToConfirmation}
									/>
								</WizardButtonsWrapper>
							</>
						) : (
							<>
								<div
									className="position-relative h-100 d-flex justify-content-center"
									style={{ minHeight: '15rem' }}
								>
									<Loading userClasses="pt-2" />
									<div className="mt-auto me-8 pb-10">
										<Label
											labelTitle={translate('CONTRACT.SERVICE.MANAGEMENT.WIZARD.MODIFY_SERVICE.LOADING_MESSAGE')}
											className="fw-bolder"
										/>
									</div>
								</div>
							</>
						)}
					</div>
				),
			},
			{
				stepperTitle: translate('CONTRACT.SERVICE.MANAGEMENT.WIZARD.CONFIRM.TITLE'),
				stepperDescription: translate('CONTRACT.SERVICE.MANAGEMENT.WIZARD.CONFIRM.DESC'),
				stepperComponent: (
					<>
						{operationSuccess !== undefined && contractedServiceOperation && offer && contractType ? (
							<>
								<EndContainer
									operation={contractedServiceOperation}
									isSuccess={operationSuccess}
									offer={offer}
								/>
								<WizardButtonsWrapper alignment="right">
									<WizardButtonNext
										wizardRef={wizardRef}
										buttonMessageId="CONTRACT.SERVICE.MANAGEMENT.WIZARD.END.END_BUTTON"
										beforeContinue={wizard.flow.beforeContinueToFinish}
									/>
								</WizardButtonsWrapper>
							</>
						) : loading.confirm ? (
							<div
								className="position-relative h-100 d-flex justify-content-center"
								style={{ minHeight: '15rem' }}
							>
								<Loading userClasses="pt-2" />
							</div>
						) : contractedService.details &&
						  managementOperations &&
						  contractType &&
						  contractedServiceOperation &&
						  (offer.value || !offer.awardsImmediately) ? (
							<ConfirmResume
								contractedServiceDetail={contractedService.details}
								contractedServiceOperation={contractedServiceOperation}
								contractType={contractType}
								offer={offer.value}
							>
								<WizardButtonsWrapper alignment="right">
									<WizardButtonClose
										wizardRef={wizardRef}
										beforeContinue={() =>
											wizard.flow.beforeCloseConfirmResume({ offer: offer.value, onConfirm: closeModal })
										}
									/>
									<WizardButtonNext
										buttonMessageId={
											contractType === ContractType.IMMEDIATE
												? 'CONTRACT.SERVICE.MANAGEMENT.WIZARD.STEP.BUTTON.AWARD'
												: 'CONTRACT.SERVICE.MANAGEMENT.WIZARD.END.END_BUTTON'
										}
										beforeContinue={wizard.flow.beforeContinueToEndScreen}
									/>
								</WizardButtonsWrapper>
							</ConfirmResume>
						) : loading.confirm ? (
							<>
								<div
									className="position-relative h-100 d-flex justify-content-center"
									style={{ minHeight: '15rem' }}
								>
									<Loading userClasses="pt-2" />
								</div>
							</>
						) : undefined}
					</>
				),
			},
		],
		[
			translate,
			managementOperations,
			contractedServiceDetailRepository,
			onChange,
			service.id,
			wizard.flow,
			loading.calculate,
			loading.confirm,
			contractedService.details,
			contractedService.base,
			clientParameters,
			contractType,
			contractedServiceOperation,
			onError,
			onEditing,
			form.onChange,
			operationSuccess,
			offer,
			closeModal,
		]
	)

	return (
		<WizardVertical
			content={contentArray}
			ref={wizardRef}
			closeFn={closeModal}
		/>
	)
}

export { ContractedServiceManageModal }
