import { ExceptionManagementTable } from 'components/services/management/exception/table/ExceptionManagementTable'
import { HttpOrsServiceExceptionRepository } from 'modules/contractedService/infrastructure/ors/exception/HttpOrsServiceExceptionRepository'
import { FC, useContext, useMemo } from 'react'
import { UfinetBox } from 'ufinet-web-components'
import { AuthContext } from 'ufinet-web-functions'

const ExceptionManagementPage: FC<{}> = () => {
	const authData = useContext(AuthContext)
	const orsServiceExceptionRepository = useMemo(() => HttpOrsServiceExceptionRepository(authData), [authData])

	return (
		<UfinetBox>
			<ExceptionManagementTable orsServiceExceptionRepository={orsServiceExceptionRepository} />
		</UfinetBox>
	)
}
export { ExceptionManagementPage }
